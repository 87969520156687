<template>
  <div class="cont_barter" v-if="items.length">
    <div class="mb-1 title_simple val-mid" :class="{ 'ml-10px': scr == 1 }"><ui-icon name="i-exchange" size="l" class="po-re top-1n" /> Дараах машинаар солино</div>
    <ui-reflow>
      <div class="cont_items" :class="{ horizontal }">
        <template v-for="(item, ind) in items">
          <span v-if="showAll || ind < 6" :key="'barter' + ind" class="item" @click="clickedItem(item, ind)" :class="{ clicked_item: ind == barterCarClickedInd, all: item.model == 99999999, bigItems }">
            {{ item.name }}<span class="count" v-if="item.count">{{ item.count }}</span>
          </span>
        </template>
        <span v-if="!showAll && items.length > 5" class="link ml-3" @click="showAll = true">цааш...</span>
        <span v-if="!showAllCars && showAll && items.length > 5" class="link ml-3" @click="showAll = false">хураах</span>
      </div>
    </ui-reflow>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "barter-car",
  components: {},
  props: { showAllCars: Boolean, horizontal: Boolean, bigItems: Boolean },
  data() {
    return {
      items: [],
      showAll: this.showAllCars ? this.showAllCars : false,
    };
  },
  methods: {
    clickedItem(item, ind) {
      this.barterCarClickedInd = ind;
      // this.$router.push({ name: "zarView", query: { o: 1, z: item.zid } });
      // this.$bus.emit("openModal", { name: "barterList", dataSet: item });
      this.$emit("clickedItem", item);
    },
    async fetchItems() {
      return await this.axiosGet("/module/barter/getBarterCar/", []);
    },
    prepare(items) {
      let models = [];
      items.forEach((i) => {
        let find = models.find((m) => m.model == i.model);
        if (!find) models.push({ model: i.model, name: i.name, count: 1 });
        else find.count++;
      });
      this.items = Object.freeze(this.sortArr(models, "count", 2));
      // this.items = this.sortArr(models, "count", 2);
      // this.items.push({ model: 99999999, name: "Ямар машин байна сонирхьёо", count: 0 });
    },
  },
  watch: {},
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    barterCarClickedInd: {
      get() {
        return this.$store.state.barterCarClickedInd;
      },
      set(val) {
        this.$store.commit("barterCarClickedInd", val);
      },
    },
  },
  async mounted() {
    this.prepare(await this.fetchItems());
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_barter {
  position: relative;
  max-width: 100vw;
  /* padding-left: 10px; */
}
/* .item {
  width: fit-content;
  border-radius: 15px;
  margin: 0 5px 5px 0 !important;
  display: inline-block;
  background: #fafafa;
  border: solid 1px #ccc;
  font-weight: 430;
  transition: all 100ms;
  white-space: nowrap;
  font-stretch: 100%;
  padding: 6px 10px 4px 10px;
  line-height: 1;
  font-size: 12px;
} */
.item {
  display: inline-block;
  cursor: pointer;
  margin: 0px 0px 5px 5px;
  border: solid 1px #ccc;
  /* background-color: #60e4ee; */
  transition: all 0.15s;
  border-radius: 15px;
  /* color: #fff; */
  /* font-family: "Regular"; */
  /* text-transform: uppercase; */
  user-select: none;
  position: relative;
  /* font-stretch: 95%; */
  font-weight: 450;
  /* gap: 5px; */
  /* line-height: 1; */
  /* letter-spacing: 0.03em; */
  /* line-height: 1.4em; */
  /* text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3); */
  white-space: nowrap;
  /* font-size: 13px; */
  padding: 4px 5px;
  line-height: 1;
  /* line-height: 1; */
}
.bigItems {
  font-size: 16px !important;
  padding: 9px 15px 7px 15px !important;
  border-radius: 18px !important;
  margin-right: 5px !important;
  margin-bottom: 0 !important;
}
.item:hover {
  cursor: pointer;
  border: solid 1px #a1a1a1;
  background: #fafafa;
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgb(232, 252, 255) 100%); */
}

.item .count {
  position: relative;
  font-weight: 300;
  font-size: 10px;
  left: 3px;
  color: #757575;
  top: 0px;
}

.item.clicked_item .count {
  opacity: 0.5;
}

.item.clicked_item {
  /* background: #f1f1f1; */
  border: solid 1px var(--color_selected);
  background-color: var(--color_selected);
}

/* .title {
  font-stretch: 92%;
} */
.all {
  font-weight: 700 !important;
}

.cont_items.horizontal {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 0px;
  width: 100vw;
  max-width: 100%;
  /* background-color: #f1f1f1 !important; */
  -webkit-overflow-scrolling: touch;
  /* padding-top: 7px; */
}
.cont_items.horizontal::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 701px) {
  .item {
    padding: 6px 10px 4px 10px;
    line-height: 1;
    font-size: 12px;
  }
  .item .count {
    top: 0px;
  }
}
@media only screen and (max-width: 700px) {
  .item {
    padding: 6px 10px 4px 10px;
    line-height: 1;
    font-size: 14px;
  }
  .item .count {
    top: -1px;
  }
}
</style>
