<template>
  <div>
    <ui-select-multi v-if="sitems.length" :items="sitems" :unit="unit" :loading="loading" :collect="scollect" :zind="zind" :max="maxSel" :forceSel="forceSel" v-on:clickedItem="clickedItem($event)" v-on:selection="$emit('selection', $event)" v-on:hideProp="$emit('hideProp', $event)" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectMulti from "./selectMulti.vue";

export default {
  name: "ui-select-value",
  components: {
    uiSelectMulti,
  },
  props: {
    unit: String,
    loading: Boolean,
    zind: Number,
    forceSel: Number,
    min: Number,
    maxSel: Number,
    max: [Number, String],
    step: Number,
    cusVals: Array,
    sort: Number,
    com: Number,
    collect: { type: [String, Object, Boolean, Number, Array] },
  },
  data() {
    return {
      // sitems: null,
      scollect: [],
    };
  },
  methods: {
    clickedItem(arr) {
      this.scollect = arr;
      this.$emit("clickedItem", arr);
    },
  },
  computed: {
    sitems() {
      let prep = [];

      if (this.cusVals) {
        this.cusVals.forEach((e, i) => {
          prep.push({ id: i, name: e.val, com: e.com });
        });
        return prep;
      }

      let max = this.max == "999999" ? new Date().getFullYear() : this.max;

      for (let i = this.min; i <= max; i += this.step ?? 1) {
        prep.push({ id: i, name: this.step && !Number.isInteger(this.step) ? i.toFixed(1) : i, com: this.com });
      }
      if (this.sort) this.sortArr(prep, "name", this.sort);
      return prep;
    },
  },
  watch: {
    collect: {
      immediate: true,
      handler() {
        if (!this.collect) {
          this.scollect = "";
        }
      },
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
