<template>
  <div class="cont_web_stats mt-2 gray-5 fs-12">
    <span v-for="(s, ind) in stats" :key="'web_stats' + ind">
      <!-- <span v-if="s.name == 'online'">Онлайн {{ JSON.parse(s.value).devices }} хүн, </span> -->
      <span v-if="s.name == 'zar_today'">Сүүлийн 24 цагт +{{ s.value }} зар</span>
    </span>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "main-web-stats",
  data() {
    return {
      stats: null,
    };
  },
  methods: {
    fetch() {
      setTimeout(async () => {
        let res = await this.axios.get("/utility/stats/getWebStats", {});
        this.stats = res.data;
      }, 2000);
    },
  },
  watch: {},
  computed: {},
  mounted() {
    this.fetch();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_web_stats {
}
</style>
