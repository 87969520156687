<template>
  <div class="cont_ui_tab">
    <span v-for="(t, k) in items" :key="'tab' + k">
      <div class="item" @click="clickedItem(t)" :class="{ active: actTab == t.name, first: k == 0, last: k == items.length - 1, with_icon: t.icon }">
        <ui-icon v-if="t.icon" :name="t.icon" size="xxl" />{{ scr != 4 ? t.text : t.textLong }}<span class="count" v-if="t.count">{{ t.count }}</span>
      </div>
      <!-- <span class="seperator" v-if="k != items.length - 1">.</span> -->
    </span>
    <!-- <div class="hori mt-3"></div> -->
    <div class="content"><slot /></div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-tab2",
  props: { items: Array, actTab: String },

  components: {},
  data() {
    return {};
  },
  methods: {
    clickedItem(ob) {
      this.$emit("clickedItem", ob.name);
    },
  },
  watch: {
    // auto select first tab
    // items: {
    //   immediate: true,
    //   handler() {
    //     this.clickedItem(this.items.first());
    //   },
    // },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_tab {
  margin: 10px 0 15px 0;
  clear: both;
  position: relative;
  gap: 5px;
  text-align: center;
}
.item {
  display: inline-block;
  padding: 6px 12px 4px 12px;
  font-size: 14px;
  border: solid 2px transparent;
  line-height: 1.2;
  border-radius: 5px;
  position: relative;
  transition: all 0.08s;
  background-color: #f1f1f1;
  /* border: solid 1px #d4d4d4; */
  transition: all 100ms;
  margin-bottom: 8px;
  /* font-stretch: 100%; */
  /* border-top-left-radius: 3px;
  border-top-right-radius: 3px; */
  margin-right: 10px;
  /* border-right: solid 1px #e6e6e6; */
  /* border-left: none; */
  transition: all 150ms;
  font-weight: 500;
}
.item.with_count {
  padding-right: 35px;
}
.item:hover {
  cursor: pointer;
  /* border: solid 1px #ccc; */
}

.item img {
  position: absolute;
  top: 3px;
  left: 6px;
}
.count {
  font-size: 11px;
  opacity: 0.8;
  margin-left: 4px;
  margin-top: 1px;
  line-height: 1;
  font-weight: 400;
}

/* .item.first {
  border-top-left-radius: 3px;
  border-left: solid 1px #ccc;
}
.item.last {
  border-top-right-radius: 3px;
  border-right: solid 1px #ccc;
} */
.with_icon {
  padding-left: 30px !important;
}
.active {
  /* border: solid 2px var(--color_blue); */
  /* font-weight: 700; */
  background-color: chartreuse;
}

.active:hover {
  cursor: default;
  /* border: solid 1px #adadad; */

  /* background-color: #f1f1f1; */
}
.content {
  margin: 10px 0;
}

.hori {
  /*transform: scaleX(2); */
  content: "...";
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 80%;
  height: 1px;
  background-color: #f1f1f1;
  font-size: 20px;
}

@media only screen and (max-width: 700px) {
  .cont_ui_tab {
    text-align: center;
  }
}
</style>
