<template>
  <div class="cont_ui_input">
    <!--       min-width:        v-model="text" -->
    <input
      ref="info"
      :value="text"
      @input="(evt) => (text = evt.target.value)"
      :disabled="disabled"
      :style="{ width: text.length + 'ch', minWidth: minWidth + 'ch' }"
      :type="tp != 'password' ? tp : showPass ? 'text' : 'password'"
      v-mask="{ mask: pat, regex, autoUnmask: unmask, greedy: false }"
      v-on:keyup="maskCheckKeyup"
      @change="maskCheckEnter"
      @paste="maskCheckPaste"
      @keyup.enter="maskCheckEnter"
      :maxlength="max"
      @focus="showGuide = true"
      :class="{ active, disabled }"
    />{{ unit }} <ui-icon v-if="active" @click="reset()" name="i-close3" class="reset clickable ani_pop" size="xxl" /> <img v-if="tp == 'password' && text.length" class="eye" @click="showPass = !showPass" src="/icon/i-eye.svg" width="12" />
    <span class="counter" v-if="mask && mask.max" v-text="ctext" :style="{}"></span><br />
    <ui-reflow>
      <div v-if="showGuide && min" class="guide">Хамгийн багадаа {{ min }} тэмдэгт байна</div>
      <div v-if="showGuide && guide" class="guide">{{ guide }}</div>
    </ui-reflow>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-input",
  props: {
    collect: { type: [String, Object, Boolean, Number, Array] },
    unit: String,
    mask: Object,
    unmask: Boolean,
    guide: String,
    pat: String,
    min: { type: Number, default: () => 1 },
    max: { type: Number, default: () => 50 },
    regex: String,
    disabled: Boolean,
    tp: { type: String, default: () => "text" },
    minWidth: { type: Number, default: () => 20 },
    autoFocus: { type: Boolean, default: () => true },
    emitEnter: { type: Boolean, default: () => false },
    active: { type: Boolean, default: () => false },
    autoType: { type: [String, Number] },
  },
  data() {
    return {
      timeout: null,
      showGuide: false,
      text: "",
      showPass: false,
    };
  },
  methods: {
    maskCheckPaste() {
      setTimeout(
        () => {
          if (this.min <= this.text.length) this.$emit("typed", this.text);
        },
        this.collect ? 100 : 1000
      );
    },
    test() {
      // console.log("TESTING WORKS");
    },
    reset() {
      this.text = "";
      this.$emit("typed", this.text);
    },
    maskCheckKeyup(field) {
      // console.log(this.min, this.text.length);

      if (!this.text || this.min > this.text.length) {
        this.$emit("typed", "");
        return;
      }
      if (this.emitEnter || !this.qualified(field)) return;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.$emit("typed", this.text), this.collect ? 100 : 1000);
    },
    maskCheckEnter(field) {
      if (!this.emitEnter || !this.qualified(field)) return;
      this.$emit("typed", this.text);
    },
    qualified(field) {
      return field.target.inputmask.isComplete() && this.text.length >= this.min && this.text && this.text != 0;
    },
  },

  watch: {
    collect() {
      if (this.collect && this.collect != this.text) this.text = this.collect;
    },
    autoType: {
      immediate: true,
      handler() {
        if (this.autoType) {
          this.text = this.autoType;
          this.$emit("typed", this.text);
        }
      },
    },
  },
  computed: {
    ctext() {
      let ctext = this.max - this.text.length;
      this.min >= this.text.length && this.text.length && (ctext = "Арай богино байна");
      return ctext;
    },
  },
  mounted() {
    if (this.autoFocus) this.$refs.info.focus();
    if (this.collect && this.collect != this.text) this.text = this.collect;
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_input {
  box-sizing: border-box;
}
.cont_ui_input input {
  margin: 0 5px 5px 0;
  max-width: 90%;
  box-sizing: border-box;
}
.cont_ui_input input:focus + .guide {
  display: block;
}
.counter {
  color: grey;
  font-size: 12px;
  position: relative;
  top: -1px;
}
.empty {
  background-color: #fffde6;
}

.active {
  font-weight: 600;
}

.reset {
  position: relative;
  top: 3px;
  opacity: 0.6;
}

.disabled {
  opacity: 0.8;
}

@keyframes glow {
  from {
    color: #001aff;
  }
  to {
    color: #777;
  }
}

@media only screen and (max-width: 700px) {
  input {
    margin: 0 5px 5px 0;
    max-width: 90%;
  }
}
</style>
