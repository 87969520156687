<template>
  <component :is="tag">
    <slot />
  </component>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import smoothReflow from "vue-smooth-reflow";
export default {
  name: "SmoothReflow",
  mixins: [smoothReflow],
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    tag: {
      type: String,
      default: "div",
    },
  },
  data() {
    return {
      options: {
        property: this.disabled ? [] : ["height", "width", "opacity"],
        transition: "height .15s linear, width .15s linear",
      },
    };
  },
  mounted() {
    this.$smoothReflow(this.options);
  },
};
</script>
