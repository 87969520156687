import mainHome from "@/comp/page/home/index.vue";
import zarView from "@/comp/page/zarView/index.vue";
import zarAdd from "@/comp/page/zarAdd/index.vue";
import userHome from "@/comp/page/userHome/index.vue";
import noPage404 from "@/comp/page/noPage404.vue";

//KEEP ALIVE SETTING HAMAAGUI BITGII OROLDOOROI!!! WEBIIG UZEH TUSAM UDASHRAH LAGTAH. ZAR UZCHIHEED BUTSAH TOVCH DARAH UED FILTERDSEN LISTRUU SHUUD RESET HIIGDEH ZEREG BUG ILERCH BAISAN

export default [
  {
    name: "home",
    path: "/",
    // path: "/home",
    meta: {
      title: "Нүүр хуудас",
      keepAlive: "base",
    },
    component: mainHome,
  },
  {
    name: "userHome",
    path: "/user/home",
    component: userHome,
    meta: {
      title: "Хэрэглэгчийн булан",
      keepAlive: false,
    },
  },
  {
    name: "zarAdd",
    path: "/zar/add",
    component: zarAdd,
    meta: {
      title: "Зар оруулах",
      keepAlive: "base",
    },
  },
  {
    name: "zarView",
    path: "/zar/view",
    // path: "/zar/:obj/:zid/",
    component: zarView,
    props: true,
    meta: {
      keepAlive: "base",
      dynaWidth: true,
    },
  },
  { path: "*", name: "noPage404", component: noPage404 },
];

// function lazyLoad(view) {
//   return () => import(`@/comp/${view}.vue`);
// }

// export default [
//   { path: "*", name: "noPage404", component: lazyLoad("page/noPage404") },
//   {
//     path: "/",
//     name: "home",
//     meta: {
//       title: "Нүүр хуудас",
//       keepAlive: "base",
//     },
//     component: lazyLoad("page/home/index"),
//   },
//   {
//     name: "zarAdd",
//     path: "/zar/add",
//     component: lazyLoad("page/zarAdd/index"),
//     meta: {
//       title: "Зар оруулах",
//       keepAlive: "base",
//     },
//   },
//   {
//     name: "zarView",
//     path: "/zar/view",
//     component: lazyLoad("page/zarView/index"),
//     props: true,
//     meta: {
//       keepAlive: false,
//       dynaWidth: true,
//     },
//   },

//   {
//     name: "userHome",
//     path: "/user/home",
//     component: lazyLoad("page/userHome/index"),
//     meta: {
//       title: "Хэрэглэгчийн булан",
//       keepAlive: "base",
//     },
//   },
// ];
