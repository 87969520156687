<template>
  <div class="cont_no_page">
    <div>
      <img src="/icon/i-404.svg" />
      <div class="no_page_message">ХУУДАС ОЛДСОНГҮЙ...</div>
      <zar-list-home-front />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarListHomeFront from "@/comp/page/home/zarListFront.vue";

export default {
  name: "noPage404",
  components: {
    zarListHomeFront,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_no_page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 90vh; */
  width: 100%;
}

.cont_no_page div {
  width: 100%;
  text-align: center;
}
.cont_no_page img {
  max-width: 350px;
}

.no_page_message {
  padding-bottom: 100px;
}
</style>
