<template>
  <div class="cont_buy_list mb-5">
    <div class="mt-3 mb-1 title_simple val-mid" :class="{ 'ml-10px': scr == 1 }"><ui-icon name="i-chat" size="l" class="po-re top-1n" /> {{ obj.name }} худалдаж авна</div>
    <div v-if="items.length" class="cont_list mb-2" :class="{ horizontal_items: horizontal }">
      <div v-for="(i, ind) in items" :key="'buy_list' + ind" class="buy_item" :class="{ border: !horizontal && ind != items.length - 1, horizontal_sub: horizontal }">
        {{ i.body }}
        <div class="date">
          <a :href="'tel:' + i.phone" class="phone link">{{ i.phone }}</a>
          <br v-if="horizontal" />
          <span>{{ $date.unix(i.cdate).fromNow(true) }}</span>
        </div>
        <span v-if="scr == 1 && ind != 0" class="horizontal_sep"></span>
      </div>
    </div>
    <span @click="clickedAddBuy()" class="link pa-1  pl-1" :class="{ 'ml-10px': scr == 1 }">{{ obj.name + " худалдаж авах зар нэмэх" }}</span>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "buy-list",
  components: {},
  props: {
    obj: Object,
    horizontal: Boolean,
  },
  data() {
    return {
      items: [],
      pageLimit: 30,
      pageNum: 1,
      totalPage: 0,
    };
  },
  methods: {
    clickedAddBuy() {
      this.$bus.emit("openDrawer", { name: "addBuy" });
    },
    close() {
      this.$emit("close");
    },
    async fetchItems() {
      if (!this.obj) return;

      let res = await this.axiosGet("/module/buy/getBuyList", [this.obj.id, this.pageLimit]);
      this.items = Object.freeze(res);
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
  },
  watch: {
    obj() {
      if (this.obj) this.fetchItems();
    },
  },
  mounted() {
    this.fetchItems();
    this.$bus.on("refreshBuyList", this.fetchItems);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_buy_list {
}
.cont_list {
  max-height: 400px;
  overflow-x: hidden;
  padding: 0px 10px;
  overflow-y: scroll;
  /* text-align: left;
  padding: 10px;
  padding-top: 0;
  overflow-y: scroll;
  max-height: 100%;
  position: relative; */
  /* border: solid 1px #dbdbdb; */
  border-radius: 5px;
  position: relative;
  background-color: #fdf7dc;
  border-radius: 5px;
}
.buy_item {
  padding: 8px 0;
  color: #000 !important;
  font-size: 12px;
  font-weight: var(--base_weight);
  line-height: 1.3;
}
.border {
  border-bottom: dashed 1px #ccc;
}
.buy_item .phone {
  /* font-size: smaller; */
}
.buy_item .date {
  text-align: left;
}
.buy_item .date span {
  color: #9e9e9e;
  font-size: 11px;
  margin-left: 6px;
}

.horizontal_sep {
  position: absolute;
  height: 60%;
  width: 1px;
  background-color: #d8d8d8;
  transform: translateY(-50%);
  top: 50%;
  margin-left: -12px;
}

.cont_buy_list .horizontal_items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  max-width: 94vw;
  -webkit-overflow-scrolling: touch;
}

/* @media only screen and (max-width: 700px) {
  .cont_buy_list .horizontal_items {
    gap: 30px;
  }
} */

.cont_buy_list .horizontal_items::-webkit-scrollbar {
  display: none;
}
.cont_buy_list .horizontal_sub {
  flex: 0 0 auto;
  max-width: 150px;
  word-wrap: anywhere;
}
</style>
