<template>
  <div>
    <ui-select
      :items="items"
      :forceSel="forceSel"
      :loading="loading"
      :collect="collect"
      :zind="zind"
      v-on:clickedItem="clickedItem($event)"
      v-on:mouseOver="mouseOver($event)"
      v-on:mouseLeave="mouseLeave($event)"
      v-on:mouseEnter="mouseEnter($event)"
      v-on:selection="selection = $event"
      disableMobileMode
      :compName="compName"
    />
    <ui-map v-if="map && selection && items.length > 1" :geoJson="geoJson" :iniZoom="iniZoom" :iniCenter="iniCenter" v-on:zoomUpdated="zoomUpdated($event)" :controlFullscreen="false" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelect from "@/comp/ui/select.vue";
import uiMap from "@/comp/ui/map.vue";
export default {
  name: "ui-loc-select",
  props: {
    items: Array,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    zind: Number,
    iniZoom: Number,
    iniCenter: Array,
    forceSel: Number,
    geoJson: Object,
    map: Boolean,
    compName: String,
  },
  components: {
    uiSelect,
    uiMap,
  },
  data() {
    return {
      selection: false,
    };
  },
  methods: {
    clickedItem(ob) {
      this.$emit("clickedItem", ob);
    },
    mouseOver(ob) {
      this.$emit("mouseOver", ob);
    },
    mouseLeave(ob) {
      this.$emit("mouseLeave", ob);
    },
    mouseEnter(ob) {
      this.$emit("mouseEnter", ob);
    },
    zoomUpdated(num) {
      this.$emit("zoomUpdated", num);
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
