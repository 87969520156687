<template>
  <div class="cont_zar_sort">
    <!-- <ui-sort :items="sort" :hidden="hidden" :disabled="zarItems && zarItems.length <= 1 ? true : false" v-on:clickedItem="clickedItem($event)" /> -->
    <ui-sort-simple v-if="scr < 3" :items="sortMapped" :hidden="hidden" v-on:clickedItem="clickedItem($event.id, $event.dir)" showCloseButton :collect="sortSimpleCollect" />
    <ui-sort v-else :items="sort" :hidden="hidden" v-on:clickedItem="clickedItem($event.id, $event.action)" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSort from "@/comp/ui/sort";
import uiSortSimple from "@/comp/ui/sortSimple";

import { mapState } from "vuex";
export default {
  components: {
    uiSort,
    uiSortSimple,
  },
  name: "zar-sort",
  data() {
    return {
      hidden: [],
    };
  },
  methods: {
    autoSelectSort() {
      let sort = this.$route.query.s;
      let det = sort.split(".");
      this.clickedItem(det[0], det[1]);
      this.$route.query.s = null;
    },
    clickedItem(id, dir) {
      if (dir == 2) id = id - 1000;

      if (this.hidden.find((e) => e == id)) return;
      let find = this.sort.find((e) => e.id == id && e.sel);
      if (!find) this.clearSort();
      this.sort.find((e) => {
        if (e.id == id) {
          if (dir) {
            e.sel = dir;
          } else {
            if (!e.sel) e.sel = 1;
            else if (e.sel == 1) e.sel = 2;
            else if (e.sel == 2) e.sel = 0;
          }
        }
      });
    },
    removeSort(id) {
      this.sort.find((e) => {
        if (e.id == id) {
          e.sel = 0;
        }
      });
    },
    clearSort() {
      this.sort.forEach((e) => (e.sel = 0));
    },
    async getSort(obj) {
      return await this.axios
        .get("/zar/sort/getSort/" + obj)
        .then((res) => {
          let data = res.data;
          data.forEach((e) => {
            this.$set(e, "sel", 0);
          });
          return data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {},
  computed: {
    ...mapState({
      obj: (s) => s.selObj,
      zarItems: (s) => s.zar.items,
      filter: (s) => s.zar.filter.filter,
    }),
    //...mapGetters("zar/filter", ["filter"]),
    sort: {
      get() {
        return this.$store.state.zar.sort;
      },
      set(val) {
        this.$store.commit("zar/sort", val);
      },
    },
    sortMapped() {
      return this.sort.reduce((res, current) => {
        let first = { id: current.id, dir: 1, name: current.custom ? current.custom[0] : current.name + " - өсөхөөр", sel: current.sel };
        let second = { id: current.id + 1000, dir: 2, name: current.custom ? current.custom[1] : current.name + " - буурахаар", sel: current.sel };
        return res.concat([first, second]);
      }, []);
    },
    sortSimpleCollect() {
      // return this.sort.find((e) => e.sel);
      let ob = this.sort.find((e) => e.sel);
      if (!ob) return null;
      ob = this.deepClone(ob);
      if (ob.sel == 2) ob.id = ob.id + 1000;
      return ob;
    },
    scr() {
      return this.$store.getters.scr;
    },
  },
  watch: {
    obj: {
      immediate: true,
      async handler() {
        this.clearSort();
        if (this.obj) {
          this.sort = await this.getSort(this.obj.id);
        } else this.sort = [];
      },
    },
    // async obj() {
    //   this.clearSort();
    //   if (this.obj) this.sort = await this.getSort(this.obj.id);
    //   else this.sort = [];
    // },
    filter() {
      if (!this.$route.query.f) this.clearSort();
    },
    zarItems() {
      this.hidden = [];
      if (this.sort && this.zarItems && this.sort.length && this.zarItems.length) {
        this.sort.forEach((s) => {
          if (!this.zarItems.find((i) => i["p" + s.id])) this.hidden.push(s.id);
        });
      }
    },
  },
  created() {
    this.$bus.on("removeSort", this.removeSort);
    this.$bus.on("clearSort", this.clearSort);
    this.$bus.on("autoSelectSort", this.autoSelectSort);
  },
  activated() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_sort {
  /* overflow: hidden; */
}
</style>
