<template>
  <div>
    <ui-select
      :compName="compName"
      :items="items"
      :loading="loading"
      :collect="collect"
      :zind="zind"
      :imageSel="imageSel"
      :imageHover="imageHover"
      :unit="pob.unit"
      :guide="pob.guide"
      :title="pob.name"
      :forceSel="forceSel"
      v-on:clickedItem="clickedItem($event)"
      v-on:mouseOver="mouseOver($event)"
      v-on:mouseLeave="mouseLeave(pob.ref)"
      v-on:mouseEnter="mouseEnter(pob.ref)"
      v-on:selection="selection($event)"
      v-on:hideProp="hideProp"
    />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelect from "@/comp/ui/select.vue";
export default {
  name: "zar-add-select",
  props: {
    items: Array,
    obj: Object,
    forceSel: Number,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: Object,
    zind: Number,
    compName: String,
  },
  components: { uiSelect },
  data() {
    return { imageHover: null };
  },
  methods: {
    mouseLeave(ref) {
      this.$emit("mouseLeave", ref);
    },
    mouseOver(sel) {
      this.$emit("mouseOver", sel.id);
      if (!this.isMobile && sel.image) this.imageHover = this.apiUrl + "/media/ref/" + sel.id + ".jpg";
      // if (!this.isMobile && sel.image) this.imageHover = this.apiUrl + "/media/ref/" + this.obj.id + "/" + this.pob.id + "/" + sel.id + ".jpg";
      else this.imageHover = null;
    },
    mouseEnter(ref) {
      this.$emit("mouseEnter", ref);
    },
    clickedItem(obj) {
      this.$emit("clickedItem", obj);
    },
    selection(bo) {
      this.$emit("selection", bo);
    },
    hideProp() {
      this.pob.active = 0;
    },
  },
  computed: {
    imageSel() {
      return this.collect ? this.apiUrl + "/media/ref/" + this.pob.ref + "/" + this.collect.id + ".jpg" : "";
    },
  },
  watch: {
    // items: {
    //   immediate: true,
    //   handler() {
    //     this.$emit("clickedItem", "");
    //   },
    // },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
