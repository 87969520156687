<template>
  <div>
    <ui-select-multi :items="filteredItems" :loading="loading" :collect="collect" v-on:clickedItem="clickedItem($event)" :max="max" :unit="pob.unit ? pob.unit : null" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectMulti from "@/comp/ui/selectMulti.vue";

export default {
  name: "zar-add-select-multi",
  props: { items: Array, loading: Boolean, collect: { type: [String, Object, Boolean, Number, Array] }, pob: Object },
  components: {
    uiSelectMulti,
  },
  data() {
    return {
      max: this.pob.opt ? this.pob.opt.max : 999,
      commonOnly: this.pob.opt && this.pob.opt.commonOnly ? this.pob.opt.commonOnly : false,
    };
  },
  methods: {
    clickedItem(ob) {
      this.$emit("clickedItem", ob);
    },
  },
  watch: {},
  computed: {
    filteredItems() {
      return this.commonOnly ? this.items.filter((e) => e.com) : this.items;
      // let items = this.commonOnly ? this.items.filter((e) => e.com) : this.items;
      // if (this.commonOnly) items.forEach((e) => (e.com = 0));
      // return items;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
