<template>
  <div class="cont_delete_item">
    <div class="title_modal">Зар устгах</div>
    <div class="ta-l">
      <p>Таны устгасан зар хэсэг хугацаанд идэвхигүй байрших бөгөөд энэ хугацаанд та устгасан зартайгаа ижил зар оруулж болохгүйг анхаарна уу</p>
      <p><b>Зар устгах шалтгаан</b> <ui-select :items="status" :collect="checkedStatus" v-on:clickedItem="clickedStatus($event)" :floating="false" /></p>
    </div>
    <p class="mt-5">
      <ui-button text="Хадгалах" v-on:clicked="submit()" widthSize="wide" :disabled="!complete" />
      <ui-button text="Болих" v-on:clicked="close()" tp="simple" />
    </p>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelect from "@/comp/ui/select.vue";

export default {
  name: "main-delete-item",
  components: { uiSelect },
  props: { dataSet: Object },
  data() {
    return {
      checkedStatus: false,
      status: [
        { id: 1, name: "Зарагдсан", statusCode: 10 },
        { id: 2, name: "Зарахаа больсон", statusCode: 2 },
        { id: 3, name: "Бусад", statusCode: 2 },
      ],
    };
  },
  methods: {
    clickedStatus(ob) {
      this.checkedStatus = ob;
    },
    submit() {
      this.$store.dispatch("user/deleteItem", { obj: this.dataSet.obj, zid: this.dataSet.zid, statusCode: this.checkedStatus.statusCode });
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    complete() {
      return this.checkedStatus ? true : false;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
  },

  mounted() {
    // setTimeout(() => this.$refs.feedback.focus(), 100);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_delete_item {
  padding: 0 10px 10px 10px;
  text-align: center;
}
img {
  width: 100%;
  height: 150px;
}
textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
}
</style>
