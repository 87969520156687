<template>
  <PhotoSwipe v-if="images && images.length" :isOpen="isOpen" :items="images" :options="options" @close="hidePhotoSwipe"></PhotoSwipe>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import PhotoSwipe from "@/comp/ui/photoSwipe.vue";

export default {
  name: "ui-photos-list",
  components: { PhotoSwipe },
  props: {
    ind: Number,
    images: Array,
    maxWidth: {
      type: Number,
      default: () => {
        650;
      },
    },
  },
  data() {
    return {
      isOpen: true,
      seeDate: false,
      isOpenGallery: false,
      options: {
        maxSpreadZoom: 1,
        getDoubleTapZoom: (isMouseClick) => {
          return isMouseClick ? 1.2 : 0.5;
        },
        zoomEl: false,
        shareEl: false,
        index: 0,
        loop: false,
        preload: [1, 4],
        history: true,

        getThumbBoundsFn: () => {
          return null;
        },
      },
    };
  },
  methods: {
    showPhotoSwipe(index) {
      //document.body.style.overflow = "hidden";
      document.body.style.overflowY = "hidden";
      this.isOpen = true;
      this.$set(this.options, "index", index);
      //document.documentElement.requestFullscreen();
    },
    hidePhotoSwipe() {
      this.isOpen = false;
      document.body.style.overflowY = "scroll";
      // document.body.style.overflow = "visible";
      //document.exitFullscreen();
      setTimeout(() => this.$emit("closephotosList"), 100);
    },
  },

  watch: {
    ind: {
      immediate: true,
      handler() {
        if (this.ind) {
          this.showPhotoSwipe(this.ind);
        }
      },
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.photo {
  height: auto;
  margin-top: 5px;
}
.photo:hover {
  cursor: zoom-in;
}

.image_cont {
  width: 100%;
}
.title {
  padding-bottom: 20px;
  font-size: 13px;
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 700px) {
  .photo {
    max-width: 100%;
    height: auto;
  }
}
</style>
