<template>
  <div>
    <div class="spinner" v-if="loading"></div>
    <input type="file" @change="onChange" :multiple="multiple" accept="image/jpg, image/jpeg" style="display:none;" ref="fileInput" />
    <ui-button class="d-in mr-1" icon="i-image" text="Зураг +" v-on:clicked="!addDisabled && $refs.fileInput.click()" :tp="loading || totalImage == maxNum ? 'disabled' : 'normal'" />
    <slot name="imgTot" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import Compress from "@/assets/js/Compress.js";
import EXIF from "exif-js";
// import exifOrient from "exif-orient";

export default {
  name: "PhotoCompress",
  components: {},
  props: {
    multiple: Boolean,
    maxNum: Number,
    totalImage: Number,
    required: Boolean,
    maxWidth: { type: Number, required: true },
    maxHeight: { type: Number, required: true },
    fileNamePrefix: { type: String, required: true },
  },
  data() {
    return { loading: false, imageNum: 0, oris: [] };
  },
  methods: {
    clear() {
      const input = this.$refs.fileInput;
      input.type = "text";
      input.type = "file";
    },
    onChange(e) {
      let files = e.target.files;
      var allFiles = [];

      if (this.totalImage == this.maxNum) {
        // this.$bus.emit("showAlert", { header: "", body: "Та нийт " + this.maxNum + " зураг оруулах боломжтой", button: { text: "Ok" } });
        this.$modal.show("dialog", {
          text: "Та нийт " + this.maxNum + " зураг оруулах боломжтой",
          buttons: [
            {
              title: "ОК",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
        // this.showAlert = { header: "Мэдээлэл", text: "Та нийт " + this.maxNum + " зураг оруулах боломжтой", button: { text: "Ok" } };
        files = null;
      }

      if (files) {
        let aLength;
        if (this.maxNum < this.totalImage + files.length) {
          let diff = this.totalImage + files.length - this.maxNum;
          aLength = files.length - diff;
          this.$modal.show("dialog", {
            text: "Нийт " + this.maxNum + " зураг оруулах боломжтой. Сүүлийн " + diff + " зургийг хасав.",
            buttons: [
              {
                title: "ОК",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
          // this.$bus.emit("showAlert", { header: "", body: "Нийт " + this.maxNum + " зураг оруулах боломжтой. Сүүлийн " + diff + " зургийг хасав.", button: { text: "Ok" } });
          // this.showAlert = { header: "Мэдээлэл", text: "Нийт " + this.maxNum + " зураг оруулах боломжтой. Сүүлийн " + diff + " зургийг хасав.", button: { text: "Ok" } };
        } else {
          aLength = files.length;
        }

        this.$emit("uploadStarted", aLength);

        for (var i = 0; i < aLength; i++) {
          let file = files[i];
          let error = null;
          let mob = this.isMobile ? "1" : "0";
          this.imageNum++;
          let imgId = this.fileNamePrefix + "_s" + file.size + "_m" + mob + "_i" + this.imageNum;

          this.getExif(file, imgId);

          if (Math.round(file.size / 1000) > 15000) error = "Зургийн дээд хэмжээ 15mb<br>";

          if (file.type != "image/jpeg" && file.type != "image/png") error = "Та зураг оруулна уу!<br>";

          if (!error) {
            this.loading = true;

            const bigImage = new Compress({
              targetSize: 2,
              quality: 0.95,
              maxWidth: this.maxWidth,
              maxHeight: this.maxHeight,
            });

            bigImage.compress2(file).then((conversions) => {
              let unit = conversions.info.endWidth > conversions.info.endHeight ? 2 : 1;
              let gsize = this.isMobile ? 120 : 120;
              let foundOri = this.oris.find((e) => e.id == imgId);
              let fileInfo = {
                id: imgId,
                name: conversions.photo.name,
                origSize: file.size,
                src: conversions.photo.data,
                //w: conversions.info.endWidth,
                //h: conversions.info.endHeight,
                mw: unit == 2 ? gsize : gsize,
                mh: unit == 1 ? gsize : gsize,
                ori: foundOri ? foundOri.ori : 0,
                // ori: unit,
                rotate: 0,
                title: "",
                var: [],
              };
              if (conversions.info.endWidth < 600 && conversions.info.endHeight < 400) {
                this.$modal.show("dialog", {
                  text: "<img src='" + conversions.photo.data + "' width='200' />" + " Энэ зураг хэтэрхий жижиг хэмжээтэй учир оруулах боломжгүй байна",
                  buttons: [
                    {
                      title: "ОК",
                      handler: () => {
                        this.$modal.hide("dialog");
                      },
                    },
                  ],
                });
                // this.$bus.emit("showAlert", { header: "", body: "<img src='" + conversions.photo.data + "' width='200' />" + " Энэ зураг хэтэрхий жижиг хэмжээтэй учир оруулах боломжгүй байна", button: { text: "Ok" } });
                // this.showAlert = { header: "<img src='" + conversions.photo.data + "' width='200' />" + " Энэ зураг хэтэрхий жижиг хэмжээтэй учир оруулах боломжгүй байна", text: error, button: { text: "Ok" } };
              } else {
                this.$emit("compressed", fileInfo);
              }

              allFiles.push({ id: i });

              if (allFiles.length == aLength) {
                this.loading = false;
                this.clear();
              }
            });
          } else {
            // this.$bus.emit("showAlert", { header: "Мэдээлэл", body: error, button: { text: "Ok" } });
            this.$modal.show("dialog", {
              title: "Мэдээлэл",
              text: error,
              buttons: [
                {
                  title: "ОК",
                  handler: () => {
                    this.$modal.hide("dialog");
                  },
                },
              ],
            });
            // this.showAlert = { header: "Мэдээлэл", text: error, button: { text: "Ok" } };
          }
        }
      }
    },
    getExif(img, id) {
      EXIF.getData(img, () => {
        let orientation = img.exifdata.Orientation;
        if (orientation) {
          this.oris.push({ id, ori: orientation });

          // this.$emit("detectedOri", { ori: orientation, id });
        }

        // console.log("orientation", orientation);
        // alert(orientation);

        // 2. Invoke `exifOrient` to orient the image and get back a canvas
        // return await exifOrient(img, orientation, function(err, canvas) {
        //   console.log(err);
        //   return canvas;
        //   // 3. Do whatever you want with the canvas, e.g. render it into an image
        // });
      });

      // var img = document.getElementById("image");
      // EXIF.getData(img, function() {
      //   var allMetaData = EXIF.pretty(this);
      //   var allMetaDataSpan = document.getElementById("metadata");
      //   allMetaDataSpan.innerHTML = JSON.stringify(allMetaData, null, "\t");
      // });
    },
  },
  watch: {},
  mounted() {
    // getExif();
  },
  computed: {
    addDisabled() {
      return this.loading || this.totalImage == this.maxNum;
    },
    // showAlert: {
    //   get() {
    //     return this.$store.state.showAlert;
    //   },
    //   set(ob) {
    //     this.$store.commit("showAlert", ob);
    //   },
    // },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
