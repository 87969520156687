<template>
  <div class="cont_zar_upload_photo">
    <ui-photo-upload
      v-on:clickedItem="clickedItem($event)"
      :lockPhotoManage="lockPhotoManage"
      :maxWidth="opt.maxWidth"
      :maxHeight="opt.maxHeight"
      :fileNamePrefix="'_u' + zid + '_o' + obj.id + '_p' + pob.id"
      :required="pob.req ? true : false"
      :collect="collect"
      :setTitle="opt.title"
      :setMax="opt.max"
      :folder="opt.tp"
    />
    <ui-reflow>
      <div class="spinner" v-if="preSizeLoading"></div>
    </ui-reflow>
    <div :class="[preSizingDone ? 'suggest_text' : 'info_text']" v-if="lockPhotoManage">{{ preSizingDone ? "Илгээгдсэн" : "Баталгаажсан" }}</div>
    <div v-if="pob && pob.guide && !lockPhotoManage" class="guide">{{ pob.guide }}</div>
    <ui-button v-if="items.length && !lockPhotoManage" text="Баталгаажуулах" v-on:clicked="submit()" tp="special" />
    <!-- <ui-button v-if="false && items.length && preSizingDone" text="Дахин шинээр хийх" v-on:clicked="reset()" /> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiPhotoUpload from "@/comp/ui/photoUpload.vue";

export default {
  name: "zar-add-photo-upload",
  components: {
    uiPhotoUpload,
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
    zid: {
      type: String,
      required: true,
    },
    pob: {
      type: Object,
      required: true,
    },
    collect: {},
    opt: {
      type: Object,
      required: true,
      maxWidth: { type: Number, required: true },
      maxHeight: { type: Number, required: true },
    },
  },
  data() {
    return {
      preSizeLoading: false,
      lockPhotoManage: false,
      preSizingDone: false,
      items: [],
      // compKey: 1,
    };
  },
  methods: {
    // reset() {
    //   this.$emit("clickedItem", "");
    //   this.compKey++;
    //   this.preSizingDone = false;
    //   this.lockPhotoManage = false;
    //   this.axiosPost("/utility/upload/resetPhotoUpload", [this.zid, this.items]);
    //   this.items = [];
    // },
    // preResizePhoto() {
    //   this.lockPhotoManage = true;
    //   this.props
    //     .filter((p) => p.pcomp_name == "addPhotoUpload")
    //     .forEach(async (e) => {
    //       this.preSizeLoading = true;
    //       let res = await this.axiosPost("/utility/upload/preResizeItemitems", [this.objSel.id, this.zid, e.id, e.collect]);
    //       if (res.status == "done") this.preSizingDone = true;
    //       this.preSizeLoading = false;
    //     });
    // },
    async preResizePhoto(fromZid) {
      if (fromZid != this.zid) return;
      // this.items.forEach(async (e) => {
      this.preSizeLoading = true;
      let res = await this.axiosPost("/utility/upload/preResizeItemImages", [this.obj.id, this.zid, this.pob.id, this.items]);
      if (res.status == "done") {
        this.preSizingDone = true;
        this.$emit("imagePresizingDone");
      }
      this.preSizeLoading = false;
      // });
    },
    submit() {
      this.$modal.show("dialog", {
        clickToClose: false,
        text: "Та зургуудын зөв тийшээ харсан байдал, дараалал зэргийг нягтлаарай. Хамгийн эхний зураг нүүр зураг болно.",
        buttons: [
          {
            title: "ОК",
            handler: () => {
              this.$modal.hide("dialog");
              this.$emit("clickedItem", this.items);
              this.lockPhotoManage = true;
              // this.preResizePhoto(this.images);
            },
          },
          {
            title: "Буцах",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
    clickedItem(items) {
      // console.log(items);
      this.items = items;
    },
  },
  mounted() {
    this.$bus.on("preResizePhoto", this.preResizePhoto);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_upload_photo {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
}
</style>
