<template>
  <div class="cont_tos">
    <div class="title_modal">Үйлчилгээний нөхцөл</div>
    <div class="body" v-html="configs.tos"></div>

    <div class="mt-3 ta-c">
      <ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Хаах" tp="simple" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "main-tos",
  components: {},
  props: {},
  data() {
    return {
      text: null,
      shake: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    configs() {
      return this.$store.state.configs;
    },
  },

  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_tos {
  padding: 0 10px 10px 10px;
  text-align: center;
}
.cont_tos .body {
  text-align: left;
}

.cont_tos .body h1 {
  font-weight: 600;
  padding-left: 10px;
  font-size: 14px !important;
}
img {
  width: 100%;
  height: 150px;
}
textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
}
</style>
