import AuthService from "@/service/auth";

const userLocal = JSON.parse(localStorage.getItem("user"));
export const auth = {
  namespaced: true,
  state: {
    status: userLocal ? { logged: true } : { logged: false },
    user: userLocal ? userLocal : null,
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (res) => {
          if (res.status) {
            commit("loginSuccess", res.info);
            return Promise.resolve(res.data);
          } else {
            commit("loginFailure");
            return Promise.reject(res.message);
          }
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (res) => {
          if (res.status) {
            commit("registerSuccess");
            return Promise.resolve(res.data);
          } else {
            commit("registerFailure");
            return Promise.reject(res.message);
          }
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    async loginSuccess(state, user) {
      state.status.logged = true;
      state.user = user;
      this._vm.$message({
        type: "info",
        message: "Нэвтэрлээ",
      });
    },
    loginFailure(state) {
      state.status.logged = false;
      state.user = null;
    },
    logout(state) {
      state.status.logged = false;
      state.user = null;
      this._vm.$message({
        type: "info",
        message: "Гарлаа",
      });
    },
    registerSuccess(state) {
      state.status.logged = false;
    },
    registerFailure(state) {
      state.status.logged = false;
    },
  },
};
