import UserService from "@/service/user";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    // favs: UserService.getUserAllFavs(),
    // favs: this.state.auth.status.logged ? UserService.getUserAllFavs() : JSON.parse(localStorage.getItem("favs_")) ?? [],
    favs: [],
    userItems: [],
    refreshZarList: false,
    viewHistory: [],
    collect: [],
    pageHistory: [],
    userInfo: null,
    favsModalActive: false,
    notiModalActive: false,
    noti: [],
  },
  /*--------------------------------------------------------------------------------GETTERS-------------------------------------------------------------------------*/
  getters: {},
  /*--------------------------------------------------------------------------------MUTATIONS-------------------------------------------------------------------------*/
  mutations: {
    viewHistory(state, ob) {
      state.viewHistory = ob;
    },

    favSet(state, ob) {
      state.favs = ob;
    },

    favsModalActive(state, bool) {
      state.favsModalActive = bool;
    },
    notiModalActive(state, bool) {
      state.notiModalActive = bool;
    },

    favChange(state, ob) {
      let found = state.favs.find((e) => e.zid == ob.zid);

      if (found) {
        let ind = state.favs.findIndex((e) => e.zid == ob.zid);
        state.favs.splice(ind, 1);
        UserService.removeUserFav(ob.obj, ob.zid);
      } else {
        ob.date = Date.now();
        state.favs.push(ob);
        UserService.addUserFav(ob.obj, ob.zid);
      }

      if (this.state.auth.status.logged) {
        let userData = JSON.parse(localStorage.getItem("user"));
        userData.favs = userData ? state.favs : [];
        localStorage.setItem("user", JSON.stringify(userData));
      }
      // else localStorage.setItem("favs", JSON.stringify(state.favs));
    },

    addViewHistory(state, ob) {
      let history = JSON.parse(localStorage.getItem("viewHistory")) ?? [];

      let found = history.find((e) => e.zid == ob.zid);
      let filtered = history.filter((e) => e.zid != ob.zid);
      let count = 1;
      if (found) {
        if (Date.now() - found.date > 1000 * 60) {
          count = found.count + 1;
        } else count = found.count;
      }

      filtered.push({ obj: ob.obj, zid: ob.zid, count, date: Date.now() });
      state.viewHistory = filtered;

      localStorage.setItem("viewHistory", JSON.stringify(filtered));

      // if (this.state.auth.status.logged) {
      //   let userData = JSON.parse(localStorage.getItem("user"));
      //   userData.viewHistory = state.viewHistory;
      //   localStorage.setItem("user", JSON.stringify(userData));
      // } else localStorage.setItem("viewHistory", JSON.stringify(state.viewHistory));
    },

    collectChange(state, ob) {
      let find = state.collect.find((e) => e.zid == ob.zid);
      if (find) {
        let ind = state.collect.findIndex((e) => e.zid == ob.zid);
        state.collect.splice(ind, 1);
      } else {
        // ob.date = Date.now();
        // ob.devid = localStorage.getItem("devid");
        state.collect.push(ob);
      }
    },
    collectEmpty(state) {
      state.collect = [];
    },
    // let favName = "favs_" + (this.state.auth.status.logged ? this.state.auth.user.uid : "");
    // localStorage.setItem(favName, JSON.stringify(state.favs));

    favEmpty(state) {
      state.favs = [];
    },

    renewItemResponse(state, ob) {
      if (ob.status == "success") {
        state.refreshZarList = !state.refreshZarList;
        this._vm.$message({
          type: "info",
          message: ob.text,
          showClose: true,
          position: "top-center",
          hasMask: false,
          duration: 5000,
        });
      }
      if (ob.status == "failed")
        this._vm.$message({
          type: "warning",
          message: ob.text,
          showClose: true,
          position: "top-center",
          hasMask: false,
          duration: 3000,
        });
    },
    updateUserInfoResponse(state, ob) {
      state.userInfo = ob;
    },
    updateUserNotiResponse(state, arr) {
      state.noti = arr;
    },
    deleteItemResponse(state, ob) {
      if (ob.status == "success") {
        state.refreshZarList = !state.refreshZarList;
        this._vm.$message({
          type: "info",
          message: ob.text,
          showClose: true,
          position: "top-center",
          hasMask: false,
          duration: 1500,
        });
      }
      if (ob.status == "failed")
        this._vm.$message({
          type: "warning",
          message: ob.text,
          showClose: true,
          position: "top-center",
          hasMask: false,
          duration: 3000,
        });
    },

    refreshZarList(state, bool) {
      state.refreshZarList = bool;
    },

    saveItemResponse(state, ob) {
      if (ob.status == "success") {
        state.refreshZarList = !state.refreshZarList;
        this._vm.$message({
          type: "info",
          message: ob.text,
          showClose: true,
          position: "top-center",
          hasMask: false,
          duration: 1500,
        });
      }
      if (ob.status == "failed")
        this._vm.$message({
          type: "warning",
          message: ob.text,
          showClose: true,
          position: "top-center",
          hasMask: false,
          duration: 3000,
        });
    },
  },

  actions: {
    async renewItem({ commit }, data) {
      let res = await UserService.renewItem(data.obj, data.zid);
      commit("renewItemResponse", res.data);
    },

    async deleteItem({ commit }, data) {
      let res = await UserService.deleteItem(data.obj, data.zid, data.statusCode);
      commit("deleteItemResponse", res.data);
    },

    async updateUserInfo({ commit }) {
      let res = await UserService.updateUserInfo();
      if (res.data) commit("updateUserInfoResponse", res.data);
      // else $store.dispatch("auth/logout");
      else {
        localStorage.removeItem("user");
        axios.defaults.headers.Authorization = localStorage.getItem("devid");
      }
    },

    async updateUserNoti({ commit }) {
      let res = await UserService.updateUserNoti();
      if (res.data) commit("updateUserNotiResponse", res.data);
    },

    async editItem(vuex, data) {
      let res = await UserService.editItem(data.obj, data.zid);
      // console.log(res);

      res.data.props.forEach((p) => (p.collect_b = p.collect));

      if (res.data.status) {
        this._vm.$bus.emit("openModal", { name: "userZarEdit", dataSet: { props: res.data.props, obj: data.obj, zid: data.zid } });
      }
    },

    async saveItem({ commit }, e) {
      let res = await UserService.saveItem({ obj: e.obj, zid: e.zid, props: e.props });
      commit("saveItemResponse", res.data);
    },

    /* getCats: async () => {
        api("http://localhost/api/cat/getcat/").then((res) => {
          this.changeCats(res.data);
          console.log(res.data);
        });
      },*/
  },
  /*--------------------------------------------------------------------------------MODULES-------------------------------------------------------------------------*/
  modules: {},
  //plugins: [vuexPersist.plugin],
};
