<template>
  <div class="cont_home_zar_filter">
    <div class="cont_spacer"></div>
    <zar-filter :filterName="'home'" :obj="obj" v-on:filterChanged="filterChanged($event)" v-on:pendingFilSelChanged="pendingFilSelChanged($event)" :pathRelData="'/zar/object/getRelData/'" :pathSelZnum="'/zar/filter/getselznum'" :pathFilData="'/zar/filter/getfilters/'" :routeQuery="routeQuery" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarFilter from "@/comp/zar/filter.vue";

export default {
  name: "home-zar-filter",
  components: {
    zarFilter,
  },
  data() {
    return {};
  },
  methods: {
    filterChanged(filter) {
      this.$store.commit("zar/filter/filter", { name: "home", arr: filter });
    },
    pendingFilSelChanged(num) {
      this.$store.commit("zar/filter/pendingFilSel", { name: "home", num });
    },
  },

  computed: {
    obj() {
      return this.$store.state.selObj;
    },
    routeQuery() {
      return this.$route.query.f;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
/* .cont_home_zar_filter {
  background-color: bisque;
} */
/* .cont {
  position: relative;
}
.cont_spacer {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: -20px;

  height: 50%;
  width: 1px;
  border-left: solid 1px #f1f1f1;
  background-color: #f1f1f1;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 52%, rgba(255,255,255,1) 100%);
} */
</style>
