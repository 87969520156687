<template>
  <div>
    <ui-bread :items="breads" v-on:clickedItem="clickedItem($event)" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiBread from "@/comp/ui/bread.vue";
import { mapState } from "vuex";
export default {
  name: "zar-tag",
  components: { uiBread },
  props: {
    srcCat: {
      type: Array,
      default: () => [],
    },
    srcObj: {
      type: [Object, Boolean],
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickedItem(ob) {
      this.$emit("clickedBread", ob);
    },
  },
  computed: {
    ...mapState({
      selObj: (s) => s.selObj,
    }),
    scr() {
      return this.$store.getters.scr;
    },
    mainCat() {
      return this.$store.getters.mainCat;
    },
    breads() {
      let items = [{ id: 11, sname: "Нүүр", class: "cat", tp: "showHome" }];
      if (this.srcCat.length) this.srcCat.forEach((cat) => items.push({ id: cat.id, sname: cat.name, class: "cat", tp: "obj" }));
      else this.mainCat.forEach((cat) => items.push({ id: cat.id, sname: cat.name, class: "cat", tp: "obj" }));
      if (this.srcObj) items.push({ id: this.srcObj.id, sname: this.srcObj.name, class: "obj", tp: "obj", icon: this.srcObj.icon });
      if (this.selObj && !this.srcObj) items.push({ id: this.selObj.id, sname: this.selObj.name, class: "obj", tp: "obj", icon: this.selObj.icon });

      return items;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
