<template>
  <ui-loc-select
    :obj="obj"
    :items="items"
    :loading="loading"
    :collect="collect"
    :iniZoom="opt.zoom"
    :iniCenter="opt.center"
    :geoJson="geoJson"
    :forceSel="forceSel"
    :zind="zind"
    :compName="pob.name + pob.id"
    v-on:clickedItem="clickedItem($event)"
    v-on:mouseOver="mouseOver($event)"
    v-on:mouseEnter="mouseEnter($event)"
    v-on:mouseLeave="mouseLeave($event)"
  />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiLocSelect from "@/comp/ui/locSelect.vue";

export default {
  name: "zar-add-loc-select",
  props: {
    obj: Object,
    items: Array,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: Object,
    zind: Number,
    opt: Object,
    forceSel: Number,
  },
  components: {
    uiLocSelect,
  },
  data() {
    return {
      geoJson: null,
      savedGeoJsons: [],
      result: null,
    };
  },
  methods: {
    mouseEnter() {},
    async mouseLeave() {
      this.geoJson = this.collect ? await this.getGeoJson(this.pob.ref, this.collect.id) : null;
    },
    async getGeoJson(ref, id) {
      let found = this.savedGeoJsons.find((e) => e.id == id);
      if (found) return found.geoJson;
      await this.axios
        .get("/utility/location/getgeojson/" + ref + "/" + id)
        .then((res) => (this.result = res.data ?? null))
        .catch((err) => {
          console.log(err);
        });
      if (this.result && !this.savedGeoJsons.find((e) => e.id == id)) this.savedGeoJsons.push({ id: id, geoJson: this.result, center: this.result.center });
      return this.result;
    },
    async mouseOver(sel) {
      this.$emit("mouseOver", sel);

      // this.geoJson = await this.getGeoJson(this.pob.ref, sel.id);
    },
    clickedItem(sel) {
      this.$emit("clickedItem", sel);
    },
    zoomUpdated() {},
  },
  computed: {},
  watch: {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
