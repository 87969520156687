<template>
  <div>
    <div class="cont_zar_filter_input" @click="showSelector = true" :class="{ selected: submitted }">
      <div class="pob_name">
        {{ pob.name }}
        <!--span>хязгаараар</span-->
      </div>
      <span v-if="!submitted" class="choose">Бичих</span>
      <span v-else class="collected">
        {{ submitted.sval.min }} - {{ submitted.sval.max }} <span v-if="pob.unit">{{ pob.unit }}</span>
      </span>
      <ch-drawer :visible.sync="showSelector" :title="pob.name" area="450px" location="bottom" :blur="false" @open="$store.commit('addOpenDrawers', 'showMenuCat')" @close="$store.commit('removeOpenDrawers', 'showMenuCat')" destroy-on-close>
        <div class="cont_drawer">
          <!-- <zar-cat v-if="catData" withVirtualCat disableFunc onlyCat /> -->
          <!-- <ui-input :key="inputKey" class="ml-3 mt-1" :minWidth="10" v-on:typed="typed($event)" :autoType="autoType" :unit="pob.unit" :autoFocus="false" emitEnter :unmask="false" :pat="'[9][9][9][-][9][9][9]'" :active="active" /> -->
          <span class="cont_min" :class="{ active: activeInput == 'min' }" @click="activeInput = 'min'">
            <div class="label">Доод хязгаар</div>
            <span class="min">{{ valuePad.min }}</span> <span class="unit" v-if="pob.unit">{{ pob.unit }}</span>
          </span>
          <span class="dash">-</span>
          <span class="cont_max mb-5 ml-3" :class="{ active: activeInput == 'max' }" @click="activeInput = 'max'">
            <div class="label">Дээд хязгаар</div>
            <span class="max">{{ valuePad.max }}</span> <span class="unit" v-if="pob.unit">{{ pob.unit }}</span>
          </span>
          <div class="cont_keypad">
            <template v-for="(k, ind) in keypads">
              <div class="keypad" :key="'filInputMob' + pob.id + ind" @click="clicked(k)" v-html="k"></div>
            </template>
          </div>
          <div class="van-hairline--bottom pb-5"></div>
          <div class="seperator pb-5"></div>
          <ui-button v-on:clicked="submit()" text="Шүүх" widthSize="wide" />
          <ui-button v-if="submitted" v-on:clicked="clear()" text="Арилгах" tp="simple" />
          <ui-button v-on:clicked="showSelector = false" text="Болих" tp="simple" />

          <div class="return_button_top" @click="showSelector = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
        </div>
      </ch-drawer>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
// import uiInput from "@/comp/ui/input.vue";

export default {
  name: "zar-filter-input",
  props: { pob: Object, filter: Array },
  components: {
    // uiInput,
  },
  data() {
    return {
      min: 0,
      max: 999999,
      name: "",
      active: false,
      inputKey: 0,
      showSelector: false,
      keypads: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "&#8592;", "C"],
      activeInput: "min",
      valuePad: { min: "0", max: "0" },
      lengthMin: 0,
      lengthMax: 5,
      changedInput: false,
    };
  },
  methods: {
    reset() {
      this.valuePad.min = "0";
      this.valuePad.max = "0";
      this.activeInput = "min";
    },
    clear() {
      this.$emit("clearItem", this.submitted);
      this.showSelector = false;
    },
    clicked(val) {
      if (this.changedInput) {
        this.valuePad[this.activeInput] = "0";
        this.changedInput = false;
      }
      if (val == "OK") {
        this.submit();
        return;
      }

      if (val == "C") {
        this.reset();
        // this.valuePad[this.activeInput] = "0";
        return;
      }
      if (val == "&#8592;") {
        if (this.valuePad[this.activeInput].length == 1) {
          this.valuePad[this.activeInput] = "0";
          return;
        }
        this.valuePad[this.activeInput] = this.valuePad[this.activeInput].slice(0, -1);
        return;
      }
      if (this.valuePad[this.activeInput].length >= this.lengthMax) return;
      if (this.valuePad[this.activeInput] == "0" && val != "0") this.valuePad[this.activeInput] = "";
      if (this.valuePad[this.activeInput] == "0" && val == "0") return;
      this.valuePad[this.activeInput] += val;
    },
    submit() {
      if (!this.validValues()) return;
      this.showSelector = false;
      this.$emit("clickedItem", { name: this.valuePad.min + " - " + this.valuePad.max, min: Number(this.valuePad.min), max: Number(this.valuePad.max) });
    },
    validValues() {
      let error = false;
      let message = "Та оруулсан утгуудаа шалгана уу";
      if (this.valuePad.min == "0" && this.valuePad.max == "0") {
        error = true;
        message = "Бүх утгууд 0 байж болохгүй";
      }
      if (Number(this.valuePad.min) > Number(this.valuePad.max)) {
        error = true;
        message = "Дээд хязгаарын утга бага байна";
      }
      if (error) {
        this.$message({
          type: "warning",
          message,
          showClose: false,
          hasMask: false,
          duration: 3000,
        });
        return false;
      }
      return true;
    },
    typed(text) {
      if (text == "") {
        this.$emit("clickedItem", "");
        this.active = false;
      }
      let data = this.explode(text, "-");

      if (data.length == 1) {
        this.min = Number(data[0]);
        let dn = this.digitNumber(this.min);
        this.max = this.min + (dn == 1 ? 0.9 : dn == 2 ? 9.9 : 99.9);
        this.name = "~" + this.min;
      } else {
        let from = data[0] == "";
        let to = data[1] == "";
        this.min = from ? 0 : Number(data[0]);
        this.max = to ? 999999 : Number(data[1]);

        if (from && !to) this.name = this.max + " хүртэл";
        if (!from && to) this.name = this.min + " дээш";
        if (!from && !to) this.name = this.min + "-" + this.max;
      }

      this.$emit("clickedItem", { name: "Үнэ", min: this.min, max: this.max, text });
      this.active = true;
    },
  },
  watch: {
    showSelector() {
      if (this.showSelector && this.submitted) {
        this.valuePad.min = this.submitted.sval.min;
        this.valuePad.max = this.submitted.sval.max;
      }
    },
    activeInput() {
      if (this.activeInput) this.changedInput = true;
    },
    submitted() {
      if (!this.submitted) this.reset();
    },
    items() {},
    pob: {
      deep: true,
      handler() {
        if (!this.pob.sel.first().selected) {
          this.inputKey++;
          this.active = false;
        }
      },
    },
  },
  computed: {
    submitted() {
      return this.filter.find((e) => e.prop == this.pob.id);
    },
    autoType() {
      let autoType = this.pob.sel.first().autoType;
      return autoType ?? null;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_filter_input {
  text-align: left;
  display: block;
  padding: 0;
  /* margin-bottom: 20px; */
  width: fit-content;
  transition: all 150ms;
  background-color: #f3f3f3;
  padding: 5px 10px !important;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: 2px;
}

.cont_drawer {
  padding: 10px;
  padding-top: 30px;
  text-align: center;
}

.pob_name {
  padding: 0px 0px 0px 0px;
  /* margin-bottom: 2px; */
  color: var(--color_head);
  font-weight: 700;

  display: block;
  transition: all 0.2s;
  width: fit-content;
  opacity: 1;
  text-transform: uppercase;
  font-size: 12px;
}

.pob_name span {
  color: #929292;
  text-transform: lowercase;
  font-weight: 450 !important;
}

.cont_keypad {
  /* padding: 10px; */
  width: 100%;
}

.dash {
  position: relative;
  top: -7px;
}

.keypad {
  font-size: 32px;
  padding: 6px 20px 2px 20px;
  display: inline-block;
  background-color: #f1f1f1;
  margin: 0 5px 5px 0;
  line-height: 60px;
  border-radius: 8px;
  width: 35px !important;
  text-align: center;
  vertical-align: middle;
  transition: all 100ms;
  transform: scale(1);
  user-select: none;
  font-weight: var(--base_weight);
}
.keypad:active {
  /* filter: brightness(0.95); */
  /* transform: scale(1.1); */
  background-color: var(--color_selected);
}

.cont_min,
.cont_max {
  display: inline-block;
  border: solid 2px #ccc;
  width: 35% !important;
  /* max-width: 200px !important; */
  /* height: 27px !important; */
  border-radius: 5px;
  margin-right: 10px;
  padding: 15px 15px 5px 10px;
  position: relative;
  /* color: #727272; */
}

.cont_min .label,
.cont_max .label {
  position: absolute;
  margin-top: -40px;
}

.unit {
  position: relative;
  left: 3px;
  /* right: 10px;
  bottom: 10px; */
  font-size: 18px !important;
  color: #000;
}

.min,
.max {
  margin-left: 10px;
  color: #000;
}

.cont_min .min,
.cont_max .max {
  font-size: 32px;
}

.cont_min.active,
.cont_max.active {
  /* background-color: aquamarine; */
  border: solid 2px var(--color_blue);
  background-color: rgb(197, 255, 255);
}

.collected {
  font-weight: 600;
  font-size: 15px;
}

.selected {
  background-color: var(--color_selected) !important;
}
</style>
