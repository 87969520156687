import Vue from "vue";
import Vuex from "vuex";
import zar from "./module/zar";
import user from "./module/user";
import { auth } from "./module/auth";
//import VuexPersist from "vuex-persist";
//import axios from "axios";

/*const api = axios.create({
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
});*/

/*
const vuexPersist = new VuexPersist({
  key: "my-app",
  storage: window.localStorage,
});
*/
Vue.use(Vuex);

function getParCat(data, cat) {
  let names = [];
  let found = data.find((e) => e.id == cat);
  names.push({ id: found.id, name: found.name, icon: found.icon });
  return found.par ? names.concat(getParCat(data, found.par)) : names;
}

export const store = new Vuex.Store({
  modules: {
    user,
    zar,
    auth,
  },
  state: {
    selObj: null,
    selCat: null,
    returnPos: {},
    swidth: null,
    showMenu: { cat: false, addcat: false, fil: false, tools: false, barterCar: false, userInfo: false },
    openDrawers: [],
    openModals: [],
    homeUrl: null,
    gLoading: false,
    cacheGeo: [],
    cachePropType: [],
    cacheOneItem: [],
    configs: [],
    appName: "Zaraa.mn",
    nearestLoc: null,
    newZar: [],
    lastZarCdate: null,
    routeFrom: null,
    homeZarListTotalPage: 0,
    homeZarListPageNum: 1,
    barterCarClickedInd: null,
    scrollDirection: 0,
    seenOwnBanner: false,
  },
  actions: {
    // catData: async () => {
    //   return axiosGet(url, params, (failReturn = null));
    // },
  },
  getters: {
    scr(state) {
      let mode = null;
      if (state.swidth >= 1150) mode = 4;
      if (state.swidth < 1150 && state.swidth >= 800) mode = 3;
      if (state.swidth < 900 && state.swidth >= 650) mode = 2;
      if (state.swidth < 700) mode = 1;
      state.showMenu = { cat: false, addcat: false, fil: false, tools: false, barterCar: false, userInfo: false };
      return mode;
    },
    mainCat(state, getters, rootState) {
      if (!state.selObj) return [];
      let data = rootState.zar.catData;
      let parCat = data.objCat.find((e) => e.obj == state.selObj.id);
      return getParCat(data.cat, parCat.cat).reverse();
    },
    selDep(state, getters) {
      if (!state.selObj) return null;
      return getters.mainCat.length ? getters.mainCat[0] : null;
    },
  },
  mutations: {
    homeZarListTotalPage(state, num) {
      state.homeZarListTotalPage = num;
    },
    homeZarListPageNum(state, num) {
      state.homeZarListPageNum = num;
    },
    lastZarCdate(state, num) {
      state.lastZarCdate = num;
    },
    routeFrom(state, str) {
      state.routeFrom = str;
    },
    addNewZar(state, arr) {
      arr.forEach((e) => state.newZar.push(e));
    },
    resetNewZar(state) {
      state.newZar = [];
    },
    seenOwnBanner(state, bool) {
      state.seenOwnBanner = bool;
    },
    cacheOneItem(state, ob) {
      let foundOld = state.cacheOneItem.find((e) => e.obj == ob.obj && e.zid == ob.zid);
      if (foundOld) foundOld.date = Date.now();
      else state.cacheOneItem.push(ob);
    },
    cacheGeo(state, ob) {
      state.cacheGeo.push(ob);
    },
    loadingItemPlaceholderInfo(state, ob) {
      state.loadingItemPlaceholderInfo = ob;
    },
    nearestLoc(state, arr) {
      state.nearestLoc = Object.freeze(arr);
      // state.nearestLoc = arr;
    },
    cachePropType(state, obj) {
      state.cachePropType.push(obj);
    },
    addOpenDrawers(state, str) {
      if (!state.openDrawers.find((e) => e == str)) state.openDrawers.push(str);
    },
    removeOpenDrawers(state, str) {
      state.openDrawers = state.openDrawers.filter((e) => e != str);
    },
    addOpenModals(state, str) {
      if (!state.openModals.find((e) => e == str)) state.openModals.push(str);
    },
    removeOpenModals(state, str) {
      state.openModals = state.openModals.filter((e) => e != str);
    },
    selObj(state, obj) {
      state.selObj = obj;
    },
    configs(state, arr) {
      // state.configs = Object.freeze(arr);
      state.configs = arr;
    },
    gLoading(state, obj) {
      state.gLoading = obj;
    },
    selCat(state, ob) {
      state.selCat = ob;
    },
    returnPos(state, obj) {
      state.returnPos = obj;
    },
    swidth(state, val) {
      state.swidth = val;
    },
    homeUrl(state, val) {
      state.homeUrl = val;
    },
    scrollDirection(state, val) {
      state.scrollDirection = val;
    },
    barterCarClickedInd(state, val) {
      state.barterCarClickedInd = val;
    },
    showMenu(state, ob) {
      let key = Object.keys(ob)[0];
      let value = ob[key];
      state.showMenu[key] = value;
    },
  },
  //plugins: [vuexPersist.plugin],
});
