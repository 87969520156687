<template>
  <div class="boffer_list">
    <div v-for="(i, ind) in items" :key="'boffer' + ind" class="cont_boffer" :class="{ bottom_border: ind != items.length - 1 }">
      <router-link :to="'/zar/view?o=1&z=' + i.zid" class="link">Илгээсэн зар</router-link>
      <div class="cont_image mt-2">
        <img class="photo clickable" v-for="(img, imgInd) in i.image" :key="'img' + imgInd" :src="apiUrl + '/media/boffer/' + i.zid + '/' + img.id + '.jpg'" @click="showPhoto(i, imgInd)" :ref="'photo' + ind + '' + imgInd" />
      </div>

      <h3 class="title mb-1 fs-l fw-base fst-100 b">
        {{ i.make_val.name }} {{ i.model_val.name }} {{ i.year }} <span>{{ $date.unix(i.cdate).format("YYYY.MM.DD[-ний] HH:mm") }}</span>
      </h3>

      <div class="boffer_body mt-2">
        <p>{{ i.body }}</p>
      </div>

      <div class="mt-2">
        Утас: <span>{{ i.phone }}</span>
      </div>

      <div v-if="showSeen && i.seen" class="seen mt-3">
        Зарын эзэн үзсэн <span>{{ $date.unix(i.seen).fromNow(true) }}</span>
      </div>
    </div>
    <div v-if="items && !items.length" class="op-08 ta-c">Солих санал алга байна...</div>
    <PhotoSwipe :isOpen="isOpen" :items="photos" :options="options" @close="hidePhotoSwipe()"></PhotoSwipe>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import PhotoSwipe from "@/comp/ui/photoSwipe.vue";

export default {
  name: "mod-boffer",
  components: { PhotoSwipe },

  props: {
    dataSet: Object,
    items: Array,
    showSeen: Boolean,
  },
  data() {
    return {
      photos: [],
      isOpen: false,
      currentBofferPhoto: 0,
      options: {
        maxSpreadZoom: 1,
        getDoubleTapZoom: (isMouseClick) => {
          return isMouseClick ? 1.2 : 0.5;
        },
        zoomEl: false,
        shareEl: false,
        index: 0,
        loop: false,
        preload: [1, 4],
        // history: false,

        getThumbBoundsFn: () => {
          return null;

          // if (this.isMobile && this.scr == 1) {
          //   // var thumbnail = document.querySelectorAll(".photo")[index];
          //   // console.log(this.$refs);
          //   // console.log(index);

          //   var thumbnail = this.$refs["photo" + index];
          //   var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          //   var rect = thumbnail.getBoundingClientRect();
          //   return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
          // } else {
          //   return null;
          // }
        },
      },
    };
  },
  methods: {
    showPhoto(item, index) {
      if (this.currentBofferPhoto == item.id) {
        this.$set(this.options, "index", index);
        this.isOpen = true;
        return;
      }

      let images = [];
      item.image.forEach((i) => {
        let url = this.apiUrl + "/media/boffer/" + item.zid + "/" + i.id + ".jpg";
        images.push({
          id: i.id,
          src: url,
          tsrc: url,
          msrc: url,
          title: "",
          w: i.width,
          h: i.height,
        });
      });
      //console.log(images);
      this.photos = images;
      document.body.style.overflowY = "hidden";
      this.isOpen = true;
      this.$set(this.options, "index", index);
      this.currentBofferPhoto = item.id;
    },
    hidePhotoSwipe() {
      document.body.style.overflowY = "scroll";
      this.isOpen = false;
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.boffer_list {
  text-align: left;
  padding: 10px;
  padding-top: 0;
  overflow-y: scroll;
  max-height: 100%;
  position: relative;
}

.cont_boffer {
  margin-bottom: 10px;
  padding: 0 0 10px 10px;
}

.bottom_border {
  border-bottom: dashed 1px #b4b4b4;
}

.cont_image {
}

.boffer_body {
  background-color: #f1f1f1;
  width: fit-content;
  padding: 1px 20px;
}

.cont_image img {
  max-width: 120px;
  max-height: 120px;
  margin-right: 5px;
  border-radius: 5px;
  vertical-align: middle;
  transition: all 200ms;
}
.seen {
  background-color: rgb(230, 255, 206);
  width: fit-content;
  padding: 2px 5px;
}
.seen span {
  font-size: 11px;
  opacity: 0.5;
}
.title span {
  font-size: 11px;
  font-weight: 400;
  color: #8a8a8a;
  margin-left: 5px;
}
@media only screen and (min-width: 701px) {
  .cont_image img:hover {
    filter: brightness(1.2);
  }
}
</style>
