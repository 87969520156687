<template>
  <div class="cont">
    <zar-list linkDirect :miniMode="miniMode" :items="items" :userMenu="userMenu" v-on:clickedItem="clickedItem($event)" v-on:reachedBottom="reachedBottom" :loading="loading" />
    <ui-paging v-if="totalPage >= pageNum && !noPaging && totalPage > 1" pagedView :curPage="pageNum" :totalPage="totalPage" v-on:clickedPageNum="pageNum = $event" v-on:next="next()" />
    <!-- <ui-paging :totalPage="totalPage" :itemsPerPage="pageLim" :currentPage="pageNum" @pageChanged="pageNum = $event" /> -->
    <!-- <div v-else class="disabled fs-12 w-100 ta-c">Дууссан...</div> -->

    <!-- <div v-if="totalPage > 1" class="pn_cont">
      <span v-for="(p, ind) in totalPage" :key="'pan' + ind">
        <span class="page_num" :class="{ selected: pageNum == p }" @click="pageNum = p">{{ p }}</span>
      </span>
    </div> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import UserService from "@/service/user";
import zarList from "@/comp/zar/list.vue";
import uiPaging from "@/comp/ui/paging.vue";

export default {
  name: "user-zar-list",
  components: { zarList, uiPaging },
  props: {
    nameSrc: String,
    userMenu: { type: Boolean, default: false },
    miniMode: { type: Boolean, default: false },
    noPaging: { type: Boolean, default: false },
    pageLim: { type: Number, default: 30 },
    customData: [Object, Number],
  },
  data() {
    return {
      loading: false,
      pageNum: 1,
      // pageLim: 12,
      totalPage: 0,
      items: [],
    };
  },
  methods: {
    clickedItem() {
      this.$emit("clickedItem", this.items);
    },
    async fetchUserItems() {
      return await UserService.getUserItems(this.pageNum, this.pageLim).then((res) => res.data);
    },
    async fetchUserFavs() {
      return await UserService.getUserFavs(this.pageNum, this.pageLim).then((res) => res.data);
    },
    async fetchUserViewedItems() {
      return await UserService.getUserViewedItems().then((res) => res.data);
    },
    async fetchCarBarter() {
      return await UserService.getUserCarBarterItems().then((res) => res.data);
    },

    // async fetchGuestFavs() {
    //   return await UserService.getGuestFavs(this.getLocal("favs")).then((res) => res.data);
    // },

    async resetItems() {
      this.pageNum = 1;
      this.items = await this.getItems();
    },

    reachedBottom() {
      // if (this.isMobile && this.pageNum < this.totalPage) this.next();
    },

    async next() {
      this.pageNum++;
      let items = await this.getItems();
      this.items = [...this.items, ...items];
    },

    prep(data) {
      data.items.forEach((z) => {
        z.pinfo.forEach((i) => {
          if (i.pcomp_tp == 3 && z["p" + i.id]) {
            let photos = z["p" + i.id];
            i.val = photos;
            i.total = photos.length;
          } else {
            i.val = z["p" + i.id + "_val"] ? z["p" + i.id + "_val"] : z["p" + i.id];
            if (i.conver && i.val) i.val = this.converters(i.conver, i.val);
            i.dep = z.dep;
          }
        });
        // z.date = this.$moment.unix(z.rdate, "YYYYMMDD").fromNow(true);
        z.date = this.$date.unix(z.rdate).fromNow(true);
        if (data.dis_tp) z.disTp = data.dis_tp.find((e) => e.id == z.dis);
      });
      this.totalPage = data.totalPage;
      return data;
    },

    async syncItems() {
      // console.log(this.nameSrc);

      this.loading = true;
      this.items = [];
      let data = [];
      if (this.nameSrc == "my") {
        let fetchData = await this.fetchUserItems();
        data = this.prep(fetchData).items;
      }

      if (this.nameSrc == "favs") {
        let fetchData = await this.fetchUserFavs();
        data = this.prep(fetchData).items;
      }

      if (this.nameSrc == "most_viewed_my") {
        let fetchData = await this.fetchUserViewedItems();
        data = this.prep(fetchData).items;
      }

      if (this.nameSrc == "car") {
        let fetchData = await this.fetchCarBarter();
        data = this.prep(fetchData).items;
      }

      // if (this.nameSrc == "favs_guest") {
      //   let favs = this.getLocal("favs");
      //   let fetchData = await this.fetchGuestFavs();

      //   data = this.prep(fetchData).items;

      //   let mappedData = data.map((e) => e.zid);
      //   let filtered = favs.filter((e) => mappedData.includes(e.zid));
      //   this.saveLocal("favs", filtered);
      // }

      this.items = data ?? [];
      this.loading = false;
    },
  },

  watch: {
    // items() {
    //   console.log(this.items.length, this.pageLim);

    //   this.$emit("lessThanPage", this.items.length <= this.pageLim);
    // },
    pageNum() {
      this.syncItems();
      // this.$bus.emit("scrollTo", "#header");
    },
    refreshZarList() {
      this.syncItems();
    },
    nameSrc: {
      immediate: true,
      async handler() {
        this.syncItems();
        this.pageNum = 1;
      },
    },
  },
  computed: {
    refreshZarList() {
      return this.$store.state.user.refreshZarList;
    },
    gLoading: {
      get() {
        return this.$store.state.gLoading;
      },
      set(val) {
        this.$store.commit("gLoading", val);
      },
    },
    user() {
      return this.$store.state.auth.status.logged ? this.$store.state.auth.user : false;
    },
    devid() {
      return this.getLocal("devid", true);
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.page_num {
  margin-right: 5px;
  background-color: #f1f1f1;
  border: solid 1px #f1f1f1;

  padding: 4px 8px;
  border-radius: 2px;
}

.selected {
  background: none;
  border: solid 1px #e2e2e2;
  padding: 5px 10px;

  /* font-weight: 600; */
}

@media only screen and (min-width: 600px) {
  .page_num:hover {
    background-color: #e2e2e2;
    border: solid 1px #e2e2e2;

    cursor: pointer;
  }
  .selected:hover {
    background: none;
  }
}
</style>
