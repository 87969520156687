Array.prototype.first = function() {
  return this.find((e) => e);
};

Array.prototype.last = function() {
  return this[this.length - 1];
};

Array.prototype.numLength = function() {
  return ((Math.log(Math.abs(this) + 1) * 0.43429448190325176) | 0) + 1;
};
