<template>
  <div class="cont_user_zar_action">
    <ui-dmenu
      menuImage="i-menu3.svg"
      :items="[
        { name: 'Шинэчлэх', action: 'renew' },
        { name: 'Засах', action: 'edit' },
        { name: 'Устгах', action: 'delete' },
      ]"
      v-on:clickedItem="action($event)"
      v-on:closeMenu="showMenu = null"
      :showMenu="showMenu"
    >
      <div class="trigger" @click.stop="showMenu = !showMenu" @mouseover="showMenu = true"><img :src="'/icon/i-menu3.svg'" height="20" /></div>
    </ui-dmenu>

    <!-- <modal name="modalDeleteItem" width="300" :height="'auto'">
      <div class="cont_modal_delete_item ta-c">
        <div class="title_modal">Зар устгах</div>
        <div>
          <div v-for="(s, k) in status" :key="'status' + k"><input type="checkbox" id="checkbox" v-model="check" />{{ s.name }}</div>
        </div>
        <div>
          <ui-button text="Хадгалах" v-on:clicked="saveTitle()" widthSize="wide" />
          <ui-button text="Болих" v-on:clicked="$modal.hide('photo_title')" tp="simple" />
        </div>
      </div>
    </modal> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiDmenu from "@/comp/ui/dmenu.vue";

export default {
  name: "user-zar-action",
  components: { uiDmenu },
  props: {
    obj: Number,
    zid: String,
    rdate: Number,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    convert(date) {
      return this.$date.unix(date).format("MM.DD");
    },
    action(ob) {
      if (ob.action == "renew") this.$store.dispatch("user/renewItem", { obj: this.obj, zid: this.zid });
      if (ob.action == "edit") this.$store.dispatch("user/editItem", { obj: this.obj, zid: this.zid });
      if (ob.action == "delete") {
        // this.$modal.show("modalDeleteItem");
        this.$bus.emit("openModal", { name: "mainDeleteItem", dataSet: { obj: this.obj, zid: this.zid } });
        // this.$modal.show("dialog", {
        //   title: "Зар устгах",
        //   text: "Таны устгасан зар хэсэг хугацаанд идэвхигүй байрших бөгөөд энэ хугацаанд та устгасан зартайгаа ижил зар оруулж болохгүйг анхаарна уу",
        //   buttons: [
        //     {
        //       title: "Устгах",
        //       handler: () => {
        //         this.$store.dispatch("user/deleteItem", { obj: this.obj, zid: this.zid, statusCode: 2 });
        //         this.$modal.hide("dialog");
        //         this.$emit("reloadItem");
        //       },
        //     },
        //     {
        //       title: "Болих",
        //       handler: () => {
        //         this.$modal.hide("dialog");
        //       },
        //     },
        //   ],
        // });
      }
    },
  },
  computed: {
    // dateToday() {
    //   let today = new Date(Date.now());
    //   return today.getFullYear() + "." + (today.getMonth() + 1) + "." + today.getDate();
    // },
    // dateItem() {
    //   let idate = new Date(this.rdate);
    //   return idate.getFullYear() + "." + (idate.getMonth() + 1) + "." + idate.getDate();
    // },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_user_zar_action {
  /* text-align: left; */
}
</style>
