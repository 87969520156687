<template>
  <div class="cont_home_zar_map mb-2">
    <span class="items_total">{{ items.length }} зар</span>
    <ui-loc-grab
      v-on:mouseDown="mapMouseDown()"
      :mapHeight="800"
      :zoom="gzoom"
      :geoJson="geoJson"
      :center="[47.9188, 106.9176]"
      :label="label"
      :pins="pins"
      :markers="markers"
      :enableCenterMarker="false"
      :enSatView="false"
      v-on:zoomUpdated="zoomUpdated($event)"
      v-on:centerUpdated="centerUpdated($event)"
      v-on:boundsUpdated="boundsUpdated($event)"
      v-on:clickedMarker="clickedMarker($event)"
    />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiLocGrab from "@/comp/ui/locGrab.vue";

export default {
  name: "home-zar-loc-map",
  components: {
    uiLocGrab,
  },
  props: { items: Array },
  data() {
    return {
      gcenter: [],
      gzoom: 12,
      zoom: null,
      center: null,
      bounds: null,
      refs: [],
      selected: [],
      cache: [],
      gcode: null,
      validGcode: 0,
      geoJson: [],
      // cacheGeo: [],
      userDragged: true,
      userTyped: false,
      nextReqGcodeSize: 0,
      label: null,
      timer: null,
      pins: [],
    };
  },
  methods: {
    zoomUpdated() {},
    centerUpdated() {},
    boundsUpdated() {},
    clickedMarker(ob) {
      this.$emit("clickedMarker", ob);
    },
    mapMouseDown() {},
    // showGeoJson(ob) {
    //   if (ob.pob.ref && ob.item) this.forceSel = { ref: ob.pob.ref, sel: ob.item, action: "hover" };
    // },
    // updatedMapLoc() {
    // },
  },
  watch: {
    // showMap() {
    //   this.saveLocal("user_settings_show_map", this.showMap);
    // },
  },
  mounted() {
    //console.log(this.items);

    // this.showMap = this.hasLocal("user_settings_show_map") ? this.getLocal("user_settings_show_map") : true;
    // this.$bus.on("mouseOverFilterItem", this.showGeoJson);
  },
  computed: {
    // fitems() {
    //   return this.$store.state.zar.filter.fitems.home;
    // },
    markers() {
      let collect = [];
      let coord;
      this.items.forEach((e) => {
        let locationPoint = e.pinfoSide.find((f) => f.ptype == "location_point");
        if (!locationPoint) return;

        let value = e["p" + locationPoint.id];
        if (this.isJson(value)) {
          value = JSON.parse(value);
          coord = { lat: value[0], lng: value[1] };
        } else coord = value;

        let price = e.pinfo.find((p) => p.ptype == "price");
        let image = e.pinfo.find((p) => p.ptype == "image");
        let title = e.pinfo.find((p) => p.ptype == "title");

        collect.push({ zid: e.zid, obj: e.obj, name: `<img src="${this.apiUrl}/media/zar/${e.obj}/${e.zid}/${image.id}/t${image.val.first().id}.jpg" /><br>${title.val}<br><b>${price.val}</b>`, coord });
      });

      // zid: e.zid,
      // let coord = [];
      // let found = this.filter.find((e) => e.ref && e.ref == "ref_location_town");
      // if (found) {
      //   let foundFitem = this.fitems.find((f) => f.obj == found.obj && f.id == found.prop && f.sel.find((s) => s.id == found.sid));
      //   if (foundFitem) coord = JSON.parse(foundFitem.sel.find((s) => s.id == found.sid).coord);
      // }
      // return coord.length ? [{ coord: { lat: coord[0], lng: coord[1] } }] : [];
      //console.log(collect);

      return collect;
    },
    // forceSel() {
    //   let found = this.filter.find((e) => e.ref && ["ref_location_section", "ref_location_area"].includes(e.ref));
    //   return found ? { ref: found.ref, sel: { id: found.sid } } : null;
    // },
    // ...mapState({
    //   obj: (s) => s.selObj,
    //   filter: (s) => s.zar.filter.filter.home,
    // }),
    // scr() {
    //   return this.$store.getters.scr;
    // },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_home_zar_map {
  position: relative;
  /* height: 220px !important; */
  margin-top: 0px;
  z-index: 20;
}

.items_total {
  position: absolute;
  z-index: 30;
  background-color: #fff;
  right: 10px;
  top: 15px;
  border: solid 2px #ccc;
  padding: 1px 7px;
  border-radius: 3px;
}

.toggler {
  z-index: 2;
  position: absolute;
}
</style>
