<template>
  <div class="cont_ui_sort">
    <ui-icon name="sort" size="xl" class="po-ab left-2 top-2" />
    <div class="cont_sort">
      <div v-for="(s, i) in items" :key="'sort' + s.id + 'i' + i" style="display:inline-block">
        <span class="sep_dot" v-if="i != 0">.</span>
        <span class="item" @mouseover="showMenu = s.id" :class="{ contrast: selected, others: !s.sel && selected, hidden: !s.sel, selected: s.sel, dis: hidden.find((e) => e == s.id) || disabled }">
          {{ items.length > 3 ? s.sname : s.name }}
          <img :src="'/icon/i-down-arrow.svg'" :class="{ rev: s.sel == 1 }" />
        </span>
        <ui-dmenu
          :showMenu="showMenu == s.id"
          :items="[
            { name: s.custom ? s.custom[0] : 'Өсөхөөр', action: 1 },
            { name: s.custom ? s.custom[1] : 'Буурахаар', action: 2 },
          ]"
          v-on:clickedItem="clickedItem(s.id, $event.action)"
          v-on:closeMenu="showMenu = null"
          :id="s.id"
        />
      </div>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiDmenu from "@/comp/ui/dmenu.vue";

export default {
  components: { uiDmenu },
  props: {
    items: Array,
    hidden: Array,
    disabled: Boolean,
  },
  name: "ui-sort",
  data() {
    return {
      showMenu: null,
    };
  },
  methods: {
    clickedItem(id, action) {
      if (action == 2) id = id + 1000;
      if (!this.disabled) this.$emit("clickedItem", { id, action });
    },
  },
  computed: {
    selected() {
      return this.items.find((e) => e.sel);
    },
  },
  mounted() {
    console.log(this.items);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_sort {
  user-select: none;
  margin: 7px 0 0 0;
  padding-left: 17px;
  position: relative;
}
.cont_sort {
  background-color: #f1f1f1;
  width: fit-content;
  border-radius: 8px;
  margin-left: 10px;
  /* padding: 2px 5px; */
}
.item {
  display: inline-block;
  margin: 0 5px 0px 0;
  padding: 4px 6px;
  /* line-height: 1; */

  font-weight: inherit;
  position: relative;
  /* margin-right: 17px; */
  transition: all 150ms;
  font-weight: var(--base_weight);
  /* font-stretch: 95%; */
}
.item:hover {
  background-color: #e6e6e6;
  border-radius: 8px;
}
.contrast {
  color: var(--color_darker) !important;
}
.sep_dot {
  position: relative;
  margin: 0 4px;
  font-size: 24px;
  top: -3px;
  font-weight: var(--base_weight);
  color: #a7a7a7;
}
.item img {
  position: absolute;
  top: 5px;
  width: 12px;
  transform: rotate(0deg);
  transition: all 150ms;
  opacity: 1;
  right: 4px;
  /* filter: invert(100%) sepia(3%) saturate(20%) hue-rotate(89deg) brightness(103%) contrast(100%); */
}
.item.hidden {
  margin-right: 0px;
}
.others {
  opacity: 0.8;
}
.others:hover {
  opacity: 1;
}
.rev {
  transform: rotateX(180deg) !important;
}
.item.hidden img {
  transition: all 150ms;
  width: 0px !important;
  opacity: 0;
}
.item:active {
  /* transform: translateY(1px); */
}
.selected.item {
  /* font-weight: 600; */
  /* color: #bf20d8 !important; */
  /* border: solid 1px #ccc; */
  padding: 2px 22px 1px 5px;
  border-radius: 4px;
  background-color: var(--color_selected);
}
.dis,
.dis:hover,
.dis:active {
  color: #bdbdbd !important;
  cursor: text;
  background: transparent;
  transform: none;
  transition: all 100ms;
}

@media only screen and (max-width: 700px) {
  .cont_ui_sort {
    width: 90%;
  }
}
</style>
