<template>
  <div class="cont_zar_sort_simple" @click="selection = true" :class="{ selected: collect }">
    <div class="collect">
      <ui-icon name="sort" size="xxl" class="icon_sort" /> Эрэмблэлт <span v-if="collect" class="b">- {{ generatedText }}</span>
    </div>
    <ui-select-drawer title="Эрэмблэх" :visible="selection" compName="homeSortSelect" v-on:close="selection = false" :items="items" :location="scr == 1 ? 'bottom' : 'right'" v-on:clickedItem="clickedItem($event)" :showCloseButton="showCloseButton" :collect="collect" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectDrawer from "@/comp/ui/selectDrawer.vue";

export default {
  props: {
    items: Array,
    hidden: Array,
    disabled: Boolean,
    collect: Object,
    showCloseButton: Boolean,
  },
  components: {
    uiSelectDrawer,
  },
  name: "ui-sort-simple",
  data() {
    return {
      selection: false,
      showPicker: false,
    };
  },
  methods: {
    clickedItem(ob) {
      if (!this.disabled) this.$emit("clickedItem", ob);
      this.selection = false;
    },
  },
  watch: {
    collect() {
      if (!this.collect) this.selected = null;
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    // drawerCollect() {
    // if (!this.collect) return null;
    // },
    generatedText() {
      if (!this.collect) return "";
      if (this.collect.custom) return this.collect.custom[this.collect.sel - 1];
      return this.collect.name + " - " + (this.collect.sel == 1 ? "өсөхөөр" : "буурахаар");
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_sort_simple {
  user-select: none;
  /* margin: 7px 0 0 0; */
  position: relative;
  background-color: #f1f1f1;
  text-align: left;
  width: fit-content;
  padding: 12px 10px 10px 37px;
  width: fit-content;
}
.icon_sort {
  position: absolute;
  margin-left: -25px;
  margin-top: -3px;
}
.collect {
  font-size: 14px;
}

.cont_zar_sort_simple.selected {
  /* background-color: var(--color_selected); */
}

.cont_zar_sort_simple.selected img {
  /* filter: invert(100%) sepia(3%) saturate(20%) hue-rotate(89deg) brightness(103%) contrast(100%); */
}

@media only screen and (max-width: 700px) {
  .collect {
    font-size: 16px;
  }
  .cont_zar_sort_simple {
    width: 100%;
  }
}
</style>
