<template>
  <div class="cont_user_car">
    <div class="title_modal">Миний машин</div>
    <div class="mb-1"><span class="prop_name">Үйлдвэрлэгч</span><ui-select :items="makes" :collect="car.make" :zind="1" v-on:clickedItem="clickedMake($event)" :floating="false" /></div>
    <div class="mb-3"><span class="prop_name">Загвар</span><ui-select :items="models" :collect="car.model" :zind="2" v-on:clickedItem="clickedModel($event)" :floating="false" /></div>
    <div class="mb-3">
      <span class="prop_name">Үйлдвэрлэсэн он</span><ui-input v-if="!car.year" v-on:typed="typed($event)" :collect="car.year" :disabled="false" :unmask="true" :pat="'9999'" :min="4" :max="4" :minWidth="8" />
      <div class="link" v-if="car.year" @click="resetYear()">{{ car.year }}</div>
    </div>

    <ui-button v-on:clicked="save()" text="Хадгалах" :disabled="!complete" />
    <ui-button v-on:clicked="close()" tp="textOnly" text="Болих" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelect from "@/comp/ui/select.vue";
import uiInput from "@/comp/ui/input.vue";

export default {
  name: "user-car",
  components: { uiSelect, uiInput },
  props: {},
  data() {
    return {
      car: { make: "", model: "", year: "" },
      makes: [],
      models: [],
    };
  },
  methods: {
    resetYear() {
      this.car.year = "";
    },
    async clickedMake(make) {
      this.car.make = { id: make.id, name: make.name };
      this.models = await this.axiosGet("/zar/object/getRelData", [1, 5, make.id, 0, 3]);
    },
    clickedModel(model) {
      this.car.model = { id: model.id, name: model.name };
    },
    typed(year) {
      if (year >= 1990 && year <= new Date().getFullYear()) this.car.year = year;
      else
        this.$message({
          type: "warning",
          message: "Буруу он байна",
          showClose: false,
          hasMask: false,
          duration: 2000,
        });
    },
    async fetchUserLoc() {
      if (this.user) await this.$store.dispatch("user/updateUserInfo");
      if (this.userInfo.car_make) this.car = { make: { id: this.userInfo.car_make, name: this.userInfo.car_make_name }, model: { id: this.userInfo.car_model, name: this.userInfo.car_model_name }, year: { name: this.userInfo.car_year.name } };
    },
    async fetchMake() {
      this.makes = await this.axiosGet("/zar/object/getRelData", [1, 4, 0, 0, "ref_make"]);
    },
    async save() {
      this.close();
      await this.axiosPost("/utility/location/saveCar", { data: this.car });
      this.$message({
        type: "info",
        message: "Амжилттай хадгалагдлаа",
        showClose: false,
        hasMask: false,
        duration: 2000,
      });
      await this.$store.dispatch("user/updateUserInfo");
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    complete() {
      return this.car.make && this.car.model && this.car.year;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    // this.fetchUserLoc();
    this.fetchMake();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_user_car {
  text-align: left;
  padding: 10px;
  padding-top: 0;
}
.suggest_text {
  max-width: 100%;
}
</style>
