<template>
  <div class="cont_edit_info">
    <div class="title_modal">Мэдээлэл засах</div>
    <span class="link" @click="changePass()">Нууц үг солих</span>
    <!-- <div class="pt-1"><ui-button v-on:clicked="submit()" text="Хадгалах" /> </div> -->
    <div class="mt-3"><ui-button v-on:clicked="close()" text="Хаах" /></div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
//import User from "@/model/user";

export default {
  name: "user-edit-info",
  data() {
    return {};
  },
  methods: {
    changePass() {
      this.$bus.emit("openModal", { name: "restorePass" });
      this.close();
    },
    close() {
      this.$emit("close");
    },
    submit() {
      let modifiedProps = this.zar.props.filter((p) => JSON.stringify(p.collect) != JSON.stringify(p.collect_b));
      // console.log(modifiedProps);
      if (modifiedProps.length) this.$store.dispatch("user/saveItem", { obj: this.zar.obj, zid: this.zar.zid, props: modifiedProps });
      // this.$store.dispatch("user/saveItem", { obj: this.zar.obj, zid: this.zar.zid, props: this.zar.props });
      this.close();
    },
    clickedItem(pid, val) {
      // console.log(val);
      this.zar.props.find((p) => p.id == pid && (p.collect = val));
    },
    mac() {
      let gamepads = navigator;
      // let firstGamepad = gamepads[0];
      // let macAddress = firstGamepad.id;
      console.log(gamepads);
    },
  },
  computed: {
    zar() {
      return this.$store.state.user.zarEdit.data;
    },
  },
  mounted() {
    this.mac();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_edit_info {
  text-align: left;
  padding: 10px;
  padding-top: 0;
}
</style>
