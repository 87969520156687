<template>
  <ch-drawer :title="title" :visible.sync="visible" v-on:close="close()" :area="drawerHeight" :blur="false" :location="location" mask destroy-on-close :escape-on-click="false" @open="$store.commit('addOpenDrawers', compName)" @close="$store.commit('removeOpenDrawers', compName)">
    <template v-for="(s, ind) in items">
      <div class="item" :class="{ selected: collect && collect.id == s.id, com: s.com }" :key="compName + '' + ind + '' + s.id" @click="clickedItem(s)">
        {{ s.name }}
        <span v-if="s.unit" class="unit">{{ s.unit }} </span>
        <span v-if="unit" class="unit">{{ unit }} </span>
        <span v-if="showZnum && s.znum" class="znum">{{ s.znum }}</span>
        <span v-if="collect && collect.id == s.id" class="selected_icon"><ui-icon class="back_icon disabled" angle="rev" name="i-close4" size="xxl"/></span>
      </div>
    </template>
    <div v-if="showCloseButton" class="return_button_top" @click="close()"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
  </ch-drawer>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  props: {
    compName: String,
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: Boolean,
    collect: [Object, Number, String],
    showCloseButton: Boolean,
    location: String,
    title: String,
    visible: Boolean,
    unit: String,
    showZnum: Boolean,
  },
  name: "ui-select-drawer",
  data() {
    return {};
  },
  methods: {
    clickedItem(ob) {
      if (!this.disabled) this.$emit("clickedItem", ob);
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    drawerHeight() {
      return this.items.length > 10 ? "450px" : this.items.length * 40 + 45 + "px";
    },
  },
  mounted() {
    // console.log(this.visible);
  },
  beforeDestroy() {
    this.$store.commit("removeOpenDrawers", this.compName);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.item {
  display: block;
  padding: 11px 10px 9px 60px;
  font-size: 16px;
  position: relative;
  transition: all 150ms;
  border-top: solid 1px #f1f1f1;
  text-align: left;
  font-weight: var(--base_weight);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  transition: all 150ms;
}
.item.com {
  font-weight: 600;
}
.item.selected {
  background-color: var(--color_selected);
}
.item .unit {
  font-size: 15px;
  font-weight: var(--base_weight);
  opacity: 0.7;
}
.item:active {
  background-color: var(--color_selected);
  transform: translateX(-50%) scale(1.02);
}
.item .znum {
  color: #b1b1b1;
  font-size: 12px !important;
  font-weight: var(--base_weight) !important;
}
.selected_icon {
  position: absolute;
  right: 50px;
}
</style>
