<template>
  <div class="cont_home_zar_list" id="cont_home_zar_list">
    <div class="cont_spacer"></div>
    <!-- <div class="mt-3 pl-1 ta-c fs-12" v-if="pagedView && pageNum != 1">
      <span class="page_num">Хуудас {{ pageNum }}</span> / {{ totalPage }}
    </div> -->

    <div class="blink_me" v-if="loading">.</div>
    <zar-loc-map v-if="viewItemLoc && selObj && selObj.mod && selObj.mod.find((o) => o.module == 4)" :items="items" v-on:clickedMarker="clickedItem($event)" />
    <ui-reflow>
      <div v-if="newZar.length" class="mt-3 warning_text clickable b" @click="clickedNewItem()">{{ newZar.length }} шинэ зар</div>
    </ui-reflow>

    <div v-if="!viewItemLoc && pageNum > 1" class="pt-0">
      <ui-paging v-if="totalPage >= pageNum" :pagedView="pagedView" :curPage="pageNum" :totalPage="totalPage" :nextButText="nextButText" v-on:clickedPageNum="changePage($event)" v-on:next="next()" />
    </div>

    <ui-reflow :disabled="!isMobile" v-if="!viewItemLoc">
      <zar-list
        :items="items"
        :cusFieldAllowed="cusFieldAllowed"
        :prevZar="prevZar"
        :pageLimit="pageLimit"
        v-on:reachedBottom="reachedBottom"
        v-on:clickedItem="clickedItem($event)"
        v-on:clickedRight="clickedRight($event)"
        v-on:clickedImage="clickedImage($event)"
        :loading="loading"
        :activeSort="activeSort"
        :enableNewlyListed="!enabledFilter && !enabledSort && pageNum == 1"
        :horizontalItems="horizontalItems"
        imagePreviewList
      />
    </ui-reflow>

    <div v-if="!viewItemLoc" class="pt-5">
      <ui-paging v-if="totalPage >= pageNum" :pagedView="pagedView" :curPage="pageNum" :totalPage="totalPage" :nextButText="nextButText" v-on:clickedPageNum="changePage($event)" v-on:next="next()" />
      <div v-else class="disabled fs-12 w-100 ta-c">Дууссан...</div>
    </div>
    <span v-hotkey="keymapHome"></span>
    <ui-photos-list v-if="photosList" :ind="0" :images="photosList" v-on:closephotosList="closephotosList" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import { mapState } from "vuex";
import zarList from "@/comp/zar/list.vue";
import uiPaging from "@/comp/ui/paging.vue";
import zarLocMap from "./zarLocMap.vue";
import uiPhotosList from "@/comp/ui/photosList.vue";

export default {
  name: "home-zar-list",
  components: { zarList, uiPaging, zarLocMap, uiPhotosList },
  props: {
    prevZar: String,
    zarFocus: String,
    cusFieldAllowed: Array,
    viewItemLoc: Boolean,
    horizontalItems: Boolean,
  },
  data() {
    return {
      photosList: null,
      // photoInd: null,
      isOpen: false,
      cacheEnabled: false,
      // pageNum: 1,
      loading: false,
      // pageLimit: this.isMobile ? 25 : 50,
      // totalPage: 0,
      options: {
        // Gallery options
        maxSpreadZoom: 1,
        getDoubleTapZoom: (isMouseClick) => {
          return isMouseClick ? 1.2 : 0.5;
        },
        zoomEl: false,
        shareEl: false,
        index: 0,
        loop: false,
        preload: [1, 4],
        // history: false,
        getThumbBoundsFn: () => {
          return null;
        },
      },
      images: [],
    };
  },
  methods: {
    closephotosList() {
      this.photosList = null;
    },
    async clickedImage({ obj, zid, p }) {
      this.itemViewed(obj, zid);
      this.photosList = this.prepPhoto(this.deepClone(p.val), obj, zid, p.id);
    },

    itemViewed(obj, zid) {
      this.axios.get("/zar/item/userViewedItemPhoto/" + obj + "/" + zid + "/home");
    },
    prepPhoto(el, obj, zid, pid) {
      let url = this.apiUrl + "/media/zar/" + obj + "/" + zid + "/" + pid + "/";
      let images = [];
      el.forEach((i) => {
        let pn = i.id + ".jpg";
        images.push({
          id: i.id,
          src: url + "b" + pn,
          tsrc: url + "t" + pn,
          msrc: url + "m" + pn,
          title: i.title,
          w: i.w,
          h: i.h,
        });
      });
      return images;
    },
    async getItems(pageNum = this.pageNum) {
      // this.items = [];
      // console.log("triggered get items page:", pageNum);
      this.gLoading = true;
      // let timer = setTimeout(() => (this.gLoading = true), 200);
      if (this.pendingFilSel > 1) return [];
      this.loading = true;

      let res = await this.axiosPost("/zar/item/getItem", {
        obj: this.selObj.id,
        nextNum: pageNum,
        pageLimit: this.pageLimit,
        filter: this.filter.map((e) => {
          return { prop: e.prop, sid: e.sid, tp: e.tp, sval: e.sval };
        }),
        sort: this.sort
          .filter((e) => e.sel)
          .map((e) => {
            return { prop: e.id, sel: e.sel };
          }),
      });

      this.loading = false;

      // let url = "/zar/item/getItem/" + this.selObj.id + "/" + pageNum + "/" + this.pageLimit;
      // url +=
      //   "/" +
      //   JSON.stringify(
      //     this.filter.map((e) => {
      //       return { prop: e.prop, sid: e.sid, tp: e.tp, sval: e.sval };
      //     })
      //   );
      // url +=
      //   "/" +
      //   JSON.stringify(
      //     this.sort
      //       .filter((e) => e.sel)
      //       .map((e) => {
      //         return { prop: e.id, sel: e.sel };
      //       })
      //   );

      // this.axios
      //   .get(url, {})
      //   .then((res) => {
      let newItems = res.item;
      let newPinfo = res.pinfo;
      let pinfoSide = res.pinfo_side;

      let aid = null;
      let findSide = pinfoSide.find((e) => e.ptype == "location_area");
      if (findSide) aid = findSide.id;
      else {
        let findMain = newPinfo.find((e) => e.ptype == "area");
        if (findMain) aid = findMain.id;
      }

      this.totalPage = res.total_page;
      if (newItems && newItems.length)
        newItems.forEach((z) => {
          z.act = null;
          if (res.dis_tp) z.disTp = res.dis_tp.find((e) => e.id == z.dis);
          z.pinfo = this.deepClone(newPinfo);
          z.pinfoSide = this.deepClone(pinfoSide);
          z.pinfo.forEach((i) => {
            if (i.pcomp_tp == 3 && z["p" + i.id]) {
              let photos = z["p" + i.id];
              i.val = photos;
              i.total = photos.length;
            } else {
              i.val = z["p" + i.id + "_val"] ? z["p" + i.id + "_val"] : z["p" + i.id];
              if (i.conver && i.val) i.val = this.converters(i.conver, i.val);
              i.dep = z.dep;
            }
          });
          z.date = this.$date.unix(z.rdate).fromNow(true);
          // z.nearLoc = aid && this.userInfo ? (z["p" + aid] == this.userInfo.loc_day ? 1 : z["p" + aid] == this.userInfo.loc_night ? 2 : false) : false;
          let tp = null;
          if (aid && this.userInfo) {
            let zarArea = z["p" + aid];
            let userDayArea = this.userInfo.loc_day;
            let userNightArea = this.userInfo.loc_night;

            if (zarArea == userDayArea) tp = 1;
            if (zarArea == userNightArea) tp = 2;
            if (!tp && this.nearestLoc) {
              let area = this.nearestLoc.find((e) => e.area == zarArea);
              if (area) {
                let nearest = area.nearest.split(",");
                let find1 = nearest.find((e) => e == userDayArea);
                let find2 = nearest.find((e) => e == userNightArea);
                if (find1) tp = 3;
                if (find2) tp = 4;
              }
            }
          }
          z.nearLoc = tp;
          //z.date = this.$moment.unix(z.rdate, "YYYYMMDD").fromNow(true);
        });
      // console.log(newItems);
      if (this.gLoading) this.gLoading = false;
      // else clearTimeout(timer);

      // return Object.freeze(newItems);
      return Object.freeze(newItems);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //  })
      //  );
    },

    nearLoc(item) {
      let find = item.pinfoSide.find((e) => e.ptype == "location_area");
      let aid;
      if (find) aid = find.id;
      console.log(aid);
      return false;
    },

    clickedRight(ob) {
      this.$store.commit("user/collectChange", ob);
    },

    clickedNewItem() {
      if (this.filter.length) this.$bus.emit("resetFilter");
      else this.resetItems();
    },
    async resetItems() {
      this.pageNum = 1;
      this.items = await this.getItems();
      let lastItemCdate = this.items.filter((e) => !e.spo);
      if (this.cacheEnabled) this.cacheNextPage();

      if (!lastItemCdate) return;

      if (!this.filter.length && this.pageNum == 1 && !this.activeSort) {
        this.$store.commit("resetNewZar");
        if (lastItemCdate[0]) this.$store.commit("lastZarCdate", lastItemCdate[0].rdate);
      }

      // this.$store.commit("resetNewZar");
      // this.$store.commit("addNewZar", [lastItem]);
      // list.forEach((e) => {
      //   let found = this.newZar.find((n) => (n.zid = e.zid));
      //   if (!found) this.$store.commit("addNewZar", e);
      // });
      // if (this.newZar.find((e)=>)) this.newZar = { obj: lastItem.obj, zid: lastItem.zid, cdate: lastItem.cdate };
    },

    async cacheNextPage() {
      let nextItems = await this.getItems(this.pageNum + 1);
      if (nextItems) this.saveLocal("nextPage", nextItems);
    },

    reachedBottom() {
      // if (!this.pagedView && this.pageNum < this.totalPage) this.next();
    },

    async changePage(pageNum) {
      // this.items = [];
      // let items = this.cacheEnabled && pageNum > this.pageNum ? this.getLocal("nextPage") : await this.getItems(pageNum);
      // setTimeout(() => {
      //   this.$scrollTo("#cont_home_zar_list", 500);
      // }, 1000);

      this.pageNum = pageNum;
      // let items = await this.getItems();
      // this.items = items;
      // console.log("scrolled yeah");

      // if (this.cacheEnabled) this.cacheNextPage();
    },
    async changePageFromGlobal(pageNum) {
      this.items = [];
      this.pageNum = pageNum;
      // let items = await this.getItems();
      // this.items = items;
    },

    async next(pagedView = this.pagedView) {
      this.pageNum++;
      let items = this.cacheEnabled && this.getLocal("nextPage") ? this.getLocal("nextPage") : await this.getItems();
      if (pagedView) this.items = items;
      else this.items = [...this.items, ...items];
      if (this.cacheEnabled) this.cacheNextPage();
    },

    clickedItem({ obj, zid }) {
      let mapCollect = this.collect.map((e) => e.zid);
      if (mapCollect.includes(zid)) {
        this.$store.commit("zar/itemSource", { name: "Сонгосон зарууд", src: "collect" });
      } else {
        if (this.filter.length || this.activeSort) this.$store.commit("zar/itemSource", { name: "Хайлтын зарууд", src: "homeZarList" });
        else {
          // this.$store.commit("zar/itemSource", { name: "Төстэй зарууд", src: "related_items", data: items });
          this.$store.commit("zar/itemSourceReset");
        }
      }

      if (this.scr == 1) {
        this.$bus.emit("openDrawer", { name: "viewItem", obj, zid });
        return;
      }

      // let query; VIEW FIRST ITEM BUT DULIPCATE ROUTE ERROR ON CONSOLE
      // if (this.collectMapped.includes(zid)) {
      //   query = { o: this.collect.first().obj, z: this.collect.first().zid };
      // } else {
      //   query = { o: obj, z: zid };
      // }
      // this.$router.replace({ name: "zarView", query });
    },
    checkZnum() {
      let check = this.filterZnum <= this.pageLimit && this.filterZnum == this.items.length;
      if (!check) {
        this.axiosGet("/zar/filter/checkZnum", [this.$route.fullPath]);
      }
    },
    keyPressed(event) {
      console.log(event);
    },
  },

  watch: {
    loading() {
      if (this.loading)
        this.$toast.loading({
          duration: 0,
          forbidClick: false,
        });
      else this.$toast.clear();
    },
    async pageNum() {
      let items = await this.getItems();
      this.items = items;
      this.$scrollTo("#cont_home_zar_list", 500);
    },
    viewItemLoc() {
      this.pageLimit = this.viewItemLoc ? 99999 : 25;
      this.resetItems();
    },
    prevZar() {
      this.prevZar && this.items && this.items.find((e) => e.zid == this.prevZar && (e.act = true));
    },
    selObj: {
      immediate: true,
      handler() {
        this.items = [];
        if (this.selObj && this.selObj.id) this.resetItems();
      },
    },
    sort: {
      deep: true,
      handler(updated, old) {
        if (old.length) this.resetItems();
      },
    },

    filter(fNew, fOld) {
      if (JSON.stringify(fNew) != JSON.stringify(fOld)) {
        this.resetItems();
      }
    },
    items: {
      immediate: true,
      handler() {
        this.$store.commit("user/collectEmpty");
        // if (this.filter.length) this.checkZnum();
      },
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    pageLimit() {
      return this.isMobile ? 25 : 40;
    },
    enabledFilter() {
      return this.filter.length;
    },
    enabledSort() {
      return this.sort.find((e) => e.sel);
    },
    activeSort() {
      return this.sort.find((e) => e.sel);
    },
    nextButText() {
      return this.items ? this.zarShould - this.items.length + " зар ..." : "";
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    keymapHome() {
      return {
        left: this.keyPressed,
        right: this.keyPressed,
        enter: this.keyPressed,
      };
    },
    pagedView() {
      // return this.scr == 1 ? false : true;
      return true;
    },
    filterZnum() {
      return this.filter.length && this.filter.last().znum ? this.filter.last().znum : null;
    },
    collectMapped() {
      return this.collect.map((e) => e.zid);
    },
    gLoading: {
      get() {
        return this.$store.state.gLoading;
      },
      set(val) {
        this.$store.commit("gLoading", val);
      },
    },
    totalPage: {
      get() {
        return this.$store.state.homeZarListTotalPage;
      },
      set(num) {
        this.$store.commit("homeZarListTotalPage", num);
      },
    },
    pageNum: {
      get() {
        return this.$store.state.homeZarListPageNum;
      },
      set(num) {
        this.$store.commit("homeZarListPageNum", num);
      },
    },
    user() {
      return this.$store.state.auth.status.logged ? this.$store.state.auth.user : false;
    },
    dev() {
      return this.getLocal("devid", true);
    },
    newZar() {
      return this.$store.state.newZar;
    },
    items: {
      get() {
        return this.$store.state.zar.items;
      },
      set(val) {
        this.$store.commit("zar/items", val);
      },
    },
    ...mapState({
      zarShould: (s) => s.zar.total,
      sort: (s) => s.zar.sort,
      selObj: (s) => s.selObj,
      nearestLoc: (s) => s.nearestLoc,
      favs: (s) => s.user.favs,
      witem: (s) => s.zar.witem,
      pendingFilSel: (s) => s.zar.filter.pendingFilSel.home,
      filter: (s) => s.zar.filter.filter.home,
      collect: (s) => s.user.collect,
    }),
  },
  mounted() {},
  created() {
    this.$bus.on("nextPage", this.next);
    this.$bus.on("changePageHome", this.changePageFromGlobal);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_home_zar_list {
  /* overflow-x: hidden; */
}
/* .cont {
  position: relative;
}
.cont_spacer {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: -25px;

  height: 50%;
  width: 1px;
  background-color: #f1f1f1;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(241, 241, 241, 1) 52%, rgba(255, 255, 255, 1) 100%);
} */
.pn_cont {
  text-align: center;
  width: 100%;
  margin: 10px 0;
}
.pn_cont span {
  padding: 2px 5px;
  margin: 3px;
}

.next_button {
  width: 100px;
}

.empty {
  color: #b8b8b8;
  font-size: 12px;
}

.page_num {
  background-color: #b7ffff;
  padding: 2px 5px;
  border-radius: 3px;
}

@media only screen and (min-width: 600px) {
  .pn_cont span:hover {
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 2px;
  }
}
</style>
