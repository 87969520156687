<template>
  <div class="boffer_list">
    <div v-if="!noTitle" class="title_modal">Ирсэн машины саналууд</div>
    <mod-boffer :dataSet="dataSet" :items="items" :showSeen="showSeen" />
    <div class="mt-3" v-if="ptot > 1">
      <ui-button :disabled="pnum == 1" v-on:clicked="changePage(pnum - 1)" tp="iconOnly2" icon="i-next" angle="rev" />
      <span v-if="minPage > 1"><ui-button v-on:clicked="changePage(1)" :text="1" tp="textOnly" /><span v-if="minPage - 1 > 1">...</span></span>
      <template v-for="ind in ptot">
        <ui-button v-if="ind <= maxPage && ind >= minPage" :key="'pnum_boffer' + ind" v-on:clicked="changePage(ind)" :text="ind" :tp="ind == pnum ? 'normal' : 'textOnly'" />
      </template>
      <span v-if="maxPage < ptot">
        <span v-if="maxPage + 1 < ptot">...</span><span @click="changePage(ptot)" class="button">{{ ptot }}</span></span
      >
      <ui-button :disabled="ptot == pnum" v-on:clicked="changePage(pnum + 1)" tp="iconOnly2" icon="i-next" widthSize="wideIcon" />
    </div>

    <ui-button v-if="!noButton" v-on:clicked="close()" class="pr-3 pl-3 float-r" text="ок" tp="simple" widthSize="wide" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import modBoffer from "@/comp/mod/boffer.vue";

export default {
  name: "boffer-list",
  components: { modBoffer },

  props: {
    dataSet: Object,
    noTitle: Boolean,
    noButton: Boolean,
    showSeen: Boolean,
  },
  data() {
    return {
      items: null,
      plim: 10,
      pnum: 1,
      ptot: 0,
    };
  },
  methods: {
    changePage(pnum) {
      this.pnum = pnum;
      this.fetchItems();
    },
    close() {
      this.$emit("close");
    },
    clickedItem() {},
    async fetchItems() {
      let res = await this.axiosGet(this.dataSet.url, [this.pnum, this.plim]);
      this.items = res.items;
      this.ptot = res.ptot;
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    // favs() {
    //   return this.$store.state.user.favs;
    // },
  },
  mounted() {
    this.fetchItems();
  },
  created() {
    if (!this.logged) this.close();
  },
  maxRange() {
    return this.pnum + 2;
  },
  maxPage() {
    return this.ptot > this.maxRange ? this.maxRange : this.ptot;
  },
  minRange() {
    return this.pnum - 2;
  },
  minPage() {
    return 1 < this.minRange ? this.minRange : 1;
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.boffer_list {
  text-align: left;
  padding: 10px;
  padding-top: 0;
  overflow-y: scroll;
  max-height: 100%;
  position: relative;
}

.cont_boffer {
  margin-bottom: 10px;
  padding: 0 0 10px 5px;
}

.bottom_border {
  border-bottom: dashed 1px #ccc;
}

.cont_image img {
  max-width: 60px;
  max-height: 60px;
  padding-right: 2px;
}
</style>
