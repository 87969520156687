<template>
  <div class="mb-1">
    <input v-show="!shortMode" type="text" ref="info" v-model="text" :size="size" @focus="onFocus" @blur="onBlur" :class="{ ani_shrink: forced, empty: !text, focused: !collect || focused, yellow: notFound }" v-on:keyup="maskCheck" :maxlength="max" />
    <ui-icon v-if="showReset && !shortMode" @click="reset()" name="i-close3" class="reset clickable hoverable" size="l" />
    <ui-select
      v-if="!collect || shortMode"
      :items="shortMode ? items : results"
      :collect="collect"
      :zind="zind"
      :imageSel="imageSel"
      :imageHover="imageHover"
      :unit="unit"
      :forceSel="forceSel"
      :title="title"
      :compName="compName"
      v-on:clickedItem="clickedItem($event)"
      v-on:mouseOver="mouseOver($event)"
      v-on:mouseLeave="mouseLeave(pRef)"
      v-on:mouseEnter="mouseEnter(pRef)"
      v-on:selection="selection($event)"
      v-on:hideProp="hideProp"
      :userAddable="userAddable"
    />
    <span v-if="guide && showGuide" class="guide ml-4">* {{ guide }}</span>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelect from "@/comp/ui/select.vue";

export default {
  name: "ui-input-sel",
  components: {
    uiSelect,
  },
  props: {
    items: Array,
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: Object,
    min: Number,
    max: Number,
    zind: Number,
    guide: String,
    mask: Object,
    userAddable: Number,
    forceSel: Number,
    imageHover: String,
    imageSel: String,
    pRef: String,
    unit: String,
    title: String,
    compName: String,
  },
  data() {
    return {
      forced: false,
      text: "",
      showGuide: false,
      refreshSelect: true,
      focused: true,
      timeout: null,
      minSize: 7,
      letterLat: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
      letterCry: ["а", "б", "в", "г", "д", "е", "ё", "ж", "з", "и", "й", "к", "л", "м", "н", "о", "ө", "п", "р", "с", "т", "у", "ү", "ф", "х", "ц", "ч", "ш", "щ", "ы", "ь", "э", "ю", "я"],
      relLatCry: {
        a: "а",
        b: "б",
        c: "к",
        d: "д",
        e: "э",
        f: "ф",
        g: "г",
        h: "х",
        i: "и",
        j: "ж",
        k: "к",
        l: "л",
        m: "м",
        n: "н",
        o: "о",
        p: "п",
        q: "к",
        r: "р",
        s: "с",
        t: "т",
        u: "у",
        v: "в",
        w: "в",
        x: "кс",
        y: "ю",
        z: "з",
      },
      relLatCryMulti: {
        kh: "х",
        ai: "ай",
        ya: "яа",
        yo: "ёо",
        ts: "ц",
        ch: "ч",
        sh: "ш",
        ii: "ий",
        ty: "ти",
        ea: "ий",
        ee: "ий",
        tion: "шион",
      },
      relCryLat: {
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        д: "d",
        е: "e",
        ё: "yo",
        ж: "j",
        з: "z",
        и: "i",
        й: "i",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        ө: "u",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ү: "u",
        ф: "p",
        х: "h",
        ц: "ts",
        ч: "ch",
        ш: "sh",
        щ: "sh",
        ы: "ii",
        ь: "",
        э: "e",
        ю: "u",
        я: "ya",
      },
      showReset: false,
    };
  },

  methods: {
    clickedItem(sel) {
      if (this.userAddable && sel.id == 888888888) {
        this.$emit("showAddSel");
        return;
      }
      this.text = sel.name;
      // this.$emit("clickedItem", sel);
    },
    onFocus() {
      this.showGuide = true;
      this.focused = true;
    },
    onBlur() {
      this.showGuide = false;
      this.focused = false;
    },
    mouseLeave(ref) {
      this.$emit("mouseLeave", ref);
    },
    mouseOver(sel) {
      this.$emit("mouseOver", sel);
    },
    mouseEnter(ref) {
      this.$emit("mouseEnter", ref);
    },
    restoreAddNew() {
      this.items.find((e) => e.id == 888888888 && (e.name = "+ Шинээр сонголт нэмэх"));
    },
    selection(bool) {
      // console.log("selection", bool);

      this.$emit("selection", bool);
      if (bool) {
        this.restoreAddNew();
        this.$emit("clickedItem", "");
      }
    },
    hideProp() {
      this.$emit("hideProp");
    },
    reset() {
      this.text = "";
      this.restoreAddNew();

      this.setFocus();
    },

    maskCheck(field) {
      if (!this.text) {
        this.$emit("clickedItem", "");
        this.restoreAddNew();
        return;
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        console.log(field);
        //field.target.inputmask.isComplete() && this.text.length >= this.min && this.text != 0 ? this.$emit("clickedItem", this.sanitize(this.text)) : this.$emit("clickedItem", "");
      }, 300);
    },
    sanitize(string) {
      const map = {
        "&": "",
        "<": "",
        ">": "",
        '"': "",
        "'": "",
        "/": "",
        /*
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "/": "&#x2F;",*/
      };
      const reg = /[&<>"'/]/gi;
      return string.replace(reg, (match) => map[match]);
    },
    setFocus() {
      this.$refs.info.focus();
    },
    toggleForced() {
      this.forced = true;
      setTimeout(() => {
        this.forced = false;
      }, 1000);
    },
  },
  computed: {
    notFound() {
      return this.text.length && !this.results.length ? true : false;
    },
    shortMode() {
      return this.items.length <= 10;
    },
    size() {
      return this.text.length >= this.minSize ? this.text.length : this.minSize;
    },
    // results() {
    //   if (this.text) {
    //     return this.items.filter((item) => {
    //       return item.name.toLowerCase().startsWith(this.text.toLowerCase());
    //     });
    //   } else {
    //     return this.items;
    //   }
    // },
    results() {
      if (this.text) {
        let found = this.converted.filter((item) => {
          return item.text.find((e) => {
            return e.startsWith(this.text.toLowerCase());
          });
        });
        let mapped = found.map((m) => m.id);
        return this.items.filter((f) => mapped.includes(f.id));
      } else {
        return this.items;
      }
    },
    converted() {
      return this.items.map((e) => {
        let names = e.name.toLowerCase().split(" ");
        // this.omitWord.forEach((o) => (names = names.filter((f) => f != o)));
        let generatedNames = [];
        names.forEach((n) => {
          if (this.letterLat.includes(n[0])) {
            let replaced = n;

            Object.keys(this.relLatCryMulti).forEach((key) => {
              let regex = new RegExp(key, "g"); // correct way
              if (n.match(regex)) replaced = n.replace(regex, this.relLatCryMulti[key]); // it works;
            });

            replaced = replaced.replace(/^x/g, "з");
            replaced = replaced.replace(/ce$/g, "с");
            replaced = replaced.replace(/dha$/g, "да");
            replaced = replaced.replace(/ge$/g, "ж");
            replaced = replaced.replace(/\S/g, (m) => this.relLatCry[m] ?? m);
            generatedNames.push(replaced);
          }
          if (this.letterCry.includes(n[0])) {
            let replaced = n.replace(/\S/g, (m) => this.relCryLat[m] ?? m);
            generatedNames.push(replaced);
          }
        });
        return { id: e.id, text: [names.join(" "), generatedNames.join(" ")].concat(names).concat(generatedNames) };
      });
    },
  },
  watch: {
    items() {
      this.text = "";
    },
    text() {
      let found = this.items.find((item) => item.name.toLowerCase() == this.text.toLowerCase());
      if ((!found && this.text) || !this.text) {
        this.$emit("clickedItem", "");
        this.showReset = false;
        // console.log("emitted empty");
      }

      if (found && this.text) {
        this.$emit("clickedItem", found);
        //FIXME: double clickedItem fired when clicked selection and removed all inputsel from all props
        this.showReset = true;
      }
      // if (!this.text) console.log("empty TEXT");
    },
    collect() {
      // if (!this.collect) this.text = ""; DISABLED FOR DOUBLE TYPING TEXT CHANGING EFFECT!!! THIS LINE ONLY
      // else this.text = this.collect;
      // this.text = !this.collect ? "" : this.collect;
    },
    forceSel() {
      this.toggleForced();
    },
  },
  mounted() {
    if (!this.isMobile) this.setFocus();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.yellow {
  background-color: rgb(255, 255, 157);
}
.reset {
  position: relative;
  top: 3px;
  left: 3px;
}
</style>
