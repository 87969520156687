<template>
  <div class="cont_ui_video_player">
    <video width="100%" playsinline :height="height" controlsList="nodownload" class="video" :autoplay="autoPlay" :muted="muted" :loop="loop" :controls="controls" :style="{ maxWidth, maxHeight }" @click="clicked()">
      <source :src="src + '#t=1.5'" type="video/mp4" />
      Та вэб хөтөчөө солин уу
    </video>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-video-player",
  props: {
    src: String,
    maxWidth: {
      type: String,
      default: () => "400px",
    },
    maxHeight: {
      type: String,
      default: () => "550px",
    },
    muted: {
      type: Boolean,
      default: () => false,
    },
    autoPlay: {
      type: Boolean,
      default: () => false,
    },
    controls: {
      type: Boolean,
      default: () => true,
    },
    loop: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {},
  data() {
    return {
      width: "100%",
      height: "auto",
    };
  },
  methods: {
    clicked() {
      this.$emit("clickedVideo");
    },
    // loaded() {
    //   console.log(this.$refs[this.uniqId]);
    // },
  },
  computed: {},
  mounted() {
    // if (this.maxHeight) this.height = this.maxHeight;
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_video_player {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video {
  border-radius: 10px;
  object-fit: contain;
}
.video:hover {
  cursor: pointer;
  transition: all 500ms;
}
.video:active {
  filter: brightness(2);
}
</style>
