<template>
  <div class="cont_textbox">
    <img v-if="emojiEnable" @click="emoji = !emoji" src="/icon/i-emoji.svg" class="emoji_toggler" />
    <div v-if="emojiEnable" :class="{ show_emoji: emoji }" class="cont_emoji">
      <span v-for="(s, ind) in smily" :key="'smily' + ind">
        <span class="emoji_item" @click="text = text + smily[ind]">{{ s }}</span>
      </span>
    </div>
    <!-- <textarea v-model="text" ref="info" :class="{ empty: !text }" v-on:keyup="maskCheck" @paste="onPaste" :maxlength="max" @focus="showGuide = true" @blur="showGuide = false"></textarea> -->
    <textarea :value="text" @input="(evt) => (text = evt.target.value)" ref="info" :class="{ empty: !text }" v-on:keyup="maskCheck" @paste="onPaste" :maxlength="max" @focus="showGuide = true" @blur="showGuide = false"></textarea>
    <div class="counter" v-if="mask && mask.max" v-text="ctext" :class="{ limited: limited }"></div>
    <ui-reflow>
      <div v-if="guide && showGuide" class="guide">{{ guide }}</div>
    </ui-reflow>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-textbox",
  props: { collect: { type: [String, Object, Boolean, Number, Array] }, min: Number, max: Number, mask: Object, guide: String, emojiEnable: Boolean },
  data() {
    return {
      emoji: false,
      smily: ["😀", "😃", "😄", "😁", "😆"],
      text: "",
      timeout: null,
      showGuide: false,
    };
  },
  methods: {
    onPaste() {
      this.timeout = setTimeout(
        () => {
          if (this.min <= this.text.length) this.$emit("clickedItem", this.sanitize(this.text));
        },
        this.collect ? 100 : 1000
      );
    },
    maskCheck() {
      if (!this.text) {
        this.$emit("clickedItem", "");
        return;
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => {
          this.text.length >= this.min && this.text != 0 ? this.$emit("clickedItem", this.sanitize(this.text)) : this.$emit("clickedItem", "");
        },
        this.collect ? 100 : 1000
      );
    },
    sanitize(string) {
      const map = {
        "&": "",
        "<": "",
        ">": "",
        '"': "",
        "'": "",
        "/": "",
        /*
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "/": "&#x2F;",*/
      };
      const reg = /[&<>"'/]/gi;
      return string.replace(reg, (match) => map[match]).replace(/^\s*\n/gm, "");
    },
  },
  computed: {
    limited() {
      return this.max - this.text.length < 100 ? true : false;
    },
    ctext() {
      let ctext = this.max - this.text.length;
      this.min >= this.text.length && this.text.length && (ctext = "Арай богино байна");
      return ctext;
    },
  },
  watch: {
    text() {
      /*  clearTimeout(this.timeout);

      let self = this;
      this.timeout = setTimeout(function() {
        self.$emit("clickedItem", self.text);
      }, 300);*/
    },
    collect() {
      if (!this.collect) {
        this.text = "";
      } else {
        this.text = this.collect;
      }
    },
  },
  mounted() {
    this.$refs.info.focus();
    if (this.collect && this.collect != this.text) this.text = this.collect;
    // var a = String.fromCharCode(72);
    //console.log("Character: " + a);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_textbox {
  box-sizing: border-box;
}
textarea {
  margin: 0 5px 5px 0;
  min-height: 250px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}
textarea:focus + .guide {
  display: block;
}
.cont_emoji {
  opacity: 0;
  height: 0px;
  transition: all 100ms;
  visibility: hidden;
}
.show_emoji {
  visibility: visible;
  opacity: 1;
  height: 43px;
}
.emoji_item {
  font-size: 18px;
  margin: 0 2px 2px 0;
  user-select: none;
  cursor: pointer;
}
.emoji_toggler {
  height: 20px;
  opacity: 0.7;
  transition: opacity 100ms;
}
.emoji_toggler:hover {
  cursor: pointer;
  user-select: none;
  opacity: 1;
}
.empty {
  background-color: rgb(248, 248, 222);
}
.counter {
  color: grey;
  font-size: 12px;
  position: relative;
  top: -1px;
  transition: all 300ms;
}
.limited {
  color: black;
}

@media only screen and (max-width: 600px) {
  textarea {
    margin: 0 0 5px 0;
  }
}
</style>
