<template>
  <div class="cont_register" :style="{ opacity: loading ? '0.5' : '1' }" :class="{ shake }">
    <div class="title_modal">Бүртгүүлэх</div>
    <form class="form" name="form" @submit.prevent="submit" @keyup.enter="submit">
      <div>
        <label for="phone">Утас</label>
        <input ref="phone" v-model="user.phone" type="text" name="phone" v-mask="{ regex, autoUnmask: true, greedy: false }" v-on:keyup="phoneCheck" />
      </div>
      <div>
        <label for="pass">Нууц үг <img v-if="user.pass.length" class="eye" @click="showPass = !showPass" src="/icon/i-eye.svg" width="12"/></label>
        <input v-model="user.pass" ref="pass" :type="showPass ? 'text' : 'password'" name="pass" v-on:keyup="passCheck" autocomplete="on" />
      </div>
      <div class="pt-1"><ui-button v-on:clicked="submit" :disabled="!isComplete" text="Бүртгүүлэх" widthSize="wide" /><ui-button v-on:clicked="close()" tp="textOnly" text="Болих" /></div>
      <div class="mt-3"><ui-button v-on:clicked="showLogin()" tp="textOnly" text="Нэвтрэх" /></div>
    </form>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import User from "@/model/user";

export default {
  name: "user-register",
  components: {},
  props: { dataSet: Object },
  data() {
    return {
      user: new User("", "", ""),
      phone: false,
      pass: false,
      showPass: false,
      regex: "[789]\\d{3}\\-\\d{4}",
      shake: false,
      loading: false,
    };
  },
  methods: {
    showLogin() {
      this.close();
      setTimeout(() => this.$bus.emit("openModal", { name: "userLogin" }), 200);
    },
    phoneCheck(field) {
      this.phone = field.target.inputmask.isComplete() && this.user.phone.length == 8 ? true : false;
      if (this.phone) this.$refs.pass.focus();
    },
    passCheck() {
      this.pass = this.user.pass.length > 5 && this.user.pass.length < 50 ? true : false;
    },
    close() {
      this.$emit("close");
    },
    reset() {
      this.user = new User("", "", "");
    },
    submit(event, returnPage) {
      this.loading = true;
      this.$store.dispatch("auth/register", this.user).then(
        () => {
          this.loading = false;
          this.showMessage("info", "Бүртгэл амжилттай", 2000);
          this.$store.dispatch("auth/login", this.user).then(() => {
            if (!returnPage) this.$router.push("/user/home");
            else if (returnPage != "noRedirect") this.$router.push(returnPage);
          });
          this.close();
        },
        (error) => {
          this.loading = false;
          this.$message({
            type: "warning",
            message: error,
            showClose: true,
            hasMask: false,
            duration: 4000,
          });
          this.shake = true;
        }
      );
      //}
      //});
    },
  },
  watch: {
    regexCheck() {
      if (this.regexCheck && this.user.pass.length == 1 && !this.isMobile)
        this.regexCheck.forEach((text) => {
          this.showMessage("warning", text, 2500);
        });
    },
    shake() {
      if (this.shake) setTimeout(() => (this.shake = false), 1000);
    },
  },
  computed: {
    isComplete() {
      return this.phone && this.pass ? true : false;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    regexCheck() {
      let warning = [];
      if (this.user.pass.match("[а-я|ө|үА-Я|Ө|Ү]")) warning.push("Та КРИЛЛЭЭР бичиж байна шүү");
      if (this.user.pass.match("[A-Z]")) warning.push("Та ТОМООР бичиж байна шүү");
      return warning;
    },
  },
  mounted() {
    if (this.dataSet) {
      this.user.phone = this.dataSet.phone;
      this.user.pass = this.dataSet.pass;
      if (this.user.phone && this.user.pass) this.submit(null, this.dataSet.returnPage);
    }
    setTimeout(() => this.$refs.phone.focus(), 100);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_register {
  text-align: center;
  padding: 10px;
  padding-top: 0;
  transition: all 500ms;
}

.form > div {
  margin-bottom: 10px;
}
.form div:nth-child(3) {
  margin-bottom: 5px;
}
.eye {
  position: relative;
  top: 3px;
}
.eye:hover {
  cursor: pointer;
}
label {
  display: block;
  margin-bottom: 2px;
  text-align: left;
  padding-left: 20px;
}
</style>
