import axios from "axios";

//const url = "http://192.168.1.19/dev/api/user/account/";
//const API_URL = 'http://localhost:8080/api/auth/';
//const url = this.apiUrl + "/user/account/";

class AuthService {
  login(user) {
    // console.log(opt);
    return axios
      .post(
        "/user/account/login",
        {
          phone: user.phone,
          pass: user.pass,
          device: typeof window.orientation !== "undefined" ? "mobile" : "pc",
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.status && res.data.info.accessToken) {
          localStorage.setItem("user", JSON.stringify(res.data.info));
          axios.defaults.headers.Authorization = res.data.info.accessToken + "." + localStorage.getItem("devid");
        }
        return res.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    axios.defaults.headers.Authorization = localStorage.getItem("devid");
  }

  register(user) {
    return axios
      .post("/user/account/register", {
        phone: user.phone,
        pass: user.pass,
        devid: localStorage.getItem("devid"),
      })
      .then((res) => res.data);
  }
}

export default new AuthService();
