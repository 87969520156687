<template>
  <div class="cont_footer">
    <div id="footer" class="footer ta-c clear">
      <span class="gray-3 mr-3">Inspired by Tobi</span><span class="clickable mr-3" @click="showTos()">Үйлчилгээний нөхцөл</span><span class="clickable mr-3" @click="showFeedback()">Санал хүсэлт</span>
      <main-web-stats v-if="false" />
    </div>
    <main-modals />
    <main-drawers />
    <!-- <main-fixed-menu /> -->
    <!-- <user-chat v-if="false" /> -->
    <main-navi v-if="scr != 4" :fullWidth="scr == 1" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import mainModals from "./modals.vue";
import mainDrawers from "./drawers.vue";
import mainWebStats from "./webStats.vue";
// import userChat from "@/comp/user/chat.vue";
import mainNavi from "@/comp/main/navi.vue";

// import mainFixedMenu from "@/comp/main/fixedMenu.vue";

export default {
  name: "main-footer",
  components: {
    // userChat,
    mainModals,
    mainDrawers,
    mainNavi,
    mainWebStats,
  },
  data() {
    return {
      showItem: null,
      drawerItemInfo: null,
    };
  },
  methods: {
    showTos() {
      this.$bus.emit("openModal", { name: "mainTos" });
    },
    showFeedback() {
      this.$bus.emit("openModal", { name: "mainFeedback" });
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_footer {
  padding-top: 100px;
}
.footer {
  width: 100%;
  min-height: 100px;
  vertical-align: middle;
  font-size: 13px;
  color: #666;
  /* display: flex; */
  justify-content: center; /* horizontal */
  align-items: center; /* vertical */
  margin-bottom: 20px;
  gap: 10px;
}
.footer span {
  font-weight: 300;
  font-size: 12px;
}
</style>
