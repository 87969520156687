<template>
  <ui-tag class="mb-1" :items="tags" :showClearTags="showClearTags" :controllable="controllable" v-on:clickedItem="clickedItem($event)" v-on:clearTags="clearTags" />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiTag from "@/comp/ui/tag.vue";
import { mapState } from "vuex";
export default {
  name: "zar-tag",
  components: { uiTag },
  props: {
    controllable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickedItem(o) {
      if (o.tp == "page") {
        this.$store.commit("homeZarListPageNum", 1);
        return;
      }
      if (o.tp == "obj") {
        if (this.scr < 3) this.$store.commit("showMenu", { cat: true });
        this.clearTags();
        return;
      }
      if (o.tp == "sort") {
        this.$bus.emit("removeSort", o.id);
        return;
      }
      this.$bus.emit("deleteFilter", { obj: o.obj, prop: o.prop, sid: o.sid, prel: o.prel });
    },
    clearTags() {
      this.$bus.emit("clearSort");
      this.$bus.emit("resetFilter");
      this.$store.commit("homeZarListPageNum", 1);
    },
  },
  computed: {
    //...mapGetters("zar/filter", ["filter"]),
    ...mapState({
      selObj: (s) => s.selObj,
      filter: (s) => s.zar.filter.filter.home,
      pageNum: (s) => s.homeZarListPageNum,
    }),
    scr() {
      return this.$store.getters.scr;
    },
    actSort() {
      return this.$store.getters["zar/activeSort"];
    },
    mainCat() {
      return this.$store.getters.mainCat;
    },
    tags() {
      let tags = [];
      if (this.selObj) tags.push({ tp: "obj", class: "main", icon: this.selObj.icon ? this.selObj.icon : "", sname: this.selObj && this.selObj.name });
      if (this.filter) {
        // console.log(this.filter);

        tags.push(...this.filter.map((e) => ({ ...e, icon: "i-filter3" })));
      }
      // console.log(this.actSort);

      if (this.actSort) {
        let as = { ...this.actSort };
        as.tp = "sort";
        as.icon = "sort";
        as.class = "main";
        if (as.custom) as.sname = as.custom[as.sel - 1];
        tags.push(as);
      }
      if (this.pageNum > 1) tags.push({ tp: "page", class: "main", icon: "i-page4", sname: "Хуудас " + this.pageNum });
      return tags;
    },
    showClearTags() {
      return this.filter.length || this.actSort ? true : false || this.pageNum > 1;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
