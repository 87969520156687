<template>
  <div class="cont_zar_add_youtube">
    <ui-reflow>
      <div v-if="items.length < max"><ui-icon name="i-youtube" size="xxl" class="youtube_icon" /> <input type="text" v-model="text" /></div>
    </ui-reflow>
    <ui-reflow>
      <ui-youtube-view v-if="items.length" :items="items" mini removeAble v-on:removeItem="removeItem($event)" class="mt-1" fitContent />
    </ui-reflow>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiYoutubeView from "@/comp/ui/youtubeView.vue";

export default {
  name: "zar-add-youtube",
  props: {
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: {
      type: Object,
      required: true,
    },
  },
  components: { uiYoutubeView },
  data() {
    return {
      text: "",
      items: [],
      max: 2,
      timeout: null,
    };
  },
  methods: {
    removeItem(item) {
      this.items = this.items.filter((i) => i != item);
    },
    typed(text) {
      let videoID = this.parseId(text);

      if (this.items.length >= this.max) {
        this.showMessage("info", "Дээд тал нь " + this.max + " видёо орох боломжтой", 3000);
        return;
      }

      if (this.items.find((e) => e == videoID)) {
        this.showMessage("warning", "Давхардсан холбоос", 2000);
        this.text = "";
        return;
      }

      if (this.validate(text)) {
        this.items.push(videoID);
        this.$emit("clickedItem", this.items);

        this.text = "";
        return;
      }

      this.$emit("clickedItem", "");
      this.showMessage("warning", "Youtube-ийн холбоос биш байна!");
      // this.items = [];
    },
    validate(url) {
      if (url != undefined || url != "") {
        //eslint-disable-next-line
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        return match && match[2].length == 11 ? true : false;
      }
    },
    parseId(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
  watch: {
    text() {
      if (this.text) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.typed(this.text), 500);
      }
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_add_youtube {
  /* width: 350px; */
  position: relative;
}
.cont_zar_add_youtube input {
  width: 80%;
  max-width: 350px;
  padding-left: 38px;
}
.youtube_icon {
  position: absolute;
  top: 4px;
  left: 10px;
  opacity: 0.6;
}
</style>
