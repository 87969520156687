<template>
  <div class="cont_banner">
    <img v-if="!isMobile" :src="apiUrl + '/media/banners/welcome.png'" />
    <img v-else class="banner_mobile" :src="apiUrl + '/media/banners/welcome_mobile.png'" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "main-banner",
  data() {
    return {};
  },
  methods: {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_banner {
  margin-bottom: 10px;
}
img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 700px) {
  .banner_mobile {
    margin-top: 58px;
  }
}
</style>
