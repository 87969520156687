<template>
  <div class="cont_add_buy">
    <div v-if="!checkExistingToday">
      <div class="prop_name mb-1"><span v-if="body" class="check">&#10004;</span><span v-else class="req">*</span> Мэдээлэл:</div>
      <textarea v-model="body" ref="body" class="mb-3" maxlength="250"></textarea>
      <div class="prop_name mb-1"><span v-if="computedPhone" class="check">&#10004;</span><span v-else class="req">*</span> Холбогдох утас:</div>
      <ui-input v-on:typed="typedPhone($event)" :collect="computedPhone" :disabled="this.logged" :autoFocus="false" :unit="''" :mask="null" :unmask="true" :guide="''" :pat="null" :min="8" :max="9" :regex="'[789]\\d{3}-\\d{4}'" :minWidth="15" class="mb-3" />

      <ui-reflow>
        <div class="warning_text mt-1 mb-3 ta-l" v-if="phoneExist && !logged">Энэ утсан дээр бүртгэлтэй хэрэглэгч олдлоо. <br />Та <b>бүртгэлтэй</b> нууц үгээ оруулна уу. <br />Хэрвээ нууц үгээ <b>сэргээх</b> бол <span class="link td-un" @click="restorePass()">энд дарна уу</span></div>
      </ui-reflow>

      <ui-reflow>
        <div v-if="!logged && phone" class="prop_name mb-1">Нууц үг :</div>
        <ui-input v-if="!logged && phone" v-on:typed="typedPass($event)" tp="password" :collect="pass" :min="6" :max="150" class="mb-3" :autoFocus="false" />
      </ui-reflow>

      <ui-reflow>
        <ui-button v-if="!logged && phoneExist && phone && pass" v-on:clicked="loginUser()" text="Нэвтрэх" pulsate />
        <ui-button v-if="!logged && !phoneExist && phone && pass" v-on:clicked="createNewUser()" text="Илгээх" pulsate />
      </ui-reflow>
    </div>
    <div class="mt-5 ta-c pb-3 ">
      <ui-reflow>
        <div v-if="checkExistingToday" class="mt-3 mb-3 warning_text">Та маргааш дахин худалдаж авах зар нэмээрэй</div>
        <ui-button v-if="checkExistingToday" v-on:clicked="close()" class="pr-3 pl-3" text="Хаах" tp="simple" />
      </ui-reflow>
      <ui-reflow>
        <div v-if="isComplete" class="mt-3 mb-3 info_text">Та мэдээллээ сайн нягтлаарай. Та нэг өдөрт нэг удаа л худалдаж авах зар нэмэх боломжтой</div>
      </ui-reflow>
      <div v-if="!checkExistingToday">
        <ui-button v-on:clicked="submit()" tp="special" class="pr-3 pl-3" text="Илгээх" widthSize="wide" :disabled="!isComplete" />
        <ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Болих" tp="simple" />
      </div>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiInput from "@/comp/ui/input.vue";
// import uiSelect from "@/comp/ui/select.vue";
// import uiSelectValue from "@/comp/ui/selectValue.vue";

export default {
  name: "zar-add-buy",
  components: { uiInput },

  props: { dataSet: Object },
  data() {
    return {
      loading: false,
      phone: null,
      pass: null,
      body: null,
      checkExistingToday: false,
    };
  },
  methods: {
    restorePass() {
      this.$bus.emit("openModal", { name: "restorePass", dataSet: { phone: this.phone } });
    },
    typedPhone(str) {
      this.phone = str;
    },
    typedPass(str) {
      this.pass = str;
    },
    submit() {
      this.axiosPost("/module/buy/addBuy", { obj: this.selObj.id, body: this.body });
      this.showMessage("info", "Амжилттай илгээгдлээ");
      this.$bus.emit("refreshBuyList");
      this.close();
    },
    close() {
      this.$emit("close");
    },
    async fetchCheckExistingToday() {
      let res = await this.axiosGet("/module/buy/checkExistingToday", [this.selObj.id]);
      this.checkExistingToday = res.status;
    },
    loginUser() {
      this.$bus.emit("openModal", { name: "userLogin", dataSet: { phone: this.phone, pass: this.pass, returnPage: "noRedirect" } });
    },
    createNewUser() {
      this.$bus.emit("openModal", { name: "userRegister", dataSet: { phone: this.phone, pass: this.pass, returnPage: "noRedirect" } });
    },
  },
  watch: {
    logged: {
      immediate: true,
      async handler() {
        if (this.logged && this.selObj) await this.fetchCheckExistingToday();
        if (this.isComplete) this.submit();
      },
    },
  },
  computed: {
    selObj() {
      return this.$store.state.selObj;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    user() {
      return this.$store.state.auth.user;
    },
    computedPhone() {
      return this.logged ? this.$store.state.auth.user.phone : this.phone;
    },
    isComplete() {
      return this.selObj && this.logged && this.body && !this.checkExistingToday;
    },
  },
  asyncComputed: {
    phoneExist() {
      if (this.phone)
        return this.axios
          .get("/user/account/phoneExist/" + this.phone)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_add_buy {
  padding: 0 0px 10px 10px;
  text-align: left;
  overflow-y: scroll;
  max-height: 100%;
}

.cont_add_buy textarea {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}

.check {
  display: inline-block;
  color: rgb(18, 170, 18);
}
.req {
  display: inline-block;
  color: red;
}
.cont_add_buy textarea {
  height: 120px;
}
</style>
