<template>
  <div>
    <div class="cont_photo_upload" :class="{ lockPhotoManage }">
      <!-- images uploaded: {{ imagesUploaded }} -->
      <!-- <ui-dialog v-if="modal" :data="modal" v-on:close="modal = null" v-on:submit="submitModal()" /> -->
      <!-- {{ images.map((e) => ({ ori: e.ori })) }} -->
      <div v-if="isMobile">
        <transition-group name="slide" tag="span">
          <span id="img_con" v-for="(i, k) in images" v-bind:key="'mob' + i.id" class="slide">
            <span id="delete" @click="deleteImage(i.id)"> <img src="/icon/i-times.svg"/></span>
            <span id="rotate" @click="rotateImage(i.id)"> <img src="/icon/i-rotate.svg"/></span>
            <span id="variation" v-if="i.var.length">
              <img src="/icon/verified-text-paper.svg" :style="{ bottom: k == 0 ? '25px' : '6px' }" />
            </span>
            <img class="photos" :src="i.src" :alt="i.alt" @click="clickedImage(i.id)" :style="{ maxWidth: pSize + 'px', maxHeight: pSize + 'px', rotate: i.rotate + 'deg' }" />
            <div class="blink_me" v-if="i.uploading != 2">.</div>
            <div class="title" v-if="i.title" @click="editTitle(i.id, i.src)">{{ i.title }}</div>
            <div class="add_title link" v-if="setTitle && !i.title" @click="editTitle(i.id, i.src)">Тайлбар +</div>
          </span>
        </transition-group>
      </div>
      <template v-else>
        <draggable v-model="images" tag="span">
          <transition-group name="slide" tag="span">
            <span id="img_con" v-for="(i, k) in images" v-bind:key="'desk' + i.id" class="slide">
              <span id="delete" @click="deleteImage(i.id)"> <img src="/icon/i-times.svg"/></span>
              <span id="rotate" @click="rotateImage(i.id)"> <img src="/icon/i-rotate.svg"/></span>
              <span id="variation" v-if="i.var.length">
                <img src="/icon/verified-text-paper.svg" :style="{ bottom: k == 0 ? '25px' : '6px' }" />
              </span>
              <img class="photos" :src="i.src" :alt="i.alt" @click="clickedImage(i.id)" :style="{ maxWidth: pSize + 'px', maxHeight: pSize + 'px', rotate: i.rotate + 'deg' }" />
              <div class="blink_me" v-if="i.uploading != 2">.</div>
              <div class="title" v-if="i.title" @click="editTitle(i.id, i.src)">{{ i.title }}</div>
              <div class="add_title link" v-if="setTitle && !i.title" @click="editTitle(i.id, i.src)">тайлбар +</div>
            </span>
          </transition-group>
        </draggable>
      </template>

      <div class="clear">
        <PhotoCompress :fileNamePrefix="fileNamePrefix" multiple :required="required" :maxNum="setMax" :maxWidth="maxWidth" :maxHeight="maxHeight" v-on:compressed="preparing($event)" :totalImage="images.length">
          <span id="imageTotal" slot="imgTot" v-if="imageTotal">
            <span @click="deleteAll()" v-show="imageTotal"><img class="trash link" src="/icon/i-trash.svg" />{{ imageTotal }}</span>
            <slot name="vari"></slot>
          </span>
        </PhotoCompress>
      </div>
      <canvas id="c" class="d-none" />
      <modal name="photo_title" width="300" :height="'auto'">
        <div class="photo_title_cont ta-c">
          <div class="title_modal">Тайлбар оруулах</div>
          {{ photoTitle.src }}
          <div><img :src="photoTitle.imageSrc" /></div>
          <textarea ref="photoTitle" v-model="photoTitle.title" style="width:90%" rows="7"></textarea>
          <div>
            <ui-button text="Хадгалах" v-on:clicked="saveTitle()" widthSize="wide" />
            <ui-button text="Болих" v-on:clicked="$modal.hide('photo_title')" tp="simple" />
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import draggable from "vuedraggable";
import PhotoCompress from "@/comp/ui/photoCompress.vue";
import VueMixinTween from "vue-mixin-tween";

export default {
  name: "ui-photo-upload",
  components: {
    draggable,
    PhotoCompress,
  },
  props: {
    fileNamePrefix: { type: String, required: true },
    required: Boolean,
    collect: {},
    setTitle: Boolean,
    lockPhotoManage: Boolean,
    setMax: Number,
    folder: String,
    maxWidth: { type: Number, required: true },
    maxHeight: { type: Number, required: true },
  },
  data() {
    return {
      images: [],
      imageTotal: 0,
      photoTitle: { id: null, title: null, imageSrc: null },
      concurrentNum: 10,
      // uploadQueue: [],
      // modal: null,
    };
  },
  methods: {
    // addToQueue(image) {
    //   image.uploading = 0;
    //   this.uploadQueue.push(image);
    // },
    showPhotoTitle() {
      this.$modal.show("photo_title");
    },
    hidePhotoTitle() {
      this.$modal.hide("photo_title");
    },
    editTitle(id, imageSrc) {
      let tit = this.images.find((e) => e.id == id);
      this.photoTitle = { id: tit.id, title: tit.title, imageSrc };
      this.$modal.show("photo_title");
      setTimeout(() => this.$refs.photoTitle.focus(), 100);
    },
    saveTitle() {
      let res = this.images.find((i) => i.id == this.photoTitle.id);
      res.title = this.photoTitle.title;
      this.$modal.hide("photo_title");
    },
    thumbnailify(base64Image, targetSize, callback) {
      var img = new Image();
      img.onload = function() {
        var width = img.width,
          height = img.height,
          canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");
        canvas.width = canvas.height = targetSize;
        ctx.drawImage(img, width > height ? (width - height) / 2 : 0, height > width ? (height - width) / 2 : 0, width > height ? height : width, width > height ? height : width, 0, 0, targetSize, targetSize);
        callback(canvas.toDataURL());
      };

      img.src = base64Image;
    },
    send(id, image) {
      let im = this.images.find((e) => e.id == id);
      im.uploading = 1;

      let base64 = image.split(",");
      let blob = this.toBlob(base64[1], "image/jpeg");
      let formData = new FormData();

      formData.append("file", blob, id);
      this.axios
        .post("/utility/upload/uploadImage/" + this.folder, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          im.uploading = 2;
        })
        .catch((err) => {
          this.axiosPost("/sys/log/uploadImageErrorLog", { id, err });
          alert("Сэрвэрт зураг хуулах үед алдаа гарлаа. Та зараа дахин оруулж үзнэ үү.");
          console.log(err);
        });
    },
    uploadStarted(n) {
      this.imageTotal += n;
    },
    deleteAll() {
      this.images = [];
    },
    preparing(o) {
      if (this.fo("origSize", o["origSize"]) || this.fo("name", o["name"])) {
        // this.$bus.emit("showAlert", { header: "", body: "Давхардсан зураг", button: { text: "ОК" }, action: "warning", image_src: o.src });
        this.$modal.show("dialog", {
          text: "<img src='" + o.src + "' width='200' /><br>" + " Давхардсан зураг",
          buttons: [
            {
              title: "ОК",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
        // this.showAlert = { header: "Мэдээлэл", text: error, button: { text: "Ok" } };
      } else {
        // this.send(o.id, o.src);
        o.uploading = 0;
        // this.addToQueue(o);

        // alert("image ori:" + o.ori);

        if (o.ori > 1) {
          let rotateDegree = o.ori == 8 ? 90 : o.ori == 3 ? 180 : o.ori == 6 ? -90 : 0;
          setTimeout(() => {
            this.rotateImage(o.id, rotateDegree);
          }, 1000);
        }

        let self = this;
        self.images.push(o);

        /*this.thumbnailify(o.src, 250, function(base64Thumbnail) {
          o.src = base64Thumbnail;
          //console.log(base64Thumbnail);
          self.images.push(o);
          //console.log(self.images);
        });*/
      }
    },
    fi(id) {
      return this.images.findIndex((e) => e.id == id);
    },
    fo(att, val) {
      return this.images.find((e) => e[att] == val);
    },
    clickedImage(id) {
      this.makeFimage(id);
    },
    makeFimage(id) {
      let ind = this.fi(id);
      if (ind > -1) {
        let fob = this.images[ind];
        this.images.splice(ind, 1);
        this.images.unshift(fob);
      }
    },
    deleteImage(id) {
      let ind = this.fi(id);
      if (ind > -1) this.images.splice(ind, 1);
    },
    rotateImage(id, deg = 90) {
      let ind = this.fi(id);
      if (ind > -1) {
        // this.rotateBase64(this.images[ind]["src"], id);
        this.images[ind]["rotate"] += deg;
        this.emitData();
      }
    },
    emitData() {
      let newData = [];
      if (this.images.length) {
        this.images.forEach((img) => {
          newData.push({
            id: img.id,
            origSize: img.origSize,
            rotate: img.rotate,
          });
        });
      } else {
        newData = "";
      }
      // let emitData = this.deepClone(this.images).map((e) => {
      //   return { id: e.id };
      // });
      this.$emit(
        "clickedItem",
        this.images.length
          ? this.images.map((e) => {
              return { id: e.id, mh: e.mh, mw: e.mw, name: e.name, ori: e.ori, origSize: e.origSize, rotate: e.rotate, title: e.title, var: e.var };
            })
          : ""
      );
      // this.$emit("clickedItem", newData);
    },
    rotateBase64(base64data, id) {
      //console.log("base:", "id:", id);

      let canvas = document.getElementById("c");
      let ctx = canvas.getContext("2d");
      let image = new Image();

      image.src = base64data;
      canvas.width = image.height;
      canvas.height = image.width;
      ctx.rotate((90 * Math.PI) / 180);
      ctx.translate(0, -canvas.width);
      ctx.drawImage(image, 0, 0);
      image.onload = this.images.find((e) => e.id == id && (e.src = canvas.toDataURL("image/jpeg", 0.9)));
      // image.onload = this.callback(canvas.toDataURL("image/jpeg", 0.9), id);
    },
    toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
  computed: {
    imagesUploaded() {
      return this.images.filter((e) => e.uploading == 2).length;
    },

    pSize() {
      let size = 300 / (this.images.length * 0.8);
      return size < 100 ? 100 : size > 150 ? 150 : size;
    },
    nextImageToUpload() {
      return this.images.find((e) => e.uploading == 0);
    },
    currentImageUploading() {
      return this.images.filter((e) => e.uploading == 1);
    },
  },
  watch: {
    // nextImageToUpload() {
    //   if (this.nextImageToUpload) {
    //     this.nextImageToUpload;
    //     this.send(this.nextImageToUpload.id, this.nextImageToUpload.src);
    //   }
    // },
    images() {
      this.imageTotal = this.images.length;
      this.emitData();
    },
  },
  created() {},
  mixins: [VueMixinTween("imageTotal", 1000)],
  mounted() {
    setInterval(() => {
      if (this.nextImageToUpload && this.currentImageUploading.length < this.concurrentNum) this.send(this.nextImageToUpload.id, this.nextImageToUpload.src);
    }, 300);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_photo_upload {
  margin-bottom: 5px;
  transition: all 1s;
}
.photo_title_cont {
  padding: 10px;
  padding-top: 0;
}
.photo_title_cont img {
  max-width: 250px;
  max-height: 200px;
}
#img_con {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding-right: 5px;
}

.lockPhotoManage {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
.upload_completed {
  padding: 5px 0 7px 0;
}

/* #img_con img {
  -webkit-touch-callout: "none" !important;
  -webkit-user-select: none; 
  -ms-user-select: none; 
   user-select: none !important;
} */
.photos {
  vertical-align: top;
  transition: all 0.1s;
  border-radius: 1px;
  margin: 0 0 5px 0;
  -webkit-touch-callout: none;
}

.photos:active {
  cursor: move;
  transform: scale(0.95);
}
.title {
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: normal;
  /* line-height: 120%; */
  font-size: 12px;
  width: 100px;
  margin: 0px auto 6px auto;
  color: #5e5e5e;
}

@keyframes glow {
  from {
    background-color: #c4ffeb;
  }
  to {
    background-color: #f1f1f1;
  }
}
.add_title {
  text-align: center;
  line-height: 150%;
  margin: 0px 0 5px 0;
  font-size: 12px;
  color: #2e21bd;
  animation: glow 3s;
  animation-iteration-count: 1;
  background-color: #f1f1f1;
  border-radius: 2px;
  z-index: 5;
  position: relative;
}

#imageTotal {
  padding-left: 25px;
  color: #5e5e5e;
  font-size: 12px;
}
#imageTotal a {
  color: #2e21bd;
}
.trash {
  width: 16px;
  position: absolute;
  margin: 4px 0 0 -20px;
  opacity: 0.5;
}

.blink_me {
  position: absolute;
  z-index: 10;
  opacity: 1;
  transform: scale(1);
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

span#delete,
span#rotate,
span#view {
  padding: 10px;
  /* background-color: aqua; */
  position: absolute;
  z-index: 10;
  opacity: 1;
  transform: scale(1);
  transition: all 50ms;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%) drop-shadow(0px 0px 1px #000000b3) contrast(103%);
}
span#rotate {
  top: 0px;
  left: 0px;
}
span#delete {
  top: -3px;
  right: 5px;
}
span#view {
  top: 0px;
  left: 25px;
}

span#view img {
  width: 23px;
  transition: all 0.1s;
}
span#delete img {
  width: 12px;
  transition: all 0.1s;
}

span#rotate img {
  width: 18px;
  transform: scaleX(1);
  transition: all 0.1s;
}
span#rotate img:active {
  transform: scale(0.8, 0.8);
}

button {
  margin-top: 8px;
}
#variation img {
  position: absolute;
  width: 16px;
  bottom: 6px;
  right: 6px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7)) contrast(103%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide {
  transition: all 0.2s;
  position: relative;
  text-align: center;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: scale(0);
}

.slide-leave,
.slide-enter-to {
  opacity: 1;
  transform: scale(1);
}
.slide-leave-active {
  transform: scale(0);
}

.slide-move {
  opacity: 1;
}

@media only screen and (max-width: 700px) {
  span#delete img {
    width: 18px;
  }

  span#rotate img {
    width: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .photos:hover {
    cursor: pointer;
  }
  .title:hover {
    background-color: #d6efff;
    cursor: pointer;
    border-radius: 2px;
  }
  .add_title:hover {
    cursor: pointer;
    background-color: #d6efff;
    border-radius: 2px;
  }
  #img_con:hover span#delete,
  #img_con:hover span#rotate {
    opacity: 1;
    transform: scale(1);
    cursor: pointer;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%) drop-shadow(0px 0px 1px #000000b3) contrast(103%);
  }
  .trash:hover {
    opacity: 1;
  }
  /* #delete:hover {
    transform: scale(1.4);
  }
  #delete:active {
    transform: scale(1.4);
  }
  #rotate:hover {
    transform: scale(1.4, 1.4);
  } */
}
</style>
