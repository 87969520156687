// import { find } from "core-js/core/array";
function getParCat(data, cat) {
  let names = [];
  let found = data.find((e) => e.id == cat);
  names.push(found.name);
  return found.par ? names.concat(getParCat(data, found.par)) : names;
}

export default {
  namespaced: true,
  state: {
    items: [],
    total: 0,
    sort: [],
    witem: {},
    itemSource: {},
    // pinfo: null,
    catData: null,
    selectedArea: null,
    placedMarkers: null,
  },
  /*--------------------------------------------------------------------------------GETTERS-------------------------------------------------------------------------*/
  getters: {
    itemPool(state, getters, rootState) {
      if (state.itemSource.src == "homeZarList") return state.items;
      // if (state.itemSource == "favs") return rootState.user.favs;
      // if (state.itemSource.src == "favs") return JSON.parse(localStorage.getItem("cacheFavs"));
      if (
        state.itemSource.src == "favs" ||
        state.itemSource.src == "my" ||
        state.itemSource.src == "newItems" ||
        state.itemSource.src == "most_viewed" ||
        state.itemSource.src == "related_items" ||
        state.itemSource.src == "most_viewed_my" ||
        state.itemSource.src == "barter_car" ||
        state.itemSource.src == "video_items"
      )
        return state.itemSource.data;
      if (state.itemSource.src == "collect") return rootState.user.collect;

      return [];
    },
    activeSort(state) {
      return state.sort.find((e) => e.sel);
    },

    currentBread(state, getters, rootState) {
      let obj = rootState.selObj;
      if (!obj || !state.catData) return [];
      let data = state.catData;
      let parCat = data.objCat.find((e) => e.obj == obj.id);
      let cats = getParCat(data.cat, parCat.cat).reverse();
      cats.join(" - ");
      return obj.name + " (" + cats + ")";
    },
  },
  /*--------------------------------------------------------------------------------MUTATIONS-------------------------------------------------------------------------*/
  mutations: {
    witem(state, obj) {
      state.witem = obj;
    },
    total(state, val) {
      state.total = val;
    },
    items(state, arr) {
      state.items = arr;
    },
    sort(state, obj) {
      state.sort = obj;
    },
    itemSource(state, ob) {
      state.itemSource = ob;
    },
    itemSourceReset(state) {
      state.itemSource = {};
    },
    selectedArea(state, ob) {
      state.selectedArea = ob;
    },
    placedMarkers(state, ob) {
      state.placedMarkers = ob;
    },
    catData(state, obj) {
      state.catData = Object.freeze(obj);
    },
    // pinfo(state, obj) {
    //   state.pinfo = Object.freeze(obj);
    // },
  },
  actions: {
    /* getCats: async () => {
        api("http://localhost/api/cat/getcat/").then((res) => {
          this.changeCats(res.data);
          console.log(res.data);
        });
      },*/
  },
  /*--------------------------------------------------------------------------------MODULES-------------------------------------------------------------------------*/
  modules: {
    user: {
      namespaced: true,
      state: {
        favs: [],
      },
      /*----------------------------------------------------GETTERS-----------------------------------------------------------*/
      getters: {},
      /*----------------------------------------------------MUTATIONS---------------------------------------------------------*/
      mutations: {
        favsAdd(state, ob) {
          state.favs.push(ob);
        },
        favsRemove(state, ob) {
          let ind = state.favs.findIndex((e) => e.zid == ob.zid);
          state.favs.splice(ind, 1);
        },
        favsEmpty(state) {
          state.favs = [];
        },
      },
    },
    filter: {
      namespaced: true,
      state: {
        filter: { home: [] },
        //filter: { home: [] },
        fitems: { home: [] },
        //lastAdd: null,
        //avgLoadTime: null,
        pendingFilSel: { home: 0 },
      },
      /*----------------------------------------------------GETTERS-----------------------------------------------------------*/
      getters: {
        /*sortedFitems(state) {
          let p = "ord";
          return state.fitems.sort((a, b) => (a[p] < b[p] ? -1 : a[p] > b[p] ? 1 : 0));
        },*/

        /*filter(state) {
          let fil = [];
          if (state.fitems)
            state.fitems.forEach((i) => {
              i.sel.forEach((s) => {
                if (s.selected) {
                  fil.push({ obj: i.obj, prop: i.id, main: i.main, sid: s.id, tp: i.tp, prel: i.prel, sval: s.sval ? s.sval : null, sname: s.name });
                }
              });
            });
          return fil;
        },*/

        llc() {
          /*   if (state.fitems.length) {
            let llc = state.fitems.find((f) => f.llc);
            if (llc) return llc.sel.find((s) => s.selected);
          }*/
          return false;
        },
      },
      /*----------------------------------------------------MUTATIONS---------------------------------------------------------*/
      mutations: {
        pendingFilSel(state, ob) {
          state.pendingFilSel[ob.name] = ob.num;
        },
        /*avgLoadTime(state, num) {
          state.avgLoadTime = num;
        },
        lastAdd(state, ob) {
          state.lastAdd = ob;
        },*/
        fitems(state, ob) {
          state.fitems[ob.name] = ob.arr;
        },
        filter(state, ob) {
          state.filter[ob.name] = ob.arr;
        },
      } /*
      actions: {
        async fitems() {
          api("http://localhost/api/cat/getcat/").then((res) => {
            this.changeCats(res.data);
            console.log(res.data);
          });
        },
      },*/,
    },
  },
  //plugins: [vuexPersist.plugin],
};
