<template>
  <div class="container" :class="{ fullWidth, menu_active: menuActive, scrolling_bottom: scrollDirection == 1 && scr == 1, scrolling_top: scrollDirection == 2 && scr == 1 }">
    <template v-for="(m, i) in items">
      <div :key="'navi' + i" class="menu_item" @click="clickedItem(m)" :class="{ active: m.act, mobile_padding: isMobile }" v-if="!m.dis">
        <span v-if="m.orangeDot" class="orangeDot">.</span>
        <ui-icon :name="m.act ? m.icon_act : m.icon" size="xxl" />
        <!-- <span v-if="m.count" class="count">{{ m.count }}</span> -->
      </div>
    </template>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-navi-bottom",
  props: { items: Array, fullWidth: Boolean },
  data() {
    return {
      menuActive: false,
    };
  },
  methods: {
    clickedItem(m) {
      this.menuActive = true;
      this.$emit("clicked", m);
    },
  },
  computed: {
    scrollDirection() {
      return this.$store.state.scrollDirection;
    },
    scr() {
      return this.$store.getters.scr;
    },
  },
  watch: {
    scrollDirection() {
      this.menuActive = false;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.container {
  /* z-index: 10000 !importnat; */
  width: fit-content;
  text-align: center;
  border-radius: 30px;
  transition: all 300ms;
  z-index: 100;
  padding: 4px 10px;
  line-height: 1;
  gap: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 0px; */
  position: relative;
  opacity: 1;
}
/* .fullWidth {
  width: 100%;
  border-radius: 0px;
  bottom: 0px !important;
  padding: 10px 1px;
} */

.container.scrolling_top {
  margin-bottom: 0px;
}
.menu_item {
  display: inline-block;
  /* margin: 0 10px; */
  transition: all 150ms;
  position: relative;
  top: 0px;
  padding: 10px 11px 12px 11px;
  transform: scale(1);

  /* background-color: aqua; */
}

.mobile_padding {
  top: 3px !important;
}
.menu_item img {
  height: 20px;
  user-select: none;
  opacity: 1;
  /* filter: invert(22%) sepia(14%) saturate(979%) hue-rotate(177deg) brightness(94%) contrast(88%); */
  filter: invert(16%) sepia(19%) saturate(1754%) hue-rotate(160deg) brightness(94%) contrast(92%);

  /* filter: invert(22%) sepia(99%) saturate(5%) hue-rotate(349deg) brightness(94%) contrast(95%); */
  /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(168deg) brightness(101%) contrast(102%); */
}

.active {
  /* filter: invert(17%) sepia(99%) saturate(2340%) hue-rotate(195deg) brightness(93%) contrast(101%); */
  /* filter: drop-shadow(0px 0px 1px #44ddd5); */
  /* border-bottom: solid 2px #000; */
  /* background-color: #fff; */
  /* filter: invert(100%) sepia(28%) saturate(6808%) hue-rotate(26deg) brightness(115%) contrast(96%); */
}

.count {
  font-size: 10px;
  color: #a5a5a5;
}

@media only screen and (min-width: 1150px) {
  .container {
    position: absolute;
    top: 6px;
    transform: translateX(50%);
    right: 200px;

    /* padding: 3px; */
    /* border: solid 1px #e0e0e0; */
  }
  /* .menu_item img {
    height: 20px;
  } */

  .menu_item {
    display: inline-block;
    /* margin: 0 10px; */
    transition: all 100ms;
    position: relative;
    top: 0px;
    padding: 10px 12px 12px 12px;
    /* background-color: aqua; */
  }

  .menu_item:hover {
    /* background-color: #f1f1f1; */
    border-radius: 5px;
  }
}

@media only screen and (max-width: 1149px) {
  .container {
    /* border: solid 1px #cacaca; */
    position: fixed;
    bottom: 20px;
    /* padding: 8px 7px 3px 7px; */
    transform: translateX(50%);
    right: 50%;
    /* background: transparent; */
    background: #f1f1f1;
    /* background: #2d343f; */
    height: 40px;

    /* border: solid 1px #acacac; */
  }

  .menu_item img {
    height: 24px;

    /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(168deg) brightness(101%) contrast(102%); */
  }

  /* .menu_item img {
    height: 23px;
  } */
}

@media only screen and (min-width: 701px) {
  .menu_item:hover {
    cursor: pointer;
    filter: invert(17%) sepia(99%) saturate(2340%) hue-rotate(195deg) brightness(93%) contrast(101%);
  }
}
@media only screen and (max-width: 700px) {
  .container {
    background-color: var(--color_blue_dark);
    /* background-color: #f1f1f1; */
    /* background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%); */
    /* border:solid 2px #ccc; */
    /* box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.1); */
  }
  .menu_item img {
    height: 24px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    /* filter: invert(59%) sepia(78%) saturate(565%) hue-rotate(37deg) brightness(100%) contrast(101%); */
  }

  .container.scrolling_bottom {
    /* backdrop-filter: blur(10px) !important; */
    /* background-color: transparent; */
    opacity: 0.4;
    /* margin-bottom: -60px;; */
  }

  /* .menu_item:active {
    transform: scale(1.2);
  } */

  .menu_active {
    opacity: 1 !important;
  }

  .active {
    filter: none;
  }
}

/* @media only screen and (max-width: 650px) {
  .container {
    bottom: 0px;
  }
} */
</style>
