<template>
  <div class="cont_barter_offer">
    <div v-if="!checkExisting">
      <div><span v-if="collect.make" class="check">&#10004;</span><span v-else class="req">*</span> Үйлдвэрлэгч: <ui-select v-if="itemsMake" title="Үйлдвэрлэгч" :items="itemsMake" :loading="loading" :collect="collect.make" class="make" v-on:clickedItem="clickedMake($event)" :zind="30" /></div>
      <div><span v-if="collect.model" class="check">&#10004;</span><span v-else class="req">*</span> Загвар: <ui-select v-if="itemsModel" title="Загвар" :items="itemsModel" :loading="loading" :collect="collect.model" class="model" v-on:clickedItem="clickedModel($event)" :zind="20" /></div>
      <div>
        <span v-if="collect.year" class="check">&#10004;</span><span v-else class="req">*</span> Үйлдвэрлэсэн он:
        <ui-select-value v-if="itemsYear" title="Үйлдвэрлэсэн он" :loading="loading" :collect="collect.year" class="year" :zind="10" :unit="'он'" :min="itemsYear.min" :max="itemsYear.max" :sort="1" v-on:clickedItem="clickedYear($event)" />
      </div>
      <div>
        <span v-if="collect.image" class="check">&#10004;</span><span v-else class="req">*</span> Зураг:
        <ui-photo-upload class="mt-3 ml-4" v-on:clickedItem="uploadedPhoto($event)" :maxWidth="1600" :maxHeight="1200" :fileNamePrefix="'_u' + zid + '_o' + 1 + '_p' + 6" :required="true" :setTitle="false" :setMax="15" folder="boffer" />
      </div>

      <div class="prop_name mb-1"><span v-if="collect.body" class="check">&#10004;</span><span v-else class="req">*</span> Мэдээлэл:</div>
      <textarea v-model="collect.body" ref="body" class="mb-3"></textarea>
      <div class="prop_name mb-1"><span v-if="computedPhone" class="check">&#10004;</span><span v-else class="req">*</span> Холбогдох утас:</div>
      <ui-input v-on:typed="typedPhone($event)" :collect="computedPhone" :disabled="this.logged" :autoFocus="false" :unit="''" :mask="null" :unmask="true" :guide="''" :pat="null" :min="8" :max="9" :regex="'[789]\\d{3}-\\d{4}'" :minWidth="15" class="mb-3" />

      <!-- {"regex":"[789]\\d{3}\\-\\d{4}","unmask":true} -->
      <ui-reflow>
        <div class="warning_text mt-1 mb-3 ta-l" v-if="phoneExist && !logged">Энэ утсан дээр бүртгэлтэй хэрэглэгч олдлоо. <br />Та <b>бүртгэлтэй</b> нууц үгээ оруулна уу. <br />Хэрвээ нууц үгээ <b>сэргээх</b> бол <span class="link td-un" @click="restorePass()">энд дарна уу</span></div>
      </ui-reflow>

      <ui-reflow>
        <div v-if="!logged" class="prop_name mb-1">Нууц үг (бүртгүүлснээр саналыг зарын эзэн үзсэн эсэхийг шалгах боломжтой болно):</div>
        <ui-input v-if="!logged" v-on:typed="typedPass($event)" tp="password" :collect="pass" :min="6" :max="150" class="mb-3" :autoFocus="false" />
      </ui-reflow>

      <ui-reflow>
        <ui-button v-if="!logged && phoneExist && phone && pass" v-on:clicked="loginUser()" text="Нэвтрэх" pulsate />
        <ui-button v-if="!logged && !phoneExist && phone && pass" v-on:clicked="createNewUser()" text="Бүртгэл үүсгэх" pulsate />
      </ui-reflow>
    </div>
    <div class="mt-5 ta-c pb-3 ">
      <ui-reflow>
        <div v-if="checkExisting" class="mt-3 mb-3 warning_text">Та энэ зард машины санал илгээчихсэн байна</div>
      </ui-reflow>
      <ui-reflow>
        <div v-if="isComplete" class="mt-3 mb-3 info_text">Та мэдээллээ сайн нягтлаарай. Та нэг зард нэг удаа л машины санал илгээх боломжтой.</div>
      </ui-reflow>
      <div v-if="!checkExisting">
        <ui-button v-on:clicked="submit()" tp="special" class="pr-3 pl-3" text="Илгээх" widthSize="wide" :disabled="!isComplete" />
        <ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Болих" tp="simple" />
      </div>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiInput from "@/comp/ui/input.vue";
import uiSelect from "@/comp/ui/select.vue";
import uiSelectValue from "@/comp/ui/selectValue.vue";
import uiPhotoUpload from "@/comp/ui/photoUpload.vue";

export default {
  name: "zar-add-boffer",
  components: { uiInput, uiSelect, uiSelectValue, uiPhotoUpload },

  props: { dataSet: Object },
  data() {
    return {
      loading: false,
      itemsMake: null,
      itemsModel: null,
      itemsYear: null,
      phone: null,
      pass: null,
      collect: { obj: this.dataSet.obj, zid: this.dataSet.zid, make: 0, model: 0, year: 0, body: null, image: null },
      obj: this.dataSet.obj,
      zid: this.dataSet.zid,
      checkExisting: false,
    };
  },
  methods: {
    restorePass() {
      this.$bus.emit("openModal", { name: "restorePass", dataSet: { phone: this.phone } });
    },
    uploadedPhoto(ob) {
      this.collect.image = ob.map((e) => e.id);
    },
    clickedMake(sel) {
      this.collect.make = { id: sel.id, name: sel.name };
      this.fetchModel(sel.id);
    },
    clickedModel(sel) {
      this.collect.model = { id: sel.id, rel: sel.rel, name: sel.name };
      this.fetchYear(sel.id);
    },
    clickedYear(sel) {
      this.collect.year = sel;
    },
    typedPhone(str) {
      this.phone = str;
    },
    typedPass(str) {
      this.pass = str;
    },
    submit() {
      this.axiosPost("/module/barter/addBoffer", this.collect);
      this.showMessage("info", "Таны машины санал амжилттай илгээгдлээ");
      this.close();
    },
    close() {
      this.$emit("close");
    },
    async fetchMake() {
      this.itemsMake = await this.axiosGet("/zar/object/getRelData/1/4/0/0/ref_make", []);
    },
    async fetchModel(id) {
      this.collect.model = 0;
      this.itemsModel = await this.axiosGet("/zar/object/getRelData/1/20/" + id + "/0/ref_model", []);
    },
    async fetchCheckExisting() {
      let res = await this.axiosGet("/module/barter/checkExisting", [this.zid]);
      this.checkExisting = res.status;
    },
    async fetchYear(id) {
      this.collect.year = null;
      this.itemsYear = null;
      let cusSel = await this.axiosGet("/zar/object/getCusSel/2/1/5/" + id, []);
      let customYear = cusSel.find((e) => e.tprop == 6);

      if (customYear) this.itemsYear = { min: customYear.tval_min, max: customYear.tval_max };
      else this.itemsYear = { min: 1990, max: new Date().getFullYear() };
    },

    loginUser() {
      this.$bus.emit("openModal", { name: "userLogin", dataSet: { phone: this.phone, pass: this.pass, returnPage: "noRedirect" } });
    },
    createNewUser() {
      this.$bus.emit("openModal", { name: "userRegister", dataSet: { phone: this.phone, pass: this.pass, returnPage: "noRedirect" }, zid: this.zid });
    },
    // createNewUser() {
    //   if (!this.collect.phone || !this.collect.pass) return;

    //   this.$bus.emit("openModal", { name: "userRegister", data: { phone: this.collect.phone, pass: this.collect.pass, returnPage: null }, zid: this.zid });
    // },
  },
  watch: {
    logged: {
      immediate: true,
      handler() {
        if (this.logged) this.fetchCheckExisting();
      },
    },
  },
  computed: {
    logged() {
      return this.$store.state.auth.status.logged;
    },
    user() {
      return this.$store.state.auth.user;
    },
    computedPhone() {
      return this.logged ? this.$store.state.auth.user.phone : this.phone;
    },
    isComplete() {
      return this.logged && this.collect.make && this.collect.model && this.collect.year && this.collect.image && this.collect.body && !this.checkExisting;
    },
  },
  asyncComputed: {
    phoneExist() {
      if (this.phone)
        return this.axios
          .get("/user/account/phoneExist/" + this.phone)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  // asyncComputed: {
  // phoneExist() {
  //   if (this.user) return false;
  //   if (this.collect.phone && this.phone.pass)
  //     return this.axios
  //       .get("/user/account/phoneExist/" + this.collect.phone)
  //       .then((res) => {
  //         return res.data ? { phone: this.collect.phone, pass: this.collect.pass } : false;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // },
  // },
  // activated() {
  //   this.fetchCheckExisting();
  // },
  mounted() {
    // setTimeout(() => this.$refs.body.focus(), 100);
    this.fetchMake();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_barter_offer {
  padding: 0 0px 10px 10px;
  text-align: left;
  overflow-y: scroll;
  max-height: 100%;
}

.cont_barter_offer textarea {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}

.check {
  display: inline-block;
  color: rgb(18, 170, 18);
}
.req {
  display: inline-block;
  color: red;
}

.make,
.model,
.year {
  display: inline-block;
  margin-right: 10px;
  /* font-weight: 500; */
}
</style>
