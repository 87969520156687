<template>
  <div>
    <div class="row" v-if="userInfo">
      <div class="col1"></div>
      <div class="col2" v-if="scr > 1">
        <h2><ui-icon name="i-user3" size="xxxxl" class="mr-2 po-re top-10" />{{ userRole }} <ui-icon name="i-check-solid2" class="edit_info clickable" @click="showEditInfo()" /></h2>
        <div class="ml-2">
          <div class="mb-1"><ui-icon name="i-phone" size="xxxl" class="mr-2 po-re top-8" v-if="userInfo" />{{ userInfo.phone }} <span class="link fs-12" v-if="false && user && !userInfo.verified" @click="verifyPhone">баталгаажуулах</span></div>
          <div class="mb-1"><ui-icon name="i-badge2" size="xxxl" class="mr-2 po-re top-8" />{{ userMerit }}</div>
          <div class="mb-1 user_credit"><ui-icon name="i-coin" size="xxxl" class="mr-2 po-re top-8" />{{ userInfo.credit }} төгрөг</div>

          <div class="mb-1"><ui-icon name="i-loc-no" size="xxxl" class="mr-2 po-re top-8" />Байршил</div>

          <div class="loc_cont" v-if="userInfo.loc_day_name || userInfo.loc_night_name">
            <div @click="userLocation()" class="link mb-1" v-if="userInfo.loc_day_name">
              <div class="pulse_container3">
                <div class="po-ab">
                  <div class="dot"></div>
                  <div class="pulse"></div>
                </div>
              </div>
              {{ userInfo.loc_day_name }}
            </div>

            <div @click="userLocation()" class="link" v-if="userInfo.loc_night_name">
              <div class="pulse_container4">
                <div class="po-ab">
                  <div class="dot"></div>
                  <div class="pulse"></div>
                </div>
              </div>
              {{ userInfo.loc_night_name }}
            </div>
          </div>
          <span v-else class="loc_cont">
            <span class="ml-3 link" @click="userLocation()">Тохируулах</span>
          </span>

          <div class="pt-3 pl-1">
            <!-- <span class="link" v-if="logged" @click="logout()"><ui-icon name="i-logout" class="logout_icon" size="xxl" /> Гарах</span> -->
            <ui-button v-if="logged" v-on:clicked="logout()" text="Гарах" />
          </div>
        </div>
      </div>
      <div class="col3">
        <div v-if="scr == 1" class="title_page ta-c pt-5 pb-3">Миний хэсэг</div>
        <ui-tab2 :items="tabs" v-on:clickedItem="clickedTab($event)" :actTab="actTab">
          <div v-if="actTab == 'car'">
            <p class="suggest_text">
              Та өөрийн машины мэдээллийг оруулснаар таны машины загвартай тохирох бартерын заруудыг харуулах боломжтой.
            </p>
            <span v-if="userInfo.car_make">
              {{ userInfo.car_make_name }}
              {{ userInfo.car_model_name }}
              {{ userInfo.car_year }}
              <span class="ml-3 link" @click="userCar()">Өөрчлөх</span>
            </span>
            <span v-else>
              <span class="ml-3 link" @click="userCar()">Тохируулах</span>
            </span>
            <user-zar-list :nameSrc="actTab" v-on:clickedItem="clickedItem($event)" />
          </div>
          <div v-if="actTab == 'my'">
            <user-zar-list :nameSrc="actTab" v-on:clickedItem="clickedItem($event)" userMenu />
          </div>
          <div v-else-if="actTab == 'favs'">
            <user-zar-list :nameSrc="actTab" v-on:clickedItem="clickedItem($event)" />
          </div>
          <div v-else-if="actTab == 'most_viewed_my'">
            <user-zar-list :nameSrc="actTab" v-on:clickedItem="clickedItem($event)" />
          </div>
          <div v-else-if="actTab == 'most_viewed'">
            <zar-list-self :nameSrc="actTab" v-on:clickedItem="clickedItem($event)" />
          </div>
          <div v-else-if="actTab == 'my_boffer'">
            <boffer-list :nameSrc="actTab" :dataSet="{ url: '/module/barter/getUserBoffer' }" noTitle noButton showSeen />
          </div>
        </ui-tab2>
      </div>
      <div class="col4"></div>
      <!-- <ch-drawer v-if="scr < 4 && $route.name == 'userhome'" :visible.sync="showMenu.tools" area="280px" :blur="false" location="right" :title="'Мэдээлэл'">
      content
    </ch-drawer> -->
    </div>
    <div v-else class="w-100 ta-c">
      <div class="no_info warning_text">Хэрэглэгчийн мэдээлэл олдсонгүй. <span class="link" @click="logout()">Гарах</span></div>
    </div>
    <div v-if="userInfo">
      <ch-drawer :visible.sync="showMenu.userInfo" area="350px" title="Хэрэглэгчийн мэдээлэл" :blur="false" :location="'left'" @open="$store.commit('addOpenDrawers', 'showMenuUserInfo')" @close="$store.commit('removeOpenDrawers', 'showMenuUserInfo')" destroy-on-close>
        <h2><ui-icon name="i-user3" size="xxxxl" class="mr-2 po-re top-10" />{{ userRole }} <ui-icon name="i-check-solid2" class="edit_info clickable" @click="showEditInfo()" /></h2>
        <div class="ml-2">
          <div class="mb-1"><ui-icon name="i-phone" size="xxxl" class="mr-2 po-re" v-if="userInfo" />{{ userInfo.phone }} <span class="link fs-12" v-if="false && user && !userInfo.verified" @click="verifyPhone">баталгаажуулах</span></div>
          <div class="mb-1"><ui-icon name="i-badge2" size="xxxl" class="mr-2 po-re top-8" />{{ userMerit }}</div>
          <div class="mb-1 user_credit"><ui-icon name="i-coin" size="xxxl" class="mr-2 po-re top-8" />{{ userInfo.credit }} төгрөг</div>

          <div class="mb-1"><ui-icon name="i-loc-no" size="xxxl" class="mr-2 po-re top-8" />Байршил</div>

          <div class="loc_cont" v-if="userInfo.loc_day_name || userInfo.loc_night_name">
            <div @click="userLocation()" class="link mb-1" v-if="userInfo.loc_day_name">
              <div class="pulse_container3">
                <div class="po-ab">
                  <div class="dot"></div>
                  <div class="pulse"></div>
                </div>
              </div>
              {{ userInfo.loc_day_name }}
            </div>

            <div @click="userLocation()" class="link" v-if="userInfo.loc_night_name">
              <div class="pulse_container4">
                <div class="po-ab">
                  <div class="dot"></div>
                  <div class="pulse"></div>
                </div>
              </div>
              {{ userInfo.loc_night_name }}
            </div>
          </div>
          <span v-else class="loc_cont">
            <span class="ml-3 link" @click="userLocation()">Тохируулах</span>
          </span>

          <div class="pt-3 pl-1">
            <span class="link" v-if="logged" @click="logout()"><ui-icon name="i-logout" class="logout_icon" size="xxl" /> Гарах</span>
          </div>
        </div>
        <div v-if="scr == 1" class="return_button_right" @click="showMenu.userInfo = false"><ui-icon class="back_icon" angle="270deg" name="i-close4" size="xxxxl" /></div>
        <div class="return_button_top" @click="showMenu.userInfo = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      </ch-drawer>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import userZarList from "@/comp/user/zarList.vue";
import zarListSelf from "@/comp/zar/listSelf.vue";
import uiTab2 from "@/comp/ui/tab2.vue";
import userEditInfo from "@/comp/user/editInfo.vue";
import bofferList from "@/comp/mod/bofferList.vue";

export default {
  name: "user-home",
  components: { userZarList, uiTab2, zarListSelf, bofferList },
  metaInfo() {
    return {
      title: "Миний хэсэг",
      meta: [
        // { name: "description", content: "Connect and follow " + this.userData.name + " on Epiloge - " + this.userData.tagline },
        // { property: "og:title", content: this.userData.name + " - Epiloge" },
        // { property: "og:site_name", content: "Epiloge" },
        // { property: "og:description", content: "Connect and follow " + this.userData.name + " on Epiloge - " + this.userData.tagline },
        // { property: "og:type", content: "profile" },
        // { property: "og:url", content: "https://epiloge.com/@" + this.userData.username },
        // { property: "og:image", content: this.aws_url + "/users/" + this.userData.profileurl + "-main.jpg" },
      ],
    };
  },
  data() {
    return {
      userItems: [],
      userFavs: [],
      totalPage: 0,
      actTab: this.$route.query.tab,
      // actTab: "favs",
      // userInfo: {},
    };
  },
  methods: {
    userLocation() {
      this.$bus.emit("openModal", { name: "userLocation", dataSet: { locDay: this.userInfo.loc_day, locNight: this.userInfo.loc_night, locDayName: this.userInfo.loc_day_name, locNightName: this.userInfo.loc_night_name } });
    },
    userCar() {
      this.$bus.emit("openModal", { name: "userCar" });
    },
    clickedTab(tab) {
      this.actTab = tab;
      history.replaceState("", "", "home?tab=" + tab);
    },
    showEditInfo() {
      this.$modal.show(userEditInfo, {}, { height: "auto", width: 350 });
    },
    async fetchUserInfo() {
      if (this.user) await this.$store.dispatch("user/updateUserInfo");
    },
    editUserInfo() {},
    clickedItem(items) {
      if (this.actTab == "my") this.$store.commit("zar/itemSource", { name: "Миний зар", src: "my", data: items });
      if (this.actTab == "favs") this.$store.commit("zar/itemSource", { name: "Тэмдэглэсэн зар", src: "favs", data: items });
      if (this.actTab == "most_viewed") this.$store.commit("zar/itemSource", { name: "Их үзэлттэй зар", src: "most_viewed", data: items });
      if (this.actTab == "most_viewed_my") this.$store.commit("zar/itemSource", { name: "Их үзэлттэй зар", src: "most_viewed_my", data: items });
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    verifyPhone() {
      this.$bus.emit("openModal", { name: "userVerify" });
    },
  },
  watch: {
    tabName() {
      this.actTab = this.tabName;
    },
    // userInfo() {
    //   console.log("userinfo:", this.userInfo);
    // },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    showMenu: {
      get() {
        return this.$store.state.showMenu;
      },
      set(ob) {
        this.$store.commit("showMenu", ob);
      },
    },
    userRole() {
      let role;
      if (!this.userInfo) return null;

      switch (this.userInfo.role) {
        case 1:
          role = "Хувь хүн";
          break;
        case 2:
          role = "Хувь хүн (премиум)";
          break;
        case 3:
          role = "ААН";
          break;
        case 4:
          role = "ААН (премиум)";
          break;
        case 5:
          role = "Админ";
          break;
        default:
          break;
      }
      return role;
    },
    userMerit() {
      let merit;
      if (!this.userInfo) return null;

      switch (this.userInfo.merit) {
        case 1:
          merit = "Хязгаарлагдмал";
          break;
        case 2:
          merit = "Хэвийн";
          break;
        case 3:
          merit = "Сайн";
          break;
        case 4:
          merit = "Онц сайн";
          break;
        default:
          break;
      }
      return merit;
    },
    tabName() {
      return this.$route.query.tab ? this.$route.query.tab : "my";
    },
    favs() {
      return this.$store.state.user.favs;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    user() {
      return this.$store.state.auth.user;
    },
    source() {
      return this.$store.state.zar.itemSource;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    tabs() {
      let my = this.userInfo && this.userInfo.item ? this.userInfo.item : 0;
      let favs = this.userInfo && this.userInfo.favs ? this.userInfo.favs : 0;

      return [
        // { name: "home", text: "Байр", textLong: "Байр", count: 0, icon: "i-home" },
        // { name: "car", text: "Машин", textLong: "Машин", count: 0, icon: "i-auto" },
        // { name: "following", text: "Дагаж байгаа", textLong: "Дагаж байгаа", count: 0 , icon:null},
        { name: "my", text: "Миний", textLong: "Миний зар ", count: my, icon: "i-user" },
        { name: "my_boffer", text: "Миний илгээсэн санал", textLong: "Миний илгээсэн санал", icon: "i-exchange" },
        { name: "favs", text: "Тэмдэглэсэн", textLong: "Тэмдэглэсэн", count: favs, icon: "i-bookmark" },
        { name: "most_viewed_my", text: "Миний их үзсэн", textLong: "Миний их үзсэн", icon: "i-view2" },
        { name: "most_viewed", text: "Их үзэлттэй", textLong: "Их үзэлттэй", count: 0, icon: "i-view2" },
      ];
    },
  },
  created() {
    if (!this.logged) {
      this.$router.push("/");
      this.$bus.emit("openModal", { name: "userLogin" });
    }
    if (!this.$route.query.tab) {
      history.replaceState("", "", "home?tab=my");
      this.actTab = "my";
    }
  },
  mounted() {
    this.$bus.on("userLogout", this.logout);

    // console.log("userinfo:", this.userInfo);
    // this.fetchUserInfo();
    // this.userInfo
  },
  activated() {
    // console.log("userinfo", this.userinfo);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.row {
  display: grid;
  /* grid-template-columns: 1fr 2fr 1fr; */
  grid-template-columns: 0.6fr 1fr 3fr 0.6fr;
  grid-gap: 10px;
}

.user_credit img {
  filter: invert(65%) sepia(75%) saturate(606%) hue-rotate(1deg) brightness(98%) contrast(107%);
}

/* .user_action {
  margin: 15px 0 0 0px;
  border: solid 1px #e4e4e4;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 8px;
}
.user_action a {
  display: block;
  padding: 2px 0;
}
.user_action icon {
  position: relative;
  top: 15px;
} */

.logout_icon {
  position: relative;
  top: 5px;
}

.loc_cont {
  padding: 10px 0 10px 8px;
  position: relative;
}

.loc_cont img {
  position: absolute;
  left: 0px;
}

.edit_info {
  position: relative;
  top: 2px;
}

.no_info {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1149px) {
  .col4 {
    display: none;
  }
  .col1 {
    display: none;
  }
  .row {
    grid-template-columns: 1.2fr 3fr;
  }
}

@media only screen and (max-width: 700px) {
  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
</style>
