<template>
  <div class="cont_ui_checkbox">
    <input type="checkbox" id="checkbox" v-model="check" />
    <span class="label clickable" @click="clickedLabel()">{{ items.first().name }}</span>
    <span v-if="unit">{{ unit }}</span>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-checkbox",
  props: { items: Array, unit: String, collect: { type: [String, Object, Boolean, Number, Array] } },
  components: {},
  data() {
    return {
      check: false,
    };
  },
  methods: {
    clickedLabel() {
      this.check = !this.check;
    },
  },
  watch: {
    check: {
      // immediate: true,
      handler() {
        // this.$emit("clickedItem", this.check ? { id: this.items.first().id, name: this.items.first().name } : "");
        this.$emit("clickedItem", this.check ? this.items.first() : "");
      },
    },
  },
  mounted() {
    if (this.collect) this.check = this.collect ? true : false;
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_checkbox {
  /* margin-top: 4px; */
  clear: both;
  padding-top: 5px;
}
input {
  margin: 0 5px 5px 0;
}
.label {
  position: absolute;
  margin: -1px 0 0 0px;
  transition: all 150ms;
  color: var(--color_darker);
}
</style>
