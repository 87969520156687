<template>
  <!-- <ui-modal v-on:close="close()" :title="(pob.name == 'Хотхон' ? 'Хотхоны' : 'Байрны') + ' мэдээлэл нэмэх'" name="addSel" :shake="shake" :width="600"> -->
  <div class="cont_add_sel">
    <div class="title_modal">Шинээр {{ pob.name.toLowerCase() + " нэмэх" }}</div>
    <div class="cont_suggest_text">
      <div class="suggest_text">Хэрвээ таны хүссэн сонголт байхгүй байгаа бол энэ хэсэгт шинээр нэмэх боломжтой. Нэмэх мэдээлэл үнэн зөв бөгөөд урьд нь ороогүй эсэхийг сайтар нягтлаарай.</div>
    </div>
    <span class="pname">Нэр: </span> <ui-input v-on:typed="typed($event)" class="input" :min="1" :max="40" /> <ui-icon v-if="addSel.name" size="m" name="i-correct" class="success ani_pop_slow" />
    <div v-if="pob.user_addable == 1" class="ta-l">
      <ui-select class="po-ab" :items="districts" unit="дүүрэг" v-on:clickedItem="selectedDistrict($event)" :collect="collectedDistrict" :floating="false" :zind="10" />
      <ui-select-value :min="1" :max="35" :step="1" unit="хороо" v-on:clickedItem="selectedHoroo($event)" :zind="11" />
      <div>
        <span class="pname">Хэсэг ({{ firstAreaCode }}): </span> <span :class="{ disabled: !areaMatched }">{{ secondAreaCode }}</span> <ui-icon v-if="areaMatched" size="m" name="i-correct" class="success ani_pop_slow" />
      </div>
      <div class="mb-3 mt-1">
        <span class="pname">Координат: </span> <span v-if="addSel.coord">{{ addSel.coord }}</span> <span class="disabled" v-else>Газрын зураг дээр заан уу</span> <ui-icon v-if="addSel.coord" size="m" name="i-correct" class="success ani_pop_slow" />
      </div>
      <ui-loc-grab-detect :disableDrag="false" :inputLocCode="false" :markers="placedMarkers" v-on:coord="updatedCoord($event)" :enSatView="false" :centerIni="centerIni" v-on:selectedArea="areaSelected($event)" />
    </div>
    <div class="mt-3 ta-c">
      <ui-button v-on:clicked="submitAddSel()" v-on:mover="checkFields()" text="Оруулах" :disabled="disabled" widthSize="wide" />
      <ui-button v-on:clicked="close()" text="Болих" tp="textOnly" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectValue from "@/comp/ui/selectValue.vue";
import uiSelect from "@/comp/ui/select.vue";
import uiInput from "@/comp/ui/input.vue";
import uiLocGrabDetect from "@/comp/ui/locGrabDetect.vue";

export default {
  name: "ui-add-sel",
  components: {
    uiInput,
    uiLocGrabDetect,
    uiSelectValue,
    uiSelect,
  },
  props: {
    pob: Object,
  },
  data() {
    return {
      addSel: { name: null, coord: null },
      shake: false,
      secondAreaCode: null,
      districts: [
        { id: 1, name: "Багануур", sname: "БНР" },
        { id: 2, name: "Багахангай", sname: "БХА" },
        { id: 3, name: "Баянгол", sname: "БГД" },
        { id: 4, name: "Баянзүрх", sname: "БЗД" },
        { id: 5, name: "Налайх", sname: "НХД" },
        { id: 6, name: "Сонгино хайрхан", sname: "СХД" },
        { id: 7, name: "Сүхбаатар", sname: "СБД" },
        { id: 8, name: "Хан-Уул", sname: "ХУД" },
        { id: 9, name: "Чингэлтэй", sname: "ЧГД" },
      ],
      collectedDistrict: false,
      collectedHoroo: false,
    };
  },
  methods: {
    selectedHoroo(sel) {
      this.collectedHoroo = sel;
    },
    selectedDistrict(sel) {
      this.collectedDistrict = sel;
    },
    areaSelected(ob) {
      this.secondAreaCode = ob.data.id;
    },
    updatedCoord(arr) {
      this.addSel.coord = arr;
    },
    typed(val) {
      this.addSel.name = val;
    },
    checkFields() {
      if (!this.addSel.name) {
        this.shake = true;
        setTimeout(() => (this.shake = false), 600);
        this.showMessage("warning", "Нэрээ оруулна уу");
        return;
      }

      if (!this.addSel.coord && this.pob.user_addable == 1) {
        this.shake = true;
        setTimeout(() => (this.shake = false), 600);
        this.showMessage("warning", "Байршлыг заана уу");
        return;
      }

      if (!this.collectedDistrict && this.pob.user_addable == 1) {
        this.shake = true;
        setTimeout(() => (this.shake = false), 600);
        this.showMessage("warning", "Дүүргээ сонгон уу");
        return;
      }

      if (!this.collectedHoroo && this.pob.user_addable == 1) {
        this.shake = true;
        setTimeout(() => (this.shake = false), 600);
        this.showMessage("warning", "Хороогоо сонгон уу");
        return;
      }

      if (!this.areaMatched && this.pob.user_addable == 1) {
        this.shake = true;
        setTimeout(() => (this.shake = false), 600);
        this.showMessage("warning", "Та " + this.firstAreaCode + " дугаартай хэсэг дээр л байршил сонгох боломжтой");
        return;
      }
    },
    submitAddSel() {
      if (this.disabled) return;
      let usel =
        this.pob.user_addable == 1 ? { name: this.addSel.name, district: this.collectedDistrict.sname, khoroo: this.collectedHoroo, title: this.collectedDistrict.sname + "-" + this.collectedHoroo, coord: "[" + this.addSel.coord.lat + "," + this.addSel.coord.lng + "]" } : { name: this.addSel.name };
      this.$bus.emit("userSubItem" + this.pob.id, usel);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    disabled() {
      if (this.pob.user_addable == 1) {
        return this.addSel.name && this.addSel.coord && this.areaMatched && this.collectedDistrict && this.collectedHoroo ? false : true;
      } else return this.addSel.name ? false : true;
    },
    areaMatched() {
      return this.firstAreaCode == this.secondAreaCode;
    },
    baseMarkers() {
      return this.$store.state.zar.selectedArea ? this.$store.state.zar.selectedArea.data.markers : false;
    },
    centerIni() {
      return this.$store.state.zar.selectedArea ? this.$store.state.zar.selectedArea.data.center : false;
      // let areaJson = this.$store.state.zar.selectedArea.data.geometry.coordinates.first();
      // areaJson = areaJson.map((e) => {
      //   return [e[1], e[0]];
      // });
      // return areaJson;
    },
    firstAreaCode() {
      return this.$store.state.zar.selectedArea ? this.$store.state.zar.selectedArea.data.id : false;
      // let areaJson = this.$store.state.zar.selectedArea.data.geometry.coordinates.first();
      // areaJson = areaJson.map((e) => {
      //   return [e[1], e[0]];
      // });
      // return areaJson;
    },
    placedMarkers() {
      return this.$store.state.zar.placedMarkers;
    },
  },
  watch: {
    areaMatched() {
      if (!this.areaMatched) this.showMessage("warning", "Та " + this.firstAreaCode + " дугаартай хэсэг дээр л байршил сонгох боломжтой");
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_add_sel {
  text-align: center;
  position: relative;
  padding: 10px;
  padding-top: 0;
}
.input {
  display: inline-block;
  padding: 0;
}
</style>
