<template>
  <div class="btn" :class="[tp, disabled && 'disabled', icon && 'withIcon', { wideIcon: widthSize == 'wideIcon', pulsate, wide: widthSize == 'wide' }]" @click="clicked()" @mouseover="mover()">
    <ui-icon v-if="icon && !iconEnd" class="icon" :class="{ angleReverse: angle == 'rev' }" :name="icon" size="xl" />
    <span v-if="text">{{ text }}</span>
    <ui-icon v-if="icon && iconEnd" class="icon ml-2" :class="{ angleReverse: angle == 'rev' }" :name="icon" size="xl" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-button",
  props: {
    text: [String, Number],
    widthSize: String,
    tp: {
      type: String,
      default: () => "normal",
    },
    pulsate: {
      type: Boolean,
      default: () => false,
    },
    icon: String,
    angle: String,
    disabled: Boolean,
    iconEnd: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    clicked() {
      if (!this.disabled) this.$emit("clicked");
    },
    mover() {
      this.$emit("mover");
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.btn {
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 14px;
  user-select: none;
  line-height: 2.2em;
  padding: 0 12px;
  font-stretch: 120%;
  /* letter-spacing: -0.01em; */
  transition: all 100ms ease-in-out;
  font-family: Segoe_UI_Historic, sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  white-space: nowrap;
}

.wide {
  padding-left: 20px;
  padding-right: 20px;
}

.withIcon {
  padding: 2px 10px 0px 10px;
}

.iconOnly {
  /* padding: 0 0.8em; */

  /* padding: 5px 5px 5px 5px; */
  height: 30px;
  /* border: solid 1px #ccc; */
  color: #353535;
  /* box-sizing: border-box; */
  transition: background 100ms;
  /* box-shadow: 0px 0px 1px 0px rgba(16, 62, 105, 1) !important; */
  /* border: solid 1px var(--color_gray_lightest) !important; */
  background-color: #fff;
  display: inline;
  /* line-height: 1; */
}

.iconOnly img {
  position: relative;
  top: 1px;
  left: 3px;
  height: 14px;
  /* filter: drop-shadow(0px 0px 1px #44ddd5) drop-shadow(0px 0px 1px #44ddd5); */
}

.iconOnly2 {
  /* padding: 0 0.8em; */
  /* line-height: 3; */
  /* padding: 9px 7px; */
  height: 25px;
  /* border: solid 1px #ccc; */
  /* color: #353535; */
  /* box-sizing: border-box; */
  /* transition: background 100ms; */
  /* box-shadow: 0px 1px 1px 1px rgba(16, 62, 105, 0.2) !important; */
  /* border: solid 1px #ccc !important; */
  /* display: inline-block; */
  background-color: #f1f1f1;
}

.iconOnly2 img {
  position: relative;
  top: 1px;
  height: 12px;
}

.iconOnly2.disabled {
  background: none !important;
  /* border: solid 1px #d6d6d6 !important; */

  opacity: 0.2 !important;
}

.iconOnly2.wideIcon {
  padding: 4px 25px !important;
}

.btn:active {
  /* box-shadow: inset 0px 1px 0px 0px #0054a3; */
  transform: translateY(1px);
  /* filter: brightness(0.96); */
}

.icon {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
  /* position: absolute;
  left: 6px;
  top: 5px; */
}

.disabled {
  opacity: 0.5;
  cursor: auto;
  background: #dddddd !important;
  color: #464646 !important;
  /* border: solid 1px #dddddd !important; */
  filter: saturate(0);
}

.btn:focus {
  outline: none;
}

.normal {
  /* background: #ddefff; */
  /* background-color: rgb(232, 245, 255); */
  background-color: var(--color_blue);
  color: #fff;
  /* border: solid 1px rgba(31, 35, 40, 0.219); */

  /* box-shadow: 0px 1px 0px 0px rgba(16, 62, 105, 0.1) !important; */
  /* border: solid 1px rgba(16, 62, 105, 0.2); */
}

.normal:active {
  /* border: solid 1px #d1e9ff; */
  /* box-shadow: 0px 0px 1px 1px rgba(16, 62, 105, 0.4) !important; */
  /* filter: brightness(0.95); */
}

.normal img {
  filter: invert(98%) sepia(3%) saturate(355%) hue-rotate(244deg) brightness(115%) contrast(100%);
}

.special {
  /* border: solid 1px #abeaeb; */
  background-color: #befeff !important;
  font-weight: 700;
  color: #000 !important;
  /* text-shadow: 0px 0px 1px #0c59a1; */
  /* box-shadow: 0px 1px 0px 1px rgba(16, 62, 105, 0.05) !important; */
}

.special:active {
  /* box-shadow: 0px 0px 0px 1px rgba(16, 62, 105, 0.8) !important; */
  /* border: solid 1px #00b7ff; */
}

.pulsate {
  animation: pulsate_bg2 1s ease-out;
  animation-iteration-count: infinite;
}

.blue {
  /* border: solid 2px #2e8be2; */
  background-color: var(--color_selected) !important;
  font-weight: 600;
  /* text-shadow: 0px 0px 1px #0c59a1; */
  /* box-shadow: 0px 1px 0px 1px rgba(16, 62, 105, 0.8) !important; */
}

.blue:active {
  /* box-shadow: 0px 0px 0px 1px rgba(16, 62, 105, 0.8) !important; */
  /* border: solid 2px #2e8be2; */
}

.simple {
  background-color: #f1f1f1;
  /* border: solid 1px #d3d3d3; */

  box-shadow: 0px 1px 0px 0px rgba(78, 78, 78, 0.05) !important;

  /* color: #000; */
}

.simple:active {
  /* border: solid 1px transparent; */

  /* box-shadow: 0px 0px 0px 1px rgba(78, 78, 78, 0.3) !important; */
}
.textOnly {
  background: none;
  color: #444444;
  box-shadow: none;
  /* border: solid 1px transparent; */
}

.textOnly:active {
  /* border: solid 1px transparent; */

  /* box-shadow: 0px 0px 0px 1px rgba(78, 78, 78, 0.3) !important; */
}

.textOnly.disabled {
  background: none !important;
  /* border: none !important; */
  color: #a8a8a8 !important;
}

.angleReverse {
  transform: rotate(180deg);
}
@media only screen and (max-width: 700px) {
  .btn {
    font-size: 15px !important;
  }
}
@media only screen and (min-width: 701px) {
  .iconOnly:hover {
    background-color: #f1f1f1;
    /* box-shadow: 0px 1px 0px 0px rgba(16, 62, 105, 0.1) !important; */
  }
  .iconOnly2:hover {
    background-color: #f1f1f1;
    /* box-shadow: 0px 1px 0px 0px rgba(16, 62, 105, 0.1) !important; */
  }
  .simple:hover {
    /* border: solid 1px #ccc; */
    /* box-shadow: 0px 1px 0px 0px rgba(16, 62, 105, 0.1) !important; */
  }
  .textOnly:hover {
    /* border: solid 1px var(--color_gray_disabled); */
    background-color: #f1f1f1;

    /* box-shadow: 0px 1px 0px 1px rgba(78, 78, 78, 0.3) !important; */

    /* background-color: #f1f1f1; */
  }
  .blue:hover {
    /* background-color: #eff7ff !important; */
    /* border: solid 2px #429cf0; */
    /* border: solid 1px #429cf0; */
  }
  .special:hover {
    /* border: solid 1px #00b7ff; */
    /* background-color: #eff7ff !important; */
    /* border: solid 2px #429cf0; */
    /* border: solid 1px #429cf0; */
  }
  .disabled:active,
  .disabled:hover {
    transform: none !important;
    cursor: default;
    box-shadow: none;
  }
  .btn:hover {
    cursor: pointer;
    /* filter: brightness(0.96); */
  }
  .normal:hover {
    /* border: solid 1px #8d8d8d; */
    /* box-shadow: 0px 1px 0px 0px rgba(16, 62, 105, 0.1) !important; */
  }
  .btn:hover {
    filter: brightness(0.95);
  }
}
</style>
