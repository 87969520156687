<template>
  <div class="cont_zar_edit">
    <div class="title_modal">Зар засах</div>
    <template v-for="(p, i) in dataSet.props">
      <div :key="'ke' + i" v-show="!p.sys" class="mb-3">
        <div class="pname ml-2">{{ p.name }}</div>
        <comp :zind="100 - i" :is="p.pcomp_name" :opt="p.opt" :items="p.sel" :obj="{ id: dataSet.obj }" :pob="p" :zid="dataSet.zid" :collect="p.collect" v-on:clickedItem="clickedItem(p.id, $event)" />
      </div>
    </template>
    <div class="pt-1"><ui-button v-on:clicked="submit()" text="Хадгалах" :disabled="complete" /> <ui-button v-on:clicked="close()" text="Болих" tp="textOnly" /></div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import addYoutube from "@/comp/page/zarAdd/youtube.vue";
import addInput from "@/comp/page/zarAdd/input.vue";
import addInputPass from "@/comp/page/zarAdd/inputPass.vue";
import addInputSel from "@/comp/page/zarAdd/inputSel.vue";
import addTextbox from "@/comp/page/zarAdd/textbox.vue";
import addSelect from "@/comp/page/zarAdd/select.vue";
import addSelectHori from "@/comp/page/zarAdd/selectHori.vue";
import addSelectMulti from "@/comp/page/zarAdd/selectMulti.vue";
import addCheckbox from "@/comp/page/zarAdd/checkbox.vue";
import addSelectValue from "@/comp/page/zarAdd/selectValue.vue";
import addLocGrab from "@/comp/page/zarAdd/locGrab.vue";
import addLocSelect from "@/comp/page/zarAdd/locSelect.vue";
import addVideoUpload from "@/comp/page/zarAdd/videoUpload.vue";
import addPhotoUpload from "@/comp/page/zarAdd/photoUpload.vue";
import addSelectMultiValue from "@/comp/page/zarAdd/selectMultiValue.vue";
import addInputPrice from "@/comp/page/zarAdd//inputPrice.vue";

export default {
  name: "user-zar-edit",
  props: { dataSet: Object },
  components: { addInputPrice, addYoutube, addPhotoUpload, addVideoUpload, addInput, addInputSel, addSelect, addSelectValue, addSelectMultiValue, addSelectHori, addSelectMulti, addTextbox, addCheckbox, addLocGrab, addLocSelect, addInputPass },
  data() {
    return {};
  },
  methods: {
    funcCalcUnitPrice_old(quantity, totalPrice, target, integer = false) {
      let tProp = this.dataSet.props.find((e) => e.id == target);
      // if (quantity == 1) return (tProp.collect = "");

      if (!quantity || !tProp || !totalPrice) return;
      tProp.collect = integer ? Math.round((totalPrice / quantity).toFixed(1)) : (totalPrice / quantity).toFixed(1);
      tProp.visible = 1;
    },
    funcCalcUnitPrice(quantity, totalPrice, target, integer = false, lowerDigit = false) {
      if (lowerDigit) totalPrice = totalPrice * 1000;
      // console.log(quantity, totalPrice, target);

      let tProp = this.dataSet.props.find((e) => e.id == target);

      if (!quantity || !tProp || !totalPrice) return;
      let unitPrice;

      unitPrice = (totalPrice / quantity).toFixed(1);
      // console.log(unitPrice);

      if (integer) unitPrice = Math.round(unitPrice);
      //console.log("unit price:", unitPrice, integer);

      // if (integer) tProp.collect = integer ? Math.round((totalPrice / quantity).toFixed(1)) : (totalPrice / quantity).toFixed(1);

      tProp.collect = unitPrice;
      tProp.visible = 1;
    },
    close() {
      this.$emit("close");
      // setTimeout(() => this.$store.commit("user/zarEditData", null), 500);
    },
    submit() {
      let modifiedProps = this.dataSet.props.filter((p) => JSON.stringify(p.collect) != JSON.stringify(p.collect_b));
      // console.log(modifiedProps);
      if (modifiedProps.length) this.$store.dispatch("user/saveItem", { obj: this.dataSet.obj, zid: this.dataSet.zid, props: modifiedProps });
      // this.$store.dispatch("user/saveItem", { obj: this.dataSet.obj, zid: this.dataSet.zid, props: this.dataSet.props });
      this.close();
    },
    clickedItem(pid, val) {
      // console.log(val);
      this.dataSet.props.find((p) => {
        if (p.id == pid) {
          p.collect = val;
          if (p.func && p.func.length) {
            p.func
              .filter((fu) => fu.trigger == "click")
              .forEach((f) => {
                if (f.name == "funcCalcUnitPrice2") this.funcCalcUnitPrice(parseFloat(val), this.dataSet.props.find((e) => e.id == f["total"]).collect, f["target"], f["integer"], f["lowerDigit"]);
                if (f.name == "funcCalcUnitPrice") this.funcCalcUnitPrice(this.dataSet.props.find((e) => e.id == f["quantity"]).collect, parseFloat(val), f["target"], f["integer"], f["lowerDigit"]);
                // if (f.name == "funcFilterHigher") this.funcFilterHigher(f["target"], parseInt(sel.name ? sel.name : sel), f["notEqualCurrentVal"]);
                // if (f.name == "funcFilterLower") this.funcFilterLower(f["target"], parseInt(sel.name ? sel.name : sel), f["notEqualCurrentVal"]);
                // if (f.name == "funcForceSelLoc") this.funcForceSelLoc(f.target, sel);
                // if (f.name == "funcSetCoord") this.funcSetCoord(f.target, sel);
              });
          }
        }
      });
    },
    async fetchPinfo() {
      return await this.axiosGet("/zar/object/getOnePropInfo", [this.dataSet.obj, "display_add"]);
    },
  },
  computed: {
    // zar() {
    //   return this.$store.state.user.zarEditData;
    // },
    complete() {
      return this.dataSet.props.find((p) => p.req && !p.collect) ? true : false;
    },
    favs() {
      return this.$store.state.user.favs;
    },
  },
  async mounted() {
    console.log(this.dataSet);

    // await this.fetchPinfo();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_edit {
  text-align: left;
  padding: 10px;
  padding-top: 0;
}
.pName {
  /* color: #000000; */
  padding: 2px 0px 2px 0px;
  transition-property: padding background-color, color, margin-right;
  transition-duration: 150ms;
  border-radius: 3px;
  margin-top: 0px;
  position: relative;
  text-align: center;
  text-align: left;
}
</style>
