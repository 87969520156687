<template>
  <span></span>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import mainFeedback from "./feedback.vue";
import mainDeleteItem from "./deleteItem.vue";
import mainTos from "./tos.vue";
import zarReport from "@/comp/zar/report.vue";
import userRegister from "@/comp/user/register.vue";
import userLogin from "@/comp/user/login.vue";
import userAskRestorePass from "@/comp/user/askRestorePass.vue";
import userRestorePass from "@/comp/user/restorePass.vue";
import userVerify from "@/comp/user/verify.vue";
import userFavs from "@/comp/user/favs.vue";
import userZarEdit from "@/comp/user/zarEdit.vue";
import userLocation from "@/comp/user/location.vue";
import userCar from "@/comp/user/car.vue";
import userNoti from "@/comp/user/noti.vue";
import barterList from "@/comp/mod/barterList.vue";
import bofferList from "@/comp/mod/bofferList.vue";
import zarAddBoffer from "@/comp/mod/addBoffer.vue";

export default {
  name: "main-modals",
  data() {
    return {
      mod: null,
    };
  },
  methods: {
    openModal(ob) {
      switch (ob.name) {
        case "zarAddBoffer":
          this.$modal.show(
            zarAddBoffer,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 600, clickToClose: false, adaptive: !this.isMobile, scrollable: this.isMobile },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "mainFeedback":
          this.$modal.show(
            mainFeedback,
            {},
            { height: "auto", width: "90%", maxWidth: 600, adaptive: true, clickToClose: false },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "mainDeleteItem":
          this.$modal.show(
            mainDeleteItem,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 600, adaptive: true, clickToClose: false },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "zarReport":
          this.$modal.show(
            zarReport,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 350, adaptive: true, clickToClose: false },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userFavs":
          this.$modal.show(
            userFavs,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 500, adaptive: true },
            {
              "before-open": this.userFavsBeforeOpen,
              "before-close": this.userFavsBeforeClose,
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userLogin":
          this.$modal.show(
            userLogin,
            { dataSet: ob.dataSet },
            { height: "auto", width: 240, clickToClose: false },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userAskRestorePass":
          this.$modal.show(
            userAskRestorePass,
            { dataSet: ob.dataSet },
            { height: "auto", width: 210, clickToClose: false },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "restorePass":
          this.$modal.show(
            userRestorePass,
            { dataSet: ob.dataSet },
            { height: "auto", width: 250 },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userRegister":
          this.$modal.show(
            userRegister,
            { dataSet: ob.dataSet },
            { height: "auto", width: 240 },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userVerify":
          this.$modal.show(
            userVerify,
            {},
            { height: "auto", width: "90%", maxWidth: 600, adaptive: true, clickToClose: false },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userZarEdit":
          this.$modal.show(
            userZarEdit,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 500, adaptive: true, clickToClose: false },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "mainTos":
          this.$modal.show(
            mainTos,
            {},
            { height: "auto", width: "90%", maxWidth: 1000, clickToClose: true, scrollable: this.isMobile },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userLocation":
          this.$modal.show(
            userLocation,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 500, adaptive: true, clickToClose: false, scrollable: this.isMobile },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userCar":
          this.$modal.show(
            userCar,
            {},
            { height: "auto", width: "90%", maxWidth: 500, adaptive: true, clickToClose: false, scrollable: this.isMobile },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "barterList":
          this.$modal.show(
            barterList,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 500, adaptive: !this.isMobile, scrollable: this.isMobile },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "bofferList":
          this.$modal.show(
            bofferList,
            { dataSet: ob.dataSet },
            { height: "auto", width: "90%", maxWidth: 500, adaptive: !this.isMobile, scrollable: this.isMobile },
            {
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        case "userNoti":
          this.$modal.show(
            userNoti,
            {},
            { height: "auto", width: "90%", maxWidth: 500, adaptive: true, scrollable: this.isMobile },
            {
              "before-open": this.userNotiBeforeOpen,
              "before-close": this.userNotiBeforeClose,
              opened: this.addModals,
              closed: this.removeModals,
            }
          );
          break;
        default:
          break;
      }
    },
    addModals(event) {
      this.$store.commit("addOpenModals", event.name);
    },
    removeModals(event) {
      this.$store.commit("removeOpenModals", event.name);
    },
    userFavsBeforeOpen() {
      this.$store.commit("user/favsModalActive", true);
    },
    userFavsBeforeClose() {
      this.$store.commit("user/favsModalActive", false);
    },
    userNotiBeforeOpen() {
      this.$store.commit("user/notiModalActive", true);
    },
    userNotiBeforeClose() {
      this.$store.commit("user/notiModalActive", false);
      this.$store.dispatch("user/updateUserNoti");
    },
  },
  mounted() {
    this.$bus.on("openModal", this.openModal);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
