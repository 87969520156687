var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont_select"},[_c('div',{staticClass:"selected",class:{
      cef: _vm.cefAct,
      chosen: _vm.items.length == 1 && _vm.only ? true : false,
      open: _vm.selection && _vm.items.length > 1 ? true : false,
    },on:{"click":function($event){_vm.selection = !_vm.selection}}},[(_vm.collect.icon)?_c('img',{attrs:{"src":'/icon/' + _vm.collect.icon + '.svg',"height":"12"}}):_vm._e(),(_vm.collect)?_c('span',[_vm._v(_vm._s(_vm.collect.name))]):_c('span',{staticClass:"disabled"},[_vm._v("Сонгох")]),(_vm.unit)?_c('span',[_vm._v(_vm._s(_vm.unit))]):_vm._e()]),(!_vm.collect && !_vm.selection)?_c('div',{staticClass:"mt-2 ml-2 fw-700",on:{"click":function($event){_vm.selection = true}}},[_vm._v(" Сонгох ")]):_vm._e(),(_vm.collect && !_vm.selection)?_c('div',[(_vm.collect.image)?_c('img',{staticClass:"image_sel",attrs:{"src":_vm.imageSel}}):_vm._e()]):_vm._e(),(_vm.imageHover && _vm.selection)?_c('div',[_c('img',{staticClass:"image_sel",attrs:{"src":_vm.imageHover}})]):_vm._e(),((_vm.scr != 1 || _vm.disableMobileMode) && _vm.selection)?_c('div',{ref:"box",staticClass:"box ani_fade",class:{ nosel: !_vm.collect },style:({ zIndex: _vm.zind, position: !_vm.floating ? 'initial' : 'absolute' }),attrs:{"id":"box"},on:{"mouseleave":_vm.mouseLeave,"mouseenter":_vm.mouseEnter}},[(_vm.loading)?_c('div',{staticClass:"blink_me"},[_vm._v(".")]):_vm._e(),(_vm.guide)?_c('div',{staticClass:"guide"},[_vm._v(_vm._s(_vm.guide))]):_vm._e(),_vm._l((_vm.itemsCom),function(s,k){return _c('span',{key:_vm.compName + 'c' + k + 'c' + s.id},[_c('div',{staticClass:"item com",class:{ active: _vm.collect && _vm.collect.id == s.id },on:{"click":function($event){return _vm.clickedItem(s)},"mouseover":function($event){return _vm.mouseOver(s)}}},[_vm._v(" "+_vm._s(s.name)+" ")])])}),_vm._l((_vm.itemsReordered),function(s,k){return _c('span',{key:_vm.compName + 's' + k + 's' + s.id},[(!_vm.only ? true : s.com > 1 ? true : false)?_c('div',{staticClass:"item",class:{
          active: _vm.collect && _vm.collect.id == s.id,
          special: s.special,
          sep: k > 0 && _vm.items[k].grp && _vm.items[k - 1] && _vm.items[k + 1] && _vm.items[k - 1].grp == _vm.items[k].grp && _vm.items[k + 1].grp != _vm.items[k].grp,
        },on:{"click":function($event){return _vm.clickedItem(s)},"mouseover":function($event){return _vm.mouseOver(s)}}},[_vm._v(" "+_vm._s(s.name)+" "),(s.title)?_c('span',{staticClass:"sel_title op-08 fs-11"},[_vm._v("("+_vm._s(s.title)+")")]):_vm._e(),(!s.special)?_c('span',[_vm._v(_vm._s(_vm.unit)+" "),(s.image)?_c('ui-icon',{staticClass:"disabled sel_icon",attrs:{"name":"i-image","size":"m"}}):_vm._e()],1):_vm._e()]):_vm._e()])})],2):_vm._e(),(_vm.scr == 1 && !_vm.disableMobileMode)?_c('ui-select-drawer',{attrs:{"compName":_vm.compName,"title":_vm.title,"showCloseButton":"","visible":_vm.selection,"items":_vm.itemsCom.concat( _vm.itemsReordered),"location":"bottom","collect":_vm.collect,"unit":_vm.unit},on:{"close":function($event){_vm.selection = false},"clickedItem":function($event){return _vm.clickedItem($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }