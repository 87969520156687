<template>
  <div class="cont_ui_youtube_view" :style="{ width: fitContent && 'fit-content' }">
    <span v-for="(item, i) in items" :key="'yt' + i" class="cont_item">
      <div class="youtube_blank" v-show="item && !readyItems.includes(item)"><p>YouTube</p></div>
      <div v-show="item && readyItems.includes(item)">
        <ui-icon v-if="removeAble" class="clickable" name="i-close3" size="xxl" @click="remove(item)" />
        <youtube :video-id="item" ref="youtube" @playing="playing" :width="playerSize.width" :height="playerSize.height" v-on:ready="ready(item)"> </youtube>
      </div>
    </span>
    <!-- <ui-button @v-on:clicked="playVideo()" text="play"></ui-button> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-youtube-view",
  props: {
    items: Array,
    mini: Boolean,
    removeAble: {
      type: Boolean,
      default: () => false,
    },
    fitContent: Boolean,
  },
  components: {},
  data() {
    return {
      readyItems: [],
    };
  },
  methods: {
    remove(item) {
      this.readyItems = this.readyItems.filter((e) => e != item);
      this.$emit("removeItem", item);
    },
    ready(item) {
      let found = this.readyItems.find((e) => e == item);
      if (!found) this.readyItems.push(item);
    },
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      //console.log(" we are watching!!!");
    },
    parseId(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
  computed: {
    playerSize() {
      let width, height;
      if (this.mini) width = 400;
      else {
        let w = this.$store.state.swidth;
        width = w > 550 ? 550 : w - 20;
      }
      height = width * (9 / 16);
      return { width, height };
    },
    player() {
      return this.$refs.youtube.player;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_youtube_view {
  position: relative;
  margin-bottom: 20px;
  border-radius: 3px;
  padding-top: 5px;
  background-color: #f1f1f1;
}
.cont_ui_youtube_view img {
  position: absolute;
  right: 5px;
  top: 5px;
  filter: invert(100%) sepia(0%) saturate(10%) hue-rotate(283deg) brightness(105%) contrast(101%);
}
.cont_item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background-color: #f1f1f1;
}
.cont_ui_youtube_view img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.youtube_blank {
  background-color: #f7f7f7;
  width: 400px;
  height: 200px;
  border-radius: 4px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 550;
  color: #ff4141;
}

.youtube_blank p {
  animation: scale_loader 10s linear infinite;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

@keyframes scale_loader {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(0.5);
  }
}

/* iframe {
  width: 100% !important;
} */
</style>
