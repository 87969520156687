<template>
  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg" />
    <div class="pswp__scroll-wrap">
      <div class="pswp__container">
        <div class="pswp__item" />
        <div class="pswp__item" />
        <div class="pswp__item" />
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter" />
          <button class="pswp__button pswp__button--close" title="Гарах" />
          <button class="pswp__button pswp__button--share" title="Хуваалцах" />
          <button class="pswp__button pswp__button--fs" title="Бүтэн дэлгэцээр" />
          <button class="pswp__button pswp__button--zoom" title="Томруулах" />
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut" />
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip" />
        </div>
        <button class="pswp__button pswp__button--arrow--left" title="Өмнөх" />
        <button class="pswp__button pswp__button--arrow--right" title="Дараачийнх" />
        <div class="pswp__caption">
          <div class="pswp__caption__center" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import events from "@/assets/js/events.js";
import PhotoSwipeFn from "photoswipe";
import PhotoSwipeUIDefault from "photoswipe/dist/photoswipe-ui-default.js";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

export default {
  name: "ui-photo-swipe",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  watch: {
    items: {
      handler: function() {
        if (this.pswp && this.isOpen) {
          /* this.pswp.items.length = 0
          val.forEach((item) => {
            this.pswp.items.push(item)
          }) */
          // this.pswp.items = this.items = val（指向同一个对象）
          // 所以当props items更新后 this.pswp.items随之自动更新
          this.pswp.invalidateCurrItems();
          this.pswp.updateSize(true);
        }
      },
      deep: true,
    },
    isOpen(val) {
      if (val) {
        this.openPhotoSwipe(this.items, this.options);
      } else {
        this.close();
      }
    },
  },
  methods: {
    openPhotoSwipe(items, options) {
      let pswpElement = this.$el;
      // var elem = document.getElementById("pswp");
      // pswpElement.requestFullscreen();
      // setTimeout(() => pswpElement.requestFullscreen(), 1);

      this.pswp = new PhotoSwipeFn(pswpElement, PhotoSwipeUIDefault, items, options);
      events.forEach((e) => {
        this.pswp.listen(e, (...args) => {
          args.unshift(this);
          this.$emit(e, [...args]);
        });
      });
      this.pswp.init();
    },
    close() {
      if (this.pswp) {
        this.pswp.close();
      }
    },
  },
  mounted() {
    if (this.isOpen) {
      this.openPhotoSwipe(this.items, this.options);
    }
  },
  beforeDestroy() {
    this.close();
  },
};
</script>

<style scoped>
.pswp__button--arrow--left:hover,
.pswp__button--arrow--right:hover,
.pswp__button--close:hover {
  border: none;
}
.pswp__button--arrow--left:active,
.pswp__button--arrow--right:active,
.pswp__button--close:active {
  background: none;
  border: none;
}
</style>
