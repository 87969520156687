<template>
  <ui-textbox :collect="collect" :min="min" :max="max" :mask="mask" :guide="guide" v-on:clickedItem="$emit('clickedItem', $event)" />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiTextbox from "@/comp/ui/textbox.vue";
export default {
  name: "zar-add-textbox",
  props: { collect: { type: [String, Object, Boolean, Number, Array] }, pob: Object },
  components: { uiTextbox },
  data() {
    return {
      text: "",
      timeout: null,
      min: this.pob.mask && this.pob.mask.min ? this.pob.mask.min : 0,
      max: this.pob.mask && this.pob.mask.max ? this.pob.mask.max : 100,
      mask: this.pob.mask,
      guide: this.pob.guide,
    };
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
