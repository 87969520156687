<template>
  <div class="cont_ui_loc_grab">
    <div class="center_marker"><img src="/icon/pin.svg" width="30" /></div>
    <l-map
      :style="{ height: mapHeight + 'px' }"
      class="map"
      :options="options"
      :geoJson="geoJson"
      :zoom="zoom"
      :center="center && center.length ? center : [47.918815, 106.91759]"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated($event)"
      @update:bounds="boundsUpdated"
      @mousedown="mouseDown()"
      @mouseup="mouseUp()"
    >
      <l-tile-layer :url="showSatView ? urlSat : url"></l-tile-layer>
      <l-control-fullscreen position="topleft" :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
      <l-geo-json v-for="(geo, ind) in reversed" :key="'geo' + ind" :geojson="geo.data" :options="{ style: geoJsonStyle[geo.ref] }"> </l-geo-json>
      <l-marker v-if="label && label.center" :lat-lng="label.center">
        <l-icon :icon-anchor="dynamicAnchor" :iconSize="dynamicSize">
          <div class="label">{{ label.text }}</div>
        </l-icon>
      </l-marker>

      <!--MAP LABELS-->
      <div v-if="showSatView && cZoom > 12">
        <l-marker v-for="(ml, ind) in mapLabels" :key="'ml_icon' + ind" :lat-lng="ml.coord">
          <l-icon v-if="ml.coord" :icon-anchor="dynamicAnchorLabel">
            <div class="map_label">{{ ml.name }}</div>
          </l-icon>
        </l-marker>
      </div>
      <l-marker v-for="(p, i) in pins" :key="'pin' + i" :lat-lng="p.coord" :icon="licon">
        <l-tooltip>{{ p.name }}</l-tooltip></l-marker
      >
      <!--UNIT MARKERS-->
      <div v-if="markers.length">
        <l-marker v-for="(mk, ind) in markers" :key="'markers' + ind" :lat-lng="mk.coord" :icon="mk.grp == 3 ? liconTown : liconApart" @mouseout="markerMouseOut(mk)" @mouseover="markerMouseOver(mk)" @click="clickedMarker(mk)"></l-marker>
        <template v-for="(mk, ind) in markers">
          <l-marker v-if="cZoom > 15 || showMarkerInfo == mk.zid" :key="'markers_icon' + ind" :lat-lng="mk.coord" @click="clickedMarker(mk)">
            <l-icon v-if="mk.coord" :icon-anchor="dynamicAnchor">
              <!-- <div class="label" @click="clickedMarker(mk)" :style="{ color: showSatView ? 'white' : 'black', textShadow: '0px 0px 2px' + (showSatView ? '#000' : '#fff') }">{{ mk.name }}</div> -->
              <div class="label" v-html="mk.name"></div>
            </l-icon>
          </l-marker>
        </template>
      </div>
      <!-- 
      <div v-if="markers.length">
        <div v-if="cZoom > 15">
          <l-marker v-for="(mk, ind) in markers" :key="'markers_icon' + ind" :lat-lng="mk.coord">
            <l-icon v-if="mk.coord" :icon-anchor="dynamicAnchor">
              <div class="label" :style="{ color: showSatView ? 'white' : 'black', textShadow: '0px 0px 2px' + (showSatView ? '#000' : '#fff') }">{{ mk.name }}</div>
              <div class="label">{{ mk.name }}</div>
            </l-icon>
          </l-marker>
        </div>
        <template v-else>
          <l-marker v-for="(mk, ind) in markers" :key="'markers' + ind" :lat-lng="mk.coord" :icon="licon2" @click="clickedMarker(mk)" class="marker_dot"></l-marker>
        </template>
      </div> -->

      <l-control v-if="enableCenterMarker" :position="'bottomright'"><ui-icon name="pin" size="xxxxl" :class="{ bounce: !collect && !mDown, glow: collect, desat: !collect }"/></l-control>
      <l-control :position="'topleft'"
        ><div class="satView"><ui-icon @click="showSatView = !showSatView" :name="enSatView ? 'i-map2' : 'i-satellite'" size="xxl" /></div
      ></l-control>
    </l-map>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import L from "leaflet";
import { LMap, LGeoJson, LControl, LIcon, LMarker, LTileLayer } from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";

export default {
  name: "ui-loc-grab",
  components: {
    LMap,
    LTileLayer,
    LControlFullscreen,
    LGeoJson,
    LControl,
    LIcon,
    LMarker,
    // LTooltip,
  },
  props: {
    zoom: Number,
    center: Array,
    geoJson: Array,
    collect: Boolean,
    enSatView: {
      type: Boolean,
      default: () => false,
    },
    mapHeight: {
      type: Number,
      default: () => 350,
    },
    enableCenterMarker: {
      type: Boolean,
      default: () => true,
    },
    label: Object,
    pins: Array,
    markers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      licon: L.icon({
        iconUrl: "/icon/pin2.svg",
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      liconApart: L.icon({
        iconUrl: "../icon/i-dot-blue.svg",
        iconSize: [98, 32],
        iconAnchor: [50, 15],
      }),
      liconTown: L.icon({
        iconUrl: "../icon/i-dot-pink.svg",
        iconSize: [98, 32],
        iconAnchor: [50, 15],
      }),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      urlSat: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
      mapLabels: [],
      sizeLabel: 10,
      geoJsonStyle: {
        ref_location_city: {
          fillColor: "#00ff2f",
          color: "#0044ff",
          weight: 2,
          opacity: 1,
          fillOpacity: 0,
        },
        ref_location_section: {
          fillColor: "#00ff2f",
          color: "#0044ff",
          weight: 2,
          opacity: 1,
          fillOpacity: 0,
        },
        ref_location_area: {
          fillColor: "#0044ff",
          color: "#0044ff",
          weight: 2,
          opacity: 1,
          fillOpacity: 0.1,
        },
      },
      options: {
        maxZoom: 18,
        minZoom: 6,
      },
      cZoom: 11,
      bounce: false,
      mDown: false,
      staticAnchor: [5, 37],
      iconSize: 20,
      showSatView: false,
      showMarkerInfo: null,
      markerInfoTimer: null,
    };
  },
  methods: {
    clickedMarkerRight(ob) {
      this.$emit("clickedRight", ob);
    },

    markerMouseOver(marker) {
      if (marker.zid) {
        clearTimeout(this.markerInfoTimer);
        this.showMarkerInfo = marker.zid;
        this.markerInfoTimer = setTimeout(() => (this.showMarkerInfo = null), 2000);
      }
      // if (ob.zid) this.showMarkerInfo = ob.zid;
    },
    markerMouseOut() {
      // if (ob.zid) this.showMarkerInfo = null;
    },
    clickedMarker(marker) {
      this.$emit("clickedMarker", marker);
    },
    mouseDown() {
      this.$emit("mouseDown");
      this.mDown = true;
    },
    mouseUp() {
      this.mDown = false;
    },
    zoomUpdated(zoom) {
      this.cZoom = zoom;
      this.$emit("zoomUpdated", zoom);
    },
    centerUpdated(center) {
      this.bounce = true;
      // setTimeout(() => (this.bounce = false), 500);
      this.$emit("centerUpdated", center);
    },
    boundsUpdated(bounds) {
      this.$emit("boundsUpdated", bounds);
    },
    async fetchMapLabels() {
      // let data = await ajaxDispatcher("sysManager", "getMapLabels", false, []);
      let data = await this.axiosGet("utility/location/getMapLabels", []);
      this.mapLabels = data.map((m) => ({ id: m.id, name: m.name, coord: JSON.parse(m.coord) }));
    },
  },
  watch: {
    geoJson() {
      // console.log(this.geoJson);
    },
    // geoJson() {
    //   if (this.geoJson.length < 2) return;
    //   console.log(this.geoJson);
    //   let arr = this.geoJson.map((e) => e.data.id).sort((a, b) => a - b);
    //   arr.forEach((e, ind) => {
    //     let preItem = arr[ind - 1];
    //     if (preItem) {
    //       if (String(preItem) == String(e).substr(0, String(preItem).length)) {
    //         console.log(String(preItem), String(e).substr(0, String(preItem).length));
    //       }
    //     }
    //   });
    // },
    pins() {
      // console.log("pins updated");
    },
  },
  computed: {
    dynamicAnchorLabel() {
      return [this.sizeLabel + 20, this.sizeLabel];
    },
    reversed() {
      return this.geoJson.slice().reverse();
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  mounted() {
    this.showSatView = this.enSatView;
    this.fetchMapLabels();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_loc_grab {
  position: relative;
  padding: 5px 0 10px 0;
}
.center_marker {
  background-image: url(/img/marker.png) no-repeat;
  position: absolute;
  z-index: 2;
  left: calc(50% - 16px);
  top: calc(50% - 34px);
  transition: all 0.4s ease;
  display: none;
}

.map {
  z-index: 1;
  border-radius: 5px;
}

.map_label {
  text-align: center;
  line-height: 1.1em;
  font-weight: 500;
  text-shadow: 0px 0px 2px #000, 0px 0px 2px #000;
  color: #fff;
  /* font-stretch: 98%; */
  font-family: system_ui, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 20px;
  font-size: 12px;
  width: fit-content;
  opacity: 0.9;
  /* background: rgba(0, 0, 0, 0.3); */
  padding: 1px 3px;
  border-radius: 3px;
  z-index: 1;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.26934523809523814) 51%, rgba(255, 255, 255, 0) 100%);
}

.label {
  text-align: center;
  line-height: 1.1em;
  font-weight: var(--base_weight);
  color: #000;
  text-shadow: none;
  /* font-stretch: 98%; */
  font-family: system_ui, system-ui, -apple-system, sans-serif;
  margin-left: 20px;
  margin-top: 80%;
  width: fit-content;
  box-shadow: 0px 0px 2px #00000093;
  border-radius: 3px;
  padding: 3px 3px;
  /* border: solid 1px rgb(0, 0, 0, 0.3); */
  position: relative;
  background: #fafafa;
  z-index: 2;

  /* opacity: 0.5; */
  /* transform: translate(-25%, 35%); */
}

.label::before {
  right: 100%;
  /* top: 50%; */
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #fafafa;
  border-width: 7px;
  margin-top: 0px;
}
.label b {
  font-size: smaller;
  margin-right: 2px;
  opacity: 0.6;
}

.pin {
  transition: all 500ms;
}

.glow {
  filter: saturate(2);
  color: #8a2be2;
}
.desat {
  /* filter: invert(25%) sepia(59%) saturate(7289%) hue-rotate(267deg) brightness(91%) contrast(94%); */
}
.satView {
  border: solid 2px #bebebe;
  background-color: #fff;
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 3px;
}
.satView:hover {
  cursor: pointer !important;
  background-color: #f1f1f1;
}
</style>
