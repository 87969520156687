<template>
  <div class="cont_home_zar_cat ml-4">
    <!-- <zar-cat :defSel="1" :autoSelFirst="scr < 3 ? false : true" notEmpty withVirtualCat v-on:clickedObj="clickedObj($event)" v-on:clickedCat="clickedCat($event)" :selectedCat="selCat" :selObj="selObj" /> -->
    <zar-cat :disableFunc="disableFunc" :defSel="0" :autoSelFirst="autoSelFirst" notEmpty withVirtualCat v-on:clickedObj="clickedObj($event)" v-on:clickedCat="clickedCat($event)" :selectedCat="selCat" :selObj="selObj" :onlyCat="onlyCat" />
    <!-- <div v-if="scr > 2" class="hr"></div> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarCat from "@/comp/zar/cat.vue";
export default {
  components: { zarCat },
  name: "home-zar-cat",
  props: {
    disableFunc: Boolean,
    autoSelFirst: Boolean,
    onlyCat: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    clickedCat(ob) {
      this.selCat = ob;
    },
    clickedObj(ob) {
      if (this.selObj && this.selObj.id == ob.id) {
        // this.$bus.emit("refreshFilter");
      }
      this.selObj = ob;
    },
  },

  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    selCat: {
      get() {
        return this.$store.state.selCat;
      },
      set(ob) {
        this.$store.commit("selCat", ob);
      },
    },
    selObj: {
      get() {
        return this.$store.state.selObj;
      },
      set(ob) {
        this.$store.commit("selObj", ob);
      },
    },
  },

  watch: {},
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_home_zar_cat {
  position: relative;
}
.cont_home_zar_cat .hr {
  /* background-color: #eee; */
  position: absolute;
  width: 2px;
  height: 50%;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-right: dotted 3px var(--color_sep);
}
</style>
