<template>
  <img @click="$emit('click')" :src="'/icon/' + name + '.svg'" :height="cssSize[size] + 'px'" :class="{ [name]: true, rotate90: angle == '90deg', rotate270: angle == '270deg', angleReverse: angle == 'rev' }" />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-icon",
  props: {
    name: String,
    angle: String,
    size: {
      type: String,
      default: () => "m",
    },
  },
  data() {
    return {
      // cssSize: { xs: 6, s: 8, x: 10, m: 13, l: 15, xl: 17, xxl: 20, xxxl: 24, xxxxl: 32, xxxxxl: 40, xxxxxxl: 52 },
      cssSize: { xs: 6, s: 8, x: 10, m: 12, l: 16, xl: 18, xxl: 20, xxxl: 24, xxxxl: 32, xxxxxl: 40, xxxxxxl: 52 },
    };
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
img {
  user-select: none;
  transition: all 200ms;
  /* vertical-align: middle; */
}
/* img:hover {
  background-color: #f1f1f1;
} */
.bookmark1 {
  filter: invert(37%) sepia(98%) saturate(3623%) hue-rotate(5deg) brightness(103%) contrast(100%);
}
.angleReverse {
  transform: rotate(180deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate270 {
  transform: rotate(270deg);
}
</style>
