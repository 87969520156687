<template>
  <div class="cont_zar_cat ta-l cont_main" @mouseover="mOver = true" @mouseout="mOver = false">
    <div class="blink_me" v-if="!items.cat.length">.</div>
    <div class="cont_obj">
      <div class="obj" v-for="o in items.obj" @click="clickedItem('obj', o)" :key="'obj' + o.id" :class="{ selObj: selObj.id == o.id }">
        <img class="obj_img" v-if="o.icon" :src="'/icon/' + o.icon + '.svg'" height="22" />
        <span class="obj_name">
          {{ o.name }}
          <i v-if="o.znum && !hideCount">{{ o.znum }}</i>
        </span>
      </div>
    </div>
    <template v-for="c in items.cat">
      <div :key="'c' + c.id" class="cont_cat" v-if="!c.virt || (withVirtualCat && c.virt)">
        <div class="cat" :class="{ selected: selCat && selCat.id == c.id }" @click="clickedItem('cat', c)">
          <span class="cat_name" :class="{ set_black: mOver || !selCat }">
            <!-- 'c.parent == 0 ? 'position:absolute; margin-left:3px;' : '' -->
            <!-- <img v-if="c.icon" :src="'/icon/' + c.icon + '.svg'" class="cat_icon" :class="{ set_opacity: !selCat }" />  -->
            {{ c.name }}
            <i v-if="c.znum && !hideCount">{{ c.znum }}</i>
          </span>
        </div>
        <ui-reflow>
          <zar-cat-sub :hideCount="hideCount" v-if="c.items && (c.items.cat.length || c.items.obj.length)" :items="c.items" :notEmpty="notEmpty" :selObj="selObj" class="child_cont ani_sdown_fast" v-on:clickedItem="clickedItem($event.tp, $event.ob)" />
        </ui-reflow>
      </div>
    </template>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import ZarCatSub from "./catSub.vue";

export default {
  components: { ZarCatSub },
  name: "zar-cat",
  props: {
    selectedCat: Object,
    notEmpty: Boolean,
    defSel: Number,
    autoSelFirst: Boolean,
    selObj: Object,
    withVirtualCat: {
      type: Boolean,
      default: () => false,
    },
    onlyCat: {
      type: Boolean,
      default: () => false,
    },
    disableFunc: {
      type: Boolean,
      default: () => false,
    },
    hideCount: Boolean,
  },
  data() {
    return {
      title: {},
      bread: [],
      items: { cat: [], obj: [] },
      loading: false,
      selCat: null,
      mOver: false,
      catData: null,
      showSubDrawer: false,
      titleSubDrawer: "",
    };
  },
  methods: {
    forceSelObj(routeObj) {
      let data = this.catData;
      let cat = data.objCat.find((i) => i.obj == routeObj);
      let catInfo = data.cat.find((e) => e.id == cat.cat);

      if (catInfo) {
        cat = this.topLevelCat(data, cat.cat);
        this.$emit("clickedCat", catInfo);
        this.selCat = { id: cat, type: "cat" };

        this.showBranch(this.items, cat);
      }

      let objInfo = data.obj.find((i) => i.id == routeObj);

      if (!objInfo) return;
      objInfo.forced = true;
      this.clickedItem("obj", objInfo);
      this.$route.query.o = null;
    },

    forceSelCat(routeCat) {
      this.refreshCat();
      let data = this.catData;
      let cat = this.topLevelCat(data, routeCat);
      let catInfo = data.cat.find((e) => e.id == cat);

      this.$emit("clickedCat", catInfo);
      this.selCat = { id: cat, type: "cat" };
      this.showBranch(this.items, cat);
      if (this.autoSelFirst) this.selFirstObj({ id: routeCat });
    },

    topLevelCat(data, cat) {
      let parCat = data.cat.find((c) => c.id == cat);
      if (parCat.par) return this.topLevelCat(data, parCat.par);
      else return parCat.id;
    },

    showBranch(tdata, catId) {
      let data = this.fetchObject(catId);
      if (!data) return;

      tdata.cat.find((e) => {
        if (e.id == catId) {
          this.$set(e, "items", data);
        }
      });

      data.cat.forEach((c) => {
        this.showBranch(data, c.id);
      });
    },

    fetchObject(id) {
      let data = this.deepClone(this.$store.state.zar.catData);
      let cat = data.cat.filter((e) => e.par == id);
      let obj = data.obj.filter((e) => data.objCat.find((i) => i.obj == e.id && i.cat == id));
      return cat || obj ? { cat, obj } : false;
    },

    refreshCat() {
      this.items = this.fetchObject(0);
    },

    selFirstObj(cat) {
      let data = this.catData;
      let catRel = this.findFirstObjOfCat(data, cat);
      // console.log(catRel);

      if (catRel) {
        let objInfo = data.obj.find((o) => o.id == catRel.obj);
        // objInfo.type = "obj";
        this.clickedItem("obj", objInfo);
      }
    },

    findFirstObjOfCat(data, cat) {
      if (!cat) return;
      let catRel = data.objCat.filter((i) => i.cat == cat.id);
      if (!catRel.length)
        return this.findFirstObjOfCat(
          data,
          data.cat.find((c) => c.par == cat.id)
        );
      else {
        catRel = catRel.map((e) => ({ cat: e.cat, obj: e.obj, znum: data.obj.find((o) => o.id == e.obj).znum }));
        catRel = this.sortArr(catRel, "znum", 2);
        return catRel.first();
      }
    },

    clickedItem(tp, ob) {
      if (tp == "cat") {
        this.refreshCat();
        this.showBranch(this.items, ob.id);
        //this.selCat = "cat" + ob.id;
        this.$emit("clickedCat", ob);
        this.selCat = ob;
        if (this.autoSelFirst) this.selFirstObj(ob);
      }
      if (tp == "obj") {
        // this.selObj = ob;
        this.$emit("clickedObj", ob);
      }
    },
    mainCatSelected(ob) {
      this.clickedItem("cat", ob);
    },
    routine() {
      if (this.items && this.items.cat.length) {
        if (!this.onlyCat) {
          this.clickedItem("cat", { id: 11, name: "Нүүр" });
          return;
        }
        this.showOnlyCat();
      }
    },
    showOnlyCat() {
      let found = this.items.cat.filter((e) => !e.virt);
      if (found.length == 1) this.clickedItem("cat", found.first());
    },
  },

  computed: {
    selMainCat() {
      return this.$store.state.selCat;
    },
  },

  watch: {
    items() {
      // if (this.items && this.items.cat.length && !this.onlyCat) {
      //   let found = this.items.cat.filter((e) => !e.virt);
      //   this.onlyCat = found.length == 1;
      //   if (this.onlyCat) {
      //     // this.clickedItem("cat", found.first());
      //   }
      // }
    },
  },
  activated() {},
  created() {
    this.catData = this.deepClone(this.$store.state.zar.catData);
    // console.log(this.catData);
  },
  mounted() {
    if (this.isMobile) this.mOver = true;
    this.refreshCat();
    if (this.disableFunc) {
      if (this.onlyCat) this.showOnlyCat();
      return;
    }

    if (this.$route.query.o) this.forceSelObj(this.$route.query.o);
    else this.routine();

    this.$bus.on("mainCatSelected", this.mainCatSelected);
    this.$bus.on("forceSelObj", this.forceSelObj);
    this.$bus.on("forceSelCat", this.forceSelCat);

    // this.$nextTick(function() {
    //   if (!this.items.length) return;
    // });
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_main {
  /* border: solid 1px #ccc; */
  /* padding: 2px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #ccc inset;
  width: fit-content;
  height: fit-content; */
}
.cont_zar_cat {
  user-select: none;
  /* padding-top: 2px; */
}
.child_cont {
  margin: 0 0 10px 5px;
  /* background-color: #f1f1f1; */
  /* border: solid 1px #e0e0e0; */
  width: fit-content;
  /* border-radius: 10px; */
  padding: 2px 3px 0 0;
  border: none;
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: #666666;
}
.title span {
  text-transform: lowercase;
  margin-right: 5px;
}
.obj_name {
  display: inline-block;
  padding: 2px 5px;
}
.cat_name {
  /* position: absolute; */
  /* padding: 2px 6px; */
  opacity: 1;
  transition: opacity 500ms;
  /* font-stretch: 95%; */
  letter-spacing: -0.02em;
  text-transform: uppercase;
  font-size: 13px;
  /* margin-bottom: 0px; */
  /* font-weight: 600; */
  /* color: #8d8d8d; */
  /* transition: font-weight 100ms; */
}

.cat_name:hover,
.obj_name:hover {
  /* background-color: #f1f1f1; */
  border-radius: 2px;
  cursor: pointer;
  color: var(--color_body) !important;
}
.cat:hover .cat_icon {
  opacity: 0.6;
}
.cat_name:active {
  background-color: #e6e6e6;
}

.obj {
  text-align: left;
}
.obj_img {
  position: relative;
  margin-bottom: -7px;
  padding-right: 3px;
}
.selObj {
  font-weight: 700;
}

.cat.selected .cat_name {
  color: var(--color_head);

  font-size: 14px;
  font-weight: 700;
  /* text-transform: uppercase; */
  opacity: 1;
  /* font-family: "Raleway"; */
  /* border-bottom: solid 1px #ccc; */
}

.selObj .obj_img {
  filter: invert(18%) sepia(62%) saturate(3965%) hue-rotate(356deg) brightness(93%) contrast(80%);
}

.cat {
  width: max-content;
  height: 22px;
  transition: all 150ms;
  margin-bottom: 5px;
}

.cont_cat {
  padding: 1px 0;
}
/* .cont_obj {
} */

.cont_obj {
  border-left: solid 1px #e0e0e0;
}

.cat_icon {
  left: -2px;
  top: 5px;
  position: relative;
  height: 20px;
  opacity: 0;
  transform: scale(1);
  /* transition: transform 100ms, opacity 200ms; */
  transition: all 300ms;
}
.cat.selected .cat_icon {
  left: -2px;
  transform: scale(1);
  /* margin-right: 3px; */
  opacity: 0.6;
}
.cat_name i {
  font-size: 11px;
  font-style: normal;
  color: #a0a0a0;
  font-weight: var(--base_weight);
  /* font-family: "Open Sans"; */
}

.set_opacity {
  opacity: 0.6;
}

/* .set_black {
  opacity: 1;
}
 */
/*
i::before {
  content: "(";
  margin: 0 1px;
  font-size: 8px;
  position: relative;
  top: -2px;
  color: #a0a0a0;
}
i::after {
  content: ")";
  margin: 0 1px;
  font-size: 8px;
  position: relative;
  top: -2px;
  color: #a0a0a0;
}
*/
.selObj i {
  font-weight: inherit;
}
.blink_me {
  position: absolute;
  margin-left: 115px;
  margin-top: -18px;
}

@media only screen and (max-width: 700px) {
  .cat_icon {
    transition: opacity 500ms;
  }
  .cat.selected .cat_icon {
    transition: transform 150ms;
  }
  .cat_name {
    font-size: 14px;
    transition-property: color, font;
    transition-duration: 300ms, 100ms;
    transition-timing-function: ease;
    padding: 7px 0;
    line-height: 1.6;
  }
  .cat img {
    height: 16px;
    margin-top: 3px;
  }
}
</style>
