<template>
  <div>
    <div v-if="showSuggest && !userClosed" class="cont_blue">Апп суулгах <ui-button text="Суулгах" v-on:clicked="install()" /> <span class="link" @click="close()">хаах</span></div>
    <div v-if="isMobile && !standaloneMode" class="cont_blue">Апп суулгах <ui-button text="Заавар үзэх" v-on:clicked="showIns" /></div>
    <ch-drawer :visible.sync="instructionOpen" area="560px" title="Суулгах заавар" :blur="false" location="top" destroy-on-close>
      <div v-if="os == 'ios'" class="ta-c">
        Та Safari интернэт хөтөчийнхөө дараах цэсрүү орж идэвхижүүлээрэй
        <div><img src="@/assets/instruction/instruction_ios_1.png" width="250" /></div>
        <div><img src="@/assets/instruction/instruction_ios_2.png" width="250" /></div>
        <div class="mt-1"><ui-button text="Хаах" v-on:clicked="instructionOpen = false" /></div>
      </div>
      <div v-if="os == 'android'" class="ta-c">
        Та Chrome интернэт хөтөчийнхөө дараах цэсрүү орж идэвхижүүлээрэй
        <div><img src="@/assets/instruction/instruction_android_1.png" width="250" /></div>
        <div><img src="@/assets/instruction/instruction_android_2.png" width="250" /></div>
        <div class="mt-1"><ui-button text="Хаах" v-on:clicked="instructionOpen = false" /></div>
      </div>
      <div class="return_button_top" @click="instructionOpen = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "main-install-pwa",
  components: {},
  props: {},
  data() {
    return {
      showSuggest: false,
      installEvent: null,
      os: null,
      standaloneMode: false,
      instructionOpen: false,
    };
  },
  methods: {
    showIns() {
      this.instructionOpen = true;
    },
    install() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          //
        } else {
          //
        }
        this.close();
      });
    },
    close() {
      this.showSuggest = false;
      this.saveLocal("userClosedInstallInstruction", true);
    },
    // saveLocal(key, val) {
    //   localStorage.setItem(key, JSON.stringify(val));
    // },
  },
  computed: {
    buttonText() {
      return this.isMobile ? "Суулгах заавар үзэх" : "Cуулгах";
    },
    userClosed() {
      return this.hasLocal("userClosedInstallInstruction") ? true : false;
    },
  },
  mounted() {
    let isInWebAppiOS = window.navigator.standalone === true;
    let isInWebAppChrome = window.matchMedia("(display-mode: standalone)").matches;
    if (isInWebAppiOS || isInWebAppChrome) this.standaloneMode = true;

    if (this.isMobile) this.os = this.getMobileOperatingSystem();
    // else
    //   window.addEventListener("beforeinstallprompt", (e) => {
    //     e.preventDefault();
    //     this.installEvent = e;
    //     this.showSuggest = true;
    //   }); //WORKING BUT DISABLED FOR DESKTOP CHROME APP INSTALL
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_blue {
  width: 100%;
  padding: 5px;
  text-align: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgb(235, 235, 235) 51%, rgba(255, 255, 255, 1) 100%);
  line-height: 1.4;
}
</style>
