<template>
  <div class="cont_zar_report" :class="{ shake }">
    <div class="title_modal">Мэдүүллэх</div>
    <!-- <img :src="imageSrc" v-if="imageSrc" width="250" /> -->
    <ui-select :items="types" :collect="collect" v-on:clickedItem="clickedItem($event)" :floating="false" />
    <textarea v-model="text" ref="report" placeholder="Нэмэлт мэдээлэл" class="mt-3"></textarea>
    <div class="mt-3">
      <ui-button v-on:clicked="submit" class="pr-3 pl-3" text="Илгээх" widthSize="wide" />
      <ui-button v-on:clicked="hide()" class="pr-3 pl-3" text="Болих" tp="textOnly" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelect from "@/comp/ui/select.vue";

export default {
  name: "zar-report",
  components: { uiSelect },
  props: {
    imageSrc: String,
    dataSet: Object,
  },
  data() {
    return {
      text: null,
      shake: false,
      collect: "",
      types: [],
    };
  },
  methods: {
    async fetchTypes() {
      this.types = await this.axiosGet("/utility/report/types", []);
    },
    clickedItem(sel) {
      this.collect = sel;
    },
    async submit() {
      if (!this.dataSet.zid) this.showMessage("warning", "Зар сонгогдсонгүй");
      if (!this.collect) {
        this.shake = true;
        setTimeout(() => (this.shake = false), 600);
        this.showMessage("warning", "Та төрлөө сонгон уу");
        return;
      }
      let res = await this.axiosPost("/utility/report/add", { body: this.text, dev_id: this.getLocal("devid", true), dev_tp: this.isMobile ? "mobile" : "pc", logged: this.logged, tp: this.collect.id, zid: this.dataSet.zid, obj: this.dataSet.obj });
      this.showMessage(res.type, res.message);
      this.hide();
    },
    hide() {
      this.$emit("close");
    },
    // show() {
    //   this.$modal.show("zarReport");
    // },
    // hide() {
    //   this.$modal.hide("zarReport");
    // },
  },
  computed: {
    logged() {
      return this.$store.state.auth.status.logged;
    },
  },

  async mounted() {
    this.fetchTypes();

    // this.show();
    // setTimeout(() => this.$refs.report.focus(), 100);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_report {
  padding: 0 10px 10px 10px;
  text-align: left;
}

img {
  width: 100%;
  height: 150px;
}
textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
}
</style>
