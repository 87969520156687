<template>
  <div class="cont_ui_video_upload">
    <div>
      <ui-button v-on:clicked="onPickFile" text="Видёо +" icon="i-camera" tp="normal" />{{ file.name }}
      <input type="file" accept=".mp4, .mov" id="file" ref="file" v-on:change="handleFileUpload()" style="display:none" />
    </div>
    <div class="guide mt-1">Бичлэгний үргэлжлэх хугацаа {{ maxDuration / 10 }} секунд</div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-video-upload",
  components: {},
  props: {
    filename: String,
    folder: String,
    maxDuration: Number,
    maxFileSize: Number,
  },
  data() {
    return {
      file: "",
    };
  },
  methods: {
    validateFile(file) {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        //this.$refs.file.value = "";
        window.URL.revokeObjectURL(video.src);
        if (video.duration > this.maxDuration) return alert("Таны оруулсан видео " + this.maxDuration + " аас дээш секунд байна.");
        if (file.type != "video/mpeg" && file.type != "video/mp4" && file.type != "video/quicktime") return alert("Видео сонгон уу!");
        if (file.size > this.maxFileSize) return alert("Таны оруулсан видеоны хэмжээ " + this.maxFileSize / 1000000 + "mb хэмжээнээс бага байх ёстой.");

        this.file = file;
        this.submitFile();
      };
      video.src = URL.createObjectURL(file);
      return video.onloadedmetadata;
    },
    onPickFile() {
      this.$refs.file.click();
    },
    submitFile() {
      let formData = new FormData();
      let fileName = this.filename + "_s" + this.file.size + "." + this.file.name.substr(this.file.name.lastIndexOf(".") + 1).toLowerCase();
      formData.append("file", this.file, fileName);
      this.axios
        .post("/utility/upload/uploadVideo/" + this.folder, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$emit("clickedItem", fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileUpload() {
      let file = this.$refs.file.files[0];
      if (!file) return;
      this.validateFile(file);
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_video_upload {
}
</style>
