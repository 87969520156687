<template>
  <div class="cont_select">
    <div
      @click="selection = !selection"
      class="selected"
      :class="{
        cef: cefAct,
        chosen: items.length == 1 && only ? true : false,
        open: selection && items.length > 1 ? true : false,
      }"
    >
      <img v-if="collect.icon" :src="'/icon/' + collect.icon + '.svg'" height="12" /><span v-if="collect">{{ collect.name }}</span> <span v-else class="disabled">Сонгох</span><span v-if="unit">{{ unit }}</span>
    </div>
    <div v-if="!collect && !selection" @click="selection = true" class="mt-2 ml-2 fw-700">
      Сонгох
    </div>
    <div v-if="collect && !selection">
      <img v-if="collect.image" :src="imageSel" class="image_sel" />
    </div>
    <div v-if="imageHover && selection">
      <img :src="imageHover" class="image_sel" />
    </div>
    <div id="box" ref="box" v-if="(scr != 1 || disableMobileMode) && selection" class="box ani_fade" :class="{ nosel: !collect }" @mouseleave="mouseLeave" @mouseenter="mouseEnter" :style="{ zIndex: zind, position: !floating ? 'initial' : 'absolute' }">
      <div class="blink_me" v-if="loading">.</div>
      <div v-if="guide" class="guide">{{ guide }}</div>
      <span v-for="(s, k) in itemsCom" :key="compName + 'c' + k + 'c' + s.id">
        <div class="item com" @click="clickedItem(s)" @mouseover="mouseOver(s)" :class="{ active: collect && collect.id == s.id }">
          {{ s.name }}
        </div>
      </span>
      <span v-for="(s, k) in itemsReordered" :key="compName + 's' + k + 's' + s.id">
        <div
          v-if="!only ? true : s.com > 1 ? true : false"
          class="item"
          :class="{
            active: collect && collect.id == s.id,
            special: s.special,
            sep: k > 0 && items[k].grp && items[k - 1] && items[k + 1] && items[k - 1].grp == items[k].grp && items[k + 1].grp != items[k].grp,
          }"
          @click="clickedItem(s)"
          @mouseover="mouseOver(s)"
        >
          {{ s.name }}
          <span v-if="s.title" class="sel_title op-08 fs-11">({{ s.title }})</span>
          <span v-if="!s.special">{{ unit }} <ui-icon v-if="s.image" name="i-image" size="m" class="disabled sel_icon"/></span>
        </div>
      </span>
    </div>
    <!-- <ui-select-drawer v-if="scr == 1 && selection" :items="itemsReordered" /> -->
    <ui-select-drawer v-if="scr == 1 && !disableMobileMode" :compName="compName" :title="title" showCloseButton :visible="selection" :items="[...itemsCom, ...itemsReordered]" location="bottom" v-on:close="selection = false" v-on:clickedItem="clickedItem($event)" :collect="collect" :unit="unit" />
    <!-- {{ selection }} -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectDrawer from "@/comp/ui/selectDrawer.vue";

export default {
  name: "ui-select",
  components: {
    uiSelectDrawer,
  },
  props: {
    compName: String,
    items: Array,
    imageSel: String,
    imageHover: String,
    unit: String,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    zind: Number,
    forceSel: Number,
    title: String,
    floating: {
      type: Boolean,
      default() {
        return true;
      },
    },
    guide: String,
    disableMobileMode: Boolean,
  },
  data() {
    return {
      selection: false,
      cefAct: false,
    };
  },
  methods: {
    mouseOver(sel) {
      this.$emit("mouseOver", sel);
    },
    mouseEnter() {
      this.$emit("mouseEnter");
    },
    mouseLeave() {
      this.$emit("mouseLeave");
    },
    clickedItem(obj) {
      // if (obj.id != 888888888) this.selection = false;
      if (this.collect.id != obj.id) {
        // console.log("Emitted clickedItem ", obj.name);
        this.$emit("clickedItem", obj);
        //this.selection = false; //TRYING TO FIX SELECTION REAPPEAR AFTER SELECTION BUG
      }
    },
    emitSel(bo) {
      this.$emit("selection", bo);
    },
    emitHideProp() {
      this.$emit("hideProp");
    },
    autoSel() {
      // console.log("only", this.only);
      // console.log("items", this.items);

      if (this.only && this.only.com > 2) this.clickedItem(this.only);
      if (this.only && this.only.com == 4) this.emitHideProp();
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    itemsCom() {
      return this.sortArr(
        this.items.filter((e) => e.com == 1),
        "znum",
        2
      );
    },
    itemsReordered() {
      return this.items.find((e) => e.grp) ? this.sortArr(this.items, "grp", 1, "name") : this.items;
    },
    only() {
      return this.items.find((el) => el.com > 1);
    },
    // selection() {
    //   let sel = false;
    //   if (this.items.length) sel = true;
    //   if (!this.collect) sel = true;

    //   return only ? (selection && items.length > 1 ? true : false) : (selection && items.length) || !collect > 0 ? true : false;
    // },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        if (this.items.length) {
          // console.log("changed items!", this.items);

          // console.log("Select items changed selection true ", this.pob ? this.pob.name : "no pob", " items:", this.items.length);

          if (this.collect == "" || this.collect == 0)
            if (this.disableMobileMode || this.scr != 1) {
              if (this.selection == false) {
                this.selection = true;
                // console.log("selection trued in watch");
              }
            } else
              setTimeout(() => {
                this.selection = true;
                // console.log("selection trued in watch with timeout");
              }, 100);
          // console.log("showing selection", this.compName, this.items, this.selection);

          // this.selection = true; //MAYBE THIS IS REAPPEAR SELECTION BUG REASON DISABLED
          this.autoSel();
        }
      },
    },
    collect: {
      immediate: true,
      handler() {
        // console.log("collect changed", this.collect);
        if (this.collect) {
          this.cefAct = true;
          setTimeout(() => {
            this.cefAct = false;
          }, 1000);
          this.selection = false;
          // this.$nextTick(() => (this.selection = false));
          // console.log("closing selection comp:", this.collect, this.compName);
        }
      },
    },
    forceSel: {
      immediate: true,
      handler() {
        if (!this.collect || (this.collect && this.collect.id != this.forceSel))
          this.items.find((f) => {
            if (f.id == this.forceSel) {
              this.$emit("clickedItem", f);
              // this.selection = false;
            }
          });
      },
    },
    selection: {
      immediate: true,
      handler() {
        // console.log("selection", this.selection);

        if (this.selection) {
          // console.log("selection true", this.compName);
        }
        // if (this.selection && this.scr == 1) {
        //   this.$bus.emit("openDrawer", { name: "selectDrawer", title: "Select", items: this.items });
        // }
        // if (this.collect && this.selection) this.selection = false;
        // this.emitSel(this.selection);
        // console.log("Selection changed to", this.selection, "collect:", this.collect);
        // console.log(this.$refs.box);
        // if (this.selection && this.collect) this.$refs.box.scrollTop = 400;
      },
    },
  },
  mounted() {
    // console.log("compName:", this.compName);
    // if (this.collect) this.selection = false;
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_select {
  position: relative;
  user-select: none;
}
.cef {
  /* animation-name: glow;
  animation-duration: 1s; */
}
.guide {
  color: #777777;
  font-size: 12px;
  display: none;
  padding-bottom: 5px;
}

.middot {
  padding: 0 4px 2px 0;
  filter: invert(61%) sepia(9%) saturate(26%) hue-rotate(324deg) brightness(94%) contrast(83%);
}

.box {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 370px;
  width: fit-content;
  top: 32px;
  /* box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.05); */
  /* box-shadow: -1px 4px 15px -6px rgba(0,0,0,0.51); */
  /* box-shadow: 0px 20px 13px -15px rgba(0,0,0,0.37); */
  /* box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.03); */
  background-color: #fff;
  text-align: left;
  animation: show_shadow 100ms;
  animation-iteration-count: 1;
  box-shadow: 0px 2px 3px 0px rgba(78, 78, 78, 0.1) !important;
}

#box::-webkit-scrollbar {
  width: 20px;
  background-color: #fff;
}

.box.nosel {
  top: 0px;
}

.item {
  padding: 7px 10px 6px 10px;
  list-style: none;
  background-color: #fff;
  color: var(--color_darker);
  white-space: nowrap;
  border-bottom: solid 1px #cccccc3f;
}

.sep {
  border-bottom: dotted 1px #ccc;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.active {
  /* font-size: var(--14px); */
  background-color: var(--color_selected) !important;
}
.com {
  /* font-family: "Roboto", arial; */
  /* font-size: 13px; */
  /* background-color: #d6efff !important; */
  font-weight: 600;
}
.common {
  background-color: #ededed;
}
.selected {
  /* border: solid 1px #fff; */
  /* text-decoration: underline; */
  /* border-bottom: dotted 1px #b9b8b8; */
  display: inline-block;
  margin-bottom: 5px;
  background-color: var(--color_selected);
  /* color: var(--color_link); */
  border-radius: 4px;
  padding: 6px 8px 4px 8px !important;
  user-select: none;
  /* font-weight: 600; */
}
.selected:focus {
  border: solid 1px #000;
}
.selected:active {
  transform: translateY(1px);
}

.selected img {
  position: relative;
  top: 1px;
  left: -3px;
}
.selected span {
  padding-left: 2px;
}

.special {
  background-color: #eed9ff !important;
  /* font-weight: 500; */
}

.open {
  /* border: solid 1px #d3d3d3;
  border-radius: 2px;
  background: linear-gradient(180deg, #f1f1f1 0%, #ffffff 100%) !important; */
}
.image_sel {
  max-height: 180px;
  max-width: 180px;
}
.chosen {
  opacity: 0.5;
}
.chosen:hover {
  cursor: default;
  background-color: #ffffff !important;
}
.chosen:active {
  transform: scale(1);
}
.blink_me {
  position: absolute;
  margin-left: 115px;
  margin-top: -18px;
}
.empty {
  background-color: rgb(255, 253, 230);
}
.sel_icon {
  position: relative;
  top: 2px;
}

@keyframes show_shadow {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0px 13px 13px -15px rgba(0, 0, 0, 0.37);
  }
}

@media only screen and (max-width: 700px) {
  .item:active {
    background-color: #d6efff;
    cursor: pointer;
  }
  .active:active {
    background-color: #d6efff;
  }

  .com:active {
    background-color: #c4ffeb !important;
  }
  .selected:active {
    cursor: pointer;
    background-color: #d6efff;
  }
}
@media only screen and (min-width: 701px) {
  .item:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .active,
  .active:hover {
    background-color: var(--color_selected) !important;
  }
  /* .com:hover {
    background-color: var(--color_selected) !important;
  } */
  .selected:hover {
    cursor: pointer;
    /* background-color: #ededed; */
  }
  .chosen:hover {
    cursor: default;
    background-color: #ffffff !important;
  }
}
/* @media only screen and (max-width: 700px) {
  .box {
    transform: translateX(50%);
    right: 50%;
  }
} */
</style>
