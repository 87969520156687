<template>
  <div class="cont_zar_cat_sub_mobile">
    <div class="blink_me" v-if="loading">.</div>
    <div class="obj_cont" v-if="items.obj.length">
      <template v-for="o in items.obj">
        <div v-if="!notEmpty || (notEmpty && o.znum)" class="obj" @click="clickedObj(o)" :key="'o' + o.id" :class="{ selected: selObj && selObj.id == o.id }">
          <ui-icon v-if="o.icon" class="obj_icon" :class="{ obj_icon_selected: selObj && selObj.id == o.id && o.icon }" :name="o.icon" size="xxxl" />
          <span class="obj_name">
            {{ o.name }}
            <i v-if="o.znum && !hideCount">{{ o.znum }}</i>
          </span>
        </div>
      </template>
    </div>

    <div v-for="c in items.cat" :key="'c' + c.id" class="cat_cont">
      <div class="cat" v-if="!rootCat">
        <img v-if="c.icon" :src="'/icon/' + c.icon + '.svg'" height="12" />
        <span class="cat_name">
          {{ c.name }}
          <!-- <i v-if="c.znum">{{ c.znum }}</i> -->
        </span>
      </div>
      <zar-cat-sub-mobile :hideCount="hideCount" :selObj="selObj" v-if="c.items" :items="c.items" :notEmpty="notEmpty" class="child_sub" v-on:clickedObj="clickedObj($event)" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarCatSubMobile from "@/comp/zar/catSubMobile.vue";
export default {
  components: {
    zarCatSubMobile,
  },
  name: "zar-cat-sub-mobile",
  props: { items: Object, selObj: Object, notEmpty: Boolean, hideCount: Boolean, rootCat: Boolean },
  data() {
    return {
      loading: false,
      timer: null,
    };
  },
  methods: {
    clickedObj(ob) {
      // if (this.selObj && this.selObj.id == ob.id) return;
      this.$emit("clickedObj", ob);
    },
  },
  watch: {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_cat_sub_mobile {
  /* border-radius: 5px; */
  /* padding: 0 10px; */
}

.child_sub {
  margin: 0 !important;
  padding: 0 !important;
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: #666666;
}
.title span {
  text-transform: lowercase;
  margin-right: 5px;
}
.obj_name {
  font-size: 16px;
  /* font-stretch: 100%; */
  /* letter-spacing: -0.01em; */
  position: relative;
  top: 1px;
  font-weight: var(--base_weight);
  transition: background 200ms;
}

.cat_name {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 3px;
  color: var(--color_head);
  font-stretch: 95%;
}

.refresh {
  display: none;
  position: relative;
  top: 2px;
  filter: invert(82%) sepia(0%) saturate(988%) hue-rotate(356deg) brightness(91%) contrast(94%);
  left: 3px;
}
.cont_obj_icon {
  display: block;
}
.obj_cont {
  display: grid;
  /* grid-gap: 10px; */
  transition: all 1s;
  grid-template-columns: 1fr;
}
.cat_cont {
  margin-bottom: 7px;
}
.obj {
  text-align: left;
  position: relative;
  /* background-color: #f1f1f1; */
  vertical-align: middle;
  /* border-radius: 3px; */
  line-height: 45px;
  height: 45px;
  overflow: hidden;
  /* padding-left: 20px; */
  border-bottom: solid 1px #f1f1f1;
}

.obj:has(.obj_icon) {
  padding-left: 52px;
}

.selected .obj_name {
  transition: all 50ms;
  border-radius: 3px;
}

.obj.selected {
  background-color: var(--color_selected);
  border-top: solid 1px var(--color_selected);
}
.obj.selected i {
  opacity: 0.5;
}

/* .obj_icon_selected {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(226deg) brightness(104%) contrast(102%);
} */

.selected .obj_name i {
  color: #555555;
}

.obj_name i,
.cat_name i {
  color: #a0a0a0;
  font-size: 12px;
  font-weight: var(--base_weight) !important;
  font-weight: inherit;
  position: relative;
  left: 2px;
}
.selected .obj_img {
  filter: invert(18%) sepia(62%) saturate(3965%) hue-rotate(356deg) brightness(93%) contrast(80%);
}

.obj_icon {
  transition: all 200ms;
  position: absolute;
  left: 20px;
  top: 10px;
}

.obj_icon_selected {
  transform: scale(1.1);
}

.cat {
  width: 100%;
  text-align: center;
  padding: 10px 0 12px 0;
  border-bottom: solid 1px #f1f1f1;
}

/* .cat img {
  margin-top: 2px;
  position: relative;
} */
i {
  font-size: 11px;
  font-style: normal;
}

.blink_me {
  position: absolute;
  margin-left: 115px;
  margin-top: -18px;
}

@media only screen and (min-width: 701px) {
  .cat:hover {
    cursor: pointer;
  }
  .obj:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 700px) {
  .obj {
    transition: all 200ms;
  }
}
</style>
