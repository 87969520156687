import Vue from "vue";

import "./assets/normalize.css";
import "./assets/app.css";
import "./assets/inline.css";
import "./assets/ui.css";
import "./assets/animation.css";
import "./assets/overwrite.css";
import "leaflet/dist/leaflet.css";

import App from "./App.vue";
import VueRouter from "vue-router";
import Routes from "./routes";
import { store } from "@/store/index";
import axios from "axios";
import VueAxios from "vue-axios";
import AsyncComputed from "vue-async-computed";
import Message from "vue-m-message";
import "vue-m-message/dist/index.css";
import ChDrawer from "ch-drawer";
import VueInputMask from "vue-inputmask";
import VueScrollTo from "vue-scrollto";
import VueBus from "vue-bus";
import "@/comp/ui";
import VueHotkey from "v-hotkey";
import VueYoutube from "vue-youtube";
import VueDayjs from "./assets/js/plugins/dayjs.js";
import VModal from "vue-js-modal";
import VueMeta from "vue-meta";
import VueSocialSharing from "vue-social-sharing";
import { uid } from "uid";
import VScrollLock from "v-scroll-lock";
import { ObserveVisibility } from "vue-observe-visibility";
import { Toast } from "vant";

Vue.use(Toast);
Vue.directive("observe-visibility", ObserveVisibility);
Vue.use(VScrollLock);
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.use(VueDayjs);
Vue.use(VueYoutube);
Vue.use(VueHotkey);
Vue.use(VueBus);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: false,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.use(ChDrawer, { zIndex: 100, blur: false });
Vue.use(Message, { showClose: true, duration: 2000, position: "top-center", hasMask: false });
// Vue.use(VueScrollTo);
Vue.use(VueInputMask);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(AsyncComputed);
Vue.use(VModal, {
  dialog: true,
});
Vue.config.productionTip = false;

// Vue.config.errorHandler = (err, vm, info) => console.log(err, vm, info);

// const biri = require("@/assets/js/biri");

if (!localStorage.getItem("devid")) {
  const fingerprint = uid(32);
  // console.log(fingerprint);
  localStorage.setItem("devid", fingerprint);
}

const apiUrl = process.env.VUE_APP_API_PATH;
const baseUrl = "https://zaraa.mn";
const isMobile = typeof window.orientation !== "undefined" ? true : false;
let fromRoute;

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(() => {});
// };

// const originalPush = router.push;
// router.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject);
//   }

//   return originalPush.call(this, location).catch((err) => {
//     if (VueRouter.isNavigationFailure(err)) {
//       return err;
//     }

//     return Promise.reject(err);
//   });
// };

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch((err) => {
//     if (VueRouter.isNavigationFailure(err)) {
//       // resolve err
//       return err;
//     }
//     // rethrow error
//     return Promise.reject(err);
//   });
// };

let user_logged = JSON.parse(localStorage.getItem("user"));
axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.Authorization = user_logged && user_logged.accessToken ? user_logged.accessToken + "." + localStorage.getItem("devid") : localStorage.getItem("devid");
// let rmode = isMobile ? "hash" : "history"; DISABLED FOR HASH MODE
let rmode = "history";
// let match = window.location.href.match(/\?(.+)/i) || window.location.href.match(/(zar\/\d\/*.+)/i); DISABLED FOR HASH MODE
// let urlPar = match && rmode == "hash" && !window.location.href.includes("#") ? match[1] : false; DISABLED FOR HASH MODE

Vue.directive("attr", (el, binding) => {
  if (binding.value === true) binding.value = "";
  if (binding.value === "" || binding.value) {
    el.setAttribute(binding.arg, binding.value);
  }
});

Vue.mixin({
  data() {
    // return { apiUrl, isMobile, fromRoute, urlPar };
    return { apiUrl, baseUrl, isMobile, fromRoute };
  },
  methods: {
    async axiosGet(url, params, failReturn = null) {
      if (params.length) url += "/";
      params.forEach((p, ind) => {
        url += p;
        if (params.length != ind + 1) url += "/";
      });
      return this.axios
        .get(url)
        .then((res) => res.data ?? failReturn)
        .catch((err) => {
          console.error(err);
        });
    },
    async axiosPost(url, params) {
      return this.axios
        .post(url, params)
        .then((res) => res.data ?? null)
        .catch((err) => {
          console.error(err);
        });
    },
    playSound(name, volume) {
      // if (!isMobile) return;
      let sound = new Audio("/sound/" + name);
      sound.volume = volume;
      sound.play();
    },
    showMessage(type = "info", message, duration = 5000) {
      this.$message({
        type,
        message,
        showClose: true,
        position: "top-center",
        hasMask: false,
        duration,
      });
    },
    removeItemByIndex(arr, ind) {
      return arr.splice(ind, 1);
    },
    converters(func, val) {
      if (func == "mgl_time") {
        let prep;
        val < 1 ? (prep = parseInt(val * 12)) : (prep = val);
        let v = prep.toString().split(".");
        prep = v[1] ? v[0] + "<span class='fs-s'>." + v[1] + "</span>" : val;
        if (val < 1) {
          prep += "<span class='fs-s ml-0'> сар<span>";
        } else {
          prep += "<span class='fs-sm ml-0'> жил<span>";
        }
        return prep;
      }

      if (func == "float_small") {
        if (this.isObject(val)) val = val.name;
        let v = val.toString().split(".");
        let res = v[1] ? v[0] + "<span class='fs-xs'>." + v[1] + "</span>" : val;
        return res;
      }

      if (func == "shift_billion") {
        if (val > 999) {
          val = (val / 1000).toFixed(1);
          let v = val.toString().split(".");
          val = v[1] ? v[0] + "<span class='fs-xs'>." + v[1] + "</span>" : val;
          return val + " <span class='fs-xs fw-base op-06'>тэрбум</span>";
        } else {
          if (!this.isInt(val)) {
            let v = val.toString().split(".");
            val = v[1] ? v[0] + "<span class='fs-xs'>." + v[1] + "</span>" : val;
          }
          return val + "<span class='fs-xs ml-1 fw-base op-06'>сая</span>";
        }
      }

      if (func == "shift_million") {
        if (val > 999) {
          val = (val / 1000).toFixed(1);
          let v = val.toString().split(".");
          val = v[1] ? v[0] + "<span class='fs-xs'>." + v[1] + "</span>" : val;
          return val + " <span class='fs-s fw-base op-06'>cая</span>";
        } else {
          return val + "<span class='fs-s ml-1 fw-base op-06'>мянга</span>";
        }
      }
    },
    sanitize(string) {
      const map = {
        "&": "",
        "<": "",
        ">": "",
        '"': "",
        "'": "",
        "/": "",
        /*
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "/": "&#x2F;",*/
      };
      const reg = /[&<>"'/]/gi;
      return string.replace(reg, (match) => map[match]);
    },
    isEmptyObj(obj) {
      return Object.keys(obj).length === 0;
    },
    isset(ref) {
      typeof ref !== "undefined";
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    getNum(str) {
      return str.replace(/[^0-9]/g, "");
    },
    imageCache(obj, zid, prop, images) {
      let imgs = [];
      for (let i = 0; i < images.length; i++) {
        let imageObject = new Image();
        imageObject.src = this.apiUrl + "/media/zar/" + obj + "/" + zid + "/" + prop + "/" + "m" + images[i].id + ".jpg";
        imgs.push(imageObject);
      }
    },
    el(id) {
      let el = document.getElementById(id);
      if (!el) console.log("Dom element not found!");
      return el;
    },
    isEqualArray(arr1, arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    },
    deepClone(data) {
      return JSON.parse(JSON.stringify(data));
    },
    sortArr(arr, p, sort, p2 = null) {
      if (!arr.length) return [];
      switch (sort) {
        case 1:
          return arr.sort((a, b) => {
            if (a[p] < b[p]) return -1;
            if (a[p] > b[p]) return 1;
            return 0;
          });
        case 2:
          return arr.sort((a, b) => {
            if (p2 && a[p] == b[p]) {
              if (a[p2] < b[p2]) return 1;
              if (a[p2] > b[p2]) return -1;
              return 0;
            }
            if (a[p] < b[p]) return 1;
            if (a[p] > b[p]) return -1;
            return 0;
          });
        case 3:
          return arr.sort((a, b) => a[p].localeCompare(b[p]));
        case 4:
          return arr.sort((a, b) => b[p].localeCompare(a[p]));
        case 5:
          return arr
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);

        default:
          break;
      }
    },
    isInt(num) {
      return num % 1 === 0;
    },
    randomInt(min, max) {
      return Math.floor(Math.random() * (1 + max - min)) + min;
    },
    sortNum(arr, p) {
      return arr.sort((a, b) => a[p].localeCompare(b[p]));
    },
    compareArr(arr1, arr2) {
      return arr1.length === arr2.length && arr1.every((e) => arr2.includes(e));
    },
    saveLocal(key, val) {
      localStorage.setItem(key, JSON.stringify(val));
    },
    getLocal(key, str = null) {
      return str ? localStorage.getItem(key) : JSON.parse(localStorage.getItem(key));
    },
    hasLocal(key) {
      return localStorage.hasOwnProperty(key);
    },
    delLocal(key) {
      localStorage.removeItem(key);
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    captalizeFirstWord(sentence) {
      const word = sentence.split(" ");
      word[0] = word[0].charAt(0).toUpperCase() + word[0].slice(1);
      return word.join(" ");
    },
    explode(str, sym = " ") {
      return str.split(sym);
    },
    implode(arr, sym = " ") {
      return arr.join(sym);
    },
    isObject(item) {
      return typeof item === "object" && !Array.isArray(item) && item !== null;
    },
    IsJsonString(str) {
      try {
        var json = JSON.parse(str);
        return typeof json === "object";
      } catch (e) {
        return false;
      }
    },
    arrDiff(arr1, arr2) {
      return arr1.filter((x) => !arr2.includes(x));
    },
    elPos(id) {
      let el = document.getElementById(id);
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left,
        top: rect.top,
      };
    },
    isStr(val) {
      let isStr = typeof val === "string";
      if (!isStr) console.log(val + " not type of STRING");
      return isStr;
    },

    lastWord(str) {
      if (!this.isStr(str)) return str;
      let words = str.split(" ");
      return words[words.length - 1];
    },

    remLastWord(str) {
      if (!this.isStr(str)) return str;
      if (str.length == 1) return str;
      let words = str.split(" ");
      return words.slice(0, words.length - 1).join(" ");
    },
    digitNumber(num) {
      return num.toString().length;
    },
    wordsNum(str) {
      return str.split(" ").length;
    },

    getMobileOperatingSystem() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) return "windows phone";
      if (/android/i.test(userAgent)) return "android";
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "ios";

      return null;
    },

    getMobileOs() {
      if (!isMobile) return false;

      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "windows_phone";
      }

      if (/android/i.test(userAgent)) {
        return "android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }

      return "unknown";
    },
    getBrowserType() {
      const test = (regexp) => {
        return regexp.test(navigator.userAgent);
      };

      // console.log(navigator.userAgent);

      if (test(/opr\//i) || !!window.opr) {
        return "Opera";
      } else if (test(/edg/i)) {
        return "Microsoft Edge";
      } else if (test(/chrome|chromium|crios/i)) {
        return "Google Chrome";
      } else if (test(/firefox|fxios/i)) {
        return "Mozilla Firefox";
      } else if (test(/safari/i)) {
        return "Apple Safari";
      } else if (test(/trident/i)) {
        return "Microsoft Internet Explorer";
      } else if (test(/ucbrowser/i)) {
        return "UC Browser";
      } else if (test(/samsungbrowser/i)) {
        return "Samsung Browser";
      } else {
        return "Unknown browser";
      }
    },
    stripHtml(html) {
      let doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    },
  },
});

// if (isMobile) //FOR MOBILE BROWSER BACK BUTTON DISABLE BUT REFRESH HOME BUGGY
//   (function(global) {
//     if (typeof global === "undefined") {
//       throw new Error("window is undefined");
//     }

//     var _hash = "!";
//     var noBackPlease = function() {
//       global.location.href += "#";

//       // Making sure we have the fruit available for juice (^__^)
//       global.setTimeout(function() {
//         global.location.href += "!";
//       }, 50);
//     };

//     global.onhashchange = function() {
//       if (global.location.hash !== _hash) {
//         global.location.hash = _hash;
//       }
//     };

//     global.onload = function() {
//       noBackPlease();

//       // Disables backspace on page except on input fields and textarea..
//       document.body.onkeydown = function(e) {
//         var elm = e.target.nodeName.toLowerCase();
//         if (e.which === 8 && elm !== "input" && elm !== "textarea") {
//           e.preventDefault();
//         }
//         // Stopping the event bubbling up the DOM tree...
//         e.stopPropagation();
//       };
//     };
//   })(window);

const router = new VueRouter({
  routes: Routes,
  mode: rmode,
  baseUrl: process.env.BASE_URL,
  scrollBehavior: (to, from) => {
    // console.log("to:", to, " from:", from);
    store.commit("routeFrom", from.name);
    // store.state.routeFrom = from.name;
    let cond = to.name == "home" && from.name == "zarView" && store.state.returnPos;
    // let cond2 = to.name == "home" && from.hash == "#itemView" && store.state.returnPos;

    return { x: 0, y: cond ? store.state.returnPos : 0 };
  },
});
// console.log(router);

// const originalPush = router.push;
// router.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject);
//   }

//   return originalPush.call(this, location).catch((err) => {
//     if (VueRouter.isNavigationFailure(err)) {
//       return err;
//     }

//     return Promise.reject(err);
//   });
// }

// router.beforeEach((to, from, next) => {
//   if (to.name === "userHome" && !user_logged) next({ name: "home" });
//   else next();
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
