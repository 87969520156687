<template>
  <div class="cont_ui_bread">
    <span v-for="(t, i) in items" :key="'bread' + i" class="bread ani_sright_fast" :class="[t.class, t.icon && 'with_icon']" @click="clickedItem(t)"> <ui-icon class="bread_icon" v-if="t.icon" :name="t.icon" size="xl" />{{ t.sname }} </span>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-bread",
  props: {
    items: Array,
  },
  methods: {
    clickedItem(ob) {
      this.$emit("clickedItem", ob);
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_bread {
  max-width: 95%;
  transition: 0.15s ease-out;
  position: relative;
}

.bread {
  display: inline-flex;
  padding: 2px 5px 0px 8px;
  cursor: pointer;
  margin: 0px 4px 5px 0;
  border: #000 !important;
  /* background-color: #e6e6e6; */
  transition: all 0.1s;
  border-radius: 15px;
  /* color: #5f5f5f; */
  /* font-family: "Regular"; */
  /* text-transform: uppercase; */
  user-select: none;
  position: relative;
  font-size: 13px;
  gap: 5px;
  /* color: var(--color_link); */

  /* line-height: 1.4em; */
}

.bread:active {
  transform: translateY(1px);
}

.bread:hover .remove_sym {
  opacity: 1;
  filter: brightness(0.9);
}

.with_icon {
  padding-left: 27px !important;
}

.bread_icon {
  position: absolute;
  left: 5px;
}

.cat {
  /* background-color: rgb(236, 255, 212); */
  border: none;
  /* font-size: var(--14px); */
  padding: 2px 2px 0 2px;
  /* font-weight: 500; */
  /* color: var(--color_darker); */
  /* box-shadow: 0px 1px 0px 1px rgb(183, 212, 150); */
  /* background-color: #e8f4ff; */
  margin-right: 0 !important;
  /* font-stretch: 95%; */
  /* font-size: 15px; */
}
.cat::after {
  content: "-";
  font-weight: 300;
  font-size: 8px;
  position: relative;
  padding: 0 0 0 7px;
  top: 0px;
  left: -4px;
}

.obj {
  /* font-stretch: 100%; */
  /* font-size: 15px; */
  /* background-color: rgb(236, 255, 212); */
  border: none;
  /* font-size: var(--14px); */
  padding: 0;
  /* font-weight: 500; */
  color: var(--color_link);
  /* box-shadow: 0px 1px 0px 1px rgb(183, 212, 150); */
  /* background-color: #e8f4ff; */
  margin-right: 0 !important;
  /* font-weight: 600; */
  /* color: #000; */
}

.cat:hover,
.obj:hover {
  color: #000;
}
</style>
