import Vue from "vue";
import uiButton from "@/comp/ui/button.vue";
// import uiAlert from "@/comp/ui/alert.vue";
import uiIcon from "@/comp/ui/icon.vue";
import uiReflow from "@/comp/ui/reflow.vue";
// import uiModal from "@/comp/ui/dialog.vue";

Vue.component("ui-button", uiButton);
// Vue.component("ui-alert", uiAlert);
Vue.component("ui-icon", uiIcon);
Vue.component("ui-reflow", uiReflow);
// Vue.component("ui-modal", uiModal);
