<template>
  <div>
    <div v-if="pob && pob.guide" class="guide">{{ pob.guide }}</div>
    <ui-loc-grab-detect :forceSel="forceSel" :markers="markers" inputLocCode :mapHeight="350" v-on:placedMarkers="placedMarkers($event)" v-on:selectedArea="selectedArea($event)" v-on:clickedItem="clickedItem($event)" v-on:clickedMarker="clickedMarker($event)" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiLocGrabDetect from "@/comp/ui/locGrabDetect.vue";

export default {
  name: "zar-add-loc-grab",
  props: {
    obj: Object,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: Object,
    markers: Array,
    zind: Number,
    forceSel: [Object, Number],
  },
  components: {
    uiLocGrabDetect,
  },
  data() {
    return {};
  },
  methods: {
    clickedItem(sel) {
      this.$emit("clickedItem", sel);
    },
    clickedMarker(marker) {
      this.$emit("clickedMarker", marker);
    },
    selectedArea(center) {
      this.$store.commit("zar/selectedArea", center);
    },
    resetselectedArea() {
      this.$store.commit("zar/selectedArea", null);
    },
    placedMarkers(markers) {
      this.$store.commit("zar/placedMarkers", markers);
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.resetselectedArea();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
