<template>
  <div class="cont_ui_loc_code">
    Код:
    <input
      ref="info"
      :disabled="disabled"
      v-model="text"
      size="5"
      type="text"
      v-mask="{ mask: '9[9][9][9][9]', regex: '', autoUnmask: true, greedy: false }"
      v-on:keyup="maskCheck"
      @focus="showGuide = true"
      @blur="showGuide = false"
      :class="{
        disabled,
        not_verified: valid == 1 && userTyped,
        bold: valid == 2 || !userTyped,
        glow: updated,
      }"
    />

    <ui-icon v-if="valid == 2" size="m" name="i-correct" class="success ani_pop_slow" />
    <ui-reflow>
      <div v-if="guide && showGuide && valid != 2" class="guide">{{ guide }}</div>
    </ui-reflow>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-loc-code",
  props: {
    collect: { type: [Number, String] },
    valid: Number,
  },
  data() {
    return {
      showGuide: false,
      text: "",
      guide: "Байршлын 5 оронтой тоон кодоо оруулна уу",
      disabled: false,
      updated: false,
      userTyped: false,
    };
  },
  methods: {
    maskCheck(field) {
      if (!this.text) {
        this.$emit("update", "");
        return;
      }
      this.userTyped = true;
      this.$emit("update", field.target.inputmask.isComplete() && this.text != 0 ? this.text : "");
    },
    updateText() {
      if (this.collect && this.collect != this.text) {
        this.userTyped = false;
        this.updated = true;
        setTimeout(() => (this.updated = false), 1000);
        this.text = this.collect;
      }
    },
  },
  watch: {
    collect() {
      this.updateText();
    },
  },
  computed: {},
  mounted() {
    // this.$refs.info.focus();
    this.updateText();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_loc_code {
  box-sizing: border-box;
  position: relative;
}
input {
  min-width: 10ch;
  margin: 0 5px 5px 0;
  max-width: 90%;
  box-sizing: border-box;
  font-size: 15px;
}
.bold {
  font-weight: 600 !important;
}
/* input:focus + .guide {
  display: block;
} */
.counter {
  color: grey;
  font-size: 12px;
  position: relative;
  top: -1px;
}
.empty {
  background-color: #fffde6;
}

.disabled {
  opacity: 0.8;
}

.not_verified {
  background-color: rgb(255, 255, 167) !important;
}

.glow {
  animation: glow 1s linear 1;
}

@keyframes glow {
  from {
    background-color: var(--color_main_lighter);
  }
  to {
    background-color: #fff;
  }
}

@media only screen and (max-width: 700px) {
  input {
    margin: 0 0 5px 0;
    max-width: 40ch;
  }
}
</style>
