<template>
  <div>
    <ui-input-loc-code v-if="inputLocCode" v-on:update="updatedGcode($event)" :collect="gcode" :valid="validGcode" />
    <ui-loc-grab
      v-on:mouseDown="mapMouseDown()"
      :mapHeight="mapHeight"
      :zoom="gzoom"
      :geoJson="geoJson"
      :collect="mapCollect"
      :center="centerIni || gcenter"
      :label="label"
      :pins="pins"
      :markers="markers"
      :enableCenterMarker="centerMarker"
      :enSatView="enSatView"
      v-on:zoomUpdated="zoomUpdated($event)"
      v-on:centerUpdated="centerUpdated($event)"
      v-on:boundsUpdated="boundsUpdated($event)"
      v-on:clickedMarker="clickedMarker($event)"
    />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiLocGrab from "@/comp/ui/locGrab.vue";
import uiInputLocCode from "@/comp/ui/inputLocCode.vue";
import rPip from "robust-point-in-polygon";

export default {
  name: "ui-locGrab-detect",
  components: {
    uiLocGrab,
    uiInputLocCode,
  },
  props: {
    enSatView: {
      type: Boolean,
      default: () => false,
    },
    forceSel: [Object, Number],
    inputLocCode: {
      type: Boolean,
      default: () => true,
    },
    mapHeight: Number,
    disableDrag: {
      type: Boolean,
      default: () => false,
    },
    centerMarker: {
      type: Boolean,
      default: () => true,
    },
    pins: Array,
    markers: Array,
    centerIni: Array,
  },
  data() {
    return {
      gcenter: [],
      gzoom: 12,
      zoom: null,
      center: null,
      bounds: null,
      refs: [],
      selected: [],
      cache: [],
      gcode: null,
      validGcode: 0,
      geoJson: [],
      // cacheGeo: [],
      userDragged: true,
      userTyped: false,
      nextReqGcodeSize: 0,
      label: null,
      timer: null,
    };
  },
  methods: {
    emit(data) {
      if (this.userDragged || this.userTyped) {
        this.$emit("clickedItem", data);
      }
    },

    clickedMarker(marker) {
      this.gcenter = marker.coord;
      this.$emit("clickedMarker", marker);
    },

    mapMouseDown() {
      this.userDragged = true;
      this.userTyped = false;
    },

    async updatedGcode(gcode) {
      this.gcode = gcode;
      this.gcodeExtract(String(gcode));
    },

    gcodeExtract(code) {
      if (code.length != this.gcodeSize) {
        this.validGcode = 0;
        return;
      }

      this.nextReqGcodeSize = 0;
      for (let ind = 0; ind < code.length; ind++) {
        const el = this.refs[ind];
        if (el) this.nextReqGcodeSize += el.size;
      }

      this.refs.forEach((r, ind) => {
        if (code.length >= this.nextReqGcodeSize) {
          let totalSize = 0;
          for (let i = 0; i < ind + 1; i++) totalSize += this.refs[i].size;
          let sliced = code.slice(0, totalSize);
          r.itemCode = sliced ?? null;
        }
      });

      this.verifyCode();
    },
    async fetchAreaName(completeGcode) {
      return await this.axiosGet("utility/location/getAreaName", [completeGcode]);
    },

    async verifyCode() {
      this.geoJson = [];
      let verified = await this.axiosPost("/utility/location/verifyGcode", { data: this.refs });

      this.validGcode = verified ? 2 : 1;
      if (!verified) {
        this.showMessage("warning", "Оруулсан код буруу байна");
        return;
      }

      this.userDragged = false;
      this.userTyped = true;

      this.selected = [];
      verified.forEach(async (v) => {
        this.selected.push({ ref: v.ref, id: v.id, coord: this.center, input: "typed" });
        // console.log("fetching from verify code");
        this.changeGeoJson(v.ref, await this.fetchGeoJson(v.ref, v.id));
      });

      this.emit(this.selected);
    },

    changeGeoJson(ref, geoJson) {
      if (!geoJson) return;
      this.geoJson = this.geoJson.filter((f) => f.ref != ref);

      if (geoJson) {
        if (ref != "ref_location_city") this.geoJson.push({ ref, data: geoJson });
      }

      if ((!this.userDragged || this.userTyped) && this.geoJson.last()) {
        this.gcenter = this.geoJson.last().data.center;
        if (this.geoJson.last().data.zoom) this.gzoom = this.geoJson.last().data.zoom;
      }
    },

    async fetchGeoJsons(ref, id) {
      let found = this.cache.find((c) => c.ref == ref && c.id == id);
      if (found) return found.data;
      let params = [ref];
      if (id) params.push(id);
      return await this.axiosGet("utility/location/getgeojsons", params);
    },

    async fetchGeoJson(ref, id) {
      // console.log("fetching geo...", this.cacheGeo.length);

      let found = this.cacheGeo.find((e) => e.id == id && e.ref == ref);
      if (found) return found.data;
      // console.log(this.cacheGeo);

      // console.log("found", this.cacheGeo.length, id, found);
      // console.log("fetching...");

      let data = this.axiosGet("utility/location/getgeojson", [ref, id]);
      // console.log(data);

      if (data) this.cacheGeo.push({ id, ref, data });

      return data;
    },

    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
      if (this.userDragged && !this.disableDrag) {
        this.geoJson = [];
        this.pip(center);
      }
    },
    updateLabel(ob) {
      this.label = { text: ob.name ? ob.name : "код: " + ob.id, center: ob.center };
    },
    async pip(center) {
      this.selected = [];
      await this.pointInPolygon(this.refs.first(), null, center);

      this.emit(this.selected);
    },

    async pointInPolygon(ref, id, center) {
      let refData = await this.fetchGeoJsons(ref.name, id);

      this.cache.push({ ref: ref.name, id, data: refData });

      if (!refData) return;
      // console.log("refData:", refData);

      let found = refData.features.find((f) => f.geometry.coordinates.find((c) => rPip(c, [center.lng, center.lat]) == -1));
      this.changeGeoJson(ref.name, found);
      // console.log("found:", found);

      if (found) {
        this.updateLabel(found);
        this.gcode = found.id;
        this.selected.push({ ref: ref.name, id: found.id, coord: this.center, input: "dragged" });

        let nextRef = this.refs[this.refs.indexOf(ref) + 1];
        if (nextRef) await this.pointInPolygon(nextRef, found.id, center);
      }
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
      // console.log(bounds);
    },
  },
  async mounted() {
    this.refs = await this.axiosGet("utility/location/getLocationRefs", [], []);
  },
  watch: {
    forceSel: {
      immediate: true,

      async handler() {
        this.label = null;
        this.geoJson = [];

        if (this.forceSel) {
          let code = this.forceSel.sel.id;

          this.geoJson = this.geoJson.filter((e) => e.data.id.toString().length < code.toString().length);

          this.userDragged = false;
          this.userTyped = false;
          this.forceSelected = true;

          this.gcode = code;

          if (this.forceSel.coordOnly) {
            // console.log(this.forceSel.sel.coord);
            let coord = JSON.parse(this.forceSel.sel.coord);
            this.gcenter = coord;
            return;
          }
          // console.log("fetching from forcesel watch", this.forceSel);

          let data = await this.fetchGeoJson(this.forceSel.ref, code);
          if (data && this.forceSel.sel.name) data.name = this.forceSel.sel.name;

          if (data) this.updateLabel(data);
          this.changeGeoJson(this.forceSel.ref, data);
        }
      },
    },
    async gcode() {
      if (!this.gcode) {
        this.validGcode = 0;
        this.geoJson = [];
      }
    },
    center() {
      this.$emit("coord", this.center);
    },
    geoJson() {
      // this.$emit("selectedArea", this.geoJson);
      let area = this.geoJson.find((e) => e.ref == "ref_location_area");
      if (area) this.$emit("selectedArea", area);
    },
    markers() {
      if (this.markers) this.$emit("placedMarkers", this.markers);
    },
    selectedArea() {
      this.$emit("selectedAreaInfo", this.selectedArea);
    },
  },
  asyncComputed: {
    async selectedArea() {
      return this.gcode && this.digitNumber(this.gcode) == 5 ? { id: this.gcode, name: await this.fetchAreaName(this.gcode) } : null;
      //       if (this.gcode && this.digitNumber(this.gcode) == 5) {
      //   this.$emit("selectedArea", { gcode: this.gcode, name: await this.fetchAreaName(this.gcode) });
      // }
    },
  },
  computed: {
    mapCollect() {
      return this.gcode ? true : false;
    },
    gcodeSize() {
      return this.refs.length ? this.refs.reduce((total, el) => total + el.size, 0) : 0;
    },
    cacheGeo: {
      get() {
        return this.$store.state.cacheGeo;
      },
      set(ob) {
        this.$store.commit("cacheGeo", ob);
      },
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
