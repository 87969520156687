<template>
  <ui-select-hori
    :items="items"
    :loading="loading"
    :collect="collect"
    :zind="zind"
    :unit="pob.unit"
    :forceSel="forceSel"
    v-on:clickedItem="clickedItem($event)"
    v-on:selection="selection($event)"
    v-on:hideProp="hideProp"
    v-on:mouseOver="mouseOver($event)"
    v-on:mouseLeave="mouseLeave(pob.ref)"
    v-on:mouseEnter="mouseEnter(pob.ref)"
  />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectHori from "@/comp/ui/selectHori.vue";
export default {
  name: "zar-add-select-hori",
  props: {
    items: Array,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: Object,
    zind: Number,
    forceSel: Number,
  },
  components: {
    uiSelectHori,
  },
  data() {
    return {};
  },
  methods: {
    hideProp() {
      this.pob.active = 0;
    },
    selection(bo) {
      this.$emit("selection", bo);
    },
    clickedItem(obj) {
      this.$emit("clickedItem", obj);
    },
    mouseLeave(ref) {
      this.$emit("mouseLeave", ref);
    },
    mouseOver(i) {
      this.$emit("mouseOver", i);
    },
    mouseEnter(ref) {
      this.$emit("mouseEnter", ref);
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
