<template>
  <div class="cont_zar_main" @mouseover="mOver = true" @mouseout="mOver = false">
    <div class="cont_cat">
      <template v-for="(c, ind) in itemsDep">
        <div class="dep" :class="{ ani_pop: isMobile }" :key="'cat' + ind">
          <div class="cat clickable" @click="clickedItem(c)" :class="{ selected: selectedDep && selectedDep.id == c.id }">
            <img class="cat_img" v-if="c.icon" :src="'/icon/' + c.icon + '.svg'" height="48" />
            <span class="cat_name" :class="{ selected: selectedDep && selectedDep.id == c.id }">
              {{ c.name }}
              <span v-if="c.znum" class="fs-10 mt-1 fw-350">
                <div>({{ c.znum }})</div>
              </span>
            </span>
          </div>
        </div>
      </template>
    </div>
    <ch-drawer :visible.sync="showSubDrawer" v-on:close="showSubDrawer = false" :area="drawerHeight" :mask="false" :title="subDrawerTitle" :blur="false" :location="scr == 1 ? 'bottom' : 'left'" destroy-on-close>
      <zar-cat-sub-mobile v-if="items && (items.cat.length || items.obj.length)" notEmpty rootCat :items="items" :selObj="selObj" class="child_cont ani_sdown_fast" v-on:clickedObj="clickedObj($event)" />
      <div class="return_button_right" @click="showSubDrawer = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      <div class="return_button_top" @click="showSubDrawer = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarCatSubMobile from "@/comp/zar/catSubMobile.vue";

export default {
  name: "zar-cat-main-step",
  components: {
    zarCatSubMobile,
  },
  props: {
    selObj: Object,
    selCat: Object,
  },
  data() {
    return {
      items: null,
      loading: false,
      mOver: false,
      titleSubDrawer: "",
      showSubDrawer: false,
      selectedDep: null,
    };
  },
  methods: {
    getObjects(dep) {
      let obs = this.getCatObject(dep);
      obs = obs.filter((e) => {
        return this.catData.obj.find((o) => o.id == e && o.znum);
      });
      return obs;
    },
    getCatObject(cat) {
      let foundObs = this.catData.objCat.filter((e) => e.cat == cat).map((e) => e.obj);
      let obs = foundObs ? foundObs : [];

      let foundCats = this.catData.cat.filter((e) => e.par == cat).map((e) => e.id);
      if (foundCats) {
        foundCats.forEach((e) => {
          obs = obs.concat(this.getCatObject(e));
        });
      }
      return obs;
    },

    showBranch(tdata, catId) {
      let data = this.fetchObject(catId);
      if (!data) return;

      tdata.cat.find((e) => {
        if (e.id == catId) {
          this.$set(e, "items", data);
        }
      });

      data.cat.forEach((c) => {
        this.showBranch(data, c.id);
      });
    },

    fetchObject(id, main = null) {
      let data = this.deepClone(this.$store.state.zar.catData);
      let cat = data.cat.filter((e) => {
        if (!main && e.par == id) return true;
        if (main && e.par == id && e.id == main) return true;
      });
      let obj = data.obj.filter((e) => data.objCat.find((i) => i.obj == e.id && i.cat == id));
      return cat || obj ? { cat, obj } : false;
    },

    countAllCatObjects(items) {
      let count = 0;
      items.obj.forEach((o) => {
        if (o.znum) count++;
      });
      count += items.cat.length;
      items.cat.forEach((c) => {
        count += this.countAllCatObjects(c.items);
      });
      return count;
    },

    clickedItem(ob) {
      this.items = this.fetchObject(0, ob.id);
      this.showBranch(this.items, ob.id);
      this.showSubDrawer = true;
      this.selectedDep = ob;
    },
    clickedObj(ob) {
      this.$emit("clickedObj", ob);
    },
  },

  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    drawerHeight() {
      if (!this.items) return "";
      let count = this.countAllCatObjects(this.items.cat[0].items);
      return count > 10 ? "450px" : (count + 1) * 48 + "px";
    },
    subDrawerTitle() {
      return this.selectedDep ? this.selectedDep.name + " (" + this.selectedDep.znum + ")" : "";
      // return this.selectedDep ? this.selectedDep.name : "";
    },
    openDrawers() {
      return this.$store.state.openDrawers;
    },
    catData() {
      return this.$store.state.zar.catData;
    },
    itemsDep() {
      return this.catData ? this.catData.cat.filter((e) => !e.par && !e.virt && e.znum) : [];
    },
  },
  watch: {
    itemsDep: {
      immediate: true,
      handler() {
        if (this.itemsDep.length == 1) {
          setTimeout(() => {
            this.clickedItem(this.itemsDep[0]);
          }, 100);
        }
      },
    },
  },
  mounted() {
    // if (this.selCat && this.itemsDep) this.selectedDep = this.itemsDep.find((e) => e.id == this.selCat.id);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_main {
  padding: 30px 20px;
  text-align: center;
  display: flex;
  justify-content: center; /* horizontal */
  align-items: center; /* vertical */
  height: 80%;
}
.cont_cat {
  /* display: grid;
  gap: 10px; */
}
.cont_cat a {
  background: none !important;
}
.dep {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 3px;
}
.cat {
  display: inline-block;
  width: 100px;
  text-align: center;
  /* margin: 0 10px 0px 0; */
  padding: 5px 0px;
  transition: all 100ms;
  vertical-align: middle;
  border-radius: 5px;
  transition: all 150ms;
  /* border: solid 1px #dbdbdb; */
}
.cat.selected {
  background-color: var(--color_selected);
}
/* .cat.selected img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(226deg) brightness(104%) contrast(102%);
} */
/* .cat:active img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(226deg) brightness(104%) contrast(102%);
} */
/* .cat_name.selected {
  color: #fff !important;
} */
/* .cat:active .cat_name {
  color: #fff !important;
} */
.cat_name {
  font-size: 12px;
  line-height: 1em;
  display: block;
  font-weight: 700;
  font-stretch: 100%;
  letter-spacing: 0.05em;
  color: #000;
  text-transform: uppercase;
}
.otems {
  font-size: 12px;
  /* display: none; */
}
.otem {
  display: block;
}

@media only screen and (min-width: 700px) {
  .otem:hover {
    background-color: #f1f1f1 !important;
  }
}
</style>
