<template>
  <div class="cont_home_front">
    <div class="cont_list">
      <div class="mb-5 title_block">Шинэ зарууд</div>
      <zar-list linkDirect :items="items" nanoMode :cusFieldAllowed="['title']" v-on:clickedItem="clickedItem($event)" v-on:reachedBottom="reachedBottom" :loading="loading" />
    </div>
    <!-- <ui-paging v-if="totalPage >= pageNum" pagedView :curPage="pageNum" :totalPage="totalPage" v-on:clickedPageNum="pageNum = $event" v-on:next="next()" /> -->
    <!-- <div v-else class="disabled fs-12 w-100 ta-c">Дууссан...</div> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarList from "@/comp/zar/list.vue";
// import uiPaging from "@/comp/ui/paging.vue";

export default {
  name: "zar-list-home-front",
  components: { zarList },
  props: {},
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageLim: 30,
      // totalPage: 0,
      items: [],
    };
  },
  methods: {
    clickedItem() {
      this.$store.commit("zar/itemSource", { name: "Шинэ зарууд", src: "newItems", data: this.items });
      this.$emit("clickedItem", this.items);
    },
    async fetchItems() {
      let res = await this.axios.get("/zar/item/getItemFront/" + this.pageNum + "/" + this.pageLim, {});
      return res.data;
    },

    async resetItems() {
      this.pageNum = 1;
      this.items = await this.getItems();
    },

    reachedBottom() {},

    async next() {
      this.pageNum++;
      let items = await this.getItems();
      this.items = [...this.items, ...items];
    },

    prep(data) {
      data.items.forEach((z) => {
        z.pinfo.forEach((i) => {
          if (i.pcomp_tp == 3 && z["p" + i.id]) {
            let photos = z["p" + i.id];
            i.val = photos;
            i.total = photos.length;
          } else {
            i.val = z["p" + i.id + "_val"] ? z["p" + i.id + "_val"] : z["p" + i.id];
            if (i.conver && i.val) i.val = this.converters(i.conver, i.val);
            i.dep = z.dep;
          }
        });
        z.date = this.$date.unix(z.rdate).fromNow(true);
      });
      this.totalPage = data.totalPage;
      return data;
    },

    async syncItems() {
      this.loading = true;
      this.items = [];
      let data = [];
      data = this.prep(await this.fetchItems());
      // console.log(data);

      this.items = Object.freeze(data.items) ?? [];
      this.loading = false;
    },
  },

  watch: {
    pageNum() {
      this.syncItems();
    },
    refreshZarList() {
      this.syncItems();
    },
  },
  computed: {
    gLoading: {
      get() {
        return this.$store.state.gLoading;
      },
      set(val) {
        this.$store.commit("gLoading", val);
      },
    },
    devid() {
      return this.getLocal("devid", true);
    },
  },
  mounted() {
    this.syncItems();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_home_front {
  text-align: center;
  display: flex;
  justify-content: center; /* horizontal */
  align-items: center; /* vertical */
}
.cont_list {
  max-width: 800px;
}
.page_num {
  margin-right: 5px;
  background-color: #f1f1f1;
  border: solid 1px #f1f1f1;

  padding: 4px 8px;
  border-radius: 2px;
}

.selected {
  background: none;
  border: solid 1px #e2e2e2;
  padding: 5px 10px;

  /* font-weight: 600; */
}

@media only screen and (min-width: 600px) {
  .page_num:hover {
    background-color: #e2e2e2;
    border: solid 1px #e2e2e2;

    cursor: pointer;
  }
  .selected:hover {
    background: none;
  }
}
</style>
