<template>
  <div>
    <span v-if="newItem" class="selected" @click="clickedNewItem()">
      {{ newItem.name }}
    </span>
    <ui-input-sel
      v-if="!newItem"
      :items="items"
      :collect="collect"
      :min="min"
      :max="max"
      :zind="zind"
      :guide="guide"
      :mask="mask"
      :imageSel="collect ? apiUrl + '/media/ref/' + obj.id + '/' + pob.id + '/' + collect.id + '.jpg' : ''"
      :forceSel="forceSel"
      :imageHover="imageHover"
      :unit="pob.unit"
      :pRef="pob.ref"
      :title="pob.name"
      :compName="'inputSel' + pob.id"
      :userAddable="userAddable"
      v-on:clickedItem="clickedItem($event)"
      v-on:mouseOver="mouseOver($event)"
      v-on:mouseLeave="mouseLeave(pob.ref)"
      v-on:mouseEnter="mouseEnter(pob.ref)"
      v-on:selection="selection($event)"
      v-on:hideProp="hideProp"
      v-on:showAddSel="showAddSel()"
    />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiInputSel from "@/comp/ui/inputSel.vue";
import uiAddSel from "@/comp/ui/addSel.vue";

export default {
  name: "zar-add-input-sel",
  props: { items: Array, selGeoJson: Array, collect: { type: [String, Object, Boolean, Number, Array] }, pob: Object, zind: Number, obj: Object, forceSel: Number, opt: Object },
  data() {
    return {
      min: this.pob.mask && this.pob.mask.min ? this.pob.mask.min : 0,
      max: this.pob.mask && this.pob.mask.max ? this.pob.mask.max : 100,
      guide: this.pob.guide,
      mask: this.pob.mask,
      imageHover: null,
      userAddable: this.pob.user_addable,
      newItem: null,
    };
  },
  components: {
    uiInputSel,
  },
  methods: {
    clickedNewItem() {
      this.newItem = null;
      this.clickedItem("");
    },
    userSubItem(sel) {
      this.newItem = sel;
      sel.id = 888888888;
      this.clickedItem(sel);
    },
    showAddSel() {
      this.$modal.show(uiAddSel, { pob: this.pob }, { height: "auto", width: 450, adaptive: true, clickToClose: false });
    },
    mouseLeave(ref) {
      this.$emit("mouseLeave", ref);
    },
    mouseOver(sel) {
      if (sel.id != 888888888) this.$emit("mouseOver", sel);
      // this.$emit("mouseOver", sel.id); //OLD but why id only!
      if (!this.isMobile && sel.image) this.imageHover = this.apiUrl + "/media/ref/" + this.obj.id + "/" + this.pob.id + "/" + sel.id + ".jpg";
      else this.imageHover = null;
    },
    mouseEnter(ref) {
      this.$emit("mouseEnter", ref);
    },
    clickedItem(obj) {
      this.$emit("clickedItem", obj);
    },
    selection(bo) {
      this.$emit("selection", bo);
    },
    hideProp() {
      this.pob.active = 0;
    },
    userSubbedItem(obj) {
      console.log(obj);
    },
  },
  watch: {
    collect() {
      if (!this.collect) this.newItem = null;
    },
    items: {
      immediate: true,
      handler() {
        if (this.items.filter((e) => e.id != 888888888).length) this.$emit("loaded", this.items);
      },
    },
  },
  mounted() {
    // console.log(this.opt);
    this.$bus.on("userSubItem" + this.pob.id, this.userSubItem);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.selected {
  border: solid 1px #fff;
  /* text-decoration: underline; */
  border-bottom: dotted 1px #b9b8b8;
  display: inline-block;
  margin-bottom: 5px;
  background-color: #fafafa;
  color: var(--color_link);
  border-radius: 4px;
  padding: 2px 0px 0px 0px !important;
}
.selected:focus {
  border: solid 1px #000;
}
.selected:active {
  transform: translateY(1px);
}
.selected:hover {
  cursor: pointer;
}
</style>
