<template>
  <div class="cont_ui_paging">
    <div v-if="pagedView">
      <ui-button :disabled="curPage == 1" v-if="pagedView" v-on:clicked="changePage(curPage - 1)" tp="iconOnly2" icon="i-next" angle="rev" />
      <span v-if="minPage > 1"><ui-button v-on:clicked="changePage(1)" :text="1" tp="textOnly" /><span v-if="minPage - 1 > 1">...</span></span>
      <template v-for="ind in totalPage">
        <ui-button v-if="ind <= maxPage && ind >= minPage" :key="'curPage' + ind" class="btn" v-on:clicked="changePage(ind)" :text="ind" :tp="ind == curPage ? 'blue' : 'textOnly'" />
      </template>
      <span v-if="maxPage < totalPage"><span v-if="maxPage + 1 < totalPage">...</span><ui-button v-on:clicked="changePage(totalPage)" :text="totalPage" tp="textOnly"/></span>
      <ui-button :disabled="totalPage == curPage" v-on:clicked="changePage(curPage + 1)" tp="iconOnly2" icon="i-next" widthSize="wideIcon" />
    </div>

    <div v-else>
      <ui-button v-if="curPage < totalPage" @clicked="next()" :text="nextButText" class="next_button" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-paging",
  props: {
    pagedView: Boolean,
    curPage: Number,
    totalPage: Number,
    nextButText: String,
    // size: {
    //   type: String,
    //   default: () => "m",
    // },
  },
  data() {
    return {};
  },
  methods: {
    changePage(pageNum) {
      this.$emit("clickedPageNum", pageNum);
      // this.$bus.emit("scrollTo", "#header");
    },
    next() {
      this.$emit("next");
    },
  },
  computed: {
    maxRange() {
      return this.curPage + 2;
    },
    maxPage() {
      return this.totalPage > this.maxRange ? this.maxRange : this.totalPage;
    },
    minRange() {
      return this.curPage - 2;
    },
    minPage() {
      return 1 < this.minRange ? this.minRange : 1;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_paging {
  text-align: center;
  max-width: 100%;
  margin: 20px auto 20px auto;
}
.btn {
  /* margin-right: 2px !important; */
}
/* @media only screen and (max-width: 700px) {
  .cont_ui_paging {
  text-align: center;
  }
} */
</style>
