<template>
  <div class="cont_user_location">
    <div class="title_modal">Байршил</div>
    <p class="suggest_text">
      Та өөрийн өдрийн болон оройн байршил тохируулснаар үзэж буй зарын мэдээлэл дээр <b>таньд ойрхон байршилтай</b> байна гэдгийг харуулна. Мөн зөвхөн тухайн хэсэг газарт хүргэх, худалдах, үйлчилэх боломжтой бүтээгдэхүүн, үйлчилгээ <b>таньд харагдах</b> болно. Таны заасан байршлын газрын нэр л
      хадгалагдана.
      <span>Жишээ нь Модны-2, Саппоро, Зайсан-1 гэх мэт..</span>
    </p>
    <div class="mb-1">
      Байршил (өдрийн цагаар)
      <span v-if="location.day" class="b">
        {{ location.day.name }} <small>({{ location.day.id }})</small> <span class="link" @click="setLocation('day')">өөрчлөх</span>
      </span>
      <span v-else class="link" @click="setLocation('day')">тохируулах</span>
    </div>
    <div class="mb-3">
      Байршил (оройн цагаар)
      <span v-if="location.night" class="b">
        {{ location.night.name }} <small>({{ location.night.id }})</small> <span class="link" @click="setLocation('night')">өөрчлөх</span>
      </span>
      <span v-else class="link" @click="setLocation('night')">тохируулах</span>
    </div>
    <ui-reflow>
      <ui-location v-if="showMap" v-on:selectedAreaInfo="selectedAreaInfo($event)" />
    </ui-reflow>
    <ui-button v-on:clicked="save()" text="Хадгалах" />
    <ui-button v-on:clicked="close()" tp="textOnly" text="Болих" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiLocation from "@/comp/ui/locGrabDetect.vue";

export default {
  name: "user-location",
  components: { uiLocation },
  props: {
    // dataSet: Object,
  },
  data() {
    return {
      showMap: false,
      location: { day: null, night: null },
      setLocTime: null,
    };
  },
  methods: {
    async fetchUserLoc() {
      if (this.user) await this.$store.dispatch("user/updateUserInfo");
      this.location = { day: { id: this.userInfo.loc_day, name: this.userInfo.loc_day_name }, night: { id: this.userInfo.loc_night, name: this.userInfo.loc_night_name } };
    },
    async save() {
      this.close();
      await this.axiosPost("/utility/location/saveLocation", { dataSet: this.location });
      this.$message({
        type: "info",
        message: "Амжилттай хадгалагдлаа",
        showClose: false,
        hasMask: false,
        duration: 2000,
      });
      await this.$store.dispatch("user/updateUserInfo");
    },
    selectedAreaInfo(ob) {
      if (ob && ob.name) this.location[this.setLocTime] = ob;
    },
    setLocation(time) {
      this.setLocTime = time;
      this.showMap = true;
    },
    close() {
      this.$emit("close");
    },
    convert(date) {
      return this.$date.unix(date).format("MM.DD");
    },
    // action(ob) {
    //   if (ob.action == "renew") this.$store.dispatch("user/renewItem", { obj: this.obj, zid: this.zid });
    //   if (ob.action == "edit") this.$store.dispatch("user/editItem", { obj: this.obj, zid: this.zid });
    //   if (ob.action == "delete") {
    //     this.$modal.show("dialog", {
    //       title: "Зар устгах",
    //       text: "Таны устгасан зар хэсэг хугацаанд идэвхигүй байрших бөгөөд энэ хугацаанд та устгасан зартайгаа ижил зар оруулж болохгүйг анхаарна уу",
    //       buttons: [
    //         {
    //           title: "Устгах",
    //           handler: () => {
    //             this.$store.dispatch("user/deleteItem", { obj: this.obj, zid: this.zid });
    //             this.$modal.hide("dialog");
    //             this.$emit("reloadItem");
    //           },
    //         },
    //         {
    //           title: "Болих",
    //           handler: () => {
    //             this.$modal.hide("dialog");
    //           },
    //         },
    //       ],
    //     });
    //   }
    // },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    user() {
      return this.$store.state.auth.user;
    },
    // dateToday() {
    //   let today = new Date(Date.now());
    //   return today.getFullYear() + "." + (today.getMonth() + 1) + "." + today.getDate();
    // },
    // dateItem() {
    //   let idate = new Date(this.rdate);
    //   return idate.getFullYear() + "." + (idate.getMonth() + 1) + "." + idate.getDate();
    // },
  },
  mounted() {
    this.fetchUserLoc();
    // this.location = { day: { id: this.dataSet.locDay, name: this.dataSet.locDayName }, night: { id: this.dataSet.locNight, name: this.dataSet.locNightName } };
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_user_location {
  text-align: center;
  padding: 10px;
  padding-top: 0;
}
.suggest_text {
  max-width: 100%;
}
</style>
