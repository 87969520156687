<template>
  <div class="cont">
    <zar-list
      :viewMain="viewMain"
      linkDirect
      :showLimit="showLimit ? 5 : false"
      :miniMode="miniMode"
      :nanoMode="nanoMode"
      :cusFieldAllowed="cusFieldAllowed"
      :items="items"
      :userMenu="userMenu"
      v-on:clickedItem="clickedItem($event)"
      v-on:reachedBottom="reachedBottom"
      :loading="loading"
      :horizontalItems="horizontalItems"
    />
    <ui-paging v-if="paged && totalPage >= pageNum && !noPaging && totalPage > 1" pagedView :curPage="pageNum" :totalPage="totalPage" v-on:clickedPageNum="pageNum = $event" v-on:next="next()" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarList from "@/comp/zar/list.vue";
import uiPaging from "@/comp/ui/paging.vue";

export default {
  name: "user-zar-list",
  components: { zarList, uiPaging },
  props: {
    nameSrc: String,
    userMenu: { type: Boolean, default: false },
    miniMode: { type: Boolean, default: false },
    nanoMode: { type: Boolean, default: false },
    noPaging: { type: Boolean, default: false },
    showLimit: { type: Boolean, default: false },
    viewMain: Boolean,
    cusFieldAllowed: {
      type: Array,
      default() {
        return null;
      },
    },
    pageLim: { type: Number, default: 30 },
    customData: [Object, Number],
    paged: { type: Boolean, default: true },
    horizontalItems: Boolean,
  },
  data() {
    return {
      loading: false,
      pageNum: 1,
      // pageLim: 12,
      totalPage: 0,
      items: [],
    };
  },
  methods: {
    // clickedPageNum() {
    //   this.$bus.emit("scrollTo", "#list" + this.nameSrc);
    // },
    clickedItem() {
      if (this.nameSrc == "barter_car") this.$store.commit("zar/itemSource", { name: "Бартер хийх машинууд", src: "barter_car", data: this.items });
      this.$emit("clickedItem", this.items);
    },
    async fetchRelatedItems(obj, zid) {
      let res = await this.axios.get("/zar/item/getRelatedItems/" + obj + "/" + zid, {});
      return res.data;
    },
    async fetchMostViewed(obj) {
      let res = await this.axios.get("/zar/item/getItemMostViewed/" + this.pageNum + "/" + this.pageLim + "/" + (obj ? obj : ""), {});
      return res.data;
    },
    async fetchBarterCar() {
      let res = await this.axios.get("/module/barter/getBarterCarList/" + this.customData + "/" + this.pageLim + "/" + this.pageNum, {});
      return res.data;
    },

    async resetItems() {
      this.pageNum = 1;
      this.items = await this.getItems();
    },

    reachedBottom() {
      // if (this.isMobile && this.pageNum < this.totalPage) this.next();
    },

    async next() {
      this.pageNum++;
      let items = await this.getItems();
      this.items = [...this.items, ...items];
    },

    prep(data) {
      data.items.forEach((z) => {
        z.pinfo.forEach((i) => {
          if (i.pcomp_tp == 3 && z["p" + i.id]) {
            let photos = z["p" + i.id];
            i.val = photos;
            i.total = photos.length;
          } else {
            i.val = z["p" + i.id + "_val"] ? z["p" + i.id + "_val"] : z["p" + i.id];
            if (i.conver && i.val) i.val = this.converters(i.conver, i.val);
            i.dep = z.dep;
          }
        });
        z.date = this.$date.unix(z.rdate).fromNow(true);
      });
      this.totalPage = data.totalPage;
      return data;
    },

    async syncItems() {
      this.loading = true;
      this.items = [];
      let data = [];

      if (this.nameSrc == "related_items") {
        let fetchData = await this.fetchRelatedItems(this.customData.obj, this.customData.zid);
        if (fetchData.items.length > 1) data = this.prep(fetchData).items;
      }

      if (this.nameSrc == "most_viewed") {
        let fetchData = await this.fetchMostViewed(this.customData ?? null);
        data = this.prep(fetchData).items;
      }

      if (this.nameSrc == "barter_car") {
        let fetchData = await this.fetchBarterCar();
        data = this.prep(fetchData).items;
      }

      this.items = Object.freeze(data) ?? [];
      // this.items = data ?? [];
      this.$emit("fetchedItemsLength", this.items.length);

      this.loading = false;
    },
  },

  watch: {
    loading() {
      if (this.loading)
        this.$toast.loading({
          duration: 0,
          forbidClick: false,
        });
      else this.$toast.clear();
    },
    customData() {
      this.pageNum = 1;

      this.syncItems();
    },
    async pageNum() {
      await this.syncItems();
      // this.$scrollTo("#list" + this.nameSrc, 0);
      // console.log(this.$refs);
      // this.$refs["list" + this.nameSrc].scrollTop = 100;
      // this.$bus.emit("scrollTo", "#list" + this.nameSrc);

      // var nyedva = document.getElementById("list" + this.nameSrc);
      // nyedva.scrollTop = 50;
    },
    refreshZarList() {
      this.syncItems();
    },
    nameSrc: {
      immediate: true,
      async handler() {
        this.syncItems();
        this.pageNum = 1;
      },
    },
  },
  computed: {
    refreshZarList() {
      return this.$store.state.user.refreshZarList;
    },
    gLoading: {
      get() {
        return this.$store.state.gLoading;
      },
      set(val) {
        this.$store.commit("gLoading", val);
      },
    },
    user() {
      return this.$store.state.auth.status.logged ? this.$store.state.auth.user : false;
    },
    devid() {
      return this.getLocal("devid", true);
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont {
  /* position: relative; */
  /* padding-bottom: 20px; */
}
.page_num {
  margin-right: 5px;
  background-color: #f1f1f1;
  border: solid 1px #f1f1f1;

  padding: 4px 8px;
  border-radius: 2px;
}

.selected {
  background: none;
  border: solid 1px #e2e2e2;
  padding: 5px 10px;

  /* font-weight: 600; */
}

@media only screen and (min-width: 600px) {
  .page_num:hover {
    background-color: #e2e2e2;
    border: solid 1px #e2e2e2;

    cursor: pointer;
  }
  .selected:hover {
    background: none;
  }
}
</style>
