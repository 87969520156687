<template>
  <div class="cont_favs">
    <user-zar-list :nameSrc="'favs'" v-on:clickedItem="clickedItem($event)" miniMode :pageLim="30" />
    <div v-if="data > 5" class="link ta-c pt-1 pb-1" @click="seeMore">Бүгдийг харах ({{ data - 5 }})...</div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import userZarList from "@/comp/user/zarList.vue";

export default {
  name: "user-favs",
  components: { userZarList },
  props: {
    data: Number,
  },
  data() {
    return {};
  },
  methods: {
    clickedItem(items) {
      this.$store.commit("zar/itemSource", { name: "Тэмдэглэсэн зарууд", src: "favs", data: items });
    },
  },
  computed: {
    logged() {
      return this.$store.state.auth.status.logged;
    },
    favs() {
      return this.$store.state.user.favs;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_favs {
  text-align: left;
  padding: 10px;
  padding-top: 0;
  position: relative;
}

.cont_favs .close {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 1000;
  opacity: 0.2;
}

.cont_favs .close:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
