<template>
  <div class="cont_ui_location">
    <div class="fw-300 mt-0">Байршил</div>
    <l-map :style="{ height: mapHeight + 'px' }" :zoom="12" :center="coord" class="map">
      <l-tile-layer :url="enSatView ? urlSat : url"></l-tile-layer>
      <l-marker :lat-lng="coord" :icon="licon"> <l-tooltip></l-tooltip></l-marker>
      <l-control-fullscreen position="topleft" :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
      <l-control :position="'topleft'"
        ><div class="satView"><ui-icon @click="enSatView = !enSatView" :name="enSatView ? 'i-map2' : 'i-satellite'" size="xxl" /></div
      ></l-control>
    </l-map>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip, LControl } from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";

//import rPip from "robust-point-in-polygon";

export default {
  name: "ui-location",
  components: {
    LMap,
    LTileLayer,
    LControlFullscreen,
    LMarker,
    LTooltip,
    LControl,
  },
  props: {
    coord: Array,
  },
  data() {
    return {
      enSatView: false,
      mapHeight: 300,
      licon: L.icon({
        iconUrl: "/icon/pin.svg",
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      urlSat: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
    };
  },
  methods: {
    // zoomUpdated(zoom) {
    //   this.$emit("zoomUpdated", zoom);
    // },
    // centerUpdated(center) {
    //   this.$emit("clickedItem", JSON.stringify(center));
    // },
    // boundsUpdated(bounds) {
    //   this.bounds = bounds;
    // },
  },
  watch: {},
  mounted() {
    /*
    let rPolygon = [
      [1, 1],
      [1, 2],
      [2, 2],
      [2, 1],
    ];
    let rPoint = [100000, 10000];
    console.log(rPip(rPolygon, rPoint));*/
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_location {
  margin: auto;
  padding-bottom: 10px;
}
.map {
  border-radius: 5px;
}
.satView {
  border: solid 2px #bebebe;
  background-color: #fff;
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 3px;
}
.satView:hover {
  cursor: pointer !important;
  background-color: #f1f1f1;
}
</style>
