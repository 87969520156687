<template>
  <div>
    <div v-if="emp && pob.active" class="cont_zar_filter_sub" :class="{ emp: emp && pob.active, [pob.ptype_name + '_prop']: true, first: contInd == 0, main: pob.main, child, under: pob.under, selected_mobile: itemSelected, last_selected: last && last.prop.id == pob.id }">
      <div @click="showSelection()">
        <div :class="{ noscroll: actItems < maxItem }">
          <div class="pob_name" :class="{ setBlack: mOver, [pob.ptype_name + '_pname']: true, disabled: singleItem }">
            {{ pob.name }}
            <!-- <span v-if="haveChilds" class="have_child">&#10095;</span> -->
            <span class="count">{{ items_with_com_with_last.length }}</span>
          </div>
          <span v-if="!itemSelected && !singleItem" class="choose">Сонгох</span>
          <span v-if="itemSelected" class="selected">{{ itemSelected.name }}</span>
          <span v-if="singleItem" class="disabled">{{ items_with_znum[0].name }}</span>
        </div>
      </div>
      <slot v-if="itemSelected" :name="'psel' + itemSelected.id" />
      <slot v-if="itemSelected" :name="'under' + itemSelected.id" />
    </div>
    <ui-select-drawer
      :title="pob.name"
      :visible="selection"
      :compName="'filterSelection' + pob.id"
      :unit="pob.unit"
      showZnum
      v-on:close="selection = false"
      :items="items_with_com_with_last"
      location="bottom"
      v-on:clickedItem="clickedItem($event.id, $event.name)"
      showCloseButton
      :collect="itemSelected"
    />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectDrawer from "@/comp/ui/selectDrawer.vue";

export default {
  name: "zar-filter-sub-mobile",
  props: { child: Boolean, items: Array, pob: Object, last: Object, contInd: Number, haveChilds: Boolean, filLength: Number, zarTotal: Number, mOver: Boolean },
  components: {
    uiSelectDrawer,
  },
  data() {
    return {
      selected: "all",
      showAll: false,
      maxItem: 9,
      selection: false,
    };
  },
  methods: {
    showSelection() {
      if (this.singleItem) return;
      this.selection = true;
    },
    clickedItem(id, name) {
      if (id != "sep") {
        this.$emit("clickedItem", { id, name });
        this.selection = false;
      }
    },
    sort() {
      this.items.sort((a, b) => a.znum.localeCompare(b.znum));
    },
  },
  watch: {
    selected() {
      this.clickedItem(this.selected.id, this.selected.name);
    },
  },
  computed: {
    singleItem() {
      let itemAct = this.pob.sel.filter((s) => s.znum);
      if (itemAct.length == 1 && itemAct[0].znum < this.zarTotal) return false;
      return !this.pob.main && !this.haveChilds && itemAct.length == 1 && this.pob.id != (this.last && this.last.prop.id) && !this.pob.sel.find((s) => s.selected);
    },
    itemSelected() {
      return this.items.find((e) => e.selected);
    },
    emp() {
      return this.items.find((s) => s.znum > 0);
    },
    actItems() {
      return this.items.filter((s) => s.znum > 0).length;
    },
    // items_com() {
    //   return this.items.filter((e) => e.com);
    // },
    items_com() {
      return this.sortArr(
        this.items.filter((e) => e.com && e.znum),
        "znum",
        2
      );
    },
    items_with_com() {
      return this.items_com.concat(this.items);
    },
    items_with_com_with_last() {
      return this.items_com.concat(this.items).filter((e) => {
        if (e.znum) return true;
        if (this.last && this.last.prop.id == this.pob.id) return true;
        return false;
      });
    },
    items_with_com_znum() {
      return this.items_com.concat(this.items).filter((e) => e.znum);
    },
    items_with_znum() {
      return this.items.filter((e) => e.znum);
    },
    scr() {
      return this.$store.getters.scr;
    },
  },
  mounted() {
    // console.log(this.pob);
    //console.log(this.$refs);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_filter_sub {
  box-sizing: border-box;
  width: 100%;
  padding: 5px 10px !important;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: 2px;
  background-color: #f3f3f3;
}
.selected_mobile {
  background-color: var(--color_selected) !important;
}

.selected_mobile .pob_name {
  font-size: 13px !important;
}

.last_selected {
  background-color: rgb(187, 211, 255) !important;
}

.cont_zar_filter_sub.model_prop {
  background-color: #f3f3f3;
}

.cont_zar_filter_sub {
  text-align: left;
  display: block;
  margin-bottom: 10px;
  width: fit-content;
  transition: all 150ms;
  padding: 5px 10px;
}

.cont_item {
  transition: all 200ms;
}

.hide {
  display: none;
}
.other {
  color: var(--color_link) !important;
}
.other:hover {
  background-color: #ebf6fc !important;
}
.other.selected {
  border-radius: 2px;
}
.child.selected {
  padding: 0 2px;
}
.child {
  margin: 5px 0 2px 0px;
}
.child .item {
  padding-left: 5px;
  font-size: 13px !important;
}

.child .pob_name {
  font-size: 12px !important;
  padding: 3px 0 0 0 !important;
  margin: 0 !important;
}

.pob_name {
  padding: 0px 0px 0px 0px;
  color: var(--color_head);
  font-weight: 700;
  display: block;
  transition: all 0.2s;
  width: fit-content;
  opacity: 1;
  text-transform: uppercase;
  font-size: 13px;
}

.pob_name .count {
  color: #6b6b6b;
  margin-left: 2px;
  font-weight: var(--base_weight);
  font-size: 11px;
}
.empty {
  display: none;
}
.empty img {
  opacity: 0.2;
}
.disabled,
.disabled span {
  opacity: 0.8;
  color: #6e6e6e !important;
}
.disabled:hover {
  cursor: text !important;
  background-color: transparent !important;
}
.icon {
  position: absolute;
  margin-left: -18px;
  top: 3px;
}

.box {
  display: block;
  width: fit-content;
  border-radius: 1px;
  text-align: left;
  display: block;
  padding: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.com .count {
  font-weight: var(--base_weight) !important;
  background-color: #ececec;
  color: #5e5e5e;
  padding: 2px 3px 1px 3px;
  border-radius: 3px;
}

.selected {
  font-weight: 600;
  font-size: 16px;
}

.item .count,
.selected .count {
  font-size: 10px !important;
  transition: opacity 0.5s;
  position: relative;
  font-weight: 300;
  left: 0px;
  top: -1px;
}
.selected .count {
  color: #474747;
}
.selected_icon {
  position: relative;
  top: 1px;
  left: 5px;
  max-height: 11px;
  max-width: 11px;
  padding-right: 5px;
  transition: all 500ms;
  animation: fade_in 200ms;
  animation-iteration-count: 1;
}
.selected:hover {
  background-color: var(--color_selected) !important;
}

.last_common_item {
  border-bottom: dashed 1px #acacac;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.selected span::before,
.selected span::after {
  color: #000000;
}

.cont_select {
  position: relative;
}
.button {
  min-width: 80px;
}
.select {
  z-index: 10;
  opacity: 0;
  top: 0px;
  position: absolute;
}
.under .empty {
  display: none;
}

.under .item {
}
.seperator {
  color: #ccc;
}

.have_child {
  position: relative;
  top: 0px;
  left: 5px;
  font-weight: 700;
  font-size: 11px;
  transform: rotate(90deg);
  /* filter: saturate(0); */
}

@keyframes fade_in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 100;
    transform: scale(1);
  }
}
</style>
