<template>
  <div class="cont_ui_location_area">
    <div class="fw-300 mt-0 ml-2">
      Байршил : <b>{{ area.name }}</b>
    </div>
    <ui-loc-grab-detect disableDrag :centerMarker="false" :forceSel="{ ref: 'ref_location_area', sel: { id: area.id, name: area.name } }" :pins="[]" :mapHeight="300" v-on:clickedItem="updatedMapLoc($event)" :inputLocCode="false" class="map" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiLocGrabDetect from "@/comp/ui/locGrabDetect.vue";

export default {
  name: "ui-location-area",
  components: {
    uiLocGrabDetect,
  },
  props: {
    area: Object,
  },
  data() {
    return {};
  },
  mounted() {
    // console.log("mounted");
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_location_area {
  margin: auto;
}
.map {
  border-radius: 5px;
}
.satView {
  border: solid 2px #bebebe;
  background-color: #fff;
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 3px;
}
.satView:hover {
  cursor: pointer !important;
  background-color: #f1f1f1;
}
</style>
