<template>
  <ui-checkbox :items="items" :collect="collect" :unit="pob.unit" v-on:clickedItem="clickedItem($event)" />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiCheckbox from "@/comp/ui/checkbox";
export default {
  name: "zar-add-checkbox",
  props: { items: Array, collect: { type: [String, Object, Boolean, Number, Array] }, pob: Object },
  components: { uiCheckbox },
  data() {
    return {};
  },
  methods: {
    clickedItem(ob) {
      this.$emit("clickedItem", ob);
    },
  },
  watch: {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
input {
  margin-left: 5px;
}
.label {
  position: absolute;
  margin: -2px 0 0 3px;
}
</style>
