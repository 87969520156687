<template>
  <div>
    <!-- <div v-if="props.length">{{ props.find((e) => e.id == 6).name }}: {{ props.find((e) => e.id == 6).opt }}</div> -->
    <div v-if="showSpinner" class="ta-c pt-5 mt-5">
      Зарыг боловсруулж байна...
      <div class="spinner"></div>
    </div>
    <div class="cont_main_add" v-show="!submitted">
      <div v-if="userMerit == 0" class="h2 w-100 ta-c pt-3">
        Таны дугаар блоклогдсон байна<br />
        Та энэ дугаараар дахин зар оруулах боломжгүй
      </div>
      <div v-else>
        <div class="title_page ta-c pt-5">Зар оруулах</div>
        <div class="row" style="margin-bottom:5px">
          <div class="cont_pname pt-3 "><span class="pname h2">Ангилал</span></div>
          <div class="cont_obj_sel">
            <div class="obj_sel">
              <ui-button v-if="objSel" v-on:clicked="clickedObjSel()" :text="objSel.name" />
              <span class="link" v-if="objSel" @click="clickedObjSel()"> Дахин эхлэх</span>

              <zar-bread v-if="objSel" :srcObj="objSel" :srcCat="getCats(objSel)" class="mt-3" />
            </div>
            <zar-cat v-if="catData" hideCount onlyCat v-on:clickedObj="objClicked($event)" v-show="!objSel" class="zar_cat pt-1" />
          </div>
        </div>

        <div v-if="addText" class="cont_suggest_text ani_sdown">
          <div class="suggest_text" v-html="addText"></div>
        </div>

        <div v-if="objSel" class="cont_suggest_text ani_sdown">
          <div class="warning_text">Зар Монгол дахь <b>бодит зурагтай</b> байх, <b>хуурамч үнэ</b> тавихгүй, <b>давхардуулахгүй</b> байх. Хэрвээ та ямар нэгэн зөрчилтэй зар оруулахгүй бол таны зар цаашид <b>урьдчилан шалгагдалгүй</b> шууд орж байна.</div>
        </div>

        <div v-if="objSel" class="cont_suggest_text ani_sdown">
          <div class="suggest_text"><b>Бичлэгтэй</b> оруулвал бусад зараас илүү <b>тодорч</b> харагдана.</div>
        </div>

        <!-- <div v-if="objSel && isMobile" class="cont_suggest_text">
          <div class="warning_text">Зарим Андройд утаснаас зар ороход алдаа гарч байгаа учир тухай бүрд нь бидэнд мэдэгднэ үү.</div>
        </div> -->

        <!-- <ch-drawer :visible.sync="showMenu.addcat" area="300px" title="Ангилал сонгох" :blur="false" location="right" @open="$store.commit('addOpenDrawers', 'showMenuAddCat')" @close="$store.commit('removeOpenDrawers', 'showMenuAddCat')" destroy-on-close>
          <zar-cat v-if="catData" hideCount onlyCat v-on:clickedObj="objClicked($event)" class="zar_cat" />
          <div v-if="scr == 1" class="return_button_right" @click="showMenu = { addcat: false }"><ui-icon class="back_icon" angle="90deg" name="i-close4" size="xxxxl" /></div>
          <div class="return_button_top" @click="showMenu = { addcat: false }"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
        </ch-drawer> -->
        <div v-if="!submitted">
          <template v-for="(p, i) in props">
            <div class="row" v-if="p.active && p.visible" :key="'add' + i" :class="{ sep: p.opt && p.opt.horiLine && p.visible }">
              <div v-if="p.opt && p.opt.horiLine && p.visible" class="spacer"></div>
              <div v-if="p.active && p.visible" class="cont_pname ani_sdown" :class="{ act: p.visible && p.active && !p.collect && p.req }">
                <div class="pname" :class="{ act_one: p.visible && p.active && !p.collect && !p.req, act_checkbox: p.pcomp == 9, bold: p.collect }">{{ p.name }}<span v-if="p.req && !p.collect" class="required">*</span></div>
              </div>
              <div class="pcomp ani_sdown">
                <comp
                  :zind="100 - i"
                  :forceSel="p.force"
                  :is="p.pcomp_name"
                  :compName="'comp' + i"
                  :opt="p.opt"
                  :items="p.mSel ? p.mSel : p.sel"
                  :obj="objSel"
                  :pob="p"
                  :zid="zid"
                  :collect="p.collect"
                  :markers="p.mapMarkers"
                  v-on:clickedItem="sClicked(p.id, $event)"
                  v-on:clickedMarker="clickedMarker(p.id, $event)"
                  v-on:mouseOver="sMouseOver(p.id, $event)"
                  v-on:loaded="sLoaded(p.id, $event)"
                  v-on:imagePresizingDone="imagePresizingDone = true"
                  emojiEnable="false"
                />
                <ui-reflow>
                  <div class="warning_text mt-1 mb-3 ta-l" v-if="p.ptype_name == 'phone' && p.visible && phoneExist">
                    Энэ утсан дээр бүртгэлтэй хэрэглэгч олдлоо. <br />Та <b>бүртгэлтэй</b> нууц үгээ оруулна уу. <br />Хэрвээ нууц үгээ <b>сэргээх</b> бол <span class="link td-un" @click="restorePass()">энд дарна уу</span>
                  </div>
                </ui-reflow>
                <ui-reflow>
                  <ui-button v-if="p.ptype_name == 'pass' && p.visible && phoneExist && p.collect" v-on:clicked="loginUser()" text="Нэвтэрч илгээх" pulsate tp="special" />
                  <ui-button v-if="p.ptype_name == 'pass' && p.visible && !phoneExist && p.collect" v-on:clicked="createNewUser()" text="Бүртгэл үүсгэж илгээх" pulsate tp="special" />
                </ui-reflow>
              </div>
            </div>
          </template>
        </div>
        <!-- </div> -->

        <div class="row ani_pop" v-if="complete && logged">
          <div class="cont_pname ani_sdown">
            <div class="pname">Утас:</div>
          </div>
          <div class="pcomp user_phone">{{ user.phone }}</div>
        </div>
      </div>
    </div>
    <div v-if="!props.find((e) => !e.collect && e.active && e.req) && props.filter((el) => el.req && el.active).length && !submitted" class="cont_suggest_text ani_sdown">
      <div class="info_text">Та зарын бүх талбаруудаа дахин нэг <b>нягтлаарай</b>. Зарын ихэнх талбарыг дараа дахин өөрчлөх боломжгүй.</div>
    </div>
    <div class="cont_sbutton" v-if="!submitted">
      <ui-button
        v-if="objSel"
        :text="complete ? 'Илгээх' : ''"
        tp="special"
        class="mt-1 clear sbutton"
        :icon="complete ? 'i-send' : ''"
        :disabled="disBut"
        :class="{ act_button: complete }"
        :style="{ background: 'linear-gradient(to right, #00b7ff ' + comPerTween + '%, #fff 0%)' }"
        :pulsate="complete ? true : false"
        v-on:clicked="submit()"
      />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import UserService from "@/service/user";

import zarCat from "@/comp/zar/cat.vue";
import VueMixinTween from "vue-mixin-tween";
import zarBread from "@/comp/zar/bread.vue";

import addYoutube from "./youtube.vue";
import addInput from "./input.vue";
import addInputPass from "./inputPass.vue";
import addInputPrice from "./inputPrice.vue";
import addInputSel from "./inputSel.vue";
import addTextbox from "./textbox.vue";
import addSelect from "./select.vue";
import addSelectValue from "./selectValue.vue";
import addSelectHori from "./selectHori.vue";
import addSelectMulti from "./selectMulti.vue";
import addCheckbox from "./checkbox.vue";
import addLocGrab from "./locGrab.vue";
import addLocSelect from "./locSelect.vue";
import addVideoUpload from "./videoUpload.vue";
import addPhotoUpload from "./photoUpload.vue";
import addSelectMultiValue from "./selectMultiValue.vue";

// const addPhotoUpload = () => import(/* webpackChunkName: "pu" */ "./photoUpload.vue");

export default {
  name: "zar-add-index",
  components: {
    zarCat,
    zarBread,
    addYoutube,
    addPhotoUpload,
    addVideoUpload,
    addInput,
    addInputSel,
    addSelect,
    addSelectValue,
    addSelectMultiValue,
    addSelectHori,
    addSelectMulti,
    addTextbox,
    addCheckbox,
    addLocGrab,
    addLocSelect,
    addInputPass,
    addInputPrice,
  },
  metaInfo() {
    return {
      title: "Зар оруулах " + (this.objSel ? "- " + this.objSel.name : ""),
      meta: [
        { name: "Зар оруулах хэсэг" },
        { property: "og:title", content: "Зарын цогц систем" },
        { property: "og:site_name", content: "Zaraa.mn" },
        { property: "og:description", content: "Бүх төрлийн зарын вэб, үл хөдлөх, машин, гар утас, компьютер" },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://zaraa.mn" },
        { property: "og:image", content: "https://zaraa.mn/images/icons-512.png" },
      ],
    };
  },
  data() {
    return {
      objSel: null,
      dep: null,
      props: [],
      propsBackup: [],
      propsBackupCus: [],
      propsModCus: [],
      zid: null,
      images: [],
      disBut: false,
      userMerit: null,
      forceSelWaiting: [],
      submitted: false,
      showSpinner: false,
      addText: null,
      // lockPhotoManage: false,
      // dialog: null,
      // preSizeLoading: false,
      imagePresizingDone: false,
      sentPhotoPresizeRequest: false,
    };
  },
  methods: {
    restorePass() {
      this.$bus.emit("openModal", { name: "restorePass", dataSet: { phone: this.userPhone } });
    },
    addField(name) {
      return { id: 888888888, name: "+ Шинээр " + name.toLowerCase() + " нэмэх", special: true };
    },
    getCats(obj) {
      if (!obj) return [];
      let data = this.catData;
      let parCat = data.objCat.find((e) => e.obj == obj.id);
      return this.getParCat(data.cat, parCat.cat).reverse();
    },
    getParCat(data, cat) {
      let names = [];
      let found = data.find((e) => e.id == cat);
      names.push({ id: found.id, name: found.name });
      return found.par ? names.concat(this.getParCat(data, found.par)) : names;
    },
    clickedMarker(pid, marker) {
      // console.log("marker clicked", pid, marker);
      this.props.find((p) => {
        if (p.id == marker.pid) {
          if (p.collect) p.collect = "";
          this.$nextTick(() => (p.force = marker.id));
          return true;
        }
      });
    },
    executeWaitingForceSel() {
      this.forceSelWaiting.forEach((f, fi) => {
        this.props.find((p) => p.id == f.pid && p.sel.find((s) => s.id == f.sid) && (p.force = f.sid) && this.removeItemByIndex(this.forceSelWaiting, fi));
      });
    },
    funcShowMapMarkers(target, pid, sel) {
      let tprop = this.props.find((e) => e.id == target);
      let markers = sel.filter((e) => e.id != 888888888).map((e) => ({ pid, id: e.id, name: e.name, coord: JSON.parse(e.coord), grp: e.grp }));
      tprop.mapMarkers = markers;
    },
    funcSetCoord(target, sel) {
      if (sel.coord)
        this.props.find((p) => {
          if (p.id == target) {
            p.collect = sel.coord;
            return true;
          }
        });
    },
    funcForceSelLoc(target, sel) {
      this.forceSelWaiting = [];
      // console.log(target, sel);
      target.forEach((t) => {
        // let found = sel.find((s) => {
        sel.find((s) => {
          if (s.ref == t.ref)
            return this.props.find((p) => {
              if (p.id == t.prop) {
                let foundSel = p.sel.find((f) => f.id == s.id);
                if (foundSel) {
                  p.force = s.id;
                } else this.forceSelWaiting.push({ pid: t.prop, sid: s.id });
                return true;
              }
            });
        });

        // if (!found) {
        //   this.props.find((p) => {
        //     if (p.id == t.prop) {
        //       p.collect = "";
        //       p.force = 0;
        //     }
        //   });
        // }
      });
    },
    funcUsagePoint(usageProp) {
      let usage = this.props.find((e) => e.id == usageProp);
      if (usage.collect) return usage.collect.name.charAt(0);
    },
    funcFilterHigher(pid, min, notEqualCurrentVal = false) {
      let tProp = this.props.find((e) => e.id == pid);
      min = notEqualCurrentVal ? min + 1 : min;
      tProp.collect = "";
      if (tProp.pcomp == 15) tProp.opt.range.min = min;
      else tProp["mSel"] = tProp["sel"].filter((e) => parseInt(e.name) >= min);
    },
    funcFilterLower(pid, max, notEqualCurrentVal = false) {
      let tProp = this.props.find((e) => e.id == pid);
      max = notEqualCurrentVal ? max - 1 : max;
      tProp.collect = "";
      if (tProp.pcomp == 15) tProp.opt.range.max = max;
      else tProp["mSel"] = tProp["sel"].filter((e) => parseInt(e.name) <= max);
    },
    funcCalcUnitPrice(quantity, totalPrice, target, integer = false, lowerDigit = false) {
      if (lowerDigit) totalPrice = totalPrice * 1000;
      // console.log(quantity, totalPrice, target);

      let tProp = this.props.find((e) => e.id == target);

      if (!quantity || !tProp || !totalPrice) return;
      let unitPrice;

      unitPrice = (totalPrice / quantity).toFixed(1);
      // console.log(unitPrice);

      if (integer) unitPrice = Math.round(unitPrice);
      // console.log("unit price:", unitPrice, integer);

      // if (integer) tProp.collect = integer ? Math.round((totalPrice / quantity).toFixed(1)) : (totalPrice / quantity).toFixed(1);

      tProp.collect = unitPrice;
      tProp.visible = 1;
    },
    funcUsedYear(year, month) {
      let yearProp = this.props.find((e) => e.id == year);
      let monthProp = this.props.find((e) => e.id == month);
      if (yearProp && monthProp && monthProp.collect && yearProp.collect) {
        let total_month = new Date().getMonth() + 1 - parseInt(monthProp.collect) + 12 * (new Date().getFullYear() - parseInt(yearProp.collect));
        return (total_month / 12).toFixed(1);
      }
    },
    funcMakeYear(year, cProp, cSel) {
      let yearProp = this.props.find((e) => e.id == year);
      let condNew = this.props.find((e) => e.id == cProp);
      if (yearProp && condNew.collect.id != cSel) {
        let total_month = new Date().getMonth() + 1 + 12 * (new Date().getFullYear() - parseInt(yearProp.collect));
        return (total_month / 12).toFixed(1);
      }
    },
    funcShowMapOverlay(target, ref, sel, coordOnly = false) {
      this.props.forEach((p) => {
        if (p.id == target) {
          p.force = { ref, sel, action: "clicked", coordOnly };
        }
      });
    },
    clickedObjSel() {
      this.reset();
      // this.scr == 1 && (this.showMenu = { addcat: true });
    },
    reset() {
      this.zid = null;
      this.props = [];
      this.images = [];
      this.objSel = null;
      this.dep = null;
      this.disBut = false;
      this.submitted = false;
      this.addText = null;
      this.imagePresizingDone = false;
      this.sentPhotoPresizeRequest = false;
      this.propsBackup = [];
      this.propsBackupCus = [];
      this.propsModCus = [];
      this.userMerit = null;
      this.forceSelWaiting = [];
    },
    showZar() {
      this.$router.push({ path: "/zar/view?o=" + this.objSel.id + "&z=" + this.zid });
      this.clickedObjSel();
    },
    addAgain() {
      this.objClicked(this.objSel);
    },
    loginUser() {
      if (this.userPhone && this.userPass) this.$bus.emit("openModal", { name: "userLogin", dataSet: { phone: this.userPhone, pass: this.userPass, returnPage: "noRedirect" } });
    },
    createNewUser() {
      if (this.userPhone && this.userPass) this.$bus.emit("openModal", { name: "userRegister", dataSet: { phone: this.userPhone, pass: this.userPass, returnPage: "noRedirect" } });
    },
    submit() {
      if (!this.logged)
        if (!this.phoneExist) this.createNewUser();
        else {
          this.loginUser();
          return;
        }

      if (!this.complete) {
        this.showMessage("warning", "Мэдээлэл дутуу байна", 3000);
        return;
      }

      // if (!this.logged) return;

      this.showSpinner = true;
      this.disBut = true;
      this.submitted = true;

      // if (!this.logged) this.createNewUser();

      // setTimeout(() => {
      let clone = [];
      this.props.forEach((p) => {
        if (p.ptype_name == "body" || p.ptype_name == "title") {
          let pat1 = /[789]\d{3}\d{4}/g;
          let pat2 = /[789]\d{3}-\d{4}/g;
          p.collect = p.collect.replace(new RegExp(pat1, "gm"), "");
          p.collect = p.collect.replace(new RegExp(pat2, "gm"), "");
        }
        if (p.sys && p.func && p.func.length) {
          p.func
            .filter((fi) => fi.trigger == "submit")
            .forEach((f) => {
              if (f.name == "funcUsedYear") {
                p.collect = this.funcUsedYear(f.year, f.month);
                // console.log(f);
              }
              if (f.name == "funcMakeYear") p.collect = this.funcMakeYear(f.madeYear, f.condProp, f.condSel);
              if (f.name == "funcUsagePoint") p.collect = this.funcUsagePoint(f.usageProp);
            });
        }
        if (p.pcomp == 1 && p.pcomp.length) {
          let images = [];
          p.collect.forEach((e) => {
            images.push({ id: e.id, name: e.name, title: e.title, rotate: e.rotate, size: e.origSize });
          });
          clone.push({ pid: p.id, collect: images });
        } else if (p.pcomp == 11 && p.collect.length) {
          let found = p.collect.find((e) => e.coord);
          if (found) clone.push({ pid: p.id, collect: { lat: found.coord.lat, lng: found.coord.lng } });
        } else {
          if (p.collect) {
            clone.push({
              pid: p.id,
              ref: p.ref,
              collect: p.collect,
            });
          }
        }
      });
      clone = { zid: this.zid, obj: this.objSel.id, data: clone };

      this.axios
        .post("/zar/item/addItem", clone)
        .then((res) => {
          this.showSpinner = false;
          this.$modal.show("dialog", {
            clickToClose: false,
            text: res.data.message,
            buttons: [
              {
                title: "ОК",
                handler: () => {
                  this.$modal.hide("dialog");
                  this.$bus.emit(res.data.status ? "zarAddSubmit" : "zarAddFailedSubmit");
                },
              },
            ],
          });
        })
        .catch((err) => {
          console.log(err);
        });
      // }, 2000);
    },
    async fetchObjInfo(id = 0) {
      return await this.axios
        .get("/zar/object/getObjProps/" + id)
        .then((res) => res.data)
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchZid(id) {
      return await this.axios
        .get("/zar/object/getZid/" + id)
        .then((res) => res.data)
        .catch((err) => {
          console.log(err);
        });
    },
    restorePropSel(modified, backup) {
      // console.log("backup:", backup);

      modified.forEach((m) => {
        this.props.forEach((p) => {
          if (p.id == m) {
            p.sel = this.deepClone(backup.find((el) => el.id == m).sel);
            // if (p.id == 6) console.log("restored sel:", this.deepClone(backup.find((el) => el.id == m).sel));
            if (p.opt && p.opt.range) p.opt.range = this.deepClone(backup.find((el) => el.id == m).opt.range);
            p.collect = "";
            p.visible = 0;
            p.active = 1;
          }
        });
      });
      // console.log(
      //   "restored custom:",
      //   this.props
      //     .filter((e) => e.id == 6)
      //     .map((e) => {
      //       return { id: e.id, name: e.name, sel: e.sel };
      //     })
      // );
      modified = [];
    },
    async getCusSel(modified, pid, sid) {
      let url = "/zar/object/getCusSel/" + this.dep + "/" + this.objSel.id + "/" + pid + "/" + sid;
      await this.axios
        .get(url)
        .then((res) => {
          let cus = res.data;
          if (!cus.length) return;
          cus.forEach((c) => {
            this.props.forEach((p) => {
              if (p.id == c.tprop) {
                if (c.tprop_sel) p.sel.forEach((s) => s.id == c.tprop_sel && (s.com = c.com));
                if (c.tval_min && c.tval_max) {
                  p.opt.range.min = c.tval_min;
                  p.opt.range.max = c.tval_max;
                }
                if (c.tval) {
                  if (!p.opt.range.cusVals) this.$set(p.opt.range, "cusVals", []);
                  if (!p.opt.range.cusVals.find((e) => e.val == c.tval)) p.opt.range.cusVals.push({ val: c.tval, com: c.com });
                }
                if (!modified.find((e) => e == c.tprop)) modified.push(c.tprop);
              }
            });
          });
          modified.forEach((m) => {
            this.props.forEach((p) => {
              if (p.id == m) {
                let com = p.sel.find((e) => e.com > 1);
                if (com) {
                  p.sel = p.sel.filter((el) => el.com == com.com);
                  switch (com.com) {
                    case 2:
                      p.collect = "";
                      break;
                    case 3:
                      p.visible = 1;
                      break;
                    default:
                      break;
                  }
                }
              }
            });
          });
          if (pid && sid) this.propsBackupCus = this.deepClone(this.props);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // async getCusSel_backup(modified, pid, sid) {
    //   // console.log(this.props);
    //   let url = "/zar/object/getCusSel/" + this.dep + "/" + this.objSel.id + "/" + pid + "/" + sid;
    //   await this.axios
    //     .get(url)
    //     .then((res) => {
    //       let cus = res.data;
    //       if (!cus.length) return;

    //       cus.forEach((c) => {
    //         this.props.forEach((p) => {
    //           if (p.id == c.tprop) {
    //             if (c.tprop_sel) p.sel.forEach((s) => s.id == c.tprop_sel && (s.com = c.com));
    //             if (c.tprop_val) {
    //               // p.opt.range.cusVals
    //             }
    //             if (c.tprop_sel_min || c.tprop_sel_max) {
    //               let val_min = c.tprop_sel_min ? parseInt(p.sel.find((e) => e.id == c.tprop_sel_min).name) : 0;
    //               let val_max = c.tprop_sel_max ? parseInt(p.sel.find((e) => e.id == c.tprop_sel_max).name) : 999999999;
    //               p.sel.forEach((s) => parseInt(s.name) >= val_min && parseInt(s.name) <= val_max && (s.com = c.com));
    //             }
    //             if (c.tval_min && c.tval_max) {
    //               p.opt.range.min = c.tval_min;
    //               p.opt.range.max = c.tval_max;
    //             }
    //             if (c.tval) {
    //               this.$set(p.opt.range, "cusVals", c.tval.split(","));
    //               this.$set(p.opt.range, "com", c.com);
    //             }
    //             if (!modified.find((e) => e == c.tprop)) modified.push(c.tprop);
    //           }
    //         });
    //       });
    //       modified.forEach((m) => {
    //         this.props.forEach((p) => {
    //           if (p.id == m) {
    //             let com = p.sel.find((e) => e.com > 1);
    //             if (com) {
    //               p.sel = p.sel.filter((el) => el.com == com.com);
    //               switch (com.com) {
    //                 case 2:
    //                   p.collect = "";
    //                   break;
    //                 case 3:
    //                   p.visible = 1;
    //                   break;
    //               }
    //             }
    //           }
    //         });
    //       });
    //       if (pid && sid) this.propsBackupCus = this.deepClone(this.props);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    getFirstRelData() {
      this.props.forEach((e) => e.ref && !e.crel && this.getRelData(e.id, 0, e.ref, e.sort));
      // console.log("triggered first get relData");
    },
    getRelData(pid, sid, sort) {
      // this.props.find((e) => e.id == pid).sel = [];

      // console.log("pid:", pid, "sid:", sid);

      let p = this.props.find((e) => e.id == pid);
      // console.log("sel:", p.sel);
      // if (p.sel.length) {
      //   if (!p.sel.rel) return;
      //   console.log(p.sel.rel);
      // }

      let url = "/zar/object/getRelData/" + this.objSel.id + "/" + pid + "/" + sid + "/" + 0 + "/" + sort;

      this.axios
        .get(url)
        .then((res) => {
          if (p) {
            let sel = res.data;
            if (p.sort) sel = this.sortArr(sel, "name", p.sort);
            let com = sel.find((s) => s.com > 1);
            if (com) {
              p.sel = sel.filter((s) => s.com == com.com);
              if (com.com == 3) p.visible = 1;
            } else {
              p.sel = sel;
              p.collect = 0;
            }
            if (p.sel.find((s) => s.grp)) p.sel = this.sortArr(p.sel, "grp", 2, "znum");
            if (p.user_addable) p.sel.push(this.addField(p.name));
            this.executeWaitingForceSel();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // prelRec(prel, show, first) {
    //   let np = this.props.find((e) => e.id == prel);
    //   if (np) {
    //     this.props.forEach((e) => {
    //       if (e.id == np.id) {
    //         console.log(e);
    //         if (show && !e.psel.length) {
    //           e.active = first ? 1 : 0;
    //           e.visible = first ? 1 : 0;
    //         } else {
    //           e.active = 0;
    //           e.collect = 0;
    //         }
    //       }
    //     });
    //   }
    //   if (np.prel.length) {
    //     let childProps = this.props.filter((e) => np.prel.includes(e.id));
    //     childProps.forEach((c) => this.prelRec(c.id, show, false));
    //   }
    // },
    pLast() {
      if (this.props.length) return this.props[this.props.length - 1].visible ? true : false;
    },
    showNext() {
      if (!this.activeProp && this.nextProp) {
        this.nextProp.visible = 1;
        // this.addLog();
        // console.log(this.props);

        // if (this.nextProp.ptype_name == "title") this.props.find((e) => e.active && !e.visible && !e.collect).visible = 1;
        // if (this.isMobile && this.nextProp && (this.nextProp.pcomp_name == "addInput" || this.nextProp.pcomp_name == "addTextbox")) setTimeout(() => (this.nextProp.visible = 1), 500);

        // setTimeout(() => this.$bus.emit("scrollTo", "#footer"), 2000);

        // console.log(this.$refs);
        // this.$refs["comp" + this.nextProp.id].scrollTop = 0;
        // this.$scrollTo(this.$refs["comp" + this.nextProp.id], 500);
        // this.$refs["comp" + this.nextProp.id].scrollIntoView({ block: "end", scrollBehavior: "smooth" });
        // this.$bus.emit("scrollTo", "#comp" + this.nextProp.id);
      }
    },
    sLoaded(pid, sel) {
      this.props.find((p) => {
        if (p.id == pid && p.func && p.func.length) {
          p.func
            .filter((fi) => fi.trigger == "loaded")
            .forEach((fo) => {
              if (fo.name == "funcShowMapMarkers") {
                this.funcShowMapMarkers(fo.target, pid, sel);
              }
            });
          return true;
        }
      });
    },
    // preResizePhoto() {
    //   this.lockPhotoManage = true;
    //   this.props
    //     .filter((p) => p.pcomp_name == "addPhotoUpload")
    //     .forEach(async (e) => {
    //       this.preSizeLoading = true;
    //       let res = await this.axiosPost("/utility/upload/preResizeItemImages", [this.objSel.id, this.zid, e.id, e.collect]);
    //       if (res.status == "done") this.imagePresizingDone = true;
    //       this.preSizeLoading = false;
    //     });
    // },
    sMouseOver(pid, sel) {
      this.props.find((p) => {
        if (p.id == pid && p.func && p.func.length) {
          p.func
            .filter((fi) => fi.trigger == "mover")
            .forEach((fo) => {
              if (fo.name == "funcShowMapOverlay") {
                this.funcShowMapOverlay(fo.target, fo.ref, sel, fo.coordOnly);
              }
            });
          return true;
        }
      });
    },
    sendPhotoPresizeRequest() {
      this.$bus.emit("preResizePhoto", this.zid);
      this.sentPhotoPresizeRequest = true;
    },
    sClicked(pid, sel) {
      // console.log("sclicked:", this.props.find((e) => e.id == 6).sel);

      // console.log("Received clicked event ", this.props.find((e) => e.id == pid).name, "sel:", sel);

      // this.props.find((e) => {
      //   if (e.id == pid) {
      //     e.collect = sel;
      //     e.force == null;
      //     return true;
      //   }
      // });

      // if (sel)
      this.props.forEach(async (p) => {
        if (p.psel.includes(sel.id) && !this.props.find((e) => e.id == pid && e.ref)) {
          p.active = "p" + pid;
          if (this.activeProp) this.activeProp.visible = 0;
          // console.log("activated psel prop");
        }
        if (p.active == "p" + pid && !p.psel.includes(sel.id)) {
          // console.log("psel p.collect", p.collect.id);
          let findChildActivePsel = this.props.find((e) => e.psel.includes(p.collect.id));
          if (findChildActivePsel) {
            findChildActivePsel.active = 0;
            findChildActivePsel.collect = 0;
            findChildActivePsel.visible = 0;
          }
          p.active = 0;
          p.collect = 0;
          p.visible = 0;
          // console.log("psel disabled", p.psel);
        }
        if (p.id == pid) {
          if (p.ptype_name == "title" && !this.sentPhotoPresizeRequest) this.sendPhotoPresizeRequest();

          p.collect = sel;
          p.force == null;

          if (p.func && p.func.length) {
            p.func
              .filter((fu) => fu.trigger == "click")
              .forEach((f) => {
                if (f.name == "funcCalcUnitPrice2") this.funcCalcUnitPrice(parseFloat(sel), this.props.find((e) => e.id == f["total"]).collect, f["target"], f["integer"], f["lowerDigit"]);
                if (f.name == "funcCalcUnitPrice") this.funcCalcUnitPrice(this.props.find((e) => e.id == f["quantity"]).collect, parseFloat(sel), f["target"], f["integer"], f["lowerDigit"]);
                if (f.name == "funcFilterHigher") this.funcFilterHigher(f["target"], parseInt(sel.name ? sel.name : sel), f["notEqualCurrentVal"]);
                if (f.name == "funcFilterLower") this.funcFilterLower(f["target"], parseInt(sel.name ? sel.name : sel), f["notEqualCurrentVal"]);
                if (f.name == "funcForceSelLoc") this.funcForceSelLoc(f.target, sel);
                if (f.name == "funcSetCoord") this.funcSetCoord(f.target, sel);
              });
          }

          if (p.prel.length) {
            let cProps = this.props.filter((e) => p.prel.includes(e.id));
            cProps.forEach((e) => {
              this.getRelData(e.id, sel.id, e.sort);
              // console.log("id", e.id, "sid", sel.id);
              //for (p.prel && p.ref && sel.child)
              // this.prelRec(e.id, false, true);
            });
          }

          // if (p.prel && p.ref && !sel.child) this.prelRec(p.prel, false, true);

          if ((p.ptype_name == "model" || p.ptype_name == "town") && p.collect) {
            if (this.propsModCus.length) this.restorePropSel(this.propsModCus, this.propsBackup);
            await this.getCusSel(this.propsModCus, p.id, sel.id);
          }
          // if (p.force) p.force = 0;
        }
      }, this);
      // console.log("propSclicked:", this.props);
      // this.addLog();
    },
    addLog() {
      let mappedProp = this.props.map((e) => {
        return { pid: e.id, name: e.name, collect: e.collect && e.ptype_name == "pass" ? "pass" : e.collect };
      });
      if (this.zid) this.axiosPost("/sys/log/additemlog/", { obj: this.objSel.id, zid: this.zid, device: { isMobile: this.isMobile, devid: localStorage.getItem("devid"), browser: this.getBrowserType() }, prop: mappedProp, complete: this.completeLog });
    },
    async objClicked(e) {
      // this.showMenu = { addcat: false };
      this.reset();
      this.objSel = e;
      this.zid = await this.fetchZid(e.id);
      // this.addLog();
      if (!this.zid) alert("error: No zid!");

      let data = await this.fetchObjInfo(e.id);
      this.addText = data["obj_add_text"];
      // console.log(this.addText);

      // console.log(data);
      data.props.forEach((p, i) => {
        if (p.psel) p.psel = p.psel.split(",").map((e) => parseInt(e));
        else p.psel = [];
        if (p.prel) p.prel = p.prel.split(",").map((e) => parseInt(e));
        else p.prel = [];
        if (!p.sys) p.active = p.psel.length == 0 ? 1 : 0;
        if (p.sel.find((el) => el.com == 4)) p.active = 0;
        p.visible = i == 0 ? 1 : 0;
        //p.sort == 2 && p.sel.reverse();
        if (p.sort) p.sel = this.sortArr(p.sel, "name", p.sort);
        // this.$set(p, "force", 0);
        p.force = 0;
        if (p.module == 3 && this.logged) p.active = 0;
        if (p.module == 4 && this.logged) p.active = 0;
        // if (p.user_addable) p.sel.push(this.addField(p.name)); DISABLED TEMP
        // if (p.opt && p.opt.range && p.opt.range.cusVals) {
        //   this.$set(p.opt.range, "cusVals", p.opt.range.cusVals);
        // }
        if (p.pcomp == 11) this.$set(p, "mapMarkers", []);
      });
      this.props = data.props;
      // console.log(this.props);
      this.propsBackup = this.deepClone(data.props);
      this.dep = data.dep;
      this.getFirstRelData();

      // this.addLog();
    },
    async updateUserMerit() {
      let userMerit = await UserService.getUserMerit().then((res) => res.data);
      this.userMerit = userMerit;
    },
    // checkPhoneExist() {
    //   if (this.logged) return false;
    //   let phoneField = this.props.find((e) => e.ptype_name == "phone" && e.collect);
    //   if (phoneField.length)
    //     return this.axios
    //       .get("/user/account/phoneExist/" + phoneField.collect)
    //       .then((res) => {
    //         return res.data;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    // },
  },
  mounted() {
    this.$bus.on("zarAddSubmit", this.showZar);
    this.$bus.on("zarAddFailedSubmit", this.clickedObjSel);
  },
  activated() {
    // if (!this.objSel && this.scr == 1) this.showMenu = { addcat: true };
    // if (this.submitted) this.reset();
  },
  watch: {
    scr() {
      // if (!this.objSel && this.scr == 1) this.showMenu = { addcat: true };
      // if (this.scr != 1) this.showMenu = { addcat: false };
    },
    activeProp() {
      // if (this.activeProp && !this.isMobile) {
      //   let visibleProp = this.props.filter((p) => p.visible);
      //   let pos = visibleProp.indexOf(this.activeProp);
      //   // console.log(visibleProp.length, pos + 1);
      //   if (visibleProp.length == pos + 1) this.$scrollTo("#footer", 2000);
      //   // this.$scrollTo("#comp" + this.activeProp.id, 2000);
      // }
    },
    phoneExist() {
      if (this.phoneExist) {
        this.$message({
          type: "info",
          message: "Бүртгэлтэй хэрэглэгч олдлоо",
          showClose: true,
          position: "top-center",
          hasMask: false,
          duration: 3000,
        });
        //     // let phone = this.props.find((e) => e.ptype_name == "phone" && e.collect).collect;
        //     // this.$bus.emit("openModal", { name: "userLogin", dataSet: { phone, pass: "", returnPage: "/zar/add" } });
      }
    },
    props: {
      deep: true,
      handler() {
        this.showNext();
      },
    },
    logged: {
      handler() {
        this.props.forEach((e) => {
          if (e.module == 3 || e.module == 4) {
            e.active = this.logged ? 0 : 1;
            e.collect = "";
            return true;
          }
        });
        if (this.logged) this.updateUserMerit();
        if (this.logged && this.complete) this.submit();
      },
    },
  },
  asyncComputed: {
    phoneExist() {
      if (this.logged) return false;
      if (this.userPhone)
        return this.axios
          .get("/user/account/phoneExist/" + this.userPhone)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  computed: {
    // showMenu: {
    //   get() {
    //     return this.$store.state.showMenu;
    //   },
    //   set(ob) {
    //     this.$store.commit("showMenu", ob);
    //   },
    // },
    scr() {
      return this.$store.getters.scr;
    },
    catData() {
      return this.$store.state.zar.catData;
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    user() {
      return this.$store.state.auth.user;
    },
    activeProp() {
      return this.props.find((e) => !e.collect && e.visible && e.req && e.active);
    },
    nextProp() {
      return this.props.find((e) => e.active && !e.visible && !e.collect);
    },
    comPer() {
      let totProp = this.props.filter((el) => el.req && el.active).length;
      let comProp = this.props.filter((el) => el.req && el.collect && el.active).length;
      return (comProp * 100) / totProp;
    },
    complete() {
      return !this.props.find((e) => !e.collect && e.active && e.req) && this.props.filter((el) => el.req && el.active).length && this.imagePresizingDone && this.logged;
    },
    completeLog() {
      return {
        "!this.props.find((e) => !e.collect && e.active && e.req): ": !this.props.find((e) => !e.collect && e.active && e.req),
        "this.props.filter((el) => el.req && el.active).length: ": this.props.filter((el) => el.req && el.active).length,
        "this.imagePresizingDone: ": this.imagePresizingDone,
        "this.logged: ": this.logged,
      };
    },
    userPhone() {
      let prop = this.props.find((e) => e.ptype_name == "phone" && e.collect);
      return prop ? prop.collect : false;
    },
    userPass() {
      let prop = this.props.find((e) => e.ptype_name == "pass" && e.collect);
      return prop ? prop.collect : false;
    },
  },
  mixins: [VueMixinTween("comPer", 1000)],
};
</script>

<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_main_add {
  /* padding-top: 0px; */
  padding: 0 10px 250px 10px;
  /* height: 100vh; */
}
.row {
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr 3fr;
}
.column {
  float: left;
}
.cat_div {
}

.cont_obj_sel {
  padding: 0 0 0 5px;
}
.obj_sel {
  margin-top: 12px;
}
.compRow {
  clear: both;
}
.cont_pname {
  vertical-align: middle;
  margin-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.required {
  color: red;
  margin-left: 2px;
}

.pname,
.prop_name {
  /* letter-spacing: 0.03em !important; */
  /* color: var(--color_lighter); */
  /* font-size: 14px !important; */
  /* font-stretch: 97% !important; */
  /* font-weight: 300; */
}

.pname {
  padding: 3px 0 2px 0;
  transition-property: padding;
  transition-duration: 350ms;
  border-radius: 3px;
  text-align: right;
  height: fit-content;
  width: fit-content;
  margin-top: 2px;
  float: right;
  line-height: 1.4em;
  /* letter-spacing: 0.03em; */
}

.pname.bold {
  /* font-weight: 600; */
  color: black !important;
}
.pcomp {
  padding-left: 5px;
  margin-bottom: 5px;
}

.user_phone {
  padding-top: 3px;
  opacity: 0.5;
}

.act {
  /* display: inline-flex;
  align-items: center; */
  /* background-color: #befeff;
  background: radial-gradient(circle, #befeff 0%, #ffffff 100%); */
  color: var(--color_darker);
  padding: 2px 5% 1px 5% !important;
  /* height: 1.6em;
  padding: 0 2em; */
  font-weight: 600;
  /* font-size: 15px; */
  animation: pulsate_bg2 1.5s ease-out;
  animation-iteration-count: infinite;
  /* background: linear-gradient(to left, #befeff 0%, #ffffff 100%); */
  /* background-size: 300% 300%; */
  background-color: #befeff;
  /* background-image: linear-gradient(-45deg, yellow 0%, yellow 25%, yellow 51%, #ff357f 100%); */
  /* animation: AnimateBG2 10s linear infinite; */
}

.cont_sbutton {
  /* z-index: 99; */
  /* position: fixed; */
  /* bottom: 60px;*/
  /* right: 50%; */
  /* margin-top: 20px; */
  /* background-color: #befeff; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.sbutton {
  transition: all 150ms;
  width: 100px;
  /* z-index: 10;
  margin-top: 20px; */
  box-shadow: 0px 0px 20px 15px #fff;
}
.sbutton:hover {
  cursor: initial;
}
.act_button {
  /* margin-left: 0px; */
  background: none !important;
}
.act_button:hover {
  cursor: pointer;
}
.act_one {
  animation: glow 3s;
  animation-iteration-count: 1;
  background-color: #f1f1f1;
  transition: all 150ms;
  padding: 0 5px;
  /* padding: 3px 5% 2px 5% !important; */
}
.act_checkbox {
  /* background: radial-gradient(circle, #fad6ff 0%, #ffffff 100%) !important; */
}
.title {
  font-family: "Bold";
  margin-top: 2px;
}
.sep {
  margin-top: 30px;
}

.black {
  color: #000;
}
.hdr {
  font-family: arial;
}
.zar_cat {
  margin-top: 2px;
  margin-left: 10px;
}

@media only screen and (max-width: 700px) {
  /* .cont_main_add {
    padding-top: 5px;
    padding-bottom: 250px;
  } */

  .cont_suggest_text {
    display: block;
  }
  .row {
    grid-template-columns: 1fr;
    border-bottom: solid 1px #f1f1f1;
    padding: 5px 0;
  }

  .act {
    padding: 0 !important;
    padding-left: 5px !important;
  }

  .pname {
    float: none;
    margin-left: 5px;
    font-weight: 600;
  }

  .warning_text {
    text-align: left !important;
  }

  .suggest_text {
    text-align: left !important;
  }

  .spacer {
    top: -10px;
    display: none;
  }
  .sep {
    margin: 0;
  }
  /* .pname {
    float: none;
    padding: 0;
    padding-left: 2px;
    margin: 0 auto;
  }
  .cont_pname {
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .pcomp {
    padding: 0;
    padding-top: 1px;
    margin-bottom: 10px;
    text-align: center;
  }
  .obj_sel {
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
  } */
}
</style>
