import axios from "axios";
import authHeader from "@/service/auth-header";

//const API_URL = "http://192.168.1.19/dev/api/user/content";
//const API_URL = "http://localhost:8080/api/test/";

class UserService {
  // getPublicContent() {
  //   //console.log(authHeader());
  //   return axios.get("/user/content/all", { headers: authHeader() });
  // }

  getVerifyCode() {
    //console.log(authHeader());
    return axios.get("/user/account/getVerifyCode", { headers: authHeader() });
  }

  getUserItems(pnum, plim) {
    //console.log(authHeader());
    return axios.get("/user/item/getUserItems/" + pnum + "/" + plim, { headers: authHeader() });
  }

  updateUserNoti() {
    //console.log(authHeader());
    return axios.get("/user/noti/getNoti", { headers: authHeader() });
  }

  // async getGuestFavs(items) {
  //   return await axios.post("/zar/item/getGuestFavs", items);
  // }

  getUserFavs(pnum, plim) {
    //console.log(authHeader());
    return axios.get("/user/fav/getUserFavs/" + pnum + "/" + plim, { headers: authHeader() });
  }

  getUserViewedItems() {
    //console.log(authHeader());
    return axios.get("/user/item/getUserViewedItems/", { headers: authHeader() });
  }

  getUserCarBarterItems() {
    //console.log(authHeader());
    return axios.get("/user/item/getUserCarBarterItems/", { headers: authHeader() });
  }

  getUserMerit() {
    //console.log(authHeader());
    return axios.get("/user/account/getUserMerit/", { headers: authHeader() });
  }

  addUserFav(obj, zid) {
    //console.log(authHeader());
    return axios.get("/user/fav/addUserFav/" + obj + "/" + zid, { headers: authHeader() });
  }

  removeUserFav(obj, zid) {
    //console.log(authHeader());
    return axios.get("/user/fav/removeUserFav/" + obj + "/" + zid, { headers: authHeader() });
  }

  renewItem(obj, zid) {
    //console.log(authHeader());
    return axios.get("/user/item/renewItem/" + obj + "/" + zid, { headers: authHeader() });
  }

  editItem(obj, zid) {
    return axios.get("/user/item/editItem/" + obj + "/" + zid, { headers: authHeader() });
  }

  updateUserInfo() {
    return axios.get("/user/account/getUserInfo/", { headers: authHeader() });
  }

  deleteItem(obj, zid, statusCode) {
    return axios.get("/user/item/deleteItem/" + obj + "/" + zid + "/" + statusCode, { headers: authHeader() });
  }

  saveItem(event) {
    //console.log(authHeader());
    return axios.post("/user/item/saveItem", event, { headers: authHeader() });
    // .post("/zar/item/addItem", clone)
    // return axios.get("/user/action/saveZarData/" + event.obj + "/" + event.zid, { headers: authHeader() });
  }
  /*
  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }*/
}

export default new UserService();
