<template>
  <div :class="[digits > 3 && 'mb-3']">
    <ui-input v-on:typed="typed($event)" :collect="collect" :disabled="disabled" :unit="unit" :mask="mask" :unmask="unmask" :guide="guide" :pat="pat" :min="min" :max="max" :regex="regex" :minWidth="8" />
    <ui-reflow>
      <div v-if="digits > 3" class="ml-3 disabled fs-13">
        {{ (collect / 1000).toFixed(1) }} <span class="fs-12">{{ unit == "сая" ? "тэрбум" : "сая" }}</span>
      </div>
    </ui-reflow>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiInput from "@/comp/ui/input.vue";
export default {
  name: "zar-add-input-price",
  props: {
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: {
      type: Object,
      required: true,
    },
  },
  components: { uiInput },
  data() {
    return {
      guide: this.pob.guide,
      unit: this.pob.unit,
      pat: this.pob.mask && this.pob.mask.pat ? this.pob.mask.pat : null,
      min: this.pob.mask && this.pob.mask.min ? this.pob.mask.min : 0,
      max: this.pob.mask && this.pob.mask.max ? this.pob.mask.max : 1000,
      mask: this.pob.mask,
      unmask: this.pob.mask && this.pob.mask.unmask ? this.pob.mask.unmask : false,
      regex: this.pob.mask && this.pob.mask.regex ? this.pob.mask.regex : null,
      disabled: this.pob.mask && this.pob.mask.disabled ? this.pob.mask.disabled : false,
      minWidth: this.pob.opt && this.pob.opt.width ? this.pob.opt.width : 15,
    };
  },
  methods: {
    typed(data) {
      // console.log(data);
      this.$emit("clickedItem", data);
    },
  },
  computed: {
    digits() {
      return this.isInt(this.collect) ? this.collect.toString().length : this.collect.toString().length - 2;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
