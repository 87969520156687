<template>
  <div class="cont_feedback">
    <div class="title_modal">Санал хүсэлт</div>
    <textarea v-model="text" ref="feedback"></textarea>
    Хэрвээ таньд хариу шаардлагатай бол холбогдох утсаа үлдээн үү
    <div class="mt-3">
      <ui-button v-on:clicked="submit()" class="pr-3 pl-3" text="Илгээх" widthSize="wide" />
      <ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Болих" tp="textOnly" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "main-feedback",
  components: {},
  props: {},
  data() {
    return {
      text: null,
      shake: false,
    };
  },
  methods: {
    submit() {
      if (!this.text) {
        this.shake = true;
        setTimeout(() => (this.shake = false), 600);
        this.showMessage("warning", "Хоосон");
        return;
      }
      this.close();
      if (!this.text) return;
      this.axiosPost("/utility/feedback/add", { body: this.text, dev_id: this.getLocal("devid", true), dev_tp: this.isMobile ? "mobile" : "pc", logged: this.logged });
      this.close();
      this.showMessage("info", "Илгээгдлээ");
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    logged() {
      return this.$store.state.auth.status.logged;
    },
  },

  mounted() {
    setTimeout(() => this.$refs.feedback.focus(), 100);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_feedback {
  padding: 0 10px 10px 10px;
  text-align: center;
}
img {
  width: 100%;
  height: 150px;
}
textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
}
</style>
