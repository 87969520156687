<template>
  <div class="cont_ui_select_hori">
    <div v-if="collect" @click="selection = !selection" class="selected" :class="{ cef: cefAct, chosen: items.length == 1 }">
      <img v-if="collect.icon" :src="'/icon/' + collect.icon + '.svg'" height="12" />{{ collect.name }}<span>{{ unit }}</span>
    </div>
    <div class="box" v-if="selection" :style="{ zIndex: zind }">
      <!-- <div class="box" v-if="only ? (selection && items.length > 1 ? true : false) : selection && items.length > 0 ? true : false" :style="{ zIndex: zind }"> -->
      <span v-for="(s, ind) in items" :key="'i' + ind + s.id" @click="clickedItem(s)" :class="{ common: collect && s.id == collect.id, pressed: collect.id == s.id }" class="selection"> {{ s.name }} {{ unit }}</span>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-select-hori",
  props: {
    items: Array,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    zind: Number,
    unit: String,
    forceSel: Number,
  },
  data() {
    return {
      selection: false,
      cefAct: false,
    };
  },
  methods: {
    emitSel(bo) {
      this.$emit("selection", bo);
    },
    clickedItem(obj) {
      this.selection = false;
      if (this.collect.id != obj.id) this.$emit("clickedItem", obj);
    },
    cefAction() {
      this.cefAct = true;
      setTimeout(() => {
        this.cefAct = false;
      }, 1000);
    },
    autoSel() {
      if (this.only && this.only.com > 2) this.clickedItem(this.only);
      if (this.only && this.only.com == 4) this.emitHideProp();
    },
    emitHideProp() {
      this.$emit("hideProp");
    },
  },
  computed: {
    only() {
      return this.items.find((el) => el.com > 1);
    },
  },
  watch: {
    selection: {
      immediate: true,
      handler() {
        // this.emitSel(this.selection);
        // console.log("collect:", this.collect, "changed selection :" + this.selection);
      },
    },
    items: {
      immediate: true,
      handler() {
        if (this.items.length) {
          this.selection = true;
          this.autoSel();
        }
      },
    },
    collect: {
      immediate: true,
      handler() {
        if (this.collect) {
          // this.selection = false;
        } else {
          // this.selection = true;
          this.cefAction();
        }
      },
    },
    forceSel() {
      this.items.find((f) => {
        if (f.id == this.forceSel) {
          this.$emit("clickedItem", f);
          this.selection = false;
        }
      });
    },
  },
  mounted() {
    // this.autoSel();
    // if (this.collect) this.selection = false;
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_select_hori {
}
.box {
  position: absolute;
  z-index: 10;
  max-width: 500px;
  background-color: #fff;
  border-radius: 5px;
  /* border: 1px solid #ccc; */
  /* padding: 5px 0 0 5px; */
}

.cef {
  animation-name: cef;
  animation-duration: 0.5s;
}
@keyframes cef {
  from {
    background-color: #d4edfd;
    border: solid 1px #a4cde6;
  }
  to {
    background-color: #fafafa;
    border: solid 1px #fff;
    border-bottom: dotted 1px #b9b8b8;
  }
}

.common {
  background-color: #d4edfd;
}
.selected {
  padding: 2px 0px 0px 0px !important;
  display: inline-block;
  margin-bottom: 5px;
  /* font-size: 13px; */
  background-color: #fafafa;
  color: var(--color_link);
  border-bottom: dotted 1px #b9b8b8;
}
.selected:focus {
}
.selected:hover {
  cursor: pointer;
  background-color: #ededed;
}
.selected:active {
  transform: scale(0.98);
}

.selected img {
  position: relative;
  top: 1px;
  left: -3px;
}
.selected span {
  padding-left: 2px;
}

.selection {
  border: solid 1px #ccc;
  display: inline-block;
  padding: 2px 7px 1px 7px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  color: var(--color_darker);
  background-color: #fff;
  /* box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.03); */
}
.selection:hover {
  background-color: #d4edfd;
}
.pressed {
  background-color: #d4edfd;
  border: solid 1px #a4cde6;
  border-bottom: solid 1px #88acc2;
  font-weight: 600;
}
.selection:active {
  transform: scale(0.95);
}
.seperator {
  color: #ccc;
}
.chosen {
  opacity: 0.5;
}
.chosen:hover {
  cursor: default;
  background-color: #ffffff !important;
}
.chosen:active {
  transform: scale(1);
}
.blink_me {
  position: absolute;
  margin-left: 115px;
  margin-top: -18px;
}
.empty {
  background-color: #fffde6;
}

.fade-enter-active {
  transition: opacity 0.3s;
}

.fade-leave-active {
  transition: none;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 700px) {
  .box {
    transform: translateX(50%);
    right: 50%;
    width: fit-content;
  }
}
</style>
