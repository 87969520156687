<template>
  <div class="cont_navi" :class="{ mobile_screen: scr == 1 }">
    <span v-if="screenHeight > innerHeight * 2" class="navi_button ani_pop" @click="direction == 'up' ? goUp() : goDown()"> <ui-icon name="i-up2" size="xxl" :angle="direction == 'down' ? '' : 'rev'"></ui-icon></span>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "main-navi-scroll",
  data() {
    return {
      pos: null,
      showNavi: false,
      bottomReached: false,
      pageScrolled: 0,
      screenHeight: 0,
      innerHeight: 0,
    };
  },
  methods: {
    goUp() {
      // this.isMobile ? this.scroll("#header") : this.scrollNative(0);
      this.scroll("#header");
    },
    goDown() {
      this.scroll("#footer");
    },
    scroll(el) {
      this.$scrollTo(el, 500);
    },
    scrollNative(y) {
      scrollTo(0, y);
    },
    // checkReachBottom() {
    //   this.pageScrolled = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    //   this.bottomReached = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
    // },
    checkPageScrolled() {
      this.pageScrolled = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    },

    updateHeight() {
      this.screenHeight = document.body.scrollHeight;
    },
    updateInnerHeight() {
      this.innerHeight = window.innerHeight;
    },
  },
  watch: {
    pageScrolled(valNew, valOld) {
      this.scrollDirection = valNew < valOld ? 2 : 1;
    },
  },
  computed: {
    scrollDirection: {
      get() {
        return this.$store.state.scrollDirection;
      },
      set(val) {
        this.$store.commit("scrollDirection", val);
      },
    },
    scr() {
      return this.$store.getters.scr;
    },
    // reached() {
    // if()
    // },
    direction() {
      // return this.screenHeight / 2 < this.pageScrolled ? "up" : "down";
      if (this.pageScrolled < this.innerHeight) return "down";
      if (this.pageScrolled > this.screenHeight - this.innerHeight * 2) return "up";
      return this.scrollDirection == 1 ? "down" : "up";
      // return this.pageScrolled >= 2000 ? "up" : "down";
      //  if(this.pageScrolled >= 2000)
      //   if="pageScrolled < 2000 && screenHeight > 1500"
    },
  },
  mounted() {
    // window.onscroll = function() {
    // this.pageScrolled = window.scrollY;
    // this.pageScrolled = window.scrollY; // Value of scroll Y in px
    // };
    // window.scrollTo({
    //   top: 1000,
    //   behavior: "instant",
    // });
    this.$bus.on("scrollTo", this.scroll);
    this.$bus.on("scrollNative", this.scrollNative);
    // window.addEventListener("scroll", this.checkReachBottom);
    window.addEventListener("scroll", this.checkPageScrolled);
    window.addEventListener("resize", this.updateInnerHeight);

    this.updateInnerHeight();
    if (this.scr == 1) setInterval(() => this.updateHeight(), 2000);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_navi {
  position: fixed;
  right: 20px;
  bottom: 40px;
  padding: 10px;
  animation: slide 0.2s;
  animation-iteration-count: 1;
  /* transform: translateX(50%); */
}

.cont_navi.mobile_screen {
  transform: none;
  bottom: 70px;
  z-index: 1;
}

.navi_button {
  /* border: solid 1px rgb(223, 223, 223); */

  padding: 15px 40px 10px 40px;
  display: block;
  border-radius: 5px;
  background-color: #dbe2efc5;
  /* background-color: rgba(0, 255, 255, 0.233); */
  transition: all 0.1s;
  transform: scale(1);

  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); */
}

.navi_button:active {
  transform: scale(0.9);
}

.navi_button_mobile:active {
  transform: scale(0.9);
}

@media only screen and (min-width: 1150px) {
  .navi_button:hover {
    /* border: solid 1px rgb(152, 255, 255); */
    cursor: pointer;
    background-color: #98ffff;
  }
}

@media only screen and (max-width: 700px) {
  .navi_button {
    padding: 15px 20px 10px 20px;
  }

  .navi_button img {
    rotate: 180deg;
    opacity: 0.7;
    position: relative;
    /* top: -3px; */
    /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(337deg) brightness(102%) contrast(102%); */
  }
}
</style>
