<template>
  <div class="cont_ui_dmenu">
    <slot></slot>
    <div v-if="showMenu" class="ani_sdown_fast">
      <div class="menu" @mouseleave="setTimer(3000)" @mouseenter="clearTimer()">
        <div class="items" v-for="(i, ind) in items" :key="'dmenu' + ind" @click.stop="clickedItem(i)">{{ i.name }}</div>
      </div>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-dmenu",
  props: {
    items: Array,
    showMenu: Boolean,
  },
  data() {
    return {
      timer: null,
    };
  },
  methods: {
    clearTimer() {
      clearTimeout(this.timer);
    },
    setTimer(duration) {
      this.timer = setTimeout(() => this.$emit("closeMenu"), duration);
    },
    clickedItem(obj) {
      this.clearTimer();
      this.$emit("closeMenu");
      this.$emit("clickedItem", obj);
    },
  },

  watch: {
    showMenu() {
      if (this.showMenu) {
        this.setTimer(3000);
      } else {
        this.clearTimer();
      }
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_dmenu {
  z-index: 10;
  position: relative;
}
.trigger {
  padding: 2px 5px;
  height: 20px;
}
.trigger img:active {
  transform: scale(0.9);
}
/* .trigger img {
  filter: invert(93%) sepia(1%) saturate(0%) hue-rotate(137deg) brightness(95%) contrast(79%);
}
.trigger:hover img {
  filter: invert(93%) sepia(1%) saturate(0%) hue-rotate(137deg) brightness(65%) contrast(79%);
} */
.menu {
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  position: absolute;
  margin-top: 0px;
  background-color: white;
  transform: translateX(0%);
  white-space: nowrap;
  /* margin-left: 30px; */
}
.items {
  padding: 8px 10px;
}
.items:hover {
  background-color: #f1f1f1;
}
.items:hover {
  cursor: pointer;
}
@media only screen and (min-width: 700px) {
  .trigger:hover {
    /* background-color: #e4e4e4;
    border-radius: 6px; */
  }
}
</style>
