<template>
  <ui-video-upload :maxDuration="opt.maxDuration * 10" :maxFileSize="opt.maxFileSize * 1000000" :filename="'_u' + this.zid + '_o' + this.obj.id + '_p' + this.pob.id" :folder="opt.tp" v-on:clickedItem="clickedItem($event)" />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiVideoUpload from "@/comp/ui/videoUpload.vue";

export default {
  components: { uiVideoUpload },
  props: {
    obj: {
      type: Object,
      required: true,
    },
    zid: {
      type: String,
      required: true,
    },
    pob: {
      type: Object,
      required: true,
    },
    opt: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickedItem(ob) {
      this.$emit("clickedItem", ob);
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
