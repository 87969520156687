<template>
  <div class="cont_zar_filter" id="filter" @mouseover="mOver = true" @mouseout="mOver = false" :class="{ with_back: scr > 1, horizontal_items: scr == 1 && !showAll, showAll }">
    <ui-button class="reset ani_pop" tp="iconOnly" v-show="scr != 1 && obj && filter.length && !isMobile" icon="i-rotate" :disabled="!filter.length" v-on:clicked="resetFilter()" />
    <div class="blink_me" v-if="loading">.</div>
    <div v-if="scr == 1 && !showAll" class="showAllButton link" @click="showAll = !showAll">Бүгдийг харах</div>
    <template v-for="(f, k) in fitems">
      <template v-if="scr == 1">
        <zar-filter-input-mobile :key="f.obj + 'i' + f.fid + 'i' + k" class="horizontal_sub" :class="{ focused: filter.length }" v-if="f.tp == 4" :contInd="k" :pob="f" :filter="filter" v-on:clickedItem="clickedFitem(obj.id, f, $event)" v-on:clearItem="deleteFilter($event)" />
        <zar-filter-sub-mobile
          :key="f.obj + 'i' + f.fid + 'i' + k"
          class="horizontal_sub"
          :class="{ focused: filter.length }"
          v-else-if="f.sel.length && f.active && !f.under && !f.psel.length"
          :haveChilds="pselProps.includes(f.id) || underProps.includes(f.id)"
          :contInd="k"
          :items="f.sel"
          :last="lastAdd"
          :pob="f"
          v-on:clickedItem="clickedFitem(obj.id, f, $event)"
          :zarTotal="zarTotal"
          :mOver="mOver || (filter.length ? true : false)"
        >
          <template v-for="s in f.sel">
            <div v-if="s.selected && pselProps.includes(f.id)" :key="'pselCont' + s.id" :slot="'psel' + s.id" class="cont_child ani_sdown">
              <zar-filter-sub-mobile
                v-for="(ps, ind) in pselChild(s.id)"
                :key="'psel' + ps.id + 'i' + ind"
                child
                :zarTotal="zarTotal"
                :last="lastAdd"
                :items="ps.sel"
                :class="[isMobile ? 'ani_sdown' : 'ani_sdown_fast']"
                :pob="ps"
                v-on:clickedItem="clickedFitem(obj.id, ps, $event)"
                :mOver="mOver || (filter.length ? true : false)"
              />
            </div>
            <div v-if="s.selected && underProps.includes(f.id)" :key="'underCont' + s.id" :slot="'under' + s.id" class="cont_child ani_sdown" :class="[isMobile ? 'ani_sdown' : 'ani_sdown_fast']">
              <zar-filter-sub-mobile v-for="pa in underChild(f)" :key="'under' + pa.id" :mOver="mOver || (filter.length ? true : false)" :last="lastAdd" :zarTotal="zarTotal" child :items="pa.sel" :pob="pa" v-on:clickedItem="clickedFitem(obj.id, pa, $event)" />
            </div>
          </template>
        </zar-filter-sub-mobile>
      </template>
      <template v-else>
        <zar-filter-input v-if="f.tp == 4" :key="f.obj + 'i' + f.fid + 'i' + k" class="horizontal_sub" :class="{ focused: filter.length }" :contInd="k" :pob="f" :filter="filter" v-on:clickedItem="clickedFitem(obj.id, f, $event)" v-on:clearItem="deleteFilter($event)" />
        <zar-filter-sub
          v-else-if="f.sel.length && f.active && !f.under && !f.psel.length"
          :key="f.obj + 'i' + f.fid + 'i' + k"
          class="horizontal_sub"
          :class="{ focused: filter.length }"
          :haveChilds="pselProps.includes(f.id) || underProps.includes(f.id)"
          :contInd="k"
          :items="f.sel"
          :last="lastAdd"
          :pob="f"
          v-on:clickedItem="clickedFitem(obj.id, f, $event)"
          :zarTotal="zarTotal"
          :mOver="mOver || (filter.length ? true : false)"
        >
          <template v-for="s in f.sel">
            <div v-if="s.selected && pselProps.includes(f.id)" :key="'pselCont' + s.id" :slot="'psel' + s.id" class="cont_child ani_sdown">
              <zar-filter-sub
                v-for="(ps, ind) in pselChild(s.id)"
                :key="'psel' + ps.id + 'i' + ind"
                child
                :zarTotal="zarTotal"
                :last="lastAdd"
                :items="ps.sel"
                :class="[isMobile ? 'ani_sdown' : 'ani_sdown_fast']"
                :pob="ps"
                v-on:clickedItem="clickedFitem(obj.id, ps, $event)"
                :mOver="mOver || (filter.length ? true : false)"
              />
            </div>
            <div v-if="s.selected && underProps.includes(f.id)" :key="'underCont' + s.id" :slot="'under' + s.id" class="cont_child ani_sdown" :class="[isMobile ? 'ani_sdown' : 'ani_sdown_fast']">
              <zar-filter-sub v-for="pa in underChild(f)" :key="'under' + pa.id" :mOver="mOver || (filter.length ? true : false)" :last="lastAdd" :zarTotal="zarTotal" child :items="pa.sel" :pob="pa" v-on:clickedItem="clickedFitem(obj.id, pa, $event)" />
            </div>
          </template>
        </zar-filter-sub>
      </template>
      <div v-if="filter.length && k == fitems.length - 1" :key="'all_item' + k">
        <ui-button v-on:clicked="resetAll()" class="link" text="Арилгах" tp="simple" />
      </div>
    </template>
    <div v-if="loading && showPlaceholder && !fitems.length">
      <h4 class="title placeholder"></h4>
      <br />
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <br />
      <h4 class="title placeholder"></h4>
      <br />
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <br />
      <h4 class="title placeholder"></h4>
      <br />
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
      <p class="text placeholder"></p>
    </div>
    <div v-if="scr == 1 && showAll">
      <div class="showAllButton ta-l link" @click="showAll = !showAll">Хураах</div>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarFilterSub from "@/comp/zar/filterSub.vue";
import zarFilterSubMobile from "@/comp/zar/filterSubMobile.vue";
import zarFilterInput from "@/comp/zar/filterInput.vue";
import zarFilterInputMobile from "@/comp/zar/filterInputMobile.vue";

export default {
  name: "zar-filter",
  components: {
    zarFilterSub,
    zarFilterSubMobile,
    zarFilterInput,
    zarFilterInputMobile,
  },

  props: { filterName: String, obj: Object, pathSelZnum: String, pathFilData: String, pathRelData: String, routeQuery: String },
  data() {
    return {
      saveLast: null,
      lastAdd: null,
      avgLoadTime: null,
      pendingFilSel: 0,
      checkAll: false,
      loading: false,
      mOver: false,
      fitemsBackup: null,
      underProps: [],
      pselProps: [],
      showPlaceholder: false,
      showAll: false,
    };
  },
  methods: {
    resetAll() {
      this.$bus.emit("clearSort");
      this.$bus.emit("resetFilter");
    },
    clickedFitem(obj, prop, sel) {
      if (prop.tp == 4) return this.addFilterRange(prop, sel);

      if (this.loading) return this.showMessage("warning", "Уншиж байн түр хүлээн үү", 1500);

      let foundSid = this.filter.find((e) => e.obj == obj && e.prop == prop.id && e.sid == sel.id);
      if (foundSid) this.deleteFilter({ obj, prop: prop.id, sid: sel.id, prel: prop.prel, crel: prop.crel, fid: prop.fid, under: prop.under });
      else {
        let foundProp = this.filter.find((e) => e.obj == obj && e.prop == prop.id);
        if (foundProp) this.deletePropFilter(foundProp);
        if (prop.main && this.filter.length) this.clearAllSel();

        this.addFilter({ obj, prop, sel });
      }
    },
    addFilterRange(prop, sel) {
      this.fitems.find((e) => {
        if (e.id == prop.id) {
          let s = e.sel.first();
          s.id = sel.text;
          s.name = sel.name;
          s.sval = { min: sel.min, max: sel.max };
          s.selected = true;
          return true;
        }
      });
      this.fitemsBackup.find((b) => b.id == prop.id && (b.sel.first().id = sel.text));
    },

    async addFilter(f) {
      this.saveLast = this.lastAdd;
      this.lastAdd = f;
      this.fitems.find((i) => i.id == f.prop.id && i.sel.find((s) => s.id == f.sel.id && (s.selected = s.id)));
      let prelChilds = this.fitems.filter((e) => f.prop.prel.includes(e.id));
      prelChilds.forEach(async (child) => {
        let relData = await this.fetchRelData(this.obj.id, child.id, f.sel.id, f.prop.sort);
        relData.forEach((s) => {
          this.$set(s, "selected", null);
        });
        this.fitems.find((e) => {
          if (e.id == child.id) {
            if (!e.selBack) e.selBack = this.deepClone(e.sel);
            e.sel = relData;
          }
        });
      });
    },

    deletePropFilter(f) {
      this.fitems.find((e) => e.id == f.prop && e.sel.forEach((s) => (s.selected = null)));
    },

    deleteFilter(f) {
      let prop = this.fitems.find((i) => i.id == f.prop);
      prop.sel.find((s) => {
        if (s.id == f.sid) {
          if (prop.tp == 4 && s.autoType) s.autoType = null;
          s.selected = null;
          return true;
        }
      });
      if (f.prel.length) this.clearChildsPrel(f);
      if (f.crel) {
        let found = this.fitems.find((p) => p.id == f.crel && !f.under);
        if (found) this.clearChildsPrel(found);
      }
    },

    clearChildsPrel(f) {
      // console.log("prel", f);
      f.prel.forEach((f) => {
        this.fitems.find((e) => {
          if (e.id == f) {
            if (e.prel.length) this.clearChildsPrel({ prel: e.prel });
            if (e.selBack) {
              e.selBack.forEach((e) => e.selected && (e.selected = null));
              e.sel = e.selBack;
            }
          }
        });
      });
    },

    pselChild(sid) {
      // console.log(
      //   "PSEL FUNCTION",
      //   // this.fitems.filter((p) => p.psel.length)
      //   this.fitems
      // );

      return this.fitems.filter((p) => p.psel.includes(sid) && !p.under);
    },

    underChild(f) {
      return this.fitems.filter((p) => p.under == f.fid);
    },

    resetFilter() {
      this.fitems.forEach((i) => {
        i.sel.forEach((s) => {
          s.selected = null;
          if (i.tp == 4 && s.autoType) s.autoType = null;
        });
        if (i.selBack) i.sel = i.selBack;
      });
    },
    clearAllSel() {
      this.fitems.forEach((i) => i.sel.forEach((s) => (s.selected = null)));
    },

    async selectFilter(pid, sid) {
      let prop = this.fitems.find((i) => i.id == pid);

      if (prop.tp == 4) {
        prop.sel.first().autoType = sid;
      } else {
        let sel = prop.sel.find((i) => i.id == sid);
        await this.addFilter({ obj: this.obj.id, prop, sel });
      }
    },

    async autoSelectFilter() {
      let routeFilter = this.$route.query.f;
      let filters = routeFilter.split(",");
      for (const f of filters) {
        let d = f.split(".");
        await new Promise((resolve) => setTimeout(resolve, this.avgLoadTime ?? 500));
        await this.selectFilter(d[0], d[1]);
        this.pendingFilSel--;
      }
      this.$route.query.f = null;
    },

    async fetchFilData() {
      this.loading = true;
      let url = this.pathFilData + this.obj.dep + "/" + this.obj.id;
      let res = await this.axios
        .get(url, {})
        .then(async (res) => res.data)
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
      return res || [];
    },

    async fetchRelData(obj, prop, rel, sort) {
      this.loading = true;
      let url = this.pathRelData + obj + "/" + prop + "/" + rel + "/" + 1 + "/" + sort;
      let res = await this.axios
        .get(url)
        .then(async (res) => res.data)
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
      return res;
    },

    async fetchSelZnum() {
      if (this.pendingFilSel > 1) return [];
      if (this.pendingFilSel == 1) {
        this.lastAdd = null;
        this.checkAll = true;
      }

      this.loading = true;

      let filter = this.filter.map((e) => {
        let data = { prop: e.prop, sid: e.sid, tp: e.tp };
        if (e.sval) data.sval = e.sval;
        return data;
      });
      let actSel = this.actSel();
      let url = this.pathSelZnum;
      url += "/" + this.obj.id;
      var par = new URLSearchParams();
      par.append("filter", JSON.stringify(filter));
      par.append("act", JSON.stringify(actSel));
      let res = await this.axios
        .post(url, par)
        .then(async (res) => res.data)
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
      return res || [];
    },

    async updateSelZnum() {
      let startTimer = Date.now();
      let newItems = await this.fetchSelZnum();
      let lastPropId = this.lastAdd ? this.lastAdd.prop.id : null;
      newItems.forEach((n) => {
        this.fitems.forEach((f) => {
          if (n.pid == f.id)
            f.sel.find((s) => {
              if (n.id == s.id)
                if (f.id != lastPropId) {
                  s.znum = n.znum;
                }
            });
        });
      });
      //console.log(this.fitems);
      let duration = Date.now() - startTimer;
      if (duration > this.avgLoadTime) this.avgLoadTime = duration;
    },

    pselChilds() {
      let psels = this.fitems.filter((ps) => ps.psel.length && !ps.under);
      if (psels) {
        let sids = this.filter.filter((f) => f.tp == 1).map((e) => e.sid);
        psels.forEach((ps) => {
          let found = false;
          ps.psel.forEach((p) => sids.includes(p) && (found = true));
          if (found && !ps.active) {
            ps.sel.forEach((s) => (s.znum = null));
            ps.active = 1;
          }
          if (!found && ps.active) {
            ps.active = 0;
            ps.sel.forEach((s) => (s.selected = null));
          }
        });
      }
    },

    underChilds() {
      let unders = this.fitems.filter((u) => u.under);

      // console.log(unders);

      if (unders.length) {
        let pids = this.filter.map((e) => e.prop);
        let fids = this.fitems
          .filter((p) => {
            if (pids.includes(p.id)) {
              let sid = this.filter.find((f) => f.prop == p.id).sid;
              let allowed = p.sel.find((s) => s.id == sid && s.sub);
              return allowed ? true : false;
            }
          })
          .map((m) => m.fid);
        // console.log(pids);
        unders.forEach((un) => {
          let found = fids.includes(un.under);
          if (found && !un.active) {
            un.active = 1;
            un.sel.forEach((s) => (s.znum = null));
          }

          if (!found && un.active) {
            un.active = 0;
            un.sel.forEach((s) => (s.selected = null));
          }
        });
      }
      // console.log("🚀 ~ file: filter.vue ~ line 286 ~ underChilds ~ unders", unders);
    },
    restoreZnum() {
      if (this.fitemsBackup)
        this.fitems.forEach((f) => {
          let backProp = this.fitemsBackup.find((b) => f.id == b.id);
          f.sel.forEach((s) => {
            let bsel = backProp.sel.find((bs) => bs.id == s.id);
            if (bsel) s.znum = bsel.znum;
          });
        });
    },
    actSel() {
      if (this.filter.length == 1) this.checkAll = true;
      let data = [];
      this.fitems.forEach((f) => {
        if (!f.main && f.active) {
          f.sel.forEach((s) => {
            if ((!this.checkAll && s.znum) || this.checkAll || (this.saveLast && this.saveLast.prop.id == f.id) || f.under || f.psel.length) {
              let prep = { pid: f.id, id: s.id };
              if (s.sval) prep.sval = s.sval;
              data.push(prep);
            }
          });
        }
      });
      return data;
    },
    async prepFilData() {
      // this.saveLocal("cacheFilter" + this.filterName, []);
      this.loading = true;
      let p = "ord";
      let fitems = await this.fetchFilData();

      fitems.forEach((p) => p.tp == 4 && (p.sel = [{ id: 1, name: "", znum: 0, sval: { min: 0, max: 999999 }, autoType: null, selected: 0 }]));
      this.fitems = fitems.sort((a, b) => (a[p] < b[p] ? -1 : a[p] > b[p] ? 1 : 0));

      this.fitemsBackup = this.deepClone(
        fitems.map((f) => {
          return { id: f.id, sel: f.sel };
        })
      );

      // console.log(fitems);

      this.fitems.forEach((f) => {
        if (f.sort) f.sel = this.sortArr(f.sel, "name", f.sort);
        if (f.prel) f.prel = f.prel.split(",").map((e) => parseInt(e));
        else f.prel = [];

        //console.log(f.psel.length);
        //console.log(typeof i.psel);
        //if (i.psel) console.log(i.psel);
        //if (i.psel) i.psel = i.psel.split(",").map((e) => parseInt(e));
        // else i.psel = [];
        f.active = f.psel.length || f.under ? 0 : 1;
        //f.active = 1;
        //console.log("f.active", f.active);
        f.sel.forEach((s) => {
          this.$set(s, "selected", null);
        });
      });

      // console.log(this.fitems);

      let underFids = this.fitems.filter((u) => u.under).map((un) => un.under);
      underFids = [...new Set(underFids)];
      this.underProps = this.fitems.filter((f) => underFids.includes(f.fid)).map((m) => m.id);

      let pselSids = this.fitems
        .filter((ps) => ps.psel.length && !ps.under)
        .map((m) => m.psel)
        .flat();
      pselSids = [...new Set(pselSids)];
      this.pselProps = this.fitems.filter((f) => f.sel.find((s) => !f.ref && pselSids.includes(s.id))).map((m) => m.id);

      this.loading = false;
      if (this.$route.query.f) await this.autoSelectFilter();
      if (this.$route.query.s) this.$bus.emit("autoSelectSort");

      //console.log(this.fitems);
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    fitems: {
      get() {
        return this.$store.state.zar.filter.fitems[this.filterName];
      },
      set(arr) {
        this.$store.commit("zar/filter/fitems", { name: this.filterName, arr });
      },
    },
    filter() {
      let fil = [];
      if (this.fitems)
        this.fitems.forEach((i) => {
          i.sel.forEach((s) => {
            if (s.selected) {
              fil.push({ obj: i.obj, prop: i.id, main: i.main, sid: s.id, tp: i.tp, znum: s.znum, ref: i.ref, prel: i.prel, sval: s.sval ? s.sval : null, sname: s.name + " " + i.unit });
            }
          });
        });
      return fil;
    },

    zarTotal() {
      if (!this.filter.length) {
        if (this.obj) return this.obj.znum;
      } else {
        let last = this.filter[this.filter.length - 1];
        let sel = this.fitems.find((i) => i.id == last.prop).sel.find((s) => s.id == last.sid);
        return sel.znum;
      }
      return 0;
    },
  },
  watch: {
    obj: {
      immediate: true,
      async handler() {
        if (!this.$route.query.o) this.resetFilter();
        this.obj ? this.prepFilData() : (this.fitems = []);
      },
    },
    zarTotal() {
      this.$store.commit("zar/total", this.zarTotal);
    },

    filter(fNew, fOld) {
      if (JSON.stringify(fNew) != JSON.stringify(fOld)) {
        this.pselChilds();
        this.underChilds();

        this.$bus.emit("clearSort");

        if (fNew.length) {
          this.checkAll = fNew.length <= fOld.length ? true : false;
          this.updateSelZnum();
        } else {
          //this.resetNewZnum();
          this.restoreZnum();
          this.lastAdd = null;
        }
        this.$emit("filterChanged", this.filter);

        // console.log(this.fitems);
      }
      // console.log(this.fitems);
    },
    pendingFilSel() {
      this.$emit("pendingFilSelChanged", this.pendingFilSel);
    },
  },
  mounted() {
    //document.onreadystatechange = () => {};
    //console.log(this.$refs);
    //this.$smoothReflow();
    setTimeout(() => (this.showPlaceholder = true), 200);
  },
  created() {
    let pendingFil = this.routeQuery;
    if (pendingFil) this.pendingFilSel = pendingFil.split(",").length;
    this.$bus.on("deleteFilter", this.deleteFilter);
    this.$bus.on("resetFilter", this.resetFilter);
  },
  activated() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_filter {
  position: relative;
  user-select: none;
  width: fit-content;
}
.horizontal_items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  /* gap: 18px; */
  /* padding-top: 12px; */
  /* width: 94vw; */
  max-width: 98vw;
  padding: 0px 0 0 5px;
  /* margin-bottom: 5px; */
  /* background-color: #d8eef59d; */
  /* border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc; */
  -webkit-overflow-scrolling: touch;
}

.showAllButton {
  text-align: center;
  margin-right: 15px;
  padding: 5px 10px;
  /* background-color: #f3f3f3; */
  height: 36px;
  border-radius: 8px;
}

.showAll {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 15px;
}

.cont_zar_filter::-webkit-scrollbar {
  display: none;
}
.horizontal_sub {
  flex: 0 0 auto;
}
/* .with_back {
  padding: 7px;
  border-radius: 5px;
  background-color: #f3f3f3;
} */
.cont_child {
  max-width: none;
}
.focused {
  color: black !important;
}
.icon {
  position: relative;
  left: 0px;
  top: 1px;
}
.reset {
  /* margin-top: -10px; */
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  color: #505050;
  position: absolute !important;
  right: 0px;
  top: 3px;
  z-index: 10;
}

.placeholder {
  background-color: #444;
  min-height: 12px;
  border-radius: 3px;
  opacity: 0.1;
  min-width: 10px;
  animation: fading 1.5s infinite;
}
.post {
  width: 100%;
  margin: 10%;
  padding: 10px 20px;
  /* box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15); */
}
.post-image {
  width: 100%;

  height: 400px;
  border-radius: 0;
}
.title {
  width: 50px;
  height: 24px;
}
.text {
  width: 80px;

  height: 14px;
}

@keyframes fading {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}

@media only screen and (max-width: 600px) {
}
</style>
