<template>
  <div class="barter_list">
    <!-- <div v-if="dataSet.model == 99999999" class="title_modal">{{ dataSet.name }} гэсэн зарууд</div> -->
    <!-- <div v-else class="title_modal">{{ dataSet.name }}-аар солих боломжтой машинууд</div> -->
    <zar-list-self v-if="dataSet" :nameSrc="'barter_car'" v-on:clickedItem="clickedItem($event)" miniMode :pageLim="30" :customData="dataSet.model" />
    <!-- <ui-button v-on:clicked="close()" class="pr-3 pl-3 float-r" text="ок" tp="simple" widthSize="wide" /> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarListSelf from "@/comp/zar/listSelf.vue";

export default {
  name: "barter-list",
  components: { zarListSelf },
  props: {
    dataSet: Object,
  },
  data() {
    return {
      items: null,
      pageLimit: 30,
      pageNum: 1,
      totalPage: 0,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    prep(resData) {
      resData.items.forEach((z) => {
        z.pinfo.forEach((i) => {
          if (i.pcomp_tp == 3 && z["p" + i.id]) {
            let photos = z["p" + i.id];
            i.val = photos;
            i.total = photos.length;
          } else {
            i.val = z["p" + i.id + "_val"] ? z["p" + i.id + "_val"] : z["p" + i.id];
            if (i.conver && i.val) i.val = this.converters(i.conver, i.val);
            i.dep = z.dep;
          }
        });
        // z.date = this.$moment.unix(z.rdate, "YYYYMMDD").fromNow(true);
        z.date = this.$date.unix(z.rdate).fromNow(true);
      });
      this.totalPage = resData.totalPage;
      return resData;
    },
    // lessThanPage(bool) {
    //   console.log(bool);

    //   this.showSeeMore = !bool;
    // },
    // seeMore() {
    //   this.$router.push({ name: "userHome", query: { tab: "favs" } });
    //   this.$emit("close");
    // },
    clickedItem() {
      this.close();
    },
    async fetchItems() {
      let res = await this.axiosGet("/module/barter/getBarterCarList", [this.dataSet.model, this.pageLimit, this.pageNum]);
      res.items.forEach((e) => (e.pinfo = res.pinfo));
      this.items = this.prep(res);
      console.log(this.items);
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    // logged() {
    //   return this.$store.state.auth.status.logged;
    // },
    // favs() {
    //   return this.$store.state.user.favs;
    // },
  },
  watch: {
    // dataSet() {
    //   this.pageNum = 1;
    // },
  },
  mounted() {
    // this.fetchItems();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.barter_list {
  /* text-align: left;
  padding: 10px;
  padding-top: 0;
  overflow-y: scroll;
  max-height: 100%;
  position: relative; */
}
</style>
