<template>
  <div class="cont_verify">
    <div class="title_modal">Баталгаажуулалт</div>
    <form name="form" @submit.prevent="submit" @keyup.enter="submit">
      <div class="form-group">
        Та <b class="code">{{ code }}</b> гэсэн кодыг манай <b class="phone">8313-2024</b> дугаарын утасруу<br />
        өөрийн <b>{{ user.phone }}</b> дугаараас илгээж утсаа баталгаажуулна уу. <br />Хугацаа: <b>{{ timerCount }}</b>
        <div class="float-r mt-3 pt-3 pb-2 mb-1"><ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Болих" tp="simple" /></div>
      </div>
    </form>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import UserService from "@/service/user";

export default {
  name: "user-verify",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      timerEnabled: true,
      timerCount: 120,
      // code: Math.floor(1000 + Math.random() * 9000),
      code: null,
      checkInterval: setInterval(() => this.checkVerifySms(), 1000),
    };
  },
  methods: {
    async getCode() {
      return await UserService.getVerifyCode().then((res) => (this.content = res.data));
    },
    passCheck() {
      this.pass = this.user.pass.length > 5 && this.user.pass.length < 50 ? true : false;
    },
    close() {
      clearInterval(this.checkInterval);
      this.$emit("close");
    },
    async checkVerifySms() {
      await this.axios
        .get("/user/account/checkVerifyCode/", {})
        .then(async (res) => {
          if (res.data.success) {
            this.close();
            this.showMessage("success", "Таны бүртгэл баталгаажлаа :)", 3000);
            this.$store.dispatch("user/updateUserInfo");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    logged() {
      return this.$store.state.auth.status.logged;
    },
  },
  async mounted() {
    let res = await this.getCode();
    if (!res) this.close();
    this.code = res;
    this.checkInterval;
    this.timerCount = 120;
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_verify {
  text-align: left;
  padding: 10px;
  padding-top: 0;
}
.cont a {
  color: #0051ff !important;
}
.form-group {
  margin-bottom: 10px;
  line-height: 160%;
  padding-left: 30px;
  font-size: 16px;
}
.code {
  font-size: 40px;
}
.phone {
  font-size: 20px;
}
.eye {
  position: relative;
  top: 3px;
}
.eye:hover {
  cursor: pointer;
}
.form-group b {
  font-weight: 450;
}
</style>
