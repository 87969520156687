<template>
  <div class="cont_feedback">
    <div class="title_modal"></div>
    <!-- <textarea v-model="text" ref="feedback"></textarea> -->
    Та нууц үгээ санахгүй байгаа бол сүүлийн оруулсан нууц үгээр шинэчилэх үү?
    <div class="mt-3">
      <ui-button v-on:clicked="submit()" class="pr-3 pl-3" text="Шинэчилье" widthSize="wide" />
      <ui-button v-on:clicked="cancel()" class="pr-3 pl-3" text="Болих" tp="textOnly" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "user-ask-restore-pass",
  components: {},
  props: { dataSet: Object },
  data() {
    return {
      text: null,
      shake: false,
    };
  },
  methods: {
    submit() {
      this.$bus.emit("openModal", { name: "restorePass", dataSet: this.dataSet });

      // if (!this.text) {
      //   this.shake = true;
      //   setTimeout(() => (this.shake = false), 600);
      //   this.showMessage("warning", "Хоосон");
      //   return;
      // }
      // this.close();
      // if (!this.text) return;
      // this.axiosPost("/utility/feedback/add", { body: this.text, dev_id: this.getLocal("devid", true), dev_tp: this.isMobile ? "mobile" : "pc", logged: this.logged });
      // this.close();
      // this.showMessage("info", "Илгээгдлээ");
    },
    cancel() {
      this.$emit("close");
      this.$bus.emit("openModal", { name: "userLogin" });
    },
  },
  computed: {
    logged() {
      return this.$store.state.auth.status.logged;
    },
  },

  mounted() {
    // setTimeout(() => this.$refs.feedback.focus(), 100);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_feedback {
  padding: 0 10px 10px 10px;
  text-align: center;
}
img {
  width: 100%;
  height: 150px;
}
textarea {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
}
</style>
