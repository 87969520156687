<template>
  <div class="cont_ui_select_multi">
    <ui-reflow>
      <span v-if="collect.length">
        <span @click="del(c.id)" class="selected" v-for="(c, k) in collect" :key="'sel1' + k">{{ c.name }} {{ unit }}</span>
        <ui-icon v-if="collect" @click="delAll()" class="reset clickable ml-2" name="i-delete" size="xl" />
        <ui-icon v-if="!selection" @click="selection = !selection" class="reset clickable ml-2" name="i-add3" size="xl" />
      </span>
      <span v-if="guide">{{ guide }}</span>
    </ui-reflow>
    <div v-if="selection">
      <hr v-if="collect && collect.length" />
      <div v-for="(s, k) in items" @click="clickedItem(s)" :key="'sel2' + k" style="display:inline-block">
        <span v-if="!s.sel" class="selection" :class="{ common: s.com }">
          <img v-if="s.icon" :src="'../assets/icon/' + s.icon + '.svg'" height="14" />
          {{ s.name }} {{ unit }}
        </span>
      </div>
      <!-- <ui-icon @click="selection = false" class="reset clickable hoverable" name="i-close3" size="xl" /> -->
    </div>
    <div class="guide mt-3">Олон сонголт хийх боломжтой</div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "ui-select-multi",
  props: {
    items: Array,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    guide: String,
    max: { type: Number, default: () => 999 },
    unit: String,
  },
  data() {
    return {
      selected: [],
      selection: false,
    };
  },
  methods: {
    clickedItem(ob) {
      if (this.selected.find((e) => e.id == ob.id)) return;
      if (this.selected.length >= this.max) {
        this.showMessage("warning", "Сонголтын дээд хязгаар " + this.max);
        return;
      }

      this.findItem(ob.id).sel = 1;
      this.selected.push(this.findItem(ob.id));

      this.$emit("clickedItem", this.selected);
    },
    findItem(id) {
      return this.items.find((e) => e.id == id);
    },
    delAll() {
      this.selected = [];
      this.items.forEach((e) => {
        e.sel = 0;
      });
      this.$emit("clickedItem", 0);
    },
    del(id) {
      this.findItem(id).sel = 0;
      this.selected = this.selected.filter(function(el) {
        return el.id != id;
      });
      this.$emit("clickedItem", this.selected);
    },
  },
  computed: {},
  watch: {
    items() {
      this.selected = [];
      this.selection = true;
    },
    collect: {
      immediate: true,
      handler() {
        if (!this.collect || !this.collect.length) {
          this.delAll();
          this.selection = true;
        } else {
          // this.selection = false;
        }
      },
    },
  },
  mounted() {
    this.selection = true;

    // if (this.collect) {
    //   this.selected = this.collect;
    //   this.selection = false;
    // }
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_select_multi {
  padding: 0 0 10px 0;
}
.prop_name {
  color: #000;
}
.common {
  font-weight: 600;
}
hr {
  display: block;
  height: 1px;
  border-top: 1px solid #ccc;
  margin: 4px 0 6px 0;
  padding: 0px 0 0px 0;
  width: 6%;
}
.selected {
  display: inline-block;
  padding: 5px 10px 4px 10px;
  cursor: pointer;
  margin: 0px 4px 5px 0;
  line-height: 1;
  border: solid 1px #859b9398;
  transition: all 0.1s;
  border-radius: 15px;
  user-select: none;
  position: relative;
  font-size: 14px;
  background: #ceffee80;
  font-weight: 500;
}
.selected:focus {
  border: solid 1px #000;
}
.selected:hover {
  cursor: pointer;
  border: solid 1px #8f8f8f;
}
.selected:active {
  transform: scale(0.98);
}

.selection {
  display: inline-block;
  border: solid 1px #d3d3d3;
  padding: 8px 12px 7px 12px;
  border-radius: 15px;
  cursor: pointer;
  margin: 2px 4px 2px 0;
  user-select: none;
  font-size: 13px;
  line-height: 1;
  font-weight: var(--base_weight);
}
.selection:hover {
  background-color: #f1f1f1;
}
.seperator {
  color: #ccc;
}
.blink_me {
  position: absolute;
  margin-left: 115px;
  margin-top: -18px;
}
button {
  margin-bottom: 5px;
}
.empty {
  background-color: rgb(255, 253, 230);
}

.reset {
  position: relative;
  top: 4px;
}
</style>
