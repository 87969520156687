<template>
  <div class="cont_login" :style="{ opacity: loading ? '0.5' : '1' }" :class="{ shake }">
    <div class="title_modal">Нууц үг шинэчлэх</div>
    <form v-if="!submitted" name="form" class="form" @submit.prevent="submit" @keyup.enter="onEnter()">
      <div>
        <label for="phone" class="mb-1">Бүртгэлтэй утас</label>
        <input ref="phone" v-model="user.phone" v-mask="{ regex, autoUnmask: true, greedy: false }" type="text" class="form-control" name="phone" />
      </div>
      <div>
        <label for="pass" class="mb-1">Шинэ нууц үг <img v-if="user.pass.length" class="eye" @click="showPass = !showPass" src="/icon/i-eye.svg" width="12"/></label>
        <input ref="pass" v-model="user.pass" :type="showPass ? 'text' : 'password'" class="form-control" name="pass" autocomplete="on" />
      </div>
      <div class="pt-1"><ui-button v-on:clicked="submit" :disabled="!isComplete" text="Шинэчлэх" widthSize="wide" /> <ui-button v-on:clicked="close()" tp="textOnly" text="Болих" /></div>
    </form>
    <div v-if="showVerify">
      Таны утас руу илгээсэн кодыг оруулна уу.
      <div class="cont_code_input">
        <input v-model="veriCode" ref="codeInput" v-mask="'9999'" type="text" class="codeInput" name="codeInput" @keyup="codeCheck" :disabled="codeDisabled" />
      </div>

      <div>
        Хугацаа: <b>{{ timerCount }}</b>
      </div>
      <div class="mt-3 pt-3 pb-2 mb-1"><ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Болих" tp="simple" /></div>
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import User from "@/model/user";

export default {
  name: "user-restore-pass",
  components: {},
  props: { dataSet: Object },
  data() {
    return {
      user: new User("", ""),
      // validPhone: false,
      // validPass: false,
      showPass: false,
      shake: false,
      regex: "[789]\\d{3}\\-\\d{4}",
      // regexCode: "d{4}",
      isComplete: false,
      loading: false,
      submitted: false,
      showVerify: false,
      timerEnabled: false,
      timerCount: 120,
      checkInterval: null,
      verifyId: null,
      veriCode: null,
      codeDisabled: false,
    };
  },
  methods: {
    codeCheck(field) {
      if (field.target.inputmask.isComplete()) {
        this.codeDisabled = true;
        this.showMessage("info", "Шалгаж байна", 1000);

        this.checkVerifyCode();
      }
    },
    async checkVerifyCode() {
      this.timerCount--;
      await this.axios
        .get("/user/account/checkVerifyRestorePass/" + this.verifyId + "/" + this.veriCode, {})
        .then(async (res) => {
          if (res.data.success) {
            this.showMessage("success", "Таны нууц үг шинэчлэгдлээ", 3000);
            if (!this.logged) this.$bus.emit("openModal", { name: "userLogin", dataSet: { phone: this.user.phone } });
            this.close();
          } else {
            this.showMessage("warning", "Таны оруулсан код таарахгүй байна", 3000);
            this.codeDisabled = false;
            this.shake = true;
            this.veriCode = null;
            setTimeout(() => this.$refs.codeInput.focus(), 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onEnter() {
      if (this.isComplete) this.submit();
    },
    close() {
      clearInterval(this.checkInterval);
      this.$emit("close");
    },
    reset() {
      this.user = new User("", "");
      this.showPass = false;
    },
    async submit() {
      if (this.user.phone && this.user.pass) {
        this.loading = true;
        this.submitted = true;
        let res = await this.axiosPost("/user/account/restorePass/", { phone: this.user.phone, pass: this.user.pass, device: this.isMobile ? "phone" : "pc" });
        if (res.success) {
          this.loading = false;
          this.showVerify = true;
          this.verifyId = res.id;
          // this.checkInterval = setInterval(() => this.checkVerifyCode(), 1000);
          // this.close();
          // await this.$store.dispatch("user/updateUserInfo");
          // this.$router.push(returnPage ?? "/user/home");
        } else {
          this.loading = false;
          this.submitted = false;
          this.$message({
            type: "warning",
            message: res.message,
            showClose: true,
            hasMask: false,
            duration: 3000,
          });
          this.shake = true;
          setTimeout(() => this.$refs.phone.focus(), 100);
        }
      }
    },
  },
  watch: {
    // timerEnabled(value) {
    //   if (value) {
    //     setTimeout(() => {
    //       this.timerCount--;
    //     }, 1000);
    //   }
    // },
    timerCount() {
      if (this.timerCount == 0 && this.checkInterval) this.close();
    },
    shake() {
      if (this.shake) setTimeout(() => (this.shake = false), 1000);
    },
    regexCheck() {
      if (this.regexCheck.length && this.user.pass.length == 1 && !this.isMobile)
        this.regexCheck.forEach((text) => {
          this.$message({
            type: "warning",
            message: text,
            showClose: true,
            position: "top-center",
            hasMask: false,
            duration: 2500,
          });
        });
    },
    showVerify() {
      if (this.showVerify) {
        setTimeout(() => this.$refs.codeInput.focus(), 100);
        this.checkInterval = setInterval(() => this.timerCount--, 1000);
      }
    },
    // veriCode() {
    //   console.log(this.veriCode.toString().length);

    //   if (this.veriCode.toString().length == 4) console.log("verified");
    // },
    user: {
      immediate: true,
      deep: true,
      handler() {
        let validPhone = this.user.phone.length == 8 ? true : false;
        if (validPhone) this.$refs.pass.focus();
        let validPass = this.user.pass.length > 5 && this.user.pass.length < 50 ? true : false;
        this.isComplete = validPhone && validPass ? true : false;
      },
    },
  },
  computed: {
    // validPhone() {
    //   console.log("valid", valid);

    //   // let valid = this.$refs.phone.target.inputmask.isComplete() && this.user.phone.length == 8 ? true : false;
    //   let valid = this.user.phone.length == 8 ? true : false;

    //   if (valid) this.$refs.pass.focus();
    //   return valid;
    // },
    // validPass() {
    //   return this.user.pass.length > 5 && this.user.pass.length < 50 ? true : false;
    // },
    // isComplete() {
    //   return this.validPass && this.validPhone ? true : false;
    // },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    regexCheck() {
      let warning = [];
      if (this.user.pass.match("[а-я|ө|үА-Я|Ө|Ү]")) warning.push("Та КРИЛЛЭЭР бичиж байна шүү");
      if (this.user.pass.match("[A-Z]")) warning.push("Та ТОМООР бичиж байна шүү");
      return warning;
    },
  },
  mounted() {
    if (this.dataSet && this.dataSet.phone) this.user.phone = this.dataSet.phone;
    if (this.dataSet && this.dataSet.pass) this.user.pass = this.dataSet.pass;
    // this.user.pass = this.dataSet.pass;
    if (this.user.phone && this.user.pass) this.submit();
    if (!this.user.phone) setTimeout(() => this.$refs.phone.focus(), 100);
    else setTimeout(() => this.$refs.pass.focus(), 100);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_login {
  padding: 10px;
  padding-top: 0;
  text-align: center;
  transition: all 0.3s;
}

.form > div {
  margin-bottom: 10px;
}
.form div:nth-child(3) {
  margin-bottom: 5px;
}
.eye {
  position: relative;
  top: 3px;
}
.eye:hover {
  cursor: pointer;
}

.cont_code_input {
  display: block;
  margin: 15px 0;
}

.codeInput {
  width: 80px;
  margin-right: 10px;
  font-size: 24px;
  padding: 5px 10px;
}
label {
  display: block;
  margin-bottom: 2px;
  text-align: left;
  padding-left: 25px;
}
</style>
