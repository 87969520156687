<template>
  <div>
    <div v-for="(c, k) in configs" :key="'srv' + k" :class="['cont_' + k]">
      <div class="body" v-html="c" v-if="tp.includes(k)"></div>
      <!-- <div class="mt-3 ta-c">
      <ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Хаах" />
    </div> -->
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "main-server-message",
  components: {},
  props: {},
  data() {
    return {
      text: null,
      shake: false,
      tp: ["blue", "yellow", "green"],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    configs() {
      return this.$store.state.configs;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_yellow {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgb(255, 255, 167) 51%, rgba(255, 255, 255, 1) 100%);
}
.cont_green {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgb(188, 255, 167) 51%, rgba(255, 255, 255, 1) 100%);
}
.cont_blue {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgb(167, 234, 255) 51%, rgba(255, 255, 255, 1) 100%);
}
</style>
