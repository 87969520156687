<template>
  <ui-input v-on:typed="$emit('clickedItem', $event)" tp="password" :collect="collect" :min="min" :max="max" :guide="guide" />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiInput from "@/comp/ui/input.vue";
export default {
  name: "zar-add-input-pass",
  props: {
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: {
      type: Object,
      required: true,
    },
  },
  components: { uiInput },
  data() {
    return {
      guide: this.pob.guide ? this.pob.guide : "",
      min: this.pob.mask && this.pob.mask.min ? this.pob.mask.min : 6,
      max: this.pob.mask && this.pob.mask.max ? this.pob.mask.max : 50,
    };
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
