<template>
  <div class="cont_noti">
    <div class="title_modal">Мэдэгдэл</div>
    <ui-icon class="close" name="i-close3" size="xxl" @click="close()" />
    <div v-for="(i, k) in items" :key="'noti' + k" class="item" :class="{ new_item: !i.seen }" @click="clickedItem(i)">
      <img v-if="i.image" :src="apiUrl + i.image" />
      <div v-html="i.text"></div>
      <div class="count">{{ i.date }}</div>
      <div class="hori" v-if="k != 0"></div>
    </div>
    <div v-if="!items.length" class="disabled ta-c">Хоосон...</div>
    <div class="ta-c mt-3"><ui-button v-on:clicked="close()" class="pr-3 pl-3" text="Хаах" /></div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "user-noti",
  components: {},
  props: {
    data: Number,
  },
  data() {
    return {};
  },
  methods: {
    clickedItem(item) {
      if (item.link) {
        let { name, query } = JSON.parse(item.link);
        this.$router.push({ name, query });
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    async fetchSeen() {
      let res = await this.axios.post(
        "/user/noti/seen/",
        this.items.map((m) => m.id),
        {}
      );
      return res.data;
    },
  },
  computed: {
    logged() {
      return this.$store.state.auth.status.logged;
    },
    items() {
      let items = this.$store.state.user.noti;
      items.forEach((i) => {
        i.date = this.$date.unix(i.cdate).fromNow(true);
      });
      return items;
    },
  },
  mounted() {
    this.fetchSeen();
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_noti {
  text-align: left;
  padding: 10px;
  padding-top: 0;
  overflow-y: scroll;
  max-height: 100%;
  position: relative;
}

.item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  gap: 7px;
  position: relative;
}
.item .count {
  font-size: 12px;
  opacity: 0.5;
}

.item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.item img {
  max-height: 60px;
  max-width: 60px;
}

.new_item {
  background-color: #d3fff9;
}

.cont_noti .close {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 1000;
  opacity: 0.2;
}

.hori {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 70%;
  top: 0px;
  height: 1px;
  /* background-color: var(--color_sep); */
  border-bottom: dashed 1px #ccc;
  z-index: 10;
}

.cont_noti .close:hover {
  cursor: pointer;
  opacity: 1;
}
</style>
