<template>
  <div class="cont_zar_main" @mouseover="mOver = true" @mouseout="mOver = false">
    <!-- <div class="blink_me" v-if="!items.cat.length">.</div> -->
    <div class="cont_cat">
      <template v-for="(c, ind) in items">
        <div class="dep" :class="{ ani_pop: isMobile }" :key="'cat' + ind">
          <div class="cat clickable" @click="clickedItem(c)" :class="{ selected: selCat == c.id }">
            <img class="cat_img" v-if="c.icon" :src="'/icon/' + c.icon + '.svg'" height="48" />
            <span class="cat_name">
              {{ c.name }}
              <span v-if="c.znum" class="fs-10 mt-1 fw-350">
                <div>({{ c.znum }})</div>
              </span>
            </span>
          </div>
          <div class="otems">
            <template v-for="(o, ond) in getObjects(c.id)">
              <a v-if="ond < 3" :href="baseUrl + '/?o=' + o.id" v-on:click.prevent="clickedOb(o)" :key="'obs' + ond" class="otem">
                {{ o.name }}
              </a>
            </template>
            ...
          </div>
        </div>
      </template>
    </div>
    <zar-cat v-if="scr < 3" v-show="false" withVirtualCat autoSelFirst />
    <!-- <zar-cat v-if="scr < 3" v-show="false" withVirtualCat autoSelFirst /> -->
    <!-- <ch-drawer :visible.sync="showSubDrawer" v-on:close="showSubDrawer = false" area="500px" :title="titleSubDrawer" :blur="false" location="left" destroy-on-close>
      <div v-if="scr == 1" class="return_button_right" @click="showSubDrawer = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      <div class="return_button_top" @click="showSubDrawer = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarCat from "./zarCat.vue";

export default {
  name: "zar-cat-main",
  components: {
    zarCat,
  },
  props: {
    catData: Object,
  },
  data() {
    return {
      loading: false,
      mOver: false,
      selCat: null,
    };
  },
  methods: {
    getObjects(dep) {
      let obs = this.getCatObject(dep);
      obs = obs
        .filter((e) => {
          return this.catData.obj.find((o) => o.id == e && o.znum);
        })
        .map((e) => {
          return this.catData.obj.find((o) => o.id == e);
        });
      return this.sortArr(obs, "znum", 2);
    },
    getCatObject(cat) {
      let foundObs = this.catData.objCat.filter((e) => e.cat == cat).map((e) => e.obj);
      let obs = foundObs ? foundObs : [];

      let foundCats = this.catData.cat.filter((e) => e.par == cat).map((e) => e.id);
      if (foundCats) {
        foundCats.forEach((e) => {
          obs = obs.concat(this.getCatObject(e));
        });
      }
      return obs;
    },
    clickedItem(ob) {
      console.log(ob);

      this.$bus.emit("mainCatSelected", ob);
    },
    clickedOb(ob) {
      this.$bus.emit("forceSelObj", ob.id);
    },
  },

  computed: {
    items() {
      return this.catData ? this.catData.cat.filter((e) => !e.par && !e.virt) : [];
    },
    selObj: {
      get() {
        return this.$store.state.selObj;
      },
      set(ob) {
        this.$store.commit("selObj", ob);
      },
    },
    scr() {
      return this.$store.getters.scr;
    },
  },

  watch: {
    selObj() {
      if (this.selObj) this.$emit("close");
    },
  },
  async activated() {},
  async mounted() {
    setTimeout(() => {
      if (!this.selObj) this.clickedItem({ icon: "i-auto2", id: 2, name: "Авто" });
    }, 100);

    // this.$bus.emit("mainCatSelected", { id: 2 });
    // this.catData = Object.freeze(this.getLocal("catDataHome"));
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_main {
  padding: 30px 20px;
  text-align: center;
}
.cont_cat {
  /* display: grid;
  gap: 10px; */
}
.cont_cat a {
  background: none !important;
}
.dep {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding: 3px;
}
.cat {
  display: inline-block;
  width: 100px;
  text-align: center;
  /* margin: 0 10px 0px 0; */
  padding: 5px 0px;
  transition: all 100ms;
  vertical-align: middle;
  border-radius: 5px;
  /* border: solid 1px #dbdbdb; */
}
.cat_name {
  font-size: 12px;
  line-height: 1em;
  display: block;
  font-weight: 700;
  font-stretch: 100%;
  letter-spacing: 0.05em;
  /* color: #000; */
  text-transform: uppercase;
}
.otems {
  font-size: 12px;
  /* display: none; */
}
.otem {
  display: block;
}

.cat_img {
  filter: invert(22%) sepia(14%) saturate(979%) hue-rotate(177deg) brightness(94%) contrast(88%);
}

@media only screen and (min-width: 701px) {
  .otem:hover {
    background-color: #f1f1f1 !important;
  }
}
@media only screen and (max-width: 700px) {
  .cat {
    background-color: #f1f1f1;
    transform: scale(1);
    transition: all 200ms;
  }
  .cat:active {
    transform: scale(1.1);
  }
}
</style>
