<template>
  <ui-select-multi-value
    :loading="loading"
    :collect="collect"
    :zind="zind"
    :unit="pob.unit"
    :forceSel="forceSel"
    :min="pob.opt.range.min"
    :max="pob.opt.range.max"
    :maxSel="pob.opt.range.maxSel"
    :step="pob.opt.range.step"
    :cusVals="pob.opt.range.cusVals"
    :com="pob.opt.range.com"
    :sort="pob.sort"
    v-on:clickedItem="clickedItem($event)"
    v-on:mouseOver="mouseOver($event)"
    v-on:mouseLeave="mouseLeave(pob.ref)"
    v-on:mouseEnter="mouseEnter(pob.ref)"
    v-on:selection="selection($event)"
    v-on:hideProp="hideProp"
  />
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiSelectMultiValue from "@/comp/ui/selectMultiValue.vue";
export default {
  name: "zar-add-select-multi-value",
  props: {
    obj: Object,
    forceSel: Number,
    loading: Boolean,
    collect: { type: [String, Object, Boolean, Number, Array] },
    pob: Object,
    zind: Number,
  },
  components: { uiSelectMultiValue },
  data() {
    return { imageHover: null };
  },
  methods: {
    mouseLeave(ref) {
      this.$emit("mouseLeave", ref);
    },
    mouseOver(sel) {
      this.$emit("mouseOver", sel.id);
    },
    mouseEnter(ref) {
      this.$emit("mouseEnter", ref);
    },
    clickedItem(obj) {
      this.$emit("clickedItem", obj);
    },
    selection(bo) {
      this.$emit("selection", bo);
    },
    hideProp() {
      this.pob.active = 0;
    },
  },
  computed: {},
  watch: {},
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
