<template>
  <div class="cont_home_zar_map mb-2" v-if="forceSel">
    <ui-loc-grab-detect disableDrag :centerMarker="false" :forceSel="forceSel" :pins="[]" :mapHeight="500" :inputLocCode="false" class="map" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiLocGrabDetect from "@/comp/ui/locGrabDetect.vue";
import { mapState } from "vuex";

//import rPip from "robust-point-in-polygon";

export default {
  name: "home-zar-map",
  components: {
    uiLocGrabDetect,
  },
  props: {},
  data() {
    return {
      showMap: false,
    };
  },
  methods: {
    showGeoJson(ob) {
      if (ob.pob.ref && ob.item) this.forceSel = { ref: ob.pob.ref, sel: ob.item, action: "hover" };
    },
    updatedMapLoc() {
      // console.log(ob);
    },
  },
  watch: {
    // showMap() {
    //   this.saveLocal("user_settings_show_map", this.showMap);
    // },
  },
  mounted() {
    // this.showMap = this.hasLocal("user_settings_show_map") ? this.getLocal("user_settings_show_map") : true;
    // this.$bus.on("mouseOverFilterItem", this.showGeoJson);
  },
  computed: {
    fitems() {
      return this.$store.state.zar.filter.fitems.home;
    },
    pins() {
      let coord = [];
      let found = this.filter.find((e) => e.ref && e.ref == "ref_location_town");
      if (found) {
        let foundFitem = this.fitems.find((f) => f.obj == found.obj && f.id == found.prop && f.sel.find((s) => s.id == found.sid));
        if (foundFitem) coord = JSON.parse(foundFitem.sel.find((s) => s.id == found.sid).coord);
      }
      return coord.length ? [{ coord: { lat: coord[0], lng: coord[1] } }] : [];
    },
    forceSel() {
      let found = this.filter.find((e) => e.ref && ["ref_location_section", "ref_location_area"].includes(e.ref));
      return found ? { ref: found.ref, sel: { id: found.sid } } : null;
    },
    ...mapState({
      obj: (s) => s.selObj,
      filter: (s) => s.zar.filter.filter.home,
    }),
    scr() {
      return this.$store.getters.scr;
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_home_zar_map {
  position: relative;
  /* height: 220px !important; */
  margin-top: 0px;
  z-index: 20;
}

.toggler {
  z-index: 2;
  position: absolute;
}
</style>
