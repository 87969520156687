<template>
  <div class="cont_login" :style="{ opacity: loading ? '0.5' : '1' }" :class="{ shake }">
    <div class="title_modal">Нэвтрэх</div>
    <form name="form" class="form" @submit.prevent="submit" @keyup.enter="onEnter()">
      <div>
        <label for="phone">Утас </label>
        <input ref="phone" v-model="user.phone" v-mask="{ regex, autoUnmask: true, greedy: false }" class="form-control" name="phone" />
      </div>
      <div>
        <label for="pass">Нууц үг <img v-if="user.pass.length" class="eye" @click="showPass = !showPass" src="/icon/i-eye.svg" width="12"/></label>
        <input ref="pass" v-model="user.pass" :type="showPass ? 'text' : 'password'" class="form-control" name="pass" autocomplete="on" />
        <div class="link mt-1 fs-12" @click="restorePass()">Нууц үгээ мартсан</div>
      </div>
      <div class="pt-1"><ui-button v-on:clicked="submit" :disabled="!isComplete" text="Нэвтрэх" widthSize="wide" /> <ui-button v-on:clicked="close()" tp="textOnly" text="Болих" /></div>
      <div class="mt-3"><ui-button v-on:clicked="showReg()" tp="textOnly" text="Бүртгүүлэх" /></div>
    </form>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import User from "@/model/user";

export default {
  name: "user-login",
  components: {},
  props: { dataSet: [Object, Number] },
  data() {
    return {
      user: new User("", ""),
      // validPhone: false,
      // validPass: false,
      showPass: false,
      shake: false,
      regex: "[789]\\d{3}\\-\\d{4}",
      isComplete: false,
      loading: false,
      returnPage: null,
      failedAttemp: 0,
    };
  },
  methods: {
    restorePass() {
      this.$bus.emit("openModal", { name: "restorePass", dataSet: { phone: this.user.phone ?? null } });
      this.close();
    },
    onEnter() {
      if (this.isComplete) this.submit();
    },
    showReg() {
      this.close();
      // setTimeout(() => this.$bus.emit("openModal", { name: "register" }), 200);
      this.$bus.emit("openModal", { name: "userRegister" });
    },

    close() {
      this.$emit("close");
    },
    reset() {
      this.user = new User("", "");
      this.showPass = false;
    },
    async submit() {
      if (!this.user.phone || !this.user.pass) return;

      this.loading = true;
      await this.$store.dispatch("auth/login", this.user).then(
        async () => {
          this.loading = false;
          this.close();
          if (!this.returnPage) this.$router.push("/user/home");
          else if (this.returnPage != "noRedirect") this.$router.push(this.returnPage);
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.showMessage("warning", error, 5000);
          this.shake = true;
          this.failedAttemp++;
        }
      );
    },
  },
  watch: {
    failedAttemp() {
      if (this.failedAttemp > 2) {
        setTimeout(() => {
          this.close();
          this.$bus.emit("openModal", { name: "userAskRestorePass", dataSet: { phone: this.user.phone, pass: this.user.pass } });

          // this.$modal.show("dialog", {
          //   clickToClose: false,
          //   text: "Та нууц үгээ саяны оруулсан нууц үгээр шинэчилэх үү?",
          //   buttons: [
          //     {
          //       title: "Шинэчилье",
          //       handler: () => {
          //         this.$modal.hide("dialog");
          //         this.$bus.emit("openModal", { name: "restorePass", dataSet: { phone: this.user.phone, pass: this.user.pass } });
          //       },
          //     },
          //     {
          //       title: "Болих",
          //       handler: () => {
          //         this.$modal.hide("dialog");
          //         this.$bus.emit("openModal", { name: "userLogin" });
          //       },
          //     },
          //   ],
          // });
        }, 1000);
      }
    },
    shake() {
      if (this.shake) setTimeout(() => (this.shake = false), 1000);
    },
    regexCheck() {
      if (this.regexCheck.length && this.user.pass.length == 1 && !this.isMobile)
        this.regexCheck.forEach((text) => {
          this.$message({
            type: "warning",
            message: text,
            showClose: true,
            position: "top-center",
            hasMask: false,
            duration: 2500,
          });
        });
    },
    user: {
      immediate: true,
      deep: true,
      handler() {
        let validPhone = this.user.phone.length == 8 ? true : false;
        if (validPhone) this.$refs.pass.focus();
        let validPass = this.user.pass.length > 5 && this.user.pass.length < 50 ? true : false;
        this.isComplete = validPhone && validPass ? true : false;
      },
    },
  },
  computed: {
    // validPhone() {
    //   console.log("valid", valid);

    //   // let valid = this.$refs.phone.target.inputmask.isComplete() && this.user.phone.length == 8 ? true : false;
    //   let valid = this.user.phone.length == 8 ? true : false;

    //   if (valid) this.$refs.pass.focus();
    //   return valid;
    // },
    // validPass() {
    //   return this.user.pass.length > 5 && this.user.pass.length < 50 ? true : false;
    // },
    // isComplete() {
    //   return this.validPass && this.validPhone ? true : false;
    // },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    regexCheck() {
      let warning = [];
      if (this.user.pass.match("[а-я|ө|үА-Я|Ө|Ү]")) warning.push("Та КРИЛЛЭЭР бичиж байна шүү");
      if (this.user.pass.match("[A-Z]")) warning.push("Та ТОМООР бичиж байна шүү");
      return warning;
    },
  },
  mounted() {
    if (this.dataSet) {
      if (this.dataSet.phone) this.user.phone = this.dataSet.phone;
      if (this.dataSet.pass) this.user.pass = this.dataSet.pass;
      if (this.dataSet.returnPage) this.returnPage = this.dataSet.returnPage;
      if (this.user.phone && this.user.pass) this.submit();
    }
    if (!this.user.phone) setTimeout(() => this.$refs.phone.focus(), 100);
    else setTimeout(() => this.$refs.pass.focus(), 100);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_login {
  padding: 10px;
  padding-top: 0;
  text-align: center;
  transition: all 0.3s;
}

.form > div {
  margin-bottom: 10px;
}
.form div:nth-child(3) {
  margin-bottom: 5px;
}
.eye {
  position: relative;
  top: 3px;
}
.eye:hover {
  cursor: pointer;
}
label {
  display: block;
  margin-bottom: 2px;
  text-align: left;
  padding-left: 20px;
}
</style>
