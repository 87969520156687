<template>
  <div class="cont_ui_map">
    <l-map class="map" :zoom="iniZoom" :center="geoJson ? geoJson.center : iniCenter" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">
      <l-tile-layer :url="enSatView ? urlSat : url"></l-tile-layer>
      <l-control-fullscreen v-if="controlFullscreen" position="topleft" :options="{ title: { false: 'Томруулах', true: 'Жижигсгэх' } }" />
      <l-geo-json v-if="geoJson" :geojson="geoJson"></l-geo-json>
    </l-map>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
//import rPip from "robust-point-in-polygon";

export default {
  name: "ui-map",
  components: {
    LMap,
    LTileLayer,
    LControlFullscreen,
    LGeoJson,
  },
  props: {
    geoJson: Object,
    iniZoom: Number,
    iniCenter: Array,
    ///opt: { type: Object, zoom: 8, center: [47.8864, 106.9057] },
    //zoom: Number,
    //center: Array,
    controlFullscreen: Boolean,
    enSatView: {
      type: Boolean,
      default: () => false,
    },
    /*
    zoom: {
      type: Number,
      default: () => 1,
    },*/
  },
  data() {
    return {
      //zoom: 5,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      urlSat: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
      //center: [47.8864, 106.9057],
      bounds: null,
    };
  },
  methods: {
    zoomUpdated(zoom) {
      //this.zoom = zoom;
      this.$emit("zoomUpdated", zoom);
    },
    centerUpdated(center) {
      //this.center = center;
      this.$emit("clickedItem", JSON.stringify(center));
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
  },
  watch: {},
  mounted() {
    /*
    let rPolygon = [
      [1, 1],
      [1, 2],
      [2, 2],
      [2, 1],
    ];
    let rPoint = [100000, 10000];
    console.log(rPip(rPolygon, rPoint));*/
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_map {
  position: relative;
  height: 250px;
  padding: 0 0 10px 0;
}

.map {
  z-index: 1;
  margin-left: 120px;
}
</style>
